import { useRedeemedPackagesSales } from 'api/useReports';
import { cleanUpText, formatDateToOriginalDate, formatInToPrice, getNumberMonthAndYearFromDate, getTodayQueryDates, limitString } from 'components/utils/misc';
import { COLORS } from 'constants/colors'
import { PackagesSales } from 'core/generated';
import { useModal, useSalonCache } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react'
import { Button, CalendarPicker, Modal, Paginator, Paragraph, SearchTerm, SvgArrowBack, SvgChevronLeft, SvgExpandCircle, Table, TableFilterProps } from 'ui'
type PaymentCollectedProps = {
  headers: {
    label: string;
    value: string;
    show: boolean;
  }[];
  data: any;
}
const VOUCHER_HEADERS = [
  {
    label: 'Code',
    value: 'code',
    show: true
  },
  {
    label: 'Service',
    value: 'service',
    show: true
  },
  {
    label: 'Amount',
    value: 'amount',
    show: true
  },
  {
    label: 'Status',
    value: 'status',
    show: true
  }
]
const DAILY_SALES_VOUCHER_HEADERS = [
  {
    label: 'Code',
    value: 'code',
    show: true
  },
  {
    label: 'Amount',
    value: 'amount',
    show: true
  },
  {
    label: 'Status',
    value: 'status',
    show: true
  }
]
const VOUCHER_HEADERS_MOBILE = [
  {
    title: 'Code',
    key: 'code',
  },
  {
    title: 'Amount',
    key: 'amount',
  },
  { 
    title: 'Status',
    key: 'status',
  }
]

const DailySalesPaymentCollected = ({
  headers,
  data
}: PaymentCollectedProps) => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id');
  const [isOpen, setIsOpen] = useState(false)
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getTodayQueryDates()
  )
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [before, setBefore] = useState<string | null>(null);
  const [after, setAfter] = useState<string | null>(null);
  const {
    isVisible: isVoucherListModalVisible,
    openModal: openVoucherListModal,
    closeModal: closeVoucherListModal
  } = useModal()

  const {
    data: packagesSales,
    loading: packagesSalesLoading,
    refetch: packagesSalesRefetch,
  } = useRedeemedPackagesSales({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
    before,
    after,
    q: debouncedSearchQuery
  })
  const redeemedPackages = useMemo(() => {
    if (packagesSales && packagesSales?.redeemedPackagesReport) {
      return packagesSales?.redeemedPackagesReport
    }
  }, [packagesSales])

  useEffect(() => {
    if (selectedDates) {
      setBefore(null)
      setAfter(null)
      packagesSalesRefetch()
    }
  }, [selectedDates, salonId])

  const generateSalonPackageSalesTableData = (
    data?: PackagesSales[],
    tableHeadings?: TableFilterProps,
  ) => {
    if (!data || data.length === 0) return []

    return data?.map((x) => {
      const rowData = {}
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'code':
              rowData[heading.value] = x?.voucherCode
              break
            case 'service':
              rowData[heading.value] = x?.servicesIncluded.map(x => x.name).join(', ')
              break
            case 'amount':
              rowData[heading.value] = formatInToPrice(x?.price)
              break
            case 'status':
              rowData[heading.value] = (
                <span className='px-2 py-1 text-[#511161] bg-[#F6EFF8]'>{cleanUpText(x?.voucherStatus)}</span>
              )
              break
          }
        }
      })
      return rowData
    });
  };

  const rows = generateSalonPackageSalesTableData(
    redeemedPackages?.nodes,
    VOUCHER_HEADERS,
  )

  // filter first 4 in rows
  const firstFourRows = generateSalonPackageSalesTableData(
    redeemedPackages?.nodes?.slice(0, 4),
    DAILY_SALES_VOUCHER_HEADERS,
  )

  const goToNext = () => {
    if (redeemedPackages?.pageInfo?.hasNextPage) {
      setBefore(null)
      setAfter(redeemedPackages?.pageInfo?.endCursor)
      packagesSalesRefetch()
    }
  }

  const goToPrev = () => {
    if (redeemedPackages?.pageInfo?.hasPreviousPage) {
      setAfter(null)
      setBefore(redeemedPackages?.pageInfo?.startCursor)
      packagesSalesRefetch()
    }
  }
  return (
    <>
      <div className="pl-6 xl:pl-11 grid grid-cols-3 space-x-2 py-4 bg-grey-10 border-t border-b border-grey-150/45">
        {headers.map((header, index) => (
          <div
            key={index}
            className={`text-grey-300 antialiased text-start whitespace-nowrap lg:whitespace-normal`}
          >
            <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
              {header?.label}
            </Paragraph>
          </div>
        ))}
      </div>
      <div className='w-full flex flex-col gap-y-3'>
        {data?.map((item: any, index: number) => {
          return (
            <>
              <div className='pl-6 xl:pl-11 grid grid-cols-3 space-x-2 py-2' key={index}>
                {headers.map((header, index) => {
                  console.log({
                    header
                  })
                  return (
                    <div className='flex items-center space-x-2'>
                      {index === 0 && item[header?.value]?.toLowerCase() === 'vouchers' ? <span className='cursor-pointer' onClick={() => {
                        setIsOpen(!isOpen)
                      }}><SvgExpandCircle /></span> : null}
                      <Paragraph size='b4' color={COLORS.GREY[900]} weight='semiBold' className={`w-full flex ${header?.value === "quantity" ? 'justify-end pr-4' : ''}`}>
                        {header?.show ? item[header?.value] : '-'}
                      </Paragraph>
                    </div>
                  )
                })}
              </div>
              {isOpen && item?.payment?.toLowerCase() === 'vouchers' ?
                <div className='w-full flex flex-col'>
                  <Table
                    headers={DAILY_SALES_VOUCHER_HEADERS}
                    mobileHeaders={VOUCHER_HEADERS_MOBILE}
                    rows={firstFourRows}
                  />
                  <div className='w-full flex items-center justify-center py-2 border-y border-grey-100/50'>
                    <Button
                      variant='text'
                      size='xs'
                      type='button'
                      rounded='none'
                      onClick={openVoucherListModal}
                      className='border-b border-dashed border-grey-300 text-grey-300'
                    >
                      View all voucher transactions
                    </Button>
                  </div>
                </div>
                : null}
            </>
          )
        })}
      </div>

      <Modal show={isVoucherListModalVisible} closeModal={closeVoucherListModal} variant='right'>
        <div className='relative'>
          <div className='fixed bg-white w-full flex border-b border-grey-100 cursor-pointer'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={closeVoucherListModal}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={closeVoucherListModal}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
            </div>
          </div>

          <div
            style={{ marginTop: '70px' }}
            className='w-full max-w-5xl flex flex-col space-y-6 h-fit'
          >
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'left'
                }}
                className='hidden xl:flex flex-col xl:justify-between xl:items-left space-x-4 p-6'
              >
                <div className='flex flex-col space-y-4'>
                  <div className='w-full flex space-x-4 items-left'>
                    <Paragraph size='b2' weight='semiBold'>
                      Voucher transactions
                    </Paragraph>
                  </div>
                  <div className='w-full flex space-x-4 items-left'>
                    <Paragraph
                      size='b4'
                      weight='normal'
                      color={COLORS.GREY[300]}
                    >
                      Find all voucher transactions here
                    </Paragraph>
                  </div>
                </div>
              </div>
              <div className='w-full flex flex-col'>
                <div className='w-full flex flex-col md:flex-row items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6 gap-3'>
                  <div className='w-[200px]'>
                    <SearchTerm setDebouncedSearchQuery={setDebouncedSearchQuery} size='sm' />
                  </div>
                  <div className='w-full xl:w-8/12 flex items-center justify-end space-x-4'>
                    <CalendarPicker {...{ selectedDates, setSelectedDates }} />
                    <Paginator pageInfo={redeemedPackages?.pageInfo} {...{ goToNext, goToPrev }} />
                  </div>
                </div>
                <Table
                  headers={VOUCHER_HEADERS}
                  mobileHeaders={VOUCHER_HEADERS_MOBILE}
                  rows={rows}
                />
              </div>
            </>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DailySalesPaymentCollected