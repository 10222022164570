import React, { useEffect, useMemo, useState } from 'react'
import { ActionModalProps } from '../types'
import { COLORS } from 'constants/colors'
import Paragraph from 'ui/atoms/paragraph/Paragraph'
import Heading from 'ui/atoms/heading/Heading'
import Button from 'ui/atoms/button/Button'
import { SvgCheckMarkGreen } from 'ui'
import { useUserCache } from 'hooks/useUserCache'
import { formatInToPrice } from '../../../utils/misc'
import { useGetAppointment } from 'api/useAppointments'
import { Country } from 'core/generated'
import { useUser } from 'api/useAccount'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader'

const CancelAppointmentModal = ({
  isVisible,
  closeModal,
  appointmentId, salonId
}: ActionModalProps) => {
  const [applyCancellationFee, setApplyCancellationFee] = useState(false)
  const [chargeCustomerWallet, setChargeCustomerWallet] = useState(false)
  const {
    data,
    refetch,
    loading
  } = useUser()
  const business = useMemo(() => data?.user?.business, [data])
  const [step, setStep] = useState<number>(1)
  const {
    loading: isLoading,
    data: appointmentDetailsData,
    refetch: refetchAppointmentDetails
  } = useGetAppointment(appointmentId, salonId)
  const appointment = useMemo(() => appointmentDetailsData?.appointment, [appointmentDetailsData])

  const proceed = () => {
    if (step === 1 && applyCancellationFee) {
      setStep(2)
    } else {
      closeModal('cancel', applyCancellationFee, chargeCustomerWallet)
      setStep(1)
    }
  }

  useEffect(() => {
    if (appointmentId) {
      refetchAppointmentDetails()
    }
  }, [appointmentId, refetchAppointmentDetails])

  const cancel = () => {
    () => closeModal('back', applyCancellationFee)
    if (step === 2) {
      setStep(1)
    } else {
      closeModal('back', applyCancellationFee)
      setStep(1)
    }
  }

  const getCancellationAmount = () => {
    if (business?.cancellationFeeType !== 'percentage') {
      return formatInToPrice(business?.cancellationFee + appointment?.outstandingFee)
    } else {
      const cancellationFee = ((business?.cancellationFee / 100) * appointment?.totalServicesAmount) + appointment?.outstandingFee
      return formatInToPrice(cancellationFee)
    }
  }

  return isVisible ? (
    <>
      {loading ? <ContentLoader /> : null}
      <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50'>
        <div className='table-row'>
          {/* <div className="table-cell align-middle"> */}
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9'>
            <div className='flex flex-col space-y-6'>
              <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>
                {step === 1 ? 'Confirm cancellation ' : `Charge cancellation fee `} {getCancellationAmount()}
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full'
                color={COLORS.GREY[400]}
              >
                Select the applicable option
              </Paragraph>

              {step === 1 ?
                <>
                  {business.cancellationFee > 0 && <div
                    className={`p-5 min-h-10 rounded-md mb-6 cursor-pointer w-[600px] ${applyCancellationFee
                      ? 'border-2 border-green-300'
                      : 'border-solid border border-grey-100'
                      }`}
                    onClick={() => setApplyCancellationFee(true)}
                  >
                    <div className='flex items-center gap-2'>
                      <Paragraph weight={applyCancellationFee ? 'bold' : 'normal'}>
                        Send payment link or deduct from customer wallet
                      </Paragraph>
                      {applyCancellationFee && <SvgCheckMarkGreen />}
                    </div>
                    <Paragraph color={COLORS.GREY[400]} size='b5'>
                      The payment link will be sent to the client
                    </Paragraph>
                  </div>}

                  <div
                    className={`p-5 min-h-10 rounded-md mb-10 cursor-pointer w-[600px] ${!applyCancellationFee
                      ? 'border-2 border-green-300'
                      : 'border-solid border border-grey-100'
                      }`}
                    onClick={() => {
                      setApplyCancellationFee(false)
                      setChargeCustomerWallet(false)
                    }}
                  >
                    <div className='flex items-center gap-2  '>
                      <Paragraph weight={!applyCancellationFee ? 'bold' : 'normal'}>
                        Confirm and waive cancellation fee
                      </Paragraph>
                      {!applyCancellationFee && <SvgCheckMarkGreen />}

                    </div>
                    <Paragraph color={COLORS.GREY[400]} size='b5'>
                      The cancellation fee will not be charged
                    </Paragraph>

                  </div>
                </>
                : null}

              {step === 2 ?
                <>
                  <div
                    className={`p-5 min-h-10 rounded-md mb-6 cursor-pointer w-[600px] ${!chargeCustomerWallet
                      ? 'border-2 border-green-300'
                      : 'border-solid border border-grey-100'
                      }`}
                    onClick={() => setChargeCustomerWallet(false)}
                  >
                    <div className='flex items-center gap-2'>
                      <Paragraph weight={!chargeCustomerWallet ? 'bold' : 'normal'}>
                        Send payment link
                      </Paragraph>
                      {applyCancellationFee && <SvgCheckMarkGreen />}
                    </div>
                    <Paragraph color={COLORS.GREY[400]} size='b5'>
                      The payment link will be sent to the client
                    </Paragraph>
                  </div>

                  <div
                    className={`p-5 min-h-10 rounded-md mb-10 cursor-pointer w-[600px] ${chargeCustomerWallet
                      ? 'border-2 border-green-300'
                      : 'border-solid border border-grey-100'
                      }`}
                    onClick={() => setChargeCustomerWallet(true)}
                  >
                    <div className='flex items-center gap-2  '>
                      <Paragraph weight={chargeCustomerWallet ? 'bold' : 'normal'}>
                        Deduct from customer wallet
                      </Paragraph>
                      {!applyCancellationFee && <SvgCheckMarkGreen />}

                    </div>
                    <Paragraph color={COLORS.GREY[400]} size='b5'>
                      Balance: {formatInToPrice(appointment?.client?.walletBalance)}
                    </Paragraph>

                  </div>
                </>
                : null}

              <Button
                variant='primary'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                disabled={isLoading}
                loading={isLoading}
                onClick={proceed}
              >
                Okay
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                fontWeight="semiBold"
                className='mx-0 text-red-500'
                disabled={isLoading}
                onClick={cancel}
              >
                {step === 1 ? 'Cancel' : 'Back'}
              </Button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  ) : null
}

export default CancelAppointmentModal
