import { useMutation, useQuery, gql } from "@apollo/client";
import { AppointmentSummary, Client, ClientNoteAttachment, CommunicationLog } from "core/generated";
import { KeyPreferenceCategory } from "./types/general";

const CLIENTS = gql`
  query salonClients(
	$salonId: ID!
	$q: String
	$after: String
	$before: String
	$first: Int
	$last: Int
	$sortBy: ClientSortTypeEnum
) {
	salonClients(
		salonId: $salonId
		q: $q
		after: $after
		before: $before
		first: $first
		last: $last
		sortBy: $sortBy
	) {
		nodes {
			address
			amountSpent
			createdAt
			dob
			id
			lastVisit
			pointsEarned
			noShowAppointments
			completedAppointments
			cancelledAppointments
			countryCode
			email
			callingCode
			fullName
			firstName
			lastName
			phone
			totalTransactions
			walletBalance
			clientMembership {
				purchaseDate
				updatedAt
				membership {
					id
					name
				}
			}
			membershipWalletBalance
		}
		pageInfo {
			endCursor
			hasNextPage
			hasPreviousPage
			startCursor
		}
	}
}
`;

export const useGetClients = ({ salonId, q, after, before, first, last, sortBy}: {
  salonId: string,
  q?: string,
  after?: string,
  before?: string,
  first?: number,
	last?: number,
	blacklisted?: boolean
	sortBy?: 'client_wallet' | 'membership_wallet'
}) => {
  return useQuery<{
    salonClients: {
      nodes: Client[],
      pageInfo: {
        endCursor: string,
        hasNextPage: boolean,
        hasPreviousPage: boolean,
        startCursor: string,
      }
    }
  }>(CLIENTS, {
    variables: {
      salonId,
      q,
      after,
      before,
      first,
			last,
			sortBy
    },
    skip: !salonId,
  });
}

const BLACK_LISTED_CLIENTS = gql`
  query salonClients(
	$salonId: ID!
	$q: String
	$after: String
	$before: String
	$first: Int
	$last: Int
	$blacklisted: Boolean
) {
	salonClients(
		salonId: $salonId
		q: $q
		after: $after
		before: $before
		first: $first
		last: $last
		blacklisted: $blacklisted
	) {
		nodes {
			address
			amountSpent
			createdAt
			dob
			id
			lastVisit
			pointsEarned
			noShowAppointments
			completedAppointments
			cancelledAppointments
			countryCode
			email
			callingCode
			fullName
			firstName
			lastName
			phone
			totalTransactions
			walletBalance
		}
		pageInfo {
			endCursor
			hasNextPage
			hasPreviousPage
			startCursor
		}
	}
}
`;
export const useBlacklistedGetClients = ({ salonId, q, after, before, first, last}: {
  salonId: string,
  q?: string,
  after?: string,
  before?: string,
  first?: number,
	last?: number,
}) => {
  return useQuery<{
    salonClients: {
      nodes: Client[],
      pageInfo: {
        endCursor: string,
        hasNextPage: boolean,
        hasPreviousPage: boolean,
        startCursor: string,
      }
    }
  }>(BLACK_LISTED_CLIENTS, {
    variables: {
      salonId,
      q,
      after,
      before,
      first,
			last,
			blacklisted: true
    },
    skip: !salonId,
  });
}

const ADD_CLIENT = gql`
	mutation CreateClient($input: CreateClientInput!) {
		createClient(input: $input) {
			clientMutationId
			status
			client {
				id
			}
			errors {
				message
				property
			}
		}
	}
`;

export const useAddClient = () => {
  const [createClient, options] =  useMutation<{
    createClient: {
			status: number;
			client: Client;
      errors: {
        message: string;
        property: string;
      }[];
    };
	}>(ADD_CLIENT);
	
	return {
		createClient,
		...options,
	}
}

const UPLOAD_CLIENTS = gql`
	mutation UploadClient($input: UploadClientInput!) {
		uploadClient(input: $input) {
			status
			errors {
				message
				property
			}
		}
	}
`;

export const useUploadClient = () => {
  const [uploadClient, options] = useMutation<{
    uploadClient: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
	}>(UPLOAD_CLIENTS);
	
	return {
		uploadClient,
		...options,
	}
}

const UPDATE_CLIENT = gql`
	mutation UpdateClient($input: UpdateClientInput!)  {
		updateClient(input: $input) {
			clientMutationId
			status
			errors {
				message
				property
			}
		}
	}
`;

export const useUpdateClient = () => {
  const [updateClient, options] = useMutation<{
    updateClient: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
	}>(UPDATE_CLIENT);

	return {
		updateClient,
		...options,
	}
}

const BASIC_MONEY_SALON_CLIENT_DETAILS = gql`
query SalonClient($clientId: ID!, $salonId: ID) {
	salonClient(clientId: $clientId, salonId: $salonId) {
		id
		dob
		address
		phone
		altPhone
		email
		firstName
		lastName
		fullName
		altCallingCode
		altCountryCode
		countryCode
		callingCode
		noShowAppointments
		amountSpent
		pointsEarned
		activeMilestones {
			createdAt
			customerPointsAttained
			customerReceives
			customerReceivesType
			id
			loyaltyProgramId
			name
			updatedAt
			products {
				name
			}
			services {
				name
			}
		}
		activeClientMilestones {
			clientId
			createdAt
			id
			milestoneId
			status
			updatedAt
			milestone {
				createdAt
				customerPointsAttained
				customerReceives
				customerReceivesType
				id
				loyaltyProgramId
				name
				updatedAt
			}
		}
		clientMembership {
			createdAt
			id
			membershipExpirationDate
			membershipStatus
			purchaseDate
			updatedAt
			membership {
				createdAt
				fee
				id
				name
				rewardPercentage
				updatedAt
				validityPeriod
				services {
					id
					name
				}
			}
		}
		membershipWalletBalance
		membershipWalletTransactions {
			amount
			createdAt
			description
			id
			purposeType
			transactionType
			updatedAt
			trailingBalance
		}
		walletBalance
		outstandingFeeAmount
		createdAt
	}
}
`

const SALON_CLIENT = gql`
	query SalonClient($clientId: ID!, $salonId: ID) {
		salonClient(clientId: $clientId, salonId: $salonId) {
			id
			dob
			address
			phone
			altPhone
			email
			firstName
			lastName
			fullName
			altCallingCode
			altCountryCode
			countryCode
			callingCode
			completedAppointments
			cancelledAppointments
			noShowAppointments
			totalTransactions
			amountSpent
			blacklisted
			optOutEmail
			optOutSms
			pointsEarned
			outstandingFeeAmount
			outstandingAppointmentsCount
			keyPreferences {
				details
				highlighted
				id
				keyPreferenceCategory {
					iconSvg
					id
					label
				}
				createdAt
			}
			activeMilestones {
				createdAt
				customerPointsAttained
				customerReceives
				customerReceivesType
				id
				loyaltyProgramId
				name
				updatedAt
				products {
					name
				}
				services {
					name
				}
			}
			activeClientMilestones {
				clientId
				createdAt
				id
				milestoneId
				status
				updatedAt
				milestone {
					createdAt
					customerPointsAttained
					customerReceives
					customerReceivesType
					id
					loyaltyProgramId
					name
					updatedAt
				}
			}
			clientWalletTransactions {
				amount
				createdAt
				description
				id
				purposeType
				transactionType
				updatedAt
				trailingBalance
			}
			clientRewardHistories {
				appointmentId
				clientId
				createdAt
				cumulativePointBalance
				description
				id
				pointsEarned
				salonId
				transactionDate
				updatedAt
			}
			clientMilestones {
				clientId
				createdAt
				id
				milestoneId
				status
				updatedAt
				milestone {
					createdAt
					customerPointsAttained
					customerReceives
					customerReceivesType
					id
					loyaltyProgramId
					name
					updatedAt
					products {
						name
					}
				}
				appointment {
					appointmentServices {
						name
						appointmentServiceStaff {
							salonStaff {
								user {
									firstName
								}
							}
						}
					}
					discountAmount
					discountType
					discountValue
				}
			}
			milestones {
				createdAt
				customerPointsAttained
				customerReceives
				customerReceivesType
				id
				loyaltyProgramId
				name
				updatedAt
				products {
					name
				}
				services {
					name
				}
				appointmentServices {
					id
					milestoneServiceId
					name
					price
					quantity
					serviceId
					startAt
					updatedAt
					appointment {
						discountAmount
						discountValue
						discountType
						totalPaid
						totalAmountPaid
						totalPrice
						startAt
					}
					appointmentServiceStaff {
						salonStaff {
							user {
								firstName
							}
						}
					}
				}
			}
			appointments {
				id
				bookingId
				paymentMethod
				totalAmountPaid
				totalPrice
				appointmentStatus
				totalPoints
				createdAt
				startAt
				endAt
				isActive
				payments {
					amount
					currency
					mode
					createdAt
				}
				appointmentServices {
					name
					price
					id
					appointmentServiceStaff {
						salonStaff {
							user {
								fullName
								firstName
							}
						}
					}
				}
				clientNote {
					note
				}
				appointmentVoucher {
					packageVoucher {
						package {
							name
						}
					}
				}
				address
				salon {
					branchName
				}
				appointmentMilestone {
					milestone {
						customerReceives
						customerReceivesType
					}
				}
			}
			sales {
				id
				createdAt
				startAt
				endAt
				amountClientPaid
				saleStatus
				paymentMethod
				discountAmount
				totalPrice
				totalPoints
				note
				salon {
					branchName
				}
				services {
					id
					name
				}
				saleStaff {
						salonStaff {
								user {
										firstName
										fullName
								}
						}
				}
			}
			clientMembership {
				createdAt
				id
				membershipExpirationDate
				membershipStatus
				purchaseDate
				updatedAt
				membership {
					createdAt
					fee
					id
					name
					rewardPercentage
					updatedAt
					validityPeriod
					services {
						id
						name
					}
				}
			}
			membershipWalletBalance
			membershipWalletTransactions {
				amount
				createdAt
				description
				id
				purposeType
				transactionType
				updatedAt
				trailingBalance
			}
			walletBalance
			packageVouchers {
				id
				package {
					id
					name
				}
				# purchaseDate
				expiresAt
				status
				price
				amountRedeemed
				validityMonths
				redemptionMode
				code
				createdAt
				voucherServices {
					createdAt
					duration
					id
					isRedeemed
					name
					packageVoucherId
					price
					quantity
					redeemedCount
					serviceId
					updatedAt
				}
			}
			clientNotes {
				id
				title
				note
				clientId
				createdAt
				clientNoteAttachments {
					id
					imageUrl
				}
				addedBy
			}
			createdAt
		}
	}
`;

export const useGetClient = ({ clientId, salonId }: { clientId: string, salonId: string }) => {
  return useQuery<{
    salonClient: Client;
  }>(SALON_CLIENT, {
    variables: {
      clientId,
      salonId,
    },
    skip: !clientId || !salonId,
  });
}

export const useGetClientDetailsInAppointmentPage = ({ clientId, salonId }: { clientId: string, salonId: string }) => {
  return useQuery<{
    salonClient: Client;
  }>(BASIC_MONEY_SALON_CLIENT_DETAILS, {
    variables: {
      clientId,
      salonId,
    },
    skip: !clientId || !salonId,
  });
}

const CREATE_CLIENT_NOTE = gql`
	mutation CreateClientNote($input: CreateClientNoteInput!) {
		createClientNote(input: $input) {
			status
			clientNote {
				id
				title

				note
			}
			errors {
				message
				property
			}
		}
	}
`;

export const useCreateClientNote = () => {
  const [createClientNote, options] = useMutation<{
    createClientNote: {
      status: number;
      clientNote: {
        id: string;
        title: string;
        note: string;
      };
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_CLIENT_NOTE);

  return {
    createClientNote,
    ...options,
  };
};

const DELETE_CLIENT_NOTE = gql`
	mutation DeleteClientNote($input: DeleteClientNoteInput!) {
		deleteClientNote(input: $input) {
			status
			errors {
				message
				property
			}
			clientNote {
				id
			}
		}
	}
`;

export const useDeleteClientNote = () => {
	const [deleteClientNote, options] = useMutation<{
    deleteClientNote: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_CLIENT_NOTE);

  return {
    deleteClientNote,
    ...options,
  };
}

const CREATE_NOTE_ATTACHMENT = gql`
	mutation CreateClientNoteAttachment($input: CreateClientNoteAttachmentInput!) {
		createClientNoteAttachment(input: $input) {
			status
			errors {
				message
				property
			}
			clientNoteAttachment {
				id
				imageUrl
			}
		}
	}
`;

export const useCreateClientNoteAttachment = () => {
  const [createClientNoteAttachment, options] = useMutation<{
    createClientNoteAttachment: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
      clientNoteAttachment: ClientNoteAttachment;
    };
  }>(CREATE_NOTE_ATTACHMENT);

  return {
    createClientNoteAttachment,
    ...options,
  };
};

const DELETE_NOTE_ATTACHMENT = gql`
	mutation DeleteClientNoteAttachment($input: DeleteClientNoteAttachmentInput!) {
		deleteClientNoteAttachment(input: $input) {
			status
			errors {
				message
				property
			}
		}
	}
`;

export const useDeleteClientNoteAttachment = () => {
  const [deleteClientNoteAttachment, options] = useMutation<{
    deleteClientNoteAttachment: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_NOTE_ATTACHMENT);

  return {
    deleteClientNoteAttachment,
    ...options,
  };
};

const RECORD_REFUND = gql`
	mutation RecordRefund($input: RecordRefundInput!) {
		recordRefund(input: $input) {
			status
			errors {
				message
				property
			}
		}
	}
`;

export const useRecordRefund = () => {
  const [recordRefund, options] = useMutation<{
    recordRefund: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(RECORD_REFUND);

  return {
    recordRefund,
    ...options,
  };
};

const RECORD_SALE_REFUND = gql`
	mutation RecordSaleRefund($input: RecordSaleRefundInput!) {
		recordSaleRefund(input: $input) {
			clientMutationId
			status
			errors {
				message
				property
			}
		}
	}
`;

export const useRecordSaleRefund = () => {
  const [recordSaleRefund, options] = useMutation<{
    recordSaleRefund: {
      clientMutationId: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(RECORD_SALE_REFUND);

  return {
    recordSaleRefund,
    ...options,
  };
};

const DELETE_CLIENT = gql`
	mutation DeleteClient($input: DeleteClientInput!) {
		deleteClient(input: $input) {
			status
			errors {
				message
				property
			}
		}
	}
`;

export const useDeleteClient = () => {
  const [deleteClient, options] = useMutation<{
    deleteClient: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_CLIENT);

  return {
    deleteClient,
    ...options,
  };
};

const COMMUNICATION_LOGS = gql`
	query ClientCommunicationLogs(
		$clientId: ID!
		$salonId: ID!
		$startDate: ISO8601Date
		$endDate: ISO8601Date
		$q: String
		$after: String
		$before: String
		$first: Int
		$last: Int
	) {
		clientCommunicationLogs(
			clientId: $clientId
			salonId: $salonId
			startDate: $startDate
			endDate: $endDate
			q: $q
			after: $after
			before: $before
			first: $first
			last: $last
		) {
			nodes {
				body
				businessId
				category
				clientId
				communicationType
				createdAt
				deliveryStatus
				salon {
					branchName
				}
				from
				id
				messageType
				subject
				to
				updatedAt
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
		}
	}
`;

export const useGetCommunicationLogs = ({
  clientId,
  salonId,
  startDate,
  endDate,
  q,
  after,
  before,
  first,
  last,
}: {
  clientId: string;
  salonId: string;
  startDate?: string;
  endDate?: string;
  q?: string;
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}) => {
  return useQuery<{
    clientCommunicationLogs: {
      nodes: CommunicationLog[];
      pageInfo: {
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string;
      };
    };
  }>(COMMUNICATION_LOGS, {
    variables: {
      clientId,
      salonId,
      startDate,
      endDate,
      q,
      after,
      before,
      first,
      last,
    },
    skip: !clientId || !salonId,
  });
};

const GET_CLIENTS_FOR_SALES_AND_APPOINTMENTS = gql`
	query salonClients(
		$salonId: ID!
		$q: String
		$after: String
		$before: String
		$first: Int
		$last: Int
	) {
		salonClients(
			salonId: $salonId
			q: $q
			after: $after
			before: $before
			first: $first
			last: $last
		) {
			nodes {
				address
				amountSpent
				createdAt
				dob
				id
				lastVisit
				pointsEarned
				noShowAppointments
				completedAppointments
				cancelledAppointments
				walletBalance
				activeMilestones {
					createdAt
					customerPointsAttained
					customerReceives
					customerReceivesType
					id
					loyaltyProgramId
					name
					updatedAt
					products {
						name
					}
					services {
						name
					}
				}
        clientMembership {
					membershipStatus
					membership {
						rewardPercentage
						services {
							id
							name
						}
					}
        }
        membershipWalletBalance
				activeClientMilestones {
					clientId
					createdAt
					id
					milestoneId
					status
					updatedAt
					milestone {
						createdAt
						customerPointsAttained
						customerReceives
						customerReceivesType
						id
						loyaltyProgramId
						name
						updatedAt
					}
				}
				milestones {
					createdAt
					customerPointsAttained
					customerReceives
					customerReceivesType
					id
					loyaltyProgramId
					name
					updatedAt
					products {
						name
					}
					services {
						name
					}
				}
				countryCode
				email
				callingCode
				fullName
				firstName
				lastName
				phone
				totalTransactions
				packageVouchers {
					id
					package {
						id
						name
					}
					# purchaseDate
					expiresAt
					status
					price
					amountRedeemed
					redemptionMode
					voucherServices {
						duration
						id
						isRedeemed
						name
						price
						serviceId
						quantity
						redeemedCount
					}
				}
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
		}
	}

`;

export const useGetClientsForSalesAndAppointments = ({
  salonId,
  q,
  after,
  before,
  first,
  last,
}: {
  salonId: string;
  q?: string;
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}) => {
  return useQuery<{
    salonClients: {
      nodes: Client[];
      pageInfo: {
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string;
      };
    };
  }>(GET_CLIENTS_FOR_SALES_AND_APPOINTMENTS, {
		fetchPolicy: 'network-only',
    variables: {
      salonId,
      q,
      after,
      before,
      first,
      last,
    },
    skip: !salonId,
  });
};

const BLACKLIST_CLIENT = gql`
	mutation BlacklistClient($input: BlacklistClientInput!) {
    blacklistClient(input: $input) {
			clientMutationId
			message
			status
			errors {
					message
					property
			}
    }
	}
`

export const useBlacklistClient = () => {
  const [blacklistClient, options] = useMutation<{
    blacklistClient: {
      clientMutationId: string;
      message: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(BLACKLIST_CLIENT);

  return {
    blacklistClient,
    ...options,
  };
}

const UNBLACKLIST_CLIENT = gql`
	mutation UnblacklistClient($input: UnblacklistClientInput!) {
    unblacklistClient(input: $input) {
			clientMutationId
			message
			status
			errors {
					message
					property
			}
    }
	}
`;

export const useUnblacklistClient = () => {
  const [unblacklistClient, options] = useMutation<{
    unblacklistClient: {
      clientMutationId: string;
      message: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(UNBLACKLIST_CLIENT);

  return {
    unblacklistClient,
    ...options,
  };
}

const CREATE_CLIENT_WALLET = gql`
	mutation CreditClientWallet($input: CreditClientWalletInput!) {
    creditClientWallet(input: $input) {
        clientMutationId
        message
        status
        errors {
            message
            property
        }
    }
	}
`;

export const useCreditClientWallet = () => {
  const [creditClientWallet, options] = useMutation<{
    creditClientWallet: {
      clientMutationId: string;
      message: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_CLIENT_WALLET);

  return {
    creditClientWallet,
    ...options,
  };
}

const DEDUCT_CLIENT_WALLET = gql`
	mutation DebitClientWallet($input: DebitClientWalletInput!) {
    debitClientWallet(input: $input) {
			clientMutationId
			message
			refreshToken
			status
			errors {
				message
				property
			}
    }
	}
`;

export const useDebitClientWallet = () => {
  const [debitClientWallet, options] = useMutation<{
    debitClientWallet: {
      clientMutationId: string;
      message: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DEDUCT_CLIENT_WALLET);

  return {
    debitClientWallet,
    ...options,
  };
}

const CREATE_CLIENT_KEY_PREFERENCES = gql`
	mutation CreateKeyPreference($input: CreateKeyPreferenceInput!) {
			createKeyPreference(input: $input) {
					clientMutationId
					message
					status
					errors {
							message
							property
					}
			}
	}
`;

export const useCreateClientKeyPreferences = () => {
  const [createKeyPreference, options] = useMutation<{
    createKeyPreference: {
      clientMutationId: string;
      message: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_CLIENT_KEY_PREFERENCES);

  return {
    createKeyPreference,
    ...options,
  };
}

const GET_PREFERENCE_CATEGORIES = gql`
	query KeyPreferenceCategories {
		keyPreferenceCategories {
			iconSvg
			id
			label
		}
	}
`;

export const useGetPreferencesCategories = () => {
  return useQuery<{
    keyPreferenceCategories: KeyPreferenceCategory[];
  }>(GET_PREFERENCE_CATEGORIES);
}

const DELETE_CLIENT_KEY_PREFERENCE = gql`
	mutation DeleteKeyPreference($input: DeleteKeyPreferenceInput!) {
    deleteKeyPreference(input: $input) {
        clientMutationId
        message
        status
        errors {
					message
					property
        }
    }
	}
`;

export const useDeleteClientKeyPreference = () => {
  const [deleteKeyPreference, options] = useMutation<{
    deleteKeyPreference: {
      clientMutationId: string;
      message: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_CLIENT_KEY_PREFERENCE);

  return {
    deleteKeyPreference,
    ...options,
  };
}

const UPDATE_CLIENT_KEY_PREFERENCE = gql`
	mutation UpdateKeyPreference($input: UpdateKeyPreferenceInput!) {
    updateKeyPreference(input: $input) {
        clientMutationId
        message
        status
        errors {
            message
            property
        }
    }
	}
`;

export const useUpdateClientKeyPreference = () => {
	const [updateKeyPreference, options] = useMutation<{
		updateKeyPreference: {
			clientMutationId: string;
			message: string;
			status: number;
			errors: {
				message: string;
				property: string;
			}[];
		};
	}>(UPDATE_CLIENT_KEY_PREFERENCE);

	return {
    updateKeyPreference,
    ...options,
  };
}

const APPOINTMENT_SUMMARY = gql`
  query AppointmentSummary($bookingId: ID!) {
    appointmentSummary(bookingId: $bookingId) {
      appointment {
        addOnAmount
        addOnReason
        address
        appointmentStatus
        appointmentStatusTransitions
        appointmentType
        bookingId
        cancelledAt
        checkedInAt
        createdAt
        depositAmount
        endAt
        id
        isActive
        locationType
        paymentMethod
        paymentMethodId
        processingFeeAmount
        refundReason
        refundedAt
        startAt
        taxAmount
        tipAmount
        timezone
        totalAmountPaid
        totalAmountRefunded
        totalDuration
        totalPaid
        totalPoints
        totalPrice
        totalProductsAmount
        totalServicesAmount
        updatedAt
        appointmentProducts {
          appointmentId
          createdAt
          id
          name
          price
          productId
          quantity
          updatedAt
        }
        appointmentServices {
          appointmentId
          createdAt
          duration
          endAt
          id
          name
          price
          serviceId
          startAt
          updatedAt
          appointmentServiceStaff {
            salonStaff {
              id
              user {
                firstName
                fullName
                lastName
              }
            }
          }
        }
        salon {
          address
          branchName
					business {
            country {
              code
              collectionsSupported
              countryCode
              emojiFlag
              id
              name
              payoutsSupported
              timezone
              utcOffset
              currency {
                code
                id
                name
                symbol
              }
            }
					}
        }
        clientNote {
          addedBy
          clientId
          createdAt
          deletedAt
          id
          note
          title
          updatedAt
        }
      }
    }
  }
`;

export const useClientAppointmentSummary = (bookingId: string) => {
  return useQuery<{
    appointmentSummary: AppointmentSummary
  }>(APPOINTMENT_SUMMARY, {
    variables: {
      bookingId,
    },
    skip: !bookingId,
  })
}

const DELETE_CLIENT_MEMBERSHIP = gql`
	mutation DeleteClientMembership($input: DeleteClientMembershipInput!) {
    deleteClientMembership(input: $input) {
        clientMutationId
        message
        refreshToken
        status
        errors {
            message
            property
        }
    }
	}
`;

export const useDeleteClientMembership = () => {
  const [deleteClientMembership, options] = useMutation<{
    deleteClientMembership: {
      clientMutationId: string;
      message: string;
      refreshToken: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_CLIENT_MEMBERSHIP);

  return {
    deleteClientMembership,
    ...options,
  };
}

const PAUSE_CLIENT_MEMBERSHIP = gql`
	mutation PauseClientMembership($input: PauseClientMembershipInput!) {
    pauseClientMembership(input: $input) {
        clientMutationId
        message
        refreshToken
        status
        errors {
            message
            property
        }
    }
	}
`;

export const usePauseClientMembership = () => {
  const [pauseClientMembership, options] = useMutation<{
    pauseClientMembership: {
      clientMutationId: string;
      message: string;
      refreshToken: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(PAUSE_CLIENT_MEMBERSHIP);

  return {
    pauseClientMembership,
    ...options,
  };
}

const WAIVE_OUTSTANDING_FEES = gql`
	mutation WaiveClientOutstandingFees($input: WaiveClientOutstandingFeesInput!) {
		waiveClientOutstandingFees(input: $input) {
			clientMutationId
			message
			refreshToken
			status
        errors {
            message
            property
        }
		}
	}
`

export const useWaiveClientOutstandingFees = () => {
	const [waiveClientOutstandingFees, options] = useMutation<{
		waiveClientOutstandingFees: {
			clientMutationId: string;
			message: string;
			refreshToken: string;
			status: number;
			errors: {
				message: string;
				property: string;
			}[];
		};
	}>(WAIVE_OUTSTANDING_FEES);

	return {
		waiveClientOutstandingFees,
		...options,
	};
}

const RESUME_CLIENT_MEMBERSHIP = gql`
	mutation ResumeClientMembership($input: ResumeClientMembershipInput!) {
    resumeClientMembership(input: $input) {
        clientMutationId
        message
        refreshToken
        status
        errors {
            message
            property
        }
    }
	}
`;

export const useResumeClientMembership = () => {
  const [resumeClientMembership, options] = useMutation<{
    resumeClientMembership: {
      clientMutationId: string;
      message: string;
      refreshToken: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(RESUME_CLIENT_MEMBERSHIP);

  return {
    resumeClientMembership,
    ...options,
  };
}

const MESSAGING_COMMUNICATION_LOGS = gql`
	query CommunicationLogs($messageType: String!, $q: String, $after: String, $before: String, $first: Int, $startDate: ISO8601Date, $endDate: ISO8601Date) {
    communicationLogs(messageType: $messageType, q: $q, after: $after, before: $before, first: $first, startDate: $startDate, endDate: $endDate) {
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
			nodes {
				body
				businessId
				category
				clientId
				communicationType
				createdAt
				deliveryStatus
				from
				id
				messageType
				subject
				to
				updatedAt
			}
    }
	}
`;

export const useCommunicationLogs = ({ messageType, q, after, before, startDate, endDate }: {
	messageType: string;
	q?: string;
	after?: string;
	before?: string;
	first?: number;
  startDate?: string;
  endDate?: string;
}) => {
	return useQuery<{
		communicationLogs: {
			pageInfo: {
				endCursor: string;
				hasNextPage: boolean;
				hasPreviousPage: boolean;
				startCursor: string;
			};
			nodes: CommunicationLog[];
		};
	}>(MESSAGING_COMMUNICATION_LOGS, {
		variables: {
			messageType,
			q,
			after,
			before,
			startDate,
			endDate,
		},
	});
}

const CLIENT_SUMMARY_COUNT = gql`
	query SalonClientsSummary($salonId: ID!) {
    salonClientsSummary(salonId: $salonId) {
        blacklistedClientsCount
        clientsCount
    }
	}
`;

export const useClientSummaryCount = (salonId: string) => {
  return useQuery<{
    salonClientsSummary: {
      blacklistedClientsCount: number;
      clientsCount: number;
    };
  }>(CLIENT_SUMMARY_COUNT, {
    variables: {
      salonId,
    },
    skip: !salonId,
  });
}