import { endOfMonth, startOfMonth } from 'date-fns';
import React from 'react'
import { DatePicker } from 'rsuite'
import { CalendarDateProps } from './types'
import { BsCalendar2MonthFill } from 'react-icons/bs';

const MonthPicker = ({
  selectedDates,
  setSelectedDates
}: CalendarDateProps) => {
  const selectedDate = Array?.isArray(selectedDates) ? selectedDates[0] : undefined
  const handleDateChange = (date: number | Date) => {
    const dates = [startOfMonth(date), endOfMonth(date)] as [Date, Date]
    setSelectedDates(dates)
  }

  return (
    <div className="relative">
      <DatePicker format="MMM yyyy"
        onChange={handleDateChange}
        value={selectedDate}
        placement='bottomEnd'
        cleanable={false}
        preventOverflow
        placeholder="Select month" caretAs={BsCalendar2MonthFill} />
      {/* <DatePicker
        oneTap
        format="yyyy-MM"
        placeholder="Select month"
        cleanable={false}
      /> */}
    </div>
  )
}

export default MonthPicker
