import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { COLORS } from 'constants/colors';
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from 'ui/atoms/toast/types';
import { useToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import {
  Country,
  User,
  BusinessUpdateInput,
} from 'core/generated';
import Input from 'ui/molecules/input/Input';
import { ERRORS } from 'constants/errors';
import {
  ROLES,
  SPECIALTIES,
  TEAM_SIZE,
  YES_OR_NO_SETTINGS,
} from 'constants/information';
import FormLabel from 'ui/atoms/formLabel/Label';
import { FormHelperText } from 'ui/atoms/helperText/FormHelperText';
import MultiSelect from 'ui/molecules/multiselect/multiselect';
import SelectWithChangeHandlerInput from 'ui/molecules/input/SelectWithChangeHandlerInput';
import Checkbox from 'ui/atoms/checkbox/Checkbox';
import Button from 'ui/atoms/button/Button';
import { IOption } from 'components/utils/types';
import { Heading, MultiSelectOption, SvgLogo } from 'ui';
import { useUpdateBusiness, useUserAuthorizations } from 'api/useAccount';
import React, { useMemo } from 'react';
import { useCountries } from 'api/useLogin';
import { PAGE_ROUTES } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { useGetSpecialties } from 'api/useStaff';
import { sortOptions } from 'components/utils/misc';

const CompleteRegistration = () => {
  const navigate = useNavigate()
  const [teamSize, setTeamSize] = React.useState<string | null>(null);
  const [role, setRole] = React.useState<string | null>(null);
  const [bookingManagement, setBookingManagement] = React.useState<string | null>(null);
  const [businessCategory, setBusinessCategory] = React.useState<string | null>(null);
  const [teamSizeDisabled, setTeamSizeDisabled] = React.useState<boolean>(false);
  const [roleDisabled, setRoleDisabled] = React.useState<boolean>(false);
  const [showRcNumberInput, setShowRcNumberInput] = React.useState<boolean>(true);
  const { addToast, toast } = useToast();
  const user = JSON.parse(localStorage.getItem('userData')) as User
  const {
    loading,
    updateBusiness
  } = useUpdateBusiness()

  const {
    data: dataPermissions,
    refetch: refetchPermission
  } = useUserAuthorizations()

  useMemo(() => {
    if (dataPermissions) {
      localStorage.setItem(
        'permissions',
        JSON.stringify(dataPermissions?.auhorizations)
      );
    }
  }, [dataPermissions])
  const {
    data: countriesData,
  } = useCountries()
  const countries = useMemo(() => {
    return countriesData?.countries || []
  }, [countriesData])

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid }
  } = useForm<BusinessUpdateInput>({
    defaultValues: {
      canServeCustomers: false
    },
  })

  const handleTeamSizeChange = (selectedOption: any) => {
    const teamSize = selectedOption.target.value;
    setTeamSize(teamSize);
    setValue('teamSize', teamSize);
  };

  const handleRoleChange = (selectedOption: any) => {
    const role = selectedOption.target.value;
    setValue('role', role);
    setRole(role);
  };

    const {
      data: specialtyData,
      refetch: refetchSpecialty,
    } = useGetSpecialties({
      specialtyType: 'business'
    })
    const specialties = useMemo(() => specialtyData?.specialties, [specialtyData]);
  

  const updateBusinessAsync = (input: BusinessUpdateInput) => {
    const businessSpecialties = input.businessSpecialties.map(
      (x: unknown) => (x as IOption).value
    );
    const payload = {
      businessData: {
        countryCode: user?.business?.countryCode,
        callingCode: user?.business?.callingCode,
        teamSize: input?.teamSize,
        rcNumber: input?.rcNumber,
        name: user?.business?.name,
        phone: user?.business?.phone,
      },
      businessSpecialties,
      role: input.role,
      canServeCustomers: input.canServeCustomers,
      onboardingStep: 'registration_step_2'
    }
    updateBusiness({
      variables: {
        input: {
          ...payload,
        }
      }
    }).then(({ data }) => {
      const { updateBusiness } = data
      if (updateBusiness.status === 200) {
        navigate(PAGE_ROUTES.ACCOUNT_SETUP, { replace: true })
      } else {
        addToast({ variant: 'error', message: updateBusiness.errors[0].message })
      }
    })
  }

  return (
    <>
      <main className="flex items-center justify-center min-h-screen bg-white xl:bg-grey-100 py-[60px]">
        <div className="w-full border border-grey-100/70 bg-white shadow-medium mx-auto py-8 max-w-[480px] xl:w-[580px] xl:max-w-[700px] space-y-4 rounded-md">
          <div className="flex flex-col items-center space-y-6 px-9">
            <SvgLogo width="65px" height="100%" />
            <div className='w-full max-w-[70%] flex flex-col justify-center'>
              <Heading variant='h1' size='h8' weight='semiBold' className='gradient-text text-center'>
                Complete setup
              </Heading>
              <Paragraph
                size="b5"
                weight="medium"
                className="w-full text-center max-w-[400px]"
                color={COLORS.GREY[400]}
              >
                Let's get you all set up so you can start managing customer
                appointments and payments
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex'>
            <div className="border-t border-2 border-green-300 w-1/2"></div>
            <div className={`border-t border-2 border-green-300 w-1/2`}></div>
          </div>
          <ToastWrapper toast={toast as ToastProps} />
          <form
            onSubmit={handleSubmit(updateBusinessAsync)}
            className="w-full space-y-6 px-9"
            autoComplete="off"
          >
            <div className="flex flex-col space-y-2">
              <Controller
                control={control}
                name="businessSpecialties"
                render={({
                  field: { onChange, value },
                  formState: { errors },
                }) => {
                  const errorMessage =
                    errors.businessSpecialties?.message;
                  return (
                    <>
                      <FormLabel htmlFor="specialty">
                        What's your specialty? (Max 3)
                      </FormLabel>
                      <MultiSelect
                        selected={
                          (value as unknown as MultiSelectOption[]) || []
                        }
                        options={[...sortOptions(specialties)]}
                        setSelected={onChange}
                      />
                      {errorMessage && (
                        <FormHelperText variant="error">
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </>
                  );
                }}
                rules={{
                  required: 'Select a specialty',
                }}
              />
            </div>
            <SelectWithChangeHandlerInput
              name="teamSize"
              id="teamSize"
              label="How big is your team?"
              control={control}
              rules={{
                required: ERRORS.TEAM_SIZE_REQUIRED,
              }}
              error={errors?.teamSize}
              options={TEAM_SIZE}
              placeholder="Select a team size"
              value={teamSize}
              disabled={teamSizeDisabled}
              onChange={handleTeamSizeChange}
            />
            <SelectWithChangeHandlerInput
              name="role"
              id="role"
              label="Role at Business"
              control={control}
              rules={{
                required: ERRORS.ROLE_REQUIRED,
              }}
              error={errors?.role}
              options={ROLES}
              placeholder="Select Role"
              value={role}
              disabled={roleDisabled}
              onChange={handleRoleChange}
            />
            {user?.business?.countryCode === 'NG' ? (
              <Input
                name="rcNumber"
                id="rc-number"
                label="RC Number"
                type="text"
                placeholder="Enter RC Number"
                control={control}
              />
            ) : null}
            <Controller
              control={control}
              name="canServeCustomers"
              render={({ field: { onChange, value } }) => {
                return (
                  <div className="w-full flex flex-col space-y-4">
                    <FormLabel htmlFor="canServeCustomers">
                      Do you as the business owner/manager/supervisor,
                      personally service customers?
                    </FormLabel>
                    <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                      {Array.isArray(YES_OR_NO_SETTINGS) &&
                        YES_OR_NO_SETTINGS.length
                        ? YES_OR_NO_SETTINGS.map(
                          (canServeCustomers: {
                            label: string;
                            value: boolean;
                          }) => {
                            return (
                              <div
                                className="flex"
                                onClick={() =>
                                  onChange(canServeCustomers?.value)
                                }
                                key={canServeCustomers?.label}
                              >
                                <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                  <Checkbox
                                    isChecked={
                                      value === canServeCustomers?.value
                                    }
                                  />
                                  <span className="text-grey-900 whitespace-nowrap">
                                    {canServeCustomers?.label}
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        )
                        : null}
                    </div>
                  </div>
                );
              }}
            />
            <Button
              variant="primary"
              className=""
              disabled={loading}
              loading={loading}
              size="lg"
              rounded="lg"
            >
              Submit
            </Button>
          </form>
        </div>
      </main>
    </>
  );
}

export default CompleteRegistration