/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { Appointment, SalesOverviewByService } from "core/generated"
import { Pill, TableFilterProps } from "../../../ui"
import { extractDateParts, formatInToPrice, getDayMonthAndNumberAndTimeFromDate, limitString } from "../../utils/misc"
import { convertAppointmentServicesNamesToString, convertAppointmentServicesToStaffString } from "../bookingSales/utils"

export const formatSaleStatusPill = (status: string) => {
  switch (status) {
    case 'completed':
      return <Pill rounded='lg' variant="success">Completed</Pill>
    case 'paid':
      return <Pill rounded='lg' variant="success">Completed</Pill>
    case 'cancelled':
      return <Pill rounded='lg' variant='danger'>Cancelled</Pill>
    case 'confirmed':
      return <Pill rounded='lg' variant='pending'>Confirmed</Pill>
    case 'pending':
      return <Pill rounded='lg' variant='pending'>Pending</Pill>
    case 'partially_paid':
      return <Pill rounded='lg' variant='secondary'>Part-paid</Pill>
    case 'unpaid':
      return <Pill rounded='lg' variant='danger'>Unpaid</Pill>
    default:
      return <Pill rounded='lg' variant='black'>Voided</Pill>
  }
}

export const formatRewardStatusPill = (status: string) => {
  switch (status) {
    case 'active':
      return <Pill rounded='lg' variant="success">Active</Pill>
    case 'redeemed':
      return <Pill rounded='lg' variant="black">Redeemed</Pill>
    case 'expired':
      return <Pill rounded='lg' variant='danger'>Expired</Pill>
    default:
      return null
  }
}

export const formatAppointmentStatusPill = (status: string) => {
  const statusMap: { [key: string]: string } = {
    completed: 'Completed',
    cancelled: 'Cancelled',
    no_show: 'No Show',
    confirmed: 'Confirmed',
    pending: 'Pending',
    checked_in: 'Checked-in',
    deposit_pending: 'Deposit Pending'
  };

  const label = statusMap[status] || '-';
  const variant = status in statusMap ? 'custom' : 'secondary';

  return <Pill rounded='lg' variant={variant} appointmentStatus={status}>{label}</Pill>;
};

export const generateSalesReportTableData = (sales: SalesOverviewByService[], tableHeadings: TableFilterProps) => {
  if (!sales) return [];
  const reports = sales?.map?.((sale) => {
    const rowData: any = {};
    tableHeadings.forEach((heading) => {
      if (heading.show) {
        switch (heading.value) {
          case 'client':
            rowData[heading.value] = sale?.client;
            break;
          case 'date':
            rowData[heading.value] = extractDateParts(sale?.paymentDate);
            break;
          case 'item':
            rowData[heading.value] = limitString(sale?.service, 15);
            break;
          case 'quantity':
            rowData[heading.value] = sale?.itemSold
            break;
          case 'discounts':
            rowData[heading.value] = formatInToPrice(sale?.discount)
            break;
          case 'net':
            rowData[heading.value] = formatInToPrice(sale?.netSales)
            break;
          case 'tax':
            rowData[heading.value] = formatInToPrice(sale?.tax)
            break;
          case 'total':
            rowData[heading.value] = formatInToPrice(sale?.totalSales)
            break;
          case 'balance':
            rowData[heading.value] = sale?.saleStatus === 'unpaid' || sale?.saleStatus === 'partially_paid' ? formatInToPrice(sale?.totalSales - sale?.amountClientPaid) : "-"
            break;
          case 'status':
            rowData[heading.value] = sale?.saleStatus ? formatSaleStatusPill(sale?.saleStatus) : "-"
            break;
        }
      }
    });
    return rowData;
  })

  if (!reports || reports?.length === 0) return [];

  // provide a sum object of array, each item in the array
  // const total = {
  //   client: 'Total',
  //   item: '',
  //   quantity: sales?.reduce((sum, item) => sum + item.itemSold, 0),
  //   gross: formatInToPrice(sales?.reduce((sum, item) => sum + item.grossSales, 0)),
  //   discounts: formatInToPrice(sales?.reduce((sum, item) => sum + item.discount, 0)),
  //   refunds: formatInToPrice(sales?.reduce((sum, item) => sum + item.refunds, 0)),
  //   net: formatInToPrice(sales?.reduce((sum, item) => sum + item.netSales, 0)),
  //   tax: formatInToPrice(sales?.reduce((sum, item) => sum + item.tax, 0)),
  //   total: formatInToPrice(sales?.reduce((sum, item) => sum + item.totalSales, 0)),
  //   status: ""
  // }

  return [...reports]
}

export const generateAppointmentSalesTableData = (appointments: Appointment[], tableHeadings: TableFilterProps) => {
  return appointments?.map?.((appointment) => {
    const rowData: any = {};
    tableHeadings.forEach((heading) => {
      if (heading.show) {
        switch (heading.value) {
          case 'date':
            rowData[heading.value] = getDayMonthAndNumberAndTimeFromDate(appointment?.startAt);
            break;
          case 'client':
            rowData[heading.value] = `${appointment.client?.firstName} ${appointment.client?.lastName}`
            break;
          case 'item':
            rowData[heading.value] = convertAppointmentServicesNamesToString(appointment?.appointmentServices)
            break;
          case 'location':
            rowData[heading.value] = appointment?.address
            break;
          case 'staff':
            rowData[heading.value] = convertAppointmentServicesToStaffString(appointment?.appointmentServices)
            break;
          case 'status':
            rowData[heading.value] = appointment.appointmentStatus ? formatAppointmentStatusPill(appointment.appointmentStatus) : "-"
            break;
          case 'price':
            rowData[heading.value] = formatInToPrice(
              appointment.actualTotalPaid
            )
            break;
        }
      }
    });
    return rowData;
  })
}