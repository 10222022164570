import { Menu, Transition } from '@headlessui/react'
import { useModal, useSalonCache, useToast } from 'hooks'
import React, { useEffect, useState } from 'react'
import { Button, Heading, Paragraph, Pill, SvgCharmMenuKebab, SvgForms, SvgNote, ToastProps, ToastWrapper } from 'ui'
import ViewForm from '../modals/ViewForm'
import { WorkFlowForm, WorkFlowForms } from '../types'
import { COLORS } from 'constants/colors'
import DeleteFormModal from '../modals/DeleteFormModal'
import FormStatusModal from '../modals/FormStatusModal'
import { useWorkflowForms } from 'api/useForms'
import CreateForm from './CreateForm'

const Forms = () => {
  const [formsCreationOrModification, setFormsCreationOrModification] = useState(false)
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id');
  const [selectedWorkFlow, setSelectedWorkFlow] = useState<WorkFlowForm | null>(null)
  const {
    data: formsData,
    refetch: refetchWorkFormFlow
  } = useWorkflowForms();
  const workFlowForms = formsData?.data || [];
  useEffect(() => {
    if (salonId) {
      refetchWorkFormFlow()
    }
  }, [salonId])
  const { toast, addToast } = useToast()
  const {
    isVisible,
    openModal,
    closeModal
  } = useModal()
  const {
    isVisible: isDeleteModalVisible,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal
  } = useModal()
  const {
    isVisible: isStatusModalVisible,
    openModal: openStatusModal,
    closeModal: closeStatusModal
  } = useModal()

  const closeCreateForm = () => {
    setFormsCreationOrModification(false)
    setSelectedWorkFlow(null)
    refetchWorkFormFlow()
  }
  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      {!formsCreationOrModification ? <>
        <div className='w-full flex justify-between bg-[#F3F6F699] border-b border-grey-50 px-4 xl:px-10 py-3 xl:py-6'>
          <div className='flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[900]} weight='bold'>
              Form templates
            </Paragraph>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='normal'>
              Create and manage your form templates here
            </Paragraph>
          </div>

          <Button
            variant='primary'
            size='md'
            rounded='md'
            fontSize='b4'
            fontWeight='bold'
            onClick={() => setFormsCreationOrModification(true)}
          > Create new</Button>
        </div>
        <div className="w-full flex flex-col space-y-4 px-2 xl:px-8 py-2 xl:py-4">
          {Array?.isArray(workFlowForms) && workFlowForms?.length ? workFlowForms?.map((form, index) => {
            return (
              <div className='w-full max-w-[600px] flex flex-col space-y-3' key={index}>
                <div className='w-full border border-grey-100 flex min-h-[66px] items-center justify-between shadow-md rounded-md px-2 xl:px-4'>
                  <div className='w-full flex items-center space-x-3'>
                    <div className='w-fit flex justify-between items-start text-green-300 rounded-full p-1.5 bg-[#16796F0D]'>
                      <SvgNote width="24px" height="24px" />
                    </div>
                    <Paragraph size='b3' weight='bold' className=''>
                      {form?.title}
                    </Paragraph>
                    <Pill variant={`${form?.status === 'active' ? 'success' : 'secondary'}`}>{form?.status}</Pill>
                  </div>

                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="flex space-x-2 w-full items-center justify-center px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85 z-0">
                        <SvgCharmMenuKebab width="24px" height="24px" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                        <div className="flex flex-col p-4 space-y-4">
                          <Menu.Item>
                            <span className="text-b4 cursor-pointer flex items-center space-x-2" onClick={() => {
                              setSelectedWorkFlow(form)
                              openModal()
                            }}>
                              View
                            </span>
                          </Menu.Item>
                          <Menu.Item>
                            <span className="text-b4 cursor-pointer flex items-center space-x-2" onClick={() => {
                              setSelectedWorkFlow(form)
                              setFormsCreationOrModification(true)
                            }}>
                              Edit
                            </span>
                          </Menu.Item>
                          {form?.status === 'active' ?
                            <Menu.Item>
                              <span className="text-b4 cursor-pointer flex items-center space-x-2" onClick={() => {
                                setSelectedWorkFlow(form)
                                openStatusModal()
                              }}>
                                De-activate
                              </span>
                            </Menu.Item> : null}
                          {form?.status === 'inactive' ?
                            <Menu.Item>
                              <span className="text-b4 cursor-pointer flex items-center space-x-2" onClick={() => {
                                setSelectedWorkFlow(form)
                                openStatusModal()
                              }}>
                                Activate
                              </span>
                            </Menu.Item> : null}
                          <Menu.Item>
                            <span className="text-b4 cursor-pointer text-red-300 flex items-center space-x-2" onClick={() => {
                              setSelectedWorkFlow(form)
                              openDeleteModal()
                            }}>
                              Delete
                            </span>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            )
          }) : (
            <div className='w-full flex flex-col items-center'>
              <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
                <SvgForms width="100%" height="100%" />
                <Heading variant='h1' size='h10' weight='bold' color={COLORS.GREY[900]}>
                  No forms yet
                </Heading>
                <Paragraph size='b4' color={COLORS.GREY[400]}>
                  Create a form to get started. You can always edit
                </Paragraph>
              </div>
            </div>
          )}
        </div> </> : <>
        <CreateForm closeCreateForm={closeCreateForm} selectedWorkFlow={selectedWorkFlow} formsCreationOrModification={formsCreationOrModification} />
      </>}
      <ViewForm
        isVisible={isVisible}
        closeModal={() => {
          setSelectedWorkFlow(null)
          closeModal()
        }}
        workflowForm={selectedWorkFlow}
      />
      <DeleteFormModal
        isVisible={isDeleteModalVisible}
        closeModal={() => {
          closeDeleteModal()
          setSelectedWorkFlow(null)
        }}
        workflow={selectedWorkFlow}
        addToast={addToast}
      />
      <FormStatusModal
        isVisible={isStatusModalVisible}
        closeModal={() => {
          closeStatusModal()
          setSelectedWorkFlow(null)
        }}
        workflow={selectedWorkFlow}
        addToast={addToast}
      />
    </>
  )
}

export default Forms