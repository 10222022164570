import { gql, useMutation, useQuery } from "@apollo/client";
import { SubscriptionPlan } from "./types/general";

const GET_PLANS = gql`
  query SubscriptionPlans {
    subscriptionPlans {
      createdAt
      featureList
      id
      name
      subtitle
      salonCount
      whatsappCredits
      title
      updatedAt
      subscriptionPlanPrices {
        billingCycle
        createdAt
        price
        salonMaxCount
        updatedAt
        country {
          currency {
            symbol
          }
        }
      }
    }
  }
`;

export const usePlans = () => {
  return useQuery<{
    subscriptionPlans: SubscriptionPlan[]
  }>(GET_PLANS);
};

const GET_BUSINESS_ACTIVE_SUBSCRIPTION = gql`
  query User {
    user {
      business {
        subscriptionPlan {
            createdAt
            featureList
            hidden
            id
            name
            salonCount
            subtitle
            title
            updatedAt
            whatsappCredits
        }
      }
    }
  }
`

export const useGetBusinessActiveSubscription = () => {
  return useQuery<{
    user: {
      business: {
        subscriptionPlan: SubscriptionPlan
      }
    }
  }>(GET_BUSINESS_ACTIVE_SUBSCRIPTION);
}

const SUBSCRIBE_TO_PLAN = gql`
  mutation SubscribeToSubscriptionPlan($input: SubscribeToSubscriptionPlanInput!) {
    subscribeToSubscriptionPlan(input: $input) {
        clientMutationId
        message
        refreshToken
        status
        errors {
            message
            property
        }
        paymentAuthorization {
            accessCode
            authorizationUrl
            reference
        }
    }
  }
`;

export const useSubscribeToPlan = () => {
  const [subscribeToPlan, options] = useMutation<{
    subscribeToSubscriptionPlan: {
      clientMutationId: string,
      message: string,
      refreshToken: string,
      status: number,
      errors: {
        message: string,
        property: string
      }[],
      paymentAuthorization: {
        accessCode: string,
        authorizationUrl: string,
        reference: string
      }
    }
  }>(SUBSCRIBE_TO_PLAN);

  return {subscribeToPlan, ...options};
};

const UNSUBSCRIBE_PLAN = gql`
  mutation UnsubscribeFromSubscriptionPlan($input: UnsubscribeFromSubscriptionPlanInput!) {
    unsubscribeFromSubscriptionPlan(input:$input) {
        clientMutationId
        message
        refreshToken
        status
        errors {
            message
            property
        }
    }
  }
`

export const useUnsubscribePlan = () => {
  const [unsubscribePlan, options] = useMutation<{
    unsubscribeFromSubscriptionPlan: {
      clientMutationId: string,
      message: string,
      refreshToken: string,
      status: number,
      errors: {
        message: string,
        property: string
      }[],
    }
  }>(UNSUBSCRIBE_PLAN);

  return {unsubscribePlan, ...options};
}
