import React, { useMemo, useState } from 'react'
import { ActionModalProps } from '../types'
import { COLORS } from 'constants/colors'
import Paragraph from 'ui/atoms/paragraph/Paragraph'
import Heading from 'ui/atoms/heading/Heading'
import Button from 'ui/atoms/button/Button'
import { SvgCheckMarkGreen } from 'ui'
import { useUserCache } from 'hooks/useUserCache'
import { formatInToPrice } from '../../../utils/misc'
import { useGetAppointment } from 'api/useAppointments'
import { useUser } from 'api/useAccount'

const NoShowAppointmentModal = ({
  isVisible,
  closeModal,
  appointmentId, salonId
}: ActionModalProps) => {
  const [applyCancellationFee, setApplyCancellationFee] = useState(false)
  const [chargeCustomerWallet, setChargeCustomerWallet] = useState(false)
  const { getBusinessData } = useUserCache()
  const {
    data,
    refetch,
    loading
  } = useUser()
  const business = useMemo(() => data?.user?.business, [data])
  const [step, setStep] = useState<number>(1)

  const {
    loading: isLoading,
    data: appointmentDetailsData,
    refetch: refetchAppointmentDetails
  } = useGetAppointment(appointmentId, salonId)
  const appointment = useMemo(() => appointmentDetailsData?.appointment, [appointmentDetailsData])


  const proceed = () => {
    if (step === 1 && applyCancellationFee) {
      setStep(2)
    } else {
      closeModal('cancel', applyCancellationFee, chargeCustomerWallet)
      setStep(1)
    }
  }

  const cancel = () => {
    () => closeModal('back', applyCancellationFee)
    if (step === 2) {
      setStep(1)
    } else {
      closeModal('back', applyCancellationFee)
      setStep(1)
    }
  }

  const getCancellationAmount = () => {
    if (business?.cancellationFee === business?.noShowFee && business?.cancellationFeeType === 'percentage') {
      if (business?.cancellationFeeType !== 'percentage') {
        return formatInToPrice(business?.cancellationFee + appointment?.outstandingFee)
      } else {
        const cancellationFee = ((business?.cancellationFee / 100) * appointment?.totalServicesAmount) + appointment?.outstandingFee
        return formatInToPrice(cancellationFee)
      }
    } else {
      return formatInToPrice(business?.noShowFee)
    }
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50'>
      <div className='table-row'>
        {/* <div className="table-cell align-middle"> */}
        <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9'>
          <div className='flex flex-col space-y-6'>
            <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>
              Confirm no show
            </Heading>
            <Paragraph
              size='b5'
              weight='medium'
              className='w-full'
              color={COLORS.GREY[400]}
            >
              Select the applicable option
            </Paragraph>

            {step === 1 ? <>
              {business.noShowFee > 0 && <div
                className={`p-5 min-h-10 rounded-md mb-6 cursor-pointer ${applyCancellationFee
                  ? 'border-2'
                  : 'border-solid border border-grey-100'
                  }`}
                style={{ width: '600px' }}
                onClick={() => setApplyCancellationFee(true)}
              >
                <div className='flex items-center gap-2'>
                  <Paragraph weight={applyCancellationFee ? 'bold' : 'normal'}>
                    Confirm and charge no show fee of {getCancellationAmount()}
                  </Paragraph>
                  {applyCancellationFee && <SvgCheckMarkGreen />}
                </div>
                <Paragraph color={COLORS.GREY[400]} size='b5'>
                  The payment link will be sent to the client
                </Paragraph>
              </div>}
              <div
                className={`p-5 min-h-10 rounded-md mb-10 cursor-pointer ${!applyCancellationFee
                  ? 'border-2'
                  : 'border-solid border border-grey-100'
                  }`}
                style={{ width: '600px' }}
                onClick={() => setApplyCancellationFee(false)}
              >
                <div className='flex items-center gap-2  '>
                  <Paragraph weight={!applyCancellationFee ? 'bold' : 'normal'}>
                    Confirm and waive no show fee
                  </Paragraph>
                  {!applyCancellationFee && <SvgCheckMarkGreen />}

                </div>
                <Paragraph color={COLORS.GREY[400]} size='b5'>
                  The cancellation fee will not be charged
                </Paragraph>

              </div>
            </> : null}

            {step === 2 ?
              <>
                <div
                  className={`p-5 min-h-10 rounded-md mb-6 cursor-pointer w-[600px] ${!chargeCustomerWallet
                    ? 'border-2 border-green-300'
                    : 'border-solid border border-grey-100'
                    }`}
                  onClick={() => setChargeCustomerWallet(false)}
                >
                  <div className='flex items-center gap-2'>
                    <Paragraph weight={!chargeCustomerWallet ? 'bold' : 'normal'}>
                      Send payment link
                    </Paragraph>
                    {applyCancellationFee && <SvgCheckMarkGreen />}
                  </div>
                  <Paragraph color={COLORS.GREY[400]} size='b5'>
                    The payment link will be sent to the client
                  </Paragraph>
                </div>

                <div
                  className={`p-5 min-h-10 rounded-md mb-10 cursor-pointer w-[600px] ${chargeCustomerWallet
                    ? 'border-2 border-green-300'
                    : 'border-solid border border-grey-100'
                    }`}
                  onClick={() => setChargeCustomerWallet(true)}
                >
                  <div className='flex items-center gap-2  '>
                    <Paragraph weight={chargeCustomerWallet ? 'bold' : 'normal'}>
                      Deduct from customer wallet
                    </Paragraph>
                    {!applyCancellationFee && <SvgCheckMarkGreen />}

                  </div>
                  <Paragraph color={COLORS.GREY[400]} size='b5'>
                    Balance: {formatInToPrice(appointment?.client?.walletBalance)}
                  </Paragraph>

                </div>
              </>
              : null}
            <Button
              variant='primary'
              size='lg'
              rounded='lg'
              fontSize='b5'
              className='capitalize'
              onClick={proceed}
            >
              Okay
            </Button>
            <Button
              variant='text'
              size='none'
              rounded='none'
              fontWeight="semiBold"
              fontSize='b5'
              className='mx-0 text-red-500'
              onClick={cancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  ) : null
}

export default NoShowAppointmentModal
