import { Menu, Transition } from "@headlessui/react"
import { useGetCommunicationLogs } from "api/useClient"
import { formatDateToOriginalDate, formatTableHeadersFilterArray, getDefaultQueryDates, getNumberMonthAndYearFromDate, limitString } from "components/utils/misc"
import { Client, CommunicationLog } from "core/generated"
import { useModal, useSalonCache } from "hooks"
import React, { Fragment } from "react"
import { useEffect, useMemo, useState } from "react"
import { CalendarPicker, Checkbox, Paginator, Paragraph, Pill, SearchTerm, SvgFilter, SvgGreenSignal, SvgGreySignal, SvgRedSignal, Table } from "ui"
import Label from "ui/atoms/formLabel/Label"
import ViewMessageLog from "./ViewMessageLog"


const ClientCommunicationLog = ({
  client
}: {
  client: Client
}) => {
  const COMMUNICATION_LOG_HEADINGS = [
    {
      label: 'Subject line',
      value: 'subject',
      show: true
    },
    {
      label: 'Location',
      value: 'salon',
      show: true
    },
    {
      label: 'Body',
      value: 'body',
      show: true
    },
    {
      label: 'Date',
      value: 'date',
      show: true
    },
    {
      label: 'Type',
      value: 'communicationType',
      show: true
    },
    {
      label: 'Status',
      value: 'deliveryStatus',
      show: true
    }
  ]

  const MOBILE_HEADERS = [
    {
      title: 'Subject line',
      key: 'subject',
    },
    {
      title: 'Date',
      key: 'date',
    },
    {
      title: 'Type',
      key: 'communicationType',
    },
    {
      title: 'Status',
      key: 'deliveryStatus',
    }
  ]
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const [communicationLogHeadings, setCommunicationLogHeadings] = useState(
    COMMUNICATION_LOG_HEADINGS
  )
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [communicationLog, setCommunicationLog] = useState<CommunicationLog>()
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const {
    data: communicationLogsData,
    loading: communicationLogsLoading,
    refetch: refetchCommunicationLogs
  } = useGetCommunicationLogs({
    clientId: client?.id,
    salonId: getSalonFieldValue('id'),
    q: debouncedSearchQuery,
    startDate: formatDateToOriginalDate(selectedDates?.[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates?.[1], 'end'),
    before,
    after,
    first: 20
  })
  const communicationLogs = useMemo(
    () => communicationLogsData?.clientCommunicationLogs,
    [communicationLogsData])
  const handleHeadingCheckboxChange = (value: string) => {
    const updatedHeadings = communicationLogHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setCommunicationLogHeadings(updatedHeadings)
  }

  const generateCommunicationLogsHistoryTableData = (
    communicationLogHistory: CommunicationLog[]
  ) => {
    return communicationLogHistory?.map?.((x) => {
      return {
        subject: limitString(x.subject, 25),
        locatin: limitString(x?.salon?.branchName, 25),
        body: limitString(x.body.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '').replace(/<\/?[^>]+(>|$)/g, '').replace(/\n/g, '').replace('/* Email styles need to be inline */', '').trim(), 25),
        date: getNumberMonthAndYearFromDate(
          x?.createdAt
        ),
        communicationType: () => (
          <div className="flex flex-col gap-2">
            <Pill
              variant={x.communicationType === 'Reminder' ? 'success' : 'warning'}
              className="capitalize"
            >
              {x?.messageType}
            </Pill>
            <Pill
              variant={x.communicationType === 'Reminder' ? 'success' : 'warning'}
              className="capitalize"
            >
              {x?.communicationType}
            </Pill>
          </div>
        ),
        status: () =>
          x?.deliveryStatus === 'sent' ? (
            <div className='flex items-center'>
              <SvgGreenSignal width='16px' height='16px' />{' '}
              <span className='ml-2 text-grey-900 text-b5'>Sent</span>
            </div>
          ) : x?.deliveryStatus === 'pending' ? (
            <div className='flex items-center'>
              <SvgGreySignal width='16px' height='16px' />{' '}
              <span className='ml-2 text-grey-900 text-b5'>Pending</span>
            </div>
          ) : x?.deliveryStatus === 'failed' ? (
            <div className='flex items-center'>
              <SvgRedSignal width='16px' height='16px' />{' '}
              <span className='ml-2 text-grey-900 text-b5'>Failed</span>
            </div>
          ) : null
      }
    })
  }

  const communicationLogHistory = generateCommunicationLogsHistoryTableData(
    communicationLogs?.nodes
  )

  const goToNext = () => {
    if (communicationLogs?.pageInfo?.hasNextPage) {
      // getCommunicationLogHistory(
      //   null,
      //   null,
      //   null,
      //   communicationLogs.pageInfo.endCursor,
      //   debouncedSearchQuery
      // )
      setAfter(communicationLogs?.pageInfo?.endCursor)
      setBefore(null)
      refetchCommunicationLogs()
    }
  }

  const goToPrev = () => {
    if (communicationLogs?.pageInfo?.hasPreviousPage) {
      setBefore(communicationLogs?.pageInfo?.startCursor)
      setAfter(null)
      refetchCommunicationLogs()
    }
  }

  const { openModal, closeModal, isVisible } = useModal()

  const openMessage = (id: number) => {
    setCommunicationLog(communicationLogs.nodes[id])
    openModal()
  }

  useEffect(() => {
    if (debouncedSearchQuery)
      refetchCommunicationLogs()
  }, [debouncedSearchQuery, selectedDates, refetchCommunicationLogs])

  useEffect(() => {
    refetchCommunicationLogs()
  }, [salonId, refetchCommunicationLogs])

  return (
    <>
      <div className='w-full flex justify-between items-center space-x-4 p-3'>
        <div className='flex items-center gap-2 w-[300px]'>
          <SearchTerm
            placeholder='Search'
            setDebouncedSearchQuery={setDebouncedSearchQuery}
            size='md'
          />
          <Menu as='div' className='relative inline-block text-left'>
            <div>
              <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                <SvgFilter width='20px' height='20px' />
                <span className='hidden xl:inline'>Filter</span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                <div className='flex flex-col p-4 space-y-4'>
                  {Array.isArray(communicationLogHeadings) &&
                    communicationLogHeadings.length &&
                    communicationLogHeadings.map((heading, index) => (
                      <Label
                        className='flex space-x-2 items-center cursor-pointer'
                        key={index}
                        htmlFor={heading.value}
                        onClick={() =>
                          handleHeadingCheckboxChange(heading.value)
                        }
                      >
                        <Checkbox
                          isChecked={heading.show}
                          borderType='Black'
                          size='Sm'
                        />
                        <span>{heading.label}</span>
                      </Label>
                    ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className='flex items-center max-w-[400px]'>
          <Paragraph className=''>Showing:</Paragraph>
          <div className='w-2/3'>
            <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          </div>
          <div className='ml-2'>
            <Paginator
              pageInfo={communicationLogs?.pageInfo}
              {...{ goToNext, goToPrev }}
            />
          </div>
        </div>
      </div>
      <Table
        headers={formatTableHeadersFilterArray(communicationLogHeadings)}
        mobileHeaders={MOBILE_HEADERS}
        rows={communicationLogHistory}
        onClick={openMessage}
      />
      <ViewMessageLog
        isVisible={isVisible}
        closeModal={closeModal}
        communicationLog={communicationLog}
      />
    </>
  )
}

export default ClientCommunicationLog