import React from 'react'
import Paragraph from 'ui/atoms/paragraph/Paragraph'
import { Modal } from 'ui/templates/modal/Modal'
import { COLORS } from 'constants/colors'
import { convertAppointmentServicesNamesToString, convertAppointmentServicesNamesToStringWithStaffAndPrice, convertSalonServicesNamesToString } from './bookingSales/utils'
import { cleanUpText, formatInToPrice, getDayMonthAndNumberAndTimeFromDate } from '../utils/misc'
import Button from 'ui/atoms/button/Button'
import { ClientBookingModalProps } from './types'

const BookingDetailsModal = ({
  isOpen,
  onClose,
  bookingOrSaleData,
}: ClientBookingModalProps) => {
  return (
    <Modal
      title={bookingOrSaleData?.type === 'appointment' ? "Booking details" : "Sale details"}
      show={isOpen}
      closeModal={onClose}
    >
      <>
        {bookingOrSaleData && (
          <div className='w-full flex flex-col my-12 space-y-6'>
            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Services</Paragraph>
              {bookingOrSaleData?.type === 'appointment' ?
                  <>
                  {Array.isArray(bookingOrSaleData?.appointmentServices) && bookingOrSaleData?.appointmentServices.map((service, index) => {
                    const serviceName = service?.name || '';
                    const price = formatInToPrice(service?.price) || '';
                    const staffList = service.appointmentServiceStaff
                      ?.map(staff => staff?.salonStaff?.user?.fullName || staff?.salonStaff?.user?.firstName || '')
                      .filter(name => name !== '')
                      .join(', ') || '';
                    
                    // Add service number for multiple bookingOrSaleData?.appointmentServices
                    const serviceNumber = bookingOrSaleData?.appointmentServices.length > 1 ? `${index + 1}. ` : '';
                    
                    return (
                      <Paragraph key={index} size='b4' color={COLORS.BLACK}>
                        {`${serviceNumber}${serviceName} • ${price} • ${staffList}`}
                      </Paragraph>
                    );
                  })}
                </>: 
              <Paragraph size='b4' color={COLORS.BLACK}>{convertSalonServicesNamesToString(bookingOrSaleData?.services)}</Paragraph>}
            </div>
            <div className='flex flex-col xl:grid xl:grid-cols-2 gap-6'>
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Appointment date</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{getDayMonthAndNumberAndTimeFromDate(bookingOrSaleData?.startAt) || "-"}</Paragraph>
              </div>
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Created date</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{getDayMonthAndNumberAndTimeFromDate(bookingOrSaleData?.createdAt) || "-"}</Paragraph>
              </div>
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Check out</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.status === "completed" && getDayMonthAndNumberAndTimeFromDate(bookingOrSaleData?.endAt) || "-"}</Paragraph>
              </div>
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Payment method</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.paymentMethod}</Paragraph>
              </div>
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Amount</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{formatInToPrice(bookingOrSaleData?.amount)}</Paragraph>
              </div>
              {bookingOrSaleData.packageName ? <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Voucher name</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.packageName}</Paragraph>
              </div> : null}
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Points</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.points}</Paragraph>
              </div>
              <div className='w-full flex flex-col'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Booking ID</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.bookingId}</Paragraph>
              </div>
              {bookingOrSaleData?.appointmentMilestone ?
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>Reward</Paragraph>
                  <Paragraph
                    size='b4'
                    color={COLORS.BLACK}
                    weight='semiBold'
                    className='flex space-x-1'
                  >
                    <span>{
                      bookingOrSaleData?.appointmentMilestone?.milestone?.customerReceivesType === 'percentage'
                        ? `${bookingOrSaleData?.appointmentMilestone?.milestone?.customerReceives}%`
                        : `${formatInToPrice(bookingOrSaleData?.appointmentMilestone?.milestone?.customerReceives)?.replace(".00", "")}`
                    } - </span>
                    <span>Reward discount in use</span>

                  </Paragraph>
                </div> : null}
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>Location</Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.location}</Paragraph>
              </div>
            </div>
            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Payments</Paragraph>
              {Array.isArray(bookingOrSaleData?.payments) && bookingOrSaleData?.payments.map((payment, index) => (
                <Paragraph key={index} size='b4' color={COLORS.BLACK}>
                  {formatInToPrice(payment?.amount)} paid by {cleanUpText(payment?.mode)} at {getDayMonthAndNumberAndTimeFromDate(payment?.createdAt)}
                </Paragraph>
              ))}
            </div>
            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Note</Paragraph>
              <Paragraph size='b4' color={COLORS.BLACK}>{bookingOrSaleData?.note}</Paragraph>
            </div>
          </div>
        )}
        <Button
          variant='primary'
          className=''
          size='lg'
          rounded='lg'
          onClick={() => onClose()}
        >
          Done
        </Button>
      </>
    </Modal>
  )
}

export default BookingDetailsModal