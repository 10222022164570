import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  CalendarPicker,
  Heading,
  Layout,
  Modal,
  Paginator,
  Paragraph,
  Pill,
  ReportTable,
  SearchTerm,
  Table,
  TableFilterProps,
  Tabs,
  ToastWrapper
} from 'ui'
import { COLORS } from 'constants/colors'
import {
  convertTextToLowercaseAndSnakeCase,
  createFileNameFromDates,
  formatDateToOriginalDate,
  formatInToPrice,
  formatTableHeadersFilterArray,
  getDefaultQueryDates,
  getNumberMonthAndYearFromDate,
  handleDownload,
  handleExcelDownload
} from '../components/utils/misc'
import { useModal, useToast } from 'hooks'
import {
  PackagesOverview,
  PackagesSales} from 'core/generated'
import { useSalonCache } from 'hooks/useSalonCache'
import { SvgArrowBack, SvgCalendar, SvgChevronLeft, SvgCreditCard, SvgInfo, SvgLocationPin, SvgSale, SvgSolarGiftLinear, SvgUser } from 'ui'
import FullTable from 'ui/organism/table/Table'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader'
import ExportDataToCsvOrXlsx from '../components/uicomponents/ExportDataToCsvOrXlsx'
import { DEFAULT_CURRENCY } from 'constants/currency'
import { usePackageOverviewSummary, usePackageSalesReport, usePackagesOverviewReportList, usePackagesOverviewReportSummary, useRedeemedPackagesSales } from 'api/useReports'
import { canPerformAction, checkPageRightStatus } from 'components/utils/permission'
import AddonSubscription from 'components/uicomponents/AddonSubscription'
import { useLocation } from 'react-router-dom'

const PACKAGE_REPORTS_TAB_NAME = {
  PACKAGES_OVERVIEW: 'Vouchers Overview',
  PACKAGES_SALES: 'Vouchers Sales',
  REDEEMED_PACKAGES: 'Redeemed Voucher'
}

const PackagesReports = () => {
  const PACKAGE_REPORTS_TABS = [
    {
      key: PACKAGE_REPORTS_TAB_NAME.PACKAGES_OVERVIEW,
      title: PACKAGE_REPORTS_TAB_NAME.PACKAGES_OVERVIEW,
      show: true
    },
    {
      key: PACKAGE_REPORTS_TAB_NAME.PACKAGES_SALES,
      title: PACKAGE_REPORTS_TAB_NAME.PACKAGES_SALES,
      show: true
    },
    {
      key: PACKAGE_REPORTS_TAB_NAME.REDEEMED_PACKAGES,
      title: PACKAGE_REPORTS_TAB_NAME.REDEEMED_PACKAGES,
      show: true
    }
  ]
  const [activeTab, setActiveTab] = useState<string>(
    PACKAGE_REPORTS_TABS?.filter((tab) => tab.show)[0].key
  )

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlTab = params.get('tab');

  useEffect(() => {
    if (urlTab) {
      const checkTab = PACKAGE_REPORTS_TABS?.find((tab) => convertTextToLowercaseAndSnakeCase(tab?.key) === convertTextToLowercaseAndSnakeCase(urlTab));
      if (checkTab && checkTab?.show) {
        setActiveTab(checkTab?.key)
      }
    }
  }, [urlTab])

  const changeTab = (tab: string) => {
    setActiveTab(tab)
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', convertTextToLowercaseAndSnakeCase(tab));
    window.history.replaceState(null, '', `?${searchParams.toString()}`);
  }

  const getTabView = () => {
    switch (activeTab) {
      case PACKAGE_REPORTS_TAB_NAME.PACKAGES_OVERVIEW:
        return checkPageRightStatus('vouchers', 'Catalogue::Packages::ViewPackage') === 'showPage' ? <PackagesOverview_ /> : <AddonSubscription module='vouchers' addToast={null} reloadPage={null} />
      case PACKAGE_REPORTS_TAB_NAME.PACKAGES_SALES:
        return checkPageRightStatus('vouchers', 'Catalogue::Packages::ViewPackage') === 'showPage' ? <PackagesList /> : <AddonSubscription module='vouchers' addToast={null} reloadPage={null} />
      case PACKAGE_REPORTS_TAB_NAME.REDEEMED_PACKAGES:
        return checkPageRightStatus('vouchers', 'Catalogue::Packages::ViewPackage') === 'showPage' ? <RedeemedPackages /> : <AddonSubscription module='vouchers' addToast={null} reloadPage={null} />
    }
  }

  return (
    <Layout pageTitle={'Vouchers'} pageLevel={3}>
      <Tabs
        tabs={PACKAGE_REPORTS_TABS}
        activeTab={activeTab}
        setActiveTab={changeTab}
      />
      <div className='w-full pb-20'>{getTabView()}</div>
    </Layout>
  )
}

const PackagesOverview_ = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const { toast, addToast } = useToast()
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const Headers = [
    { name: 'Voucher name', span: 1 },
    { name: 'Sold', span: 1 },
    { name: 'Redeemed', span: 1 },
    { name: 'Outstanding', span: 1 },
    { name: 'Sold Value', span: 1 }
  ]

  const {
    data: packagesOverviewData,
    loading: loadingPackagesOverview,
    refetch: refetchPackagesOverviewAsync
  } = usePackagesOverviewReportSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates[1], 'end')
  })
  const packagesOverviewSummary = useMemo(() => packagesOverviewData?.packagesOverviewSummaryReport, [packagesOverviewData])

  const {
    data: packagesOverviewListData,
    loading: loadingPackagesOverviewList,
    refetch: refetchPackagesOverviewListAsync
  } = usePackagesOverviewReportList({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates[1], 'end'),
    first: 30, before, after,
  })
  const packagesOverview = useMemo(() => packagesOverviewListData?.packagesOverviewReport, [packagesOverviewListData])

  useEffect(() => {
    refetchPackagesOverviewAsync()
    refetchPackagesOverviewListAsync()
  }, [selectedDates])

  const loadPackagesOverviewShimmer = loadingPackagesOverviewList || loadingPackagesOverview;

  const generatePackagesOverviewTableData = (data: PackagesOverview[]) => {
    if (!data) return []
    const x = data.map((x) => ({
      name: x.packageName,
      totalPackageVouchers: x.sold,
      totalRedeemed: x.redeemed,
      totalOutstanding: x.outstanding,
      totalPrice: formatInToPrice(x.soldValue)
    }))
    x.push({
      name: 'Total',
      totalPackageVouchers: data.reduce((n, { sold }) => n + sold, 0),
      totalRedeemed: data.reduce((n, { redeemed }) => n + redeemed, 0),
      totalOutstanding: data.reduce((n, { outstanding }) => n + outstanding, 0),
      totalPrice: formatInToPrice(
        data.reduce((n, { soldValue }) => n + soldValue, 0)
      )
    })
    return x
  }

  const rows = generatePackagesOverviewTableData(packagesOverview?.nodes)

  const formatSalonOverviewCsvDownloadData = (
    salonOverview: PackagesOverview[]
  ) => {
    return salonOverview.map((row) => ({
      'Package Name': row.packageName,
      Sold: row.sold,
      Redeemed: row.redeemed,
      Outstanding: row.outstanding,
      'Sold Value': formatInToPrice(row.soldValue)
    }))
  }

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_voucher_overview_report`;
  const _handleDownload = () => {
    if (packagesOverview) {
      handleDownload(formatSalonOverviewCsvDownloadData(packagesOverview?.nodes), filename);
    }
  }

  const downloadExcel = () => {
    if (packagesOverview) {
      handleExcelDownload(formatSalonOverviewCsvDownloadData(packagesOverview?.nodes), filename, 'Vouchers overview')
    }
  }

  const goToNext = () => {
    if (packagesOverview?.pageInfo?.hasNextPage) {
      setBefore(null)
      setAfter(packagesOverview?.pageInfo?.endCursor)
      refetchPackagesOverviewListAsync()
    }
  }

  const goToPrev = () => {
    if (packagesOverview?.pageInfo?.hasPreviousPage)
      setAfter(null)
    setBefore(packagesOverview?.pageInfo?.startCursor)
    refetchPackagesOverviewListAsync()
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      {loadPackagesOverviewShimmer ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Vouchers overview
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-2 xl:grid-cols-5 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSale width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total sold
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.totalSold}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSolarGiftLinear width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total redeemed
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.totalRedeemed}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSolarGiftLinear width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total outstanding
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.totalOutstanding}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCreditCard width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total sold value ({DEFAULT_CURRENCY})
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.totalSoldValue}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSolarGiftLinear width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Top selling package
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.topSellingPackage}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        <div className='flex space-x-2'>
          <Paginator pageInfo={packagesOverview?.pageInfo} {...{ goToNext, goToPrev }} />
        </div>
      </div>
      <div className='w-full hidden md:flex flex-col space-y-12 overflow-x-scroll'>
        <div className='w-full flex flex-col h-fit border-t border-grey-100'>
          <ReportTable rows={rows} headers={Headers} hasFooterBg={true} />
        </div>
      </div>
    </>
  )
}

const PackagesList = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const salonCountryCode = getSalonFieldValue('countryCode')
  const { toast, addToast } = useToast()
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [packageSale, setPackageSale] = useState<PackagesSales>()
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('')
  const [before, setBefore] = useState<string | null>(null);
  const [after, setAfter] = useState<string | null>(null);
  const PACKAGES_SALES_HEADINGS = [
    {
      label: 'Voucher name',
      value: 'name',
      show: true
    },
    {
      label: 'Voucher code',
      value: 'code',
      show: true
    },
    {
      label: 'Client',
      value: 'client',
      show: true
    },
    {
      label: 'Services included',
      value: 'services',
      show: true
    },
    {
      label: 'Date of purchase',
      value: 'purchaseDate',
      show: true
    },
    {
      label: 'Date of last use',
      value: 'lastUseDate',
      show: true
    },
    {
      label: 'Price',
      value: 'price',
      show: true
    }
  ]

  const [packagesSalesHeadings, setPackagesSalesHeadings] = useState(
    PACKAGES_SALES_HEADINGS
  )

  const {
    data: packagesOverviewSummaryData,
    loading: packagesOverviewSummaryLoading,
    refetch: packagesOverviewSummaryRefetch
  } = usePackageOverviewSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const packagesOverviewSummary = useMemo(() => {
    if (packagesOverviewSummaryData && packagesOverviewSummaryData?.packagesOverviewSummaryReport) {
      return packagesOverviewSummaryData.packagesOverviewSummaryReport
    }
    return null
  }, [packagesOverviewSummaryData])

  const {
    data: packagesSalesData,
    loading: packagesSalesLoading,
    refetch: packagesSalesRefetch,
  } = usePackageSalesReport({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
    before,
    after,
    q: debouncedSearchQuery
  })
  const packageSales = useMemo(() => {
    if (packagesSalesData && packagesSalesData?.packagesSalesReport) {
      return packagesSalesData?.packagesSalesReport
    }
  }, [packagesSalesData])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in clientHeadings then update the show
    const updatedHeadings = packagesSalesHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setPackagesSalesHeadings(updatedHeadings)
  }

  const generateSalonPackageListTableData = (
    data?: PackagesSales[],
    tableHeadings?: TableFilterProps,
    countryCode?: string
  ) => {
    if (!data) return []
    return data?.map((x) => {
      const rowData = {}
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = x?.packageName
              break
            case 'code':
              rowData[heading.value] = x?.voucherCode
              break
            case 'client':
              rowData[heading.value] = x?.client
              break
            case 'services':
              rowData[heading.value] = x?.servicesIncluded
                ?.map((service) => service.name)
                .join(', ')
              break
            case 'purchaseDate':
              rowData[heading.value] = getNumberMonthAndYearFromDate(
                x?.dateOfPurchase
              )
              break
            case 'lastUseDate':
              rowData[heading.value] = x?.dateOfLastUse
                ? getNumberMonthAndYearFromDate(x?.dateOfLastUse)
                : null
              break
            case 'price':
              rowData[heading.value] = formatInToPrice(x?.price)
              break
          }
        }
      })

      return rowData
    })
  }

  const rows = generateSalonPackageListTableData(packageSales?.nodes, packagesSalesHeadings, salonCountryCode || "NG")

  const formatSalonPackageListCsvDownloadData = (
    data: PackagesSales[],
    countryCode?: string
  ) => {
    return data.map((row) => ({
      'Package Name': row?.packageName,
      Client: row?.client,
      'Services Included': row?.servicesIncluded,
      'Date of purchase': getNumberMonthAndYearFromDate(
        row?.dateOfPurchase
      ),
      'Date of last use': getNumberMonthAndYearFromDate(row?.dateOfLastUse),
      Price: formatInToPrice(row?.price),
      'Voucher code': row?.voucherCode
    }))
  }

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_salon_voucher_list_report`;
  const _handleDownload = () => {
    if (packageSales) {
      handleDownload(formatSalonPackageListCsvDownloadData(packageSales?.nodes, salonCountryCode || "NG"), filename);
    }
  }

  const downloadExcel = () => {
    if (packageSales) {
      handleExcelDownload(formatSalonPackageListCsvDownloadData(packageSales?.nodes, salonCountryCode || "NG"), filename, 'Salon voucher list')
    }
  }

  const { openModal, closeModal, isVisible } = useModal()

  const handleViewPackage = (id: number) => {
    setPackageSale(packageSales?.nodes[id])
    openModal()
  }

  useEffect(() => {
    packagesOverviewSummaryRefetch()
    packagesSalesRefetch()
  }, [selectedDates])

  useEffect(() => {
    packagesSalesRefetch()
  }, [debouncedSearchQuery])

  const goToNext = () => {
    if (packageSales?.pageInfo?.hasNextPage) {
      setBefore(null)
      setAfter(packageSales.pageInfo.endCursor)
      packagesSalesRefetch()
    }
  }

  const goToPrev = () => {
    if (packageSales?.pageInfo?.hasPreviousPage) {
      setAfter(null)
      setBefore(packageSales.pageInfo.startCursor)
      packagesSalesRefetch()
    }
  }
  return (
    <>
      <ToastWrapper toast={toast} />
      {packagesOverviewSummaryLoading || packagesSalesLoading ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Voucher list
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-2 xl:grid-cols-4 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[276px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSale width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total sold
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.totalSold}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[276px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSolarGiftLinear width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total redeemed
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.totalRedeemed}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[276px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCreditCard width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total sold value ({DEFAULT_CURRENCY})
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.totalSoldValue}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[276px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSolarGiftLinear width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Top selling package
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.topSellingPackage}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col md:flex-row items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        <div className='w-full xl:w-8/12 flex items-center justify-end space-x-6'>
          <div className='w-[200px]'>
            <SearchTerm setDebouncedSearchQuery={setDebouncedSearchQuery} size='sm' />
          </div>
          <Paginator pageInfo={packageSales?.pageInfo} {...{ goToNext, goToPrev }} />
        </div>
      </div>
      <div className='w-full hidden md:flex flex-col space-y-12 overflow-x-scroll'>
        <div className='w-full flex flex-col h-fit border-t border-grey-100'>
          <FullTable rows={rows} headers={formatTableHeadersFilterArray(packagesSalesHeadings)}
            onClick={handleViewPackage}
            hasBorder={true}
            tableOptions={{
              view: true,
              edit: true,
              delete: true,
              duplicate: false
            }}
          />
        </div>
        <ViewPackageSalesModal
          packageSale={packageSale}
          closeModal={closeModal}
          isVisible={isVisible}
        />
      </div>
    </>
  )
}

type ViewPackageSalesModalProps = {
  isVisible: boolean
  closeModal: () => void
  packageSale?: PackagesSales
}

const ViewPackageSalesModal = ({
  isVisible,
  closeModal,
  packageSale
}: ViewPackageSalesModalProps) => {
  const nameMap = {
    packageName: 'Voucher Name',
    servicesIncluded: 'Services',
    voucherCode: 'Voucher Code',
    price: 'Total Price',
    totalPrice: 'Total Price',
    totalServices: 'Total Services',
    voucherBalance: 'Voucher Balance',
    servicesRemaining: 'Services Remaining',
    location: 'Location',
    dateOfPurchase: 'Date of Purchase',
    status: 'Status'
  }
  return (
    <>
      <Modal show={isVisible} closeModal={closeModal} variant='right'>
        <div className='relative'>
          <div className='fixed bg-white w-full flex border-b border-grey-100 cursor-pointer'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={closeModal}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={closeModal}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
            </div>
          </div>

          <div
            style={{ marginTop: '70px' }}
            className='w-full max-w-5xl flex flex-col space-y-6 h-fit p-6'
          >
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'left'
                }}
                className='hidden xl:flex flex-col xl:justify-between xl:items-left space-x-4'
              >
                <div className='flex flex-col space-y-4'>
                  <div className='w-full flex space-x-4 items-left'>
                    <Paragraph size='b2' weight='semiBold'>
                      Package details
                    </Paragraph>
                  </div>
                  <div className='w-full flex space-x-4 items-left'>
                    <Paragraph
                      size='b4'
                      weight='normal'
                      color={COLORS.GREY[300]}
                    >
                      Find information about voucher here
                    </Paragraph>
                  </div>

                  {packageSale && (
                    <>
                      <div className='w-full flex border border-grey-50 rounded-md p-4 flex-col space-y-3'>
                        {Object.entries(packageSale).map(
                          ([key, value]) =>
                            nameMap[key] &&
                            value !== null && (
                              <div
                                key={key}
                                className='px-2 flex justify-between items-center space-x-4 bg-grey-10'
                              >
                                <div className='flex flex-col space-y-1 p-2'>
                                  <Paragraph
                                    size='b4'
                                    color='grey-300'
                                    className='text-left'
                                  >
                                    {nameMap[key]}
                                  </Paragraph>
                                </div>
                                <div className='flex flex-col space-y-1'>
                                  <Paragraph
                                    size='b4'
                                    color='grey-800'
                                    weight='semiBold'
                                    className='text-right'
                                  >
                                    {Array.isArray(value) &&
                                      key === 'servicesIncluded'
                                      ? value.map((service, index) => (
                                        <div key={index}>
                                          <Pill variant={'light'}>
                                            {service.name}
                                            <span className='bg-grey-300 text-white px-1 ml-2 rounded-full text-xs'>
                                              {service.quantity}
                                            </span>
                                          </Pill>
                                        </div>
                                      ))
                                      : Array.isArray(value)
                                        ? value.map((service, index) => (
                                          <div key={index}>
                                            <Pill variant={'light'}>
                                              {service.name}
                                              <span className='bg-grey-300 text-white px-1 ml-2 rounded-full text-xs'>
                                                {service.quantity}/
                                                {service.total}
                                              </span>
                                            </Pill>
                                          </div>
                                        ))
                                        : key === 'price'
                                          ? formatInToPrice(value)
                                          : value}
                                  </Paragraph>
                                </div>
                              </div>
                            )
                        )}
                      </div>

                      <div>
                        <Table
                          headers={[
                            { label: 'Service Name', value: 'serviceName' },
                            {
                              label: 'Staff Assigned',
                              value: 'staffsAssigned'
                            },
                            { label: 'Date of Use', value: 'dateOfUse' },
                            { label: 'Location', value: 'location' }
                          ]}
                          mobileHeaders={[
                            { title: 'Service Name', key: 'serviceName' },
                            {
                              title: 'Staff Assigned',
                              key: 'staffsAssigned'
                            },
                            { title: 'Date of Use', key: 'dateOfUse' },
                            { title: 'Location', key: 'location' }
                          ]}
                          rows={packageSale.packagesSalesHistory?.map((data) => ({
                            serviceName: data?.serviceName || 'N/A',
                            staffsAssigned: data?.staffsAssigned || 'N/A',
                            dateOfUse: data?.dateOfUse || 'N/A',
                            location: data?.location || 'N/A'
                          }))}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          </div>
        </div>
      </Modal>
    </>
  )
}

const RedeemedPackages = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id');
  const salonCountryCode = getSalonFieldValue('countryCode')
  const [packageSale, setPackageSale] = useState<PackagesSales>()
  const { toast, addToast } = useToast()
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [before, setBefore] = useState<string | null>(null);
  const [after, setAfter] = useState<string | null>(null);
  const REDEEMED_PACKAGES_HEADINGS = [
    { label: 'Voucher name', value: 'name', show: true },
    { label: 'Services', value: 'services', show: true },
    { label: 'Client', value: 'client', show: true },
    { label: 'Redemption date', value: 'redemptionDate', show: true },
    { label: 'Location', value: 'location', show: true },
    { label: 'Staff', value: 'staff', show: true },
    { label: 'Price', value: 'price', show: true }
  ]

  const MOBILE_REDEEMED_PACKAGES_HEADINGS = [
    { title: 'Voucher name', key: 'name' },
    { title: 'Services', key: 'services' },
    { title: 'Client', key: 'client' },
    { title: 'Redemption date', key: 'redemptionDate' },
    { title: 'Location', key: 'location' },
    { title: 'Staff', key: 'staff' },
    { title: 'Price', key: 'price' }
  ]
  const [redeemedPackagesHeadings, setRedeemedPackagesHeadings] = useState(REDEEMED_PACKAGES_HEADINGS)

  const {
    data: packagesOverviewSummaryData,
    loading: packagesOverviewSummaryLoading,
    refetch: packagesOverviewSummaryRefetch
  } = usePackageOverviewSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const packagesOverviewSummary = useMemo(() => {
    if (packagesOverviewSummaryData && packagesOverviewSummaryData?.packagesOverviewSummaryReport) {
      return packagesOverviewSummaryData.packagesOverviewSummaryReport
    }
    return null
  }, [packagesOverviewSummaryData])

  const {
    data: packagesSales,
    loading: packagesSalesLoading,
    refetch: packagesSalesRefetch,
  } = useRedeemedPackagesSales({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
    before,
    after,
    q: debouncedSearchQuery
  })
  const redeemedPackages = useMemo(() => {
    if (packagesSales && packagesSales?.redeemedPackagesReport) {
      return packagesSales?.redeemedPackagesReport
    }
  }, [packagesSales])

  useEffect(() => {
    packagesSalesRefetch()
    packagesOverviewSummaryRefetch()
  }, [selectedDates])


  useEffect(() => {
    packagesSalesRefetch()
  }, [debouncedSearchQuery])

  const { openModal, closeModal, isVisible } = useModal()

  const handleViewPackage = (id: number) => {
    setPackageSale(redeemedPackages?.nodes[id])
    openModal()
  }

  const generateSalonPackageSalesTableData = (
    data?: PackagesSales[],
    tableHeadings?: TableFilterProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    countryCode?: string
  ) => {
    if (!data || data.length === 0) return []
    return data?.map((x) => {
      const rowData = {}
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = x?.packageName
              break
            case 'services':
              rowData[heading.value] = x?.servicesIncluded.map(x => x.name).join(', ')
              break
            case 'client':
              rowData[heading.value] = x?.client
              break
            case 'redemptionDate':
              rowData[heading.value] = getNumberMonthAndYearFromDate(x?.dateOfLastUse)
              break
            case 'location':
              rowData[heading.value] = x?.location
              break
            case 'staff':
              rowData[heading.value] = x?.staff
              break
            case 'price':
              rowData[heading.value] = formatInToPrice(x?.price)
              break
          }
        }
      })
      return rowData
    });
  };

  const formatSalonPackageSalesCsvDownloadData = (
    data: PackagesSales[],
    countryCode?: string
  ) => {
    return data.map((row) => ({
      'Package Name': row?.packageName,
      Services: 'row?.services',
      Client: row?.client,
      'Date of purchase': getNumberMonthAndYearFromDate(
        row?.dateOfPurchase
      ),
      Location: row?.location,
      Staff: 'row?.staff',
      Price: formatInToPrice(row?.price)
    }))
  }

  // const rows = generateSalonPackageSalesTableData(redeemedPackages?.nodes, salonCountryCode)
  const rows = generateSalonPackageSalesTableData(
    redeemedPackages?.nodes,
    REDEEMED_PACKAGES_HEADINGS,
    salonCountryCode
  )

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_voucher_redeemed_list_report`;
  const _handleDownload = () => {
    if (redeemedPackages?.nodes) {
      handleDownload(formatSalonPackageSalesCsvDownloadData(redeemedPackages?.nodes, salonCountryCode), filename);
    }
  }

  const downloadExcel = () => {
    if (redeemedPackages?.nodes) {
      handleExcelDownload(formatSalonPackageSalesCsvDownloadData(redeemedPackages?.nodes, salonCountryCode), filename, '');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in clientHeadings then update the show
    const updatedHeadings = redeemedPackagesHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setRedeemedPackagesHeadings(updatedHeadings)
  }

  const goToNext = () => {
    if (redeemedPackages?.pageInfo?.hasNextPage) {
      setBefore(null)
      setAfter(redeemedPackages?.pageInfo?.endCursor)
      packagesSalesRefetch()
    }
  }

  const goToPrev = () => {
    if (redeemedPackages?.pageInfo?.hasPreviousPage) {
      setAfter(null)
      setBefore(redeemedPackages?.pageInfo?.startCursor)
      packagesSalesRefetch()
    }
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      {packagesOverviewSummaryLoading || packagesSalesLoading ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Redeemed vouchers
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-2 xl:grid-cols-5 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSale width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total sold
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.totalSold}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSolarGiftLinear width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total redeemed
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.totalRedeemed}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCreditCard width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total sold value ({DEFAULT_CURRENCY})
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.totalSoldValue}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSolarGiftLinear width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Top performing
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.topSellingPackage}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgUser width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className='flex space-x-2 items-center'>
              <span>MVP Client</span>
              <div className='group flex relative text-grey-300'>
                <SvgInfo width='15px' height='15px' />
                <span className='w-[200px] group-hover:opacity-100 transition-opacity bg-grey-50 p-2 text-b6 text-grey-300 rounded-md absolute right-2 -translate-x-2 opacity-0 m-4 mx-auto z-30'>
                  This is the client with the highest purchase of packages.
                </span>
              </div>
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {packagesOverviewSummary?.mvpClient}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col md:flex-row items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6 gap-3'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        <div className='w-full xl:w-8/12 flex items-center justify-end space-x-4'>
          <div className='w-[200px]'>
            <SearchTerm setDebouncedSearchQuery={setDebouncedSearchQuery} size='sm' />
          </div>
          <Paginator pageInfo={redeemedPackages?.pageInfo} {...{ goToNext, goToPrev }} />
        </div>
      </div>
      <div className='w-full hidden md:flex flex-col space-y-12 overflow-x-scroll'>
        <div className='w-full flex flex-col h-fit border-t border-grey-100'>
          <FullTable
            headers={formatTableHeadersFilterArray(redeemedPackagesHeadings)}
            mobileHeaders={MOBILE_REDEEMED_PACKAGES_HEADINGS}
            rows={rows}
            onClick={handleViewPackage}
            hasBorder={true}
            tableOptions={{
              view: true,
              edit: true,
              delete: true,
              duplicate: false
            }}
          />
        </div>
        <ViewPackageSalesModal
          packageSale={packageSale}
          closeModal={closeModal}
          isVisible={isVisible}
        />
      </div>
    </>
  )
}

export default PackagesReports
