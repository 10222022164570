export const TRANSFERABLE_MODULES_OPTIONS = [
  {
    label: 'Services',
    value: 'service',
  },
  {
    label: 'Products',
    value: 'product',
  },
  {
    label: 'Working Hours',
    value: 'salon_hour',
  },
  {
    label: 'Staff',
    value: 'salon_staff',
  },
]

export type PermissionLevelList = {
  levelTitle: string;
  modules: {
    label: string;
    module: string[];
    ids: number[];
    levels: boolean[];
    id?: string;
  }[];
}

export const PERMISSION_LEVELS_LISTING : PermissionLevelList[] = [
  {
    levelTitle: 'Locations',
    modules: [{
      label: 'Access all locations',
      module: ["Location::ViewAll"],
      ids: [],
      levels: [false, false, false, false]
    }]
  }, {
    levelTitle: 'Bookings and clients',
    modules: [{
      label: 'Access own calendar',
      module: ["Calendar::ViewOwnCalendar"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Access other calendar',
      module: ["Calendar::ViewAll"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can book appointments',
      module: ["Home::AddAppointment", "Sales::AddAppointment", "Calendar::AddAppointment"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can apply discounts to appointments',
      module: ["Calendar::ApplyDiscounts"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can access the home page',
      module: ["Home::ViewPage"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can access the clients page',
      module: ["Clients::ViewClient"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can see clients contact info',
      module: ["Clients::ViewContactInfo",],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can manage clients',
      module: ["Clients::AddClient", "Clients::ModifyClient", "Clients::DeleteClient", "Clients::RecordRefund", "Clients::ViewAppointmentHistory"],
      ids: [],
      levels: [false, false, false, false]
    },
    // {
    //   label: 'Can download clients',
    //   module: []
    //   levels: [false, false, false, false]
    // },
    {
      label: 'Can access the message page',
      module: ["Messaging::ViewCustomMessageList", "Messaging::SendMessage", "Messaging::ViewSentMessages"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can manage own blocked times',
      module: ["Calendar::ManageOwnBlockedTime"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can manage all blocked times',
      module: ["Staff::ManageAllBlockedTime"],
      ids: [],
      levels: [false, false, false, false]
    }]
  }, {
    levelTitle: 'Services',
    modules: [{
      label: 'Can view services',
      module: ["Catalogue::Service::ViewService"],
      ids: [],
      levels: [false, false, false, false]
    }, {
      label: 'Can manage services',
      module: ["Catalogue::Service::AddService", "Catalogue::Service::DeleteService", "Catalogue::Service::ModifyService"],
      ids: [],
      levels: [false, false, false, false]
    }]
  }, {
    levelTitle: 'Inventory',
    modules: [{
      label: 'Can view products',
      module: ["Catalogue::Product::ViewProduct"],
      ids: [],
      levels: [false, false, false, false]
    }, {
      label: 'Can manage products',
      module: ["Catalogue::Product::AddProduct", "Catalogue::Product::DeleteProduct", "Catalogue::Product::ModifyProduct", "Catalogue::Product::IncreaseStock", "Catalogue::Product::DecreaseStock"],
      ids: [],
      levels: [false, false, false, false]
    }, {
      label: 'Can import products',
      module: ["Catalogue::Product::ImportProducts"],
      ids: [],
      levels: [false, false, false, false]
    }]
  }, {
    levelTitle: 'Vouchers',
    modules: [{
      label: 'Can view, edit, add vouchers',
      module: ["Catalogue::Packages::AddPackage", "Catalogue::Packages::DeletePackage", "Catalogue::Packages::ModifyPackage", "Catalogue::Packages::ViewPackage"],
      ids: [],
      levels: [false, false, false, false]
    }]
  }, {
    levelTitle: 'Sales',
    modules: [{
      label: 'Can check out sales',
      module: ["Home::CheckoutCheckinAppointment"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can edit prices at checkout',
      module: ["Calendar::ModifyPriceAtCheckout"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can add sales',
      module: ["Sales::AddSale", "Home::AddSale", "Sales::AddVoucherSale"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can void sales',
      module: ["Sales::VoidSale"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can view sales page',
      module: ["Sales::ViewPaymentTransactions", "Sales::ViewAppointmentsList", "Sales::ViewSalesReport"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can manage appointments',
      module: ["Calendar::ViewAppointment", "Home::ViewAppointment", "Calendar::DeleteAppointment", "Calendar::MarkNoShow", "Calendar::CancelAppointment", "Calendar::RescheduleAppointment", "Calendar::RevertAppointmentCancellation", "Calendar::ModifyAppointment",],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can edit sale',
      module: ["Sales::ModifySale"],
      ids: [],
      levels: [false, false, false, false]
    }]
  }, {
    levelTitle: 'Staff',
    modules: [{
      label: 'Can manage staff schedule',
      module: ["Staff::ManageStaffWorkingHours", "Staff::AddTimeOff", "Staff::ModifyTimeOff", "Staff::DeleteTimeOff", "Calendar::ModifyBlockTime", "Calendar::AddBlockTime"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can manage closed dates',
      module: ["Staff::ModifyClosedPeriod", "Staff::AddClosedPeriod", "Calendar::ModifyClosedPeriod"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can manage staff',
      module: ["Staff::ModifyStaff", "Staff::ViewStaff", "Staff::AddStaff", "Staff::DeactivateStaff", "Staff::ActivateStaff", "Staff::DeleteStaff"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can manage permission levels',
      module: ["Settings::ModifyPermission"],
      ids: [],
      levels: [false, false, false, false]
    }]
  }, {
    levelTitle: 'Reports and Money',
    modules: [{
      label: 'Can access reports',
      module: ["Reports::"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can access money ',
      module: ["Money::ViewWallet", "Money::Withdraw", "Money::AddVirtualAccount"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can manage payment transactions',
      module: ["Money::ViewPaymentsTab"],
      ids: [],
      levels: [false, false, false, false]
    }]
  }, {
    levelTitle: 'Promotions and Loyalty',
    modules: [
      {
        label: 'Can create promotions and loyalty',
        module: ["Promos::AddPromo", "Loyalty::AddLoyalty"],
        ids: [],
        levels: [false, false, false, false]
      },
      {
        label: 'Can view promotions and loyalty',
        module: ["Promos::ViewPromo", "Loyalty::ViewLoyalty"],
        ids: [],
        levels: [false, false, false, false]
      },
      {
        label: 'Can edit promotions and loyalty',
        module: ["Promos::ModifyPromo", "Promos::DeletePromo", "Loyalty::EditLoyalty", "Loyalty::DeleteLoyalty"],
        ids: [],
        levels: [false, false, false, false]
      }
    ]
  }, {
    levelTitle: 'Notes',
    modules: [{
      label: 'Can view, edit, create notes',
      module: ['Clients::AddNote', "Clients::ViewNotes", "Clients::DeleteNote", "Clients::ModifyNote"],
      ids: [],
      levels: [false, false, false, false]
    }]
  }, {
    levelTitle: 'Business Settings',
    modules: [{
      label: 'Can edit/manage business information',
      module: ['Settings::EditBusinessInfo'],
      ids: [],
      levels: [false, false, false, false]
    },{
      label: 'Can edit/manage business settings and public site',
      module: ["Settings::AddModifyCancellationFees", "Settings::AddModifyNoShowFees", "Settings::EditPersonalInfo", "Settings::ModifyBusinessHours", "Settings::ChangePublicUrl", "Settings::AddFAQ", "Settings::DeleteFAQ"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can manage payment methods',
      module: ["Settings::AddPOSDevices", "Settings::DeletePOSDevices", "Settings::AddBankAccounts", "Settings::DeleteBankAccounts", "Settings::ModifyProcessingFees", "Settings::ModifyTaxSettings", "Settings::ManageAddons", "Settings::ManageBilling"],
      ids: [],
      levels: [false, false, false, false]
    },
    {
      label: 'Can add new location',
      module: ['Settings::AddLocation'],
      ids: [],
      levels: [false, false, false, false]
    }]
  }
]

export const subscriptions = [
  {
    name: 'Splice Basic',
    price: 0,
    description: 'Your starter plan to get started with your beauty clients.',
    features: [
      'Calendar',
      'Appointments',
      'Clients',
      'Staff',
      'Reports',
      'Promotions',
      'Notes',
      'Business Settings',
    ],
    active: true,
  },
  {
    name: 'Splice Pro',
    price: 19.99,
    description: 'Get all the features of Splice Basic plus more.',
    features: [
      'Inventory',
      'Sales',
      'Vouchers',
      'Money',
      'Loyalty',
    ],
    active: false,
  },
  {
    name: 'Splice Premium',
    price: 39.99,
    description: 'Get all the features of Splice Pro plus more.',
    features: [
      'Unlimited Locations',
      'Unlimited Staff',
      'Unlimited Clients',
      'Unlimited Appointments',
    ],
    active: false,
  },
]