import * as React from 'react'
import { useController } from 'react-hook-form';
import { InputProps } from './types';
import { getHelperTextForReactHookFormErrors } from '../../../components/utils/form';
import { useMemo } from 'react';
import { HelperTextProps } from '../../atoms/helperText/types';
import  FormLabel  from '../../atoms/formLabel/Label';
import { FormHelperTextList } from '../../atoms/helperText/FormHelperTextList';

const Input: React.FC<InputProps> = ({ name, label, coLabel, control, type, className, rules, error, id, ...rest }) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
    rules,
  });

  const helperText = getHelperTextForReactHookFormErrors(error?.message || "");

  const helperTexts: HelperTextProps[] = useMemo(() => {
    if (Array.isArray(helperText)) {
      return helperText;
    }

    if (helperText) {
      return [helperText];
    }

    return [];
  }, [helperText]);

  return (
    <div className={`w-full flex flex-col ${label ? 'space-y-2' : ''}`}>
      {label && (
        <FormLabel htmlFor={id}>{label} {coLabel && <span className='text-grey-400'>{coLabel}</span>}</FormLabel>
      )}
      <input
        {...rest}
        {...inputProps}
        id={id}
        type={type}
        ref={ref}
        className={`w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400 ${
          className || ""
        }`}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission when Enter is pressed
          }
        }}
      />
      {error && (
        <FormHelperTextList helperTexts={helperTexts} />
      )}
    </div>
  );
};

export default Input