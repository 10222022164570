import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSplicePlanIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#splice_plan_icon_svg__a)" fill="#16796F">
      <path d="M22.4 2h-4.8c-1.714 0-2.88 0-3.78.076-.876.07-1.326.2-1.638.36a4 4 0 0 0-1.748 1.748c-.14.294-.242.605-.306.924-.108.498-.522.892-1.032.892-.564 0-1.02-.48-.94-1.036a5.4 5.4 0 0 1 .496-1.688 6.04 6.04 0 0 1 2.62-2.62C12.556 0 14.232 0 17.592 0h4.8c3.36 0 5.04 0 6.32.654a6.04 6.04 0 0 1 2.62 2.62c.654 1.284.654 2.96.654 6.32v4.8c0 3.36 0 5.04-.654 6.32a6 6 0 0 1-2.62 2.62 5.399 5.399 0 0 1-1.688.496c-.558.08-1.036-.376-1.036-.94 0-.51.394-.924.892-1.032.418-.092.73-.208.924-.306a4.012 4.012 0 0 0 1.748-1.748c.16-.312.29-.762.36-1.64.074-.9.076-2.06.076-3.78v-4.8c0-1.712-.002-2.88-.076-3.78-.072-.874-.202-1.324-.36-1.636a4 4 0 0 0-1.748-1.748c-.312-.16-.762-.29-1.638-.36-.9-.074-2.06-.076-3.78-.076L22.4 2Zm-4.74 14.24a1 1 0 0 1 .108 1.41l-6 7a.996.996 0 0 1-1.466.056l-4-4a1 1 0 0 1 1.414-1.414l3.24 3.24 5.3-6.18a1 1 0 0 1 1.41-.108l-.006-.004Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.654 11.282C0 12.566 0 14.242 0 17.602v4.8c0 3.36 0 5.04.654 6.32a6.04 6.04 0 0 0 2.62 2.62c1.284.654 2.96.654 6.32.654h4.8c3.36 0 5.04 0 6.32-.654a6 6 0 0 0 2.62-2.62c.654-1.284.654-2.96.654-6.32v-4.8c0-3.36 0-5.04-.654-6.32a6 6 0 0 0-2.62-2.62c-1.284-.654-2.96-.654-6.32-.654h-4.8c-3.36 0-5.04 0-6.32.654a6.04 6.04 0 0 0-2.62 2.62Zm13.74-1.276h-4.8c-1.714 0-2.88 0-3.78.076-.876.07-1.326.2-1.638.36a4 4 0 0 0-1.748 1.748c-.16.312-.29.76-.36 1.638-.074.9-.076 2.06-.076 3.78v4.8c0 1.714.002 2.88.076 3.78.072.876.202 1.326.36 1.638a4.012 4.012 0 0 0 1.748 1.748c.312.16.762.29 1.638.36.9.072 2.06.074 3.78.074h4.8c1.714 0 2.88 0 3.78-.074.876-.072 1.326-.202 1.638-.36a4.012 4.012 0 0 0 1.748-1.748c.16-.312.29-.762.36-1.64.074-.9.076-2.06.076-3.78v-4.8c0-1.712-.002-2.88-.076-3.78-.072-.874-.202-1.324-.36-1.636a4 4 0 0 0-1.748-1.748c-.312-.16-.762-.29-1.638-.36-.9-.074-2.06-.076-3.78-.076Z"
      />
    </g>
    <defs>
      <clipPath id="splice_plan_icon_svg__a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSplicePlanIcon;
