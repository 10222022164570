import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGreenBankIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3 13h3v8H4a1 1 0 1 0 0 2h24a1 1 0 0 0 0-2h-2v-8h3a1 1 0 0 0 .524-1.852l-13-8a1 1 0 0 0-1.048 0l-13 8A1 1 0 0 0 3 13Zm5 0h4v8H8v-8Zm10 0v8h-4v-8h4Zm6 8h-4v-8h4v8ZM16 5.172 25.468 11H6.532L16 5.173ZM31 26a1 1 0 0 1-1 1H2a1 1 0 0 1 0-2h28a1 1 0 0 1 1 1Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgGreenBankIcon;
