import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBusinessAddons = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M16.333 30c-7.72 0-14-6.28-14-14s6.28-14 14-14 14 6.28 14 14-6.28 14-14 14Zm0-26c-6.62 0-12 5.38-12 12s5.38 12 12 12 12-5.38 12-12-5.38-12-12-12Z"
      fill="#16796F"
    />
    <path
      d="M16.333 23c-.56 0-1-.44-1-1V10c0-.56.44-1 1-1s1 .44 1 1v12c0 .56-.44 1-1 1Z"
      fill="#16796F"
    />
    <path
      d="M22.333 17h-12c-.56 0-1-.44-1-1s.44-1 1-1h12c.56 0 1 .44 1 1s-.44 1-1 1Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgBusinessAddons;
