import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStreamlineWebIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g
      clipPath="url(#streamline_web_icon_svg__a)"
      stroke="#16796F"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M30.857 16.002a14.858 14.858 0 0 1-29.714 0m29.714 0a14.857 14.857 0 1 0-29.714 0m29.714 0H1.143" />
      <path d="M21.714 16.002A25.646 25.646 0 0 1 16 30.859a25.646 25.646 0 0 1-5.714-14.857A25.646 25.646 0 0 1 16 1.145a25.646 25.646 0 0 1 5.714 14.857Z" />
    </g>
    <defs>
      <clipPath id="streamline_web_icon_svg__a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgStreamlineWebIcon;
