import { usePauseClientMembership, useWaiveClientOutstandingFees } from "api/useClient"
import { ClientMembershipProps } from "../types"
import { Button, Checkbox, Heading, Modal, Paragraph } from "ui"
import { COLORS } from "constants/colors"
import { useState } from "react"
import { DEFAULT_CURRENCY } from "constants/currency"

const WaiveOutstandingFees = ({ isVisible, closeModal, addToast, client, cancellationFeeMessages }: ClientMembershipProps) => {
  const { waiveClientOutstandingFees, loading } = useWaiveClientOutstandingFees()
  const [bookingIds, setBookingIds] = useState([])

  const _closeModal = () => {
    closeModal()
    setBookingIds([])
  }
  const waiveFees = () => {
    waiveClientOutstandingFees({
      variables: {
        input: {
          id: client?.id,
          bookingIds: bookingIds?.length ? bookingIds : null
        },
      }
    }).then(({ data }) => {
      const { waiveClientOutstandingFees } = data
      if (waiveClientOutstandingFees?.status === 200) {
        addToast({
          message: 'Client Outstanding Fee Waived successfully',
          variant: 'success',
        })
        _closeModal()
      }

      if (waiveClientOutstandingFees?.errors?.length) {
        addToast({
          message: waiveClientOutstandingFees?.errors[0]?.message,
          variant: 'error',
        })
      }
    })
  }

  const formatMessage = (message: string) => {
    if (!message) return null;

    const regex = /^([\p{Sc}\p{L}]+)\s*([\d,]+\.?\d{0,2}) cancellation fee for (.*?) appointment (#[A-Za-z0-9]+)/u;
    const match = message.match(regex);
    
    if (!match) return null;
    
    return {
      currency: match[1],
      amount: match[1] + match[2],
      date: match[3],
      appointmentId: match[4]?.replaceAll('#', ''),
    }
  }

  const selectAppointment = (appointmentId: string) => {
    if (!appointmentId) return;

    // update bookingIds
    if (bookingIds.includes(appointmentId)) {
      setBookingIds(bookingIds.filter((id) => id !== appointmentId))
    } else {
      setBookingIds([...bookingIds, appointmentId])
    }
  }
  return (
    <Modal
      title="Waive Client Outstanding Fees"
      subTitle={`Choose outstanding cancellation fees to waive`}
      show={isVisible}
      closeModal={_closeModal}
      showCloseButton={true}
    >
      <div className="w-full flex flex-col space-y-6 py-5">
          <div className="w-full flex flex-col space-y-3">
            {cancellationFeeMessages?.map((message, index) => {
              const cancellationMessage = formatMessage(message);
              return cancellationMessage && (
                <div className="w-full flex items-center space-x-2 cursor-pointer" key={index} onClick={() => selectAppointment(cancellationMessage?.appointmentId)}>
                  <Checkbox
                    isChecked={bookingIds.includes(cancellationMessage?.appointmentId)}
                  />
                  <Paragraph className="text-sm">{cancellationMessage?.amount} on {cancellationMessage?.date}</Paragraph>
                </div>
              )
            })}
          </div>
        <div className="w-full flex space-x-2">
          <Button
            variant='secondary'
            size='lg'
            rounded='lg'
            fontSize='b5'
            className='capitalize'
            onClick={() => waiveFees()}
            disabled={loading}
            loading={loading}
          >
            Waive all
          </Button>
          <Button
            variant='primary'
            size='lg'
            rounded='lg'
            fontSize='b5'
            className='mx-0'
            onClick={() => waiveFees()}
            disabled={!bookingIds?.length || loading}
            loading={loading}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default WaiveOutstandingFees
