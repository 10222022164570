import { useGetAppointmentForm } from 'api/useForms'
import { getNumberMonthAndYearFromDate } from 'components/utils/misc'
import { COLORS } from 'constants/colors'
import { Appointment } from 'core/generated'
import { useModal, useSalonCache } from 'hooks'
import { CLIENT_FORMS_HISTORY_HEADERS, MOBILE_CLIENT_FORMS_HISTORY_HEADERS } from 'modules/clients/constants'
import ViewClientForm from 'modules/clients/modals/ViewClientForm'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Heading, Modal, Paragraph, Pill, SvgArrowBack, SvgChevronLeft, Table } from 'ui'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader'

const FormList = ({
  isVisible,
  closeModal,
  appointment
}: {
  isVisible: boolean
  closeModal: () => void
  appointment: Appointment
}) => {
  const {
    isVisible: isOpenViewFormModalVisible,
    openModal: openViewFormModal,
    closeModal: closeViewFormModal
  } = useModal()
  const [selectedForm, setSelectedForm] = useState(null)
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')

  const {
    data,
    isLoading: loading,
    refetch
  } = useGetAppointmentForm({
    id: appointment?.id,
  })

  const clientForm = useMemo(() => {
    return data?.data?.data
  }, [data?.data]);

  useEffect(() => {
    if (isVisible) {
      refetch()
    }
  }, [isVisible, appointment?.id, refetch])

  const openFormItem = (index: number) => {
    // open form from table list
    const form = clientForm?.[index]
    if (!form) return
    setSelectedForm({
      id: form?.workflowForm?.id,
      title: form?.workflowForm?.title,
      clientName: `${form?.client?.first_name} ${form?.client?.last_name}`,
      clientEmail: form?.client?.email,
      status: form?.status,
      answers: form?.answers,
      date_sent: form?.createdAt,
      date_completed: form?.completed_at,
      createdAt: form?.createdAt,
    })
    openViewFormModal()
  }

  const rows = Array?.isArray(clientForm) && clientForm?.length ? clientForm?.map((form) => {
    return {
      formName: form?.title,
      dateSent: getNumberMonthAndYearFromDate(form?.createdAt),
      dateCompleted: getNumberMonthAndYearFromDate(form?.completed_at),
      status: form?.status ? (
              <Pill variant={form?.status === 'sent' ? 'primary' : 'success'}>{form?.status}</Pill>
      ) : '-'
    }
  }) : [];

  return (
    <>
      {loading ? <ContentLoader /> : null}
      <Modal
        show={isVisible}
        closeModal={closeModal}
        variant="right"
      >
        <div className="w-full relative my-[80px]">
          <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
            <div className="w-full hidden xl:flex space-x-2 px-6 py-5 items-start">
              <Button
                variant="text"
                size="none"
                type="button"
                className="w-fit"
                fontSize="b4"
                onClick={closeModal}
              >
                <SvgArrowBack width="24px" height="24px" /> <span>Back</span>
              </Button>
            </div>
            <div className="w-full flex xl:hidden space-x-[69px] p-4 items-center">
              <Button
                variant="icon"
                size="square"
                type="button"
                rounded="md"
                className="w-fit"
                fontSize="b4"
                onClick={closeModal}
              >
                <SvgChevronLeft width="24px" height="24px" />
              </Button>
              <Paragraph size="b3" weight="bold">
                Back
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col p-4 space-y-10'>
            <div className='flex flex-col items-start px-10'>
              <Heading variant="h1" size='h9' weight='bold' color={COLORS.GREY['900']}>Forms</Heading>
              <Paragraph size='b4' color={COLORS.GREY[400]}>
                List of forms attached to this appointment
              </Paragraph>
            </div>
            <Table
              headers={CLIENT_FORMS_HISTORY_HEADERS}
              mobileHeaders={MOBILE_CLIENT_FORMS_HISTORY_HEADERS}
              rows={rows}
              onClick={openFormItem}
              tableOptions={{
                view: true,
                send_again: false,
                edit: false,
                delete: false,
                duplicate: false
              }}
            />
          </div>
        </div>
      </Modal>
      <ViewClientForm
        isVisible={isOpenViewFormModalVisible}
        closeModal={() => {
          closeViewFormModal()
          setSelectedForm(null)
        }}
        selectedForm={selectedForm}
      />
    </>
  )
}

export default FormList