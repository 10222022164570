import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLockIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 50 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M45 26h-2v-7.5C43 8.299 34.701 0 24.5 0S6 8.299 6 18.5V26H5c-2.757 0-5 2.243-5 5v26c0 2.757 2.243 5 5 5h40c2.757 0 5-2.243 5-5V31c0-2.757-2.243-5-5-5ZM8 18.5C8 9.402 15.402 2 24.5 2 33.598 2 41 9.402 41 18.5V26h-4v-7.5C37 11.607 31.393 6 24.5 6S12 11.607 12 18.5V26H8v-7.5ZM35 26H14v-7.5C14 12.71 18.71 8 24.5 8S35 12.71 35 18.5V26Zm13 31c0 1.654-1.346 3-3 3H5c-1.654 0-3-1.346-3-3V31c0-1.654 1.346-3 3-3h40c1.654 0 3 1.346 3 3v26ZM25 36c-2.206 0-4 1.794-4 4v8c0 2.206 1.794 4 4 4s4-1.794 4-4v-8c0-2.206-1.794-4-4-4Zm2 12c0 1.103-.897 2-2 2s-2-.897-2-2v-8c0-1.103.897-2 2-2s2 .897 2 2v8Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgLockIcon;
