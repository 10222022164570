import SvgFacebook from 'assets/icons/Facebook'
import SvgInstagram from 'assets/icons/Instagram'
import SvgTwitter from 'assets/icons/Twitter'
import { SalonSocialInput } from 'components/uicomponents/accountSetupComponents/types'

export const ROLES = [
  {
    label: 'Owner',
    value: 'owner'
  },
  {
    label: 'Manager',
    value: 'manager'
  },
]

export const ACCOUNT_SETUP_ERRORS = {
  FIRST_NAME_REQUIRED: 'First name is required',
  LAST_NAME_REQUIRED: 'Last name is required',
  EMAIL_REQUIRED: 'Email is required',
  EMAIL_INVALID: 'Email is invalid',
  PHONE_REQUIRED: 'Phone number is required',
  PHONE_INVALID: 'Phone number is invalid',
  ROLE_REQUIRED: 'Role is required',
  DOB_REQUIRED: 'Date of birth is required',
  SERVICES_REQUIRED: 'At least one service is required'
}

export const YES_OR_NO_SETTINGS = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
]

export const HEARD_ABOUT_SPLICE_FROM = [
  {
    label: 'Google search',
    value: 'google_search'
  },
  {
    label: 'Instagram',
    value: 'instagram'
  },
  {
    label: 'Facebook',
    value: 'facebook'
  },
  {
    label: 'LinkedIn',
    value: 'linkedin'
  },
  {
    label: 'Splice Blog',
    value: 'splice_blog'
  },
  {
    label: 'I was referred by a beauty business owner',
    value: 'referred_by_beauty_business_owner'
  },
  {
    label: 'Media publication',
    value: 'media_publication'
  },
  {
    label: 'Other - please specify',
    value: 'other'
  }
]

export const SCHEDULE_HOURS_SELECTION_SETTINGS = [
  {
    label: "Staff Working Hours (Individual staff member's working schedule)",
    value: 'staff'
  },
  {
    label: 'Location Hours (General business hours of the location)',
    value: 'location'
  }
]

export const VOUCHER_SALE_ON = [
  {
    label: 'Online',
    value: 'online'
  }, {
    label: 'In-House only',
    value: 'salon'
  }
]

export const STAFF_ROLES = [
  {
    label: 'Owner',
    value: 'owner'
  },
  {
    label: 'Staff',
    value: 'staff'
  },
  {
    label: 'Social Media Manager',
    value: 'social_media_manager'
  },
  {
    label: 'Manager',
    value: 'manager'
  },
  {
    label: 'Receptionist',
    value: 'receptionist'
  }
]
export const LOCAL_STORAGE_KEYS = {
  APP_AUTH_INFO: 'splice:auth-info',
  APP_AUTH_TOKEN: 'token',
  APP_SPLICE_USER: 'splice:user',
  APP_SPLICE_USER_DATA: "userData",
  APP_SPLICE_CUSTOMER: 'splice:customer',
  APP_SPLICE_SALON: 'splice:salon',
  APP_SPLICE_BUSINESS: 'splice:business',
  APP_SPLICE_SKIP_SETUP: 'splice:skip-setup'
}

export const FORM_ERRORS = {
  SERVICE_NAME_REQUIRED: 'Service name is required',
  PRODUCT_NAME_REQUIRED: 'Service name is required',
  DESCRIPTION_REQUIRED: 'Description is required',
  CATEGORY_REQUIRED: 'Category is required',
  PRICE_REQUIRED: 'Price is required',
  CUSTOMER_CAPACITY_REQUIRED: 'Customer capacity is required',
  THRESHOLD_REQUIRED: 'Low stock alert is required',
  STOCK_COUNT_REQUIRED: 'Stock count is required'
}

export const SERVICE_PRICE_TYPES = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'From', value: 'starting_at' }
]

export const DEPOSIT_TYPE_REQUIREMENTS = [
  {
    value: 'percentage',
    label: 'Percentage'
  },
  {
    value: 'fixed_value',
    label: 'Fixed Amount'
  }
]

export const PERMISSION_LEVELS = [
  {
    label: 'Level 1',
    value: '0',
    description: 'Has access to only own calendar',
  },
  {
    label: 'Level 2',
    value: '1',
    description: 'Has access to location, bookings & clients etc',
  },
  {
    label: 'Level 3',
    value: '2',
    description: 'Has access to everything, except reports',
  },
  {
    label: 'Level 4',
    value: '3',
    description: 'Has access to everything',
  },
]

export const SPECIALTIES = [
  { label: 'Hair', value: 'hair' },
  { label: 'Nails', value: 'nails' },
  { label: 'Full spa', value: 'full_spa' },
  { label: 'Med spa', value: 'med_spa' },
  { label: 'Full Beauty Salon', value: 'beauty_salon' },
  { label: 'Lash/brows', value: 'lash_brows' },
  { label: 'Wax bar', value: 'wax_bar' },
  { label: 'Makeup', value: 'makeup' },
  { label: 'Aesthetician', value: 'aesthetician' },
  { label: 'Barber', value: 'barber' },
  { label: 'Wellness', value: 'wellness' },
  { label: 'Consultant', value: 'consultant' },
  { label: 'Photography', value: 'photography' }
]

export const STAFF_SPECIALTIES = [
  { value: 'shampoo_assistant', label: 'Shampoo Assistant' },
  { value: 'salon_receptionist', label: 'Salon Receptionist' },
  { value: 'junior_hairstylist', label: 'Junior Hairstylist' },
  { value: 'hairstylist', label: 'Hairstylist' },
  { value: 'senior_hairstylist', label: 'Senior Hairstylist' },
  { value: 'salon_manager', label: 'Salon Manager' },
  { value: 'social_media', label: 'Social Media' },
  { value: 'junior_makeup_artist', label: 'Junior Makeup Artist' },
  { value: 'senior_makeup_artist', label: 'Senior Makeup Artist' },
  { value: 'junior_photographer', label: 'Junior Photographer' },
  { value: 'senior_photographer', label: 'Senior Photographer' },
  { value: 'colourist', label: 'Colourist' },
  { value: 'assistant_hairstylist', label: 'Assistant Hairstylist' },
  { value: 'manicurist', label: 'Manicurist' },
  { value: 'pedicurist', label: 'Pedicurist' },
  { value: 'nail_technician_acrylic', label: 'Nail Technician - Acrylic' },
  { value: 'nail_technician_gel', label: 'Nail Technician - Gel' },
  {
    value: 'nail_technician_dipping_powder',
    label: 'Nail Technician - Dipping Powder',
  },
  {
    value: 'nail_technician_acrylic_design',
    label: 'Nail Technician - Acrylic Design',
  },
  { value: 'waxing_specialist', label: 'Waxing Specialist' },
  { value: 'junior_barber', label: 'Junior Barber' },
  { value: 'senior_barber', label: 'Senior Barber' },
  { value: 'junior_lash_technician', label: 'Junior Lash Technician' },
  { value: 'senior_lash_technician', label: 'Senior Lash Technician' },
  { value: 'beauty_therapist', label: 'Beauty Therapist' },
  { value: 'aesthetician', label: 'Aesthetician' },
  {
    value: 'hot_wax_treatment_specialist',
    label: 'Hot Wax & Treatment Specialist',
  },
  { value: 'sugaring_specialist', label: 'Sugaring Specialist' },
  { value: 'waxing_specialist_2', label: 'Waxing Specialist' },
  { value: 'nurse', label: 'Nurse' },
  { value: 'dermatologist', label: 'Dermatologist' },
  { value: 'nail_technician', label: 'Nail Technician' },
  { value: 'junior_lash_artist', label: 'Junior Lash Artist' },
  { value: 'senior_lash_artist', label: 'Senior Lash Artist' },
  { value: 'senior_lash_and_lamination_artist', label: 'Senior Lash and Lamination Artist' },
  { value: 'master_brow_artist', label: 'Master Brow Artist' },
  { value: 'senior_brow_artist', label: 'Senior Brow Artist' },
  { value: 'junior_lash_artist', label: 'Junior Brow Artist' },
  { value: 'head_brow_artist', label: 'Head Brow Artist' },
  { value: 'nail_tech', label: 'Nail Tech' },
  { value: 'spa_therapist', label: 'Spa Therapist' },
  { value: 'mixologist', label: 'Mixologist' },
  { value: 'lash_tech', label: 'Lash Tech' },
  { value: 'braider', label: 'Braider' },
  { value: 'pastry_chef', label: 'Pastry Chef' },
  { value: 'stylist', label: 'Stylist' },
  { value: 'waitress', label: 'Waitress' },
  { value: 'chef', label: 'Chef' },
  { value: 'wig_maker', label: 'Wig Maker' },
];

export const PRODUCT_TYPE_LIST_OPTIONS = [
  {
    label: 'Internal Use',
    value: 'internal_use'
  },
  {
    label: 'New Stock',
    value: 'new_stock'
  },
  {
    label: 'Damaged',
    value: 'damaged'
  },
  {
    label: 'Adjustment',
    value: 'adjustment'
  },
  {
    label: 'Transfer',
    value: 'transfer'
  },
  {
    label: 'Return',
    value: 'return'
  },
  {
    label: 'Other',
    value: 'other'
  }
]

export const TEAM_SIZE = [
  {
    label: 'Just me',
    value: 'just_me'
  },
  {
    label: '2-5',
    value: 'two_to_five'
  },
  {
    label: '6-10',
    value: 'six_to_ten'
  },
  {
    label: '10+',
    value: 'ten_plus'
  }
]

export const BUSINESS_CATEGORIES = [
  {
    label: 'I have more than one location',
    value: 'more_than_one_location'
  },
  {
    label: 'I manage a team of beauty professionals',
    value: 'manage_a_team'
  },
  {
    label: 'I am an independent/solo beauty professional',
    value: 'independent_professional'
  }
]

export const VALID_FOR = [
  // 1 month to 12 months
  {
    label: '1 Month',
    value: 1
  },
  {
    label: '2 Months',
    value: 2
  },
  {
    label: '3 Months',
    value: 3
  },
  {
    label: '4 Months',
    value: 4
  },
  {
    label: '5 Months',
    value: 5
  },
  {
    label: '6 Months',
    value: 6
  },
  {
    label: '7 Months',
    value: 7
  },
  {
    label: '8 Months',
    value: 8
  },
  {
    label: '9 Months',
    value: 9
  },
  {
    label: '10 Months',
    value: 10
  },
  {
    label: '11 Months',
    value: 11
  },
  {
    label: '12 Months',
    value: 12
  }
]

export const PACKAGE_STATUS = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Inactive',
    value: 'inactive'
  }
]

export const BOOKING_MANAGEMENT = [
  { label: 'Pen and paper', value: 'pen_paper' },
  { label: 'New business', value: 'new_business' },
  { label: 'Fresha', value: 'fresha' },
  { label: 'Walk-ins', value: 'walk_ins' },
  { label: 'Whatsapp', value: 'whatsapp' },
  { label: 'Timely', value: 'timely' },
  { label: 'Zenoti', value: 'zenoti' },
  { label: 'Acuity', value: 'acuity' },
  { label: 'Hey Goldie', value: 'hey_goldie' }
]

export const NIGERIA_STATES = [
  {
    label: 'Abia',
    value: 'Abia'
  },
  {
    label: 'Adamawa',
    value: 'Adamawa'
  },
  {
    label: 'Akwa Ibom',
    value: 'Akwa Ibom'
  },
  {
    label: 'Anambra',
    value: 'Anambra'
  },
  {
    label: 'Bauchi',
    value: 'Bauchi'
  },
  {
    label: 'Bayelsa',
    value: 'Bayelsa'
  },
  {
    label: 'Benue',
    value: 'Benue'
  },
  {
    label: 'Borno',
    value: 'Borno'
  },
  {
    label: 'Cross River',
    value: 'Cross River'
  },
  {
    label: 'Delta',
    value: 'Delta'
  },
  {
    label: 'Ebonyi',
    value: 'Ebonyi'
  },
  {
    label: 'Edo',
    value: 'Edo'
  },
  {
    label: 'Ekiti',
    value: 'Ekiti'
  },
  {
    label: 'Enugu',
    value: 'Enugu'
  },
  {
    label: 'FCT Abuja',
    value: 'FCT Abuja'
  },
  {
    label: 'Gombe',
    value: 'Gombe'
  },
  {
    label: 'Imo',
    value: 'Imo'
  },
  {
    label: 'Jigawa',
    value: 'Jigawa'
  },
  {
    label: 'Kaduna',
    value: 'Kaduna'
  },
  {
    label: 'Kano',
    value: 'Kano'
  },
  {
    label: 'Katsina',
    value: 'Katsina'
  },
  {
    label: 'Kebbi',
    value: 'Kebbi'
  },
  {
    label: 'Kogi',
    value: 'Kogi'
  },
  {
    label: 'Kwara',
    value: 'Kwara'
  },
  {
    label: 'Lagos',
    value: 'Lagos'
  },
  {
    label: 'Nasarawa',
    value: 'Nasarawa'
  },
  {
    label: 'Niger',
    value: 'Niger'
  },
  {
    label: 'Ogun',
    value: 'Ogun'
  },
  {
    label: 'Ondo',
    value: 'Ondo'
  },
  {
    label: 'Osun',
    value: 'Osun'
  },
  {
    label: 'Oyo',
    value: 'Oyo'
  },
  {
    label: 'Plateau',
    value: 'Plateau'
  },
  {
    label: 'Rivers',
    value: 'Rivers'
  },
  {
    label: 'Sokoto',
    value: 'Sokoto'
  },
  {
    label: 'Taraba',
    value: 'Taraba'
  },
  {
    label: 'Yobe',
    value: 'Yobe'
  },
  {
    label: 'Zamfara',
    value: 'Zamfara'
  }
]

// export const SALON_LOCATION_TYPES = [
//   {
//     label: "Salon",
//     value: LocationSetupEnum.SALON,
//     formName: "locationSetupSalon"
//   },
//   {
//     label: "Home",
//     value: LocationSetupEnum.HOME,
//     formName: "locationSetupHome"
//   }
// ];

export const SALON_HAS_BRANCHES = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
]

export const DAYS: string[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]
export const SOCIALS_USERNAME: string[] = ['instagram', 'facebook', 'twitter']
export const SALON_INTERVAL = [
  {
    label: '15 minutes',
    value: 15
  },
  {
    label: '30 minutes',
    value: 30
  },
  {
    label: '45 minutes',
    value: 45
  },
  {
    label: '1 hour',
    value: 60
  },
  {
    label: '2 hours',
    value: 120
  }
]

export const SOCIALS = [
  {
    name: 'instagram',
    url: 'https://www.instagram.com/',
    isAvailable: true,
    prefix: 'insta',
    icon: SvgInstagram,
    id: 1
  },
  {
    name: 'facebook',
    url: 'https://www.facebook.com/',
    isAvailable: true,
    prefix: 'fb',
    icon: SvgFacebook,
    id: 2
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/',
    isAvailable: false,
    prefix: 'twitter',
    icon: SvgTwitter,
    id: 3
  }
] as SalonSocialInput[];

export const STAFF = [
  {
    firstName: 'Ronke',
    lastName: 'Okon',
    role: 'Manager',
    staffId: '1234567890'
  },
  {
    firstName: 'Esther',
    lastName: 'Smith',
    role: 'Stylist',
    staffId: '1234567891'
  },
  {
    firstName: 'Taylor',
    lastName: 'Swift',
    role: 'Stylist',
    staffId: '1234567892'
  },
  {
    firstName: 'Farida',
    lastName: 'Usman',
    role: 'Nail Technician',
    staffId: '1234567893'
  }
]

export const SPLICE_BOOKING_LINK = 'https://app.withsplice.com/s/'
export const SPLICE_RECEIPT_LINK = 'https://app.withsplice.com/receipt/'
export const SPLICE_SALE_RECEIPT_LINK = 'https://app.withsplice.com/sale/'

export const CSV_IMPORT_LINK =
  'https://docs.google.com/spreadsheets/d/1SXT6_sP7wc377726uNsabHwdn0kqjOv2imh2haLsHbY/edit?usp=sharing'

export const FILE_SIZE = 2048000

export const PROCESSING_FEE = 2

export const DEFAULT_TME_ZONE = 'NG'

export const INVENTORY_TAB_NAME = {
  SERVICES: 'Services',
  PRODUCTS: 'Products',
  PACKAGES: 'Vouchers'
} as const

export const SERVICES_HEADINGS = [
  {
    label: 'Service name',
    value: 'name',
    show: true
  },
  {
    label: 'Category',
    value: 'category',
    show: true
  },
  {
    label: 'Description',
    value: 'description',
    show: true
  },
  {
    label: 'Duration',
    value: 'duration',
    show: true
  },
  {
    label: 'Price',
    value: 'price',
    show: true
  },
  {
    label: 'Custom Price',
    value: 'customPrice',
    show: true
  }
]

export const PRODUCTS_HEADINGS = [
  {
    label: 'Product name',
    value: 'name',
    show: true
  },
  {
    label: 'Category',
    value: 'category',
    show: true
  },
  {
    label: 'Description',
    value: 'description',
    show: true
  },
  {
    label: 'Stock count',
    value: 'stock',
    show: true
  },
  {
    label: 'Retail price',
    value: 'price',
    show: true
  },
  {
    label: 'Expiry date',
    value: 'expirationDate',
    show: true
  },
  {
    label: 'Status',
    value: 'status',
    show: true
  }
]

export const TREND_DROP_DOWN_OPTIONS = [
  {
    value: 'Monthly',
    label: 'Last Month'
  }, {
    value: 'Weekly',
    label: 'Last Week'
  }
]

export const LOYALTY_MEMBER_HEADINGS = [
  {
    label: 'Date',
    value: 'date',
    show: true
  },
  {
    label: 'Client Name',
    value: 'name',
    show: true
  }, {
    label: 'Spend value/No of visits',
    value: 'spendValue',
    show: true
  }, {
    label: 'Points earned',
    value: 'pointsEarned',
    show: true
  }, {
    label: 'Reward value',
    value: 'discountValue',
    show: true
  }
]

export const LOYALTY_MEMBER_HEADINGS_MOBILE = [
  {
    title: 'Name',
    key: 'name',
  }, {
    title: 'Spend value',
    key: 'spendValue',
  }, {
    title: 'Points earned',
    key: 'pointsEarned',
  }, {
    title: 'Discout value',
    key: 'discountValue',
  }
]
