import { COLORS } from 'constants/colors'
import { SalonStaff } from 'core/generated'
import React, { useContext } from 'react'
import { Button, Heading, Paragraph, SvgCartBag, SvgRefresh, SvgUser } from 'ui'
import BookContext from '../context'
import { ADDITIONAL_SERVICES, INITIAL_STEP } from '../constants'
import { formatInToPrice } from 'components/utils/misc'

const SelectedServicesView = () => {
  const { selectedServices, handleNextStep, businessInfo } = useContext(BookContext);
  const totalPrice = () => {
    let total = 0;
    selectedServices?.forEach((service) => {
      total += service?.staffPrice || service?.price;
    });
    return formatInToPrice(total);
  }

  return (
    <div className='flex items-center justify-between p-4 rounded-md text-green-300 bg-green-300/10 cursor-pointer' onClick={() => handleNextStep(businessInfo?.singleStaffBooking ? ADDITIONAL_SERVICES : INITIAL_STEP)}>
      <div className="flex space-x-4 items-center">
        <div className='w-[40px]'>
          <SvgCartBag width="20px" height="20px" />
        </div>
        <div className="flex flex-col">
          <Paragraph size='b5' color={COLORS.GREEN[300]}> {selectedServices?.map((service) => service?.name).join(', ')}</Paragraph>
          <Paragraph size='b6' color={COLORS.GREEN[300]}> {totalPrice()} • {selectedServices?.length} services selected</Paragraph>
        </div>
      </div>

      <Button
        variant="text"
        size="none"
        onClick={() => handleNextStep(businessInfo?.singleStaffBooking ? ADDITIONAL_SERVICES : INITIAL_STEP)}
      >
        <SvgRefresh width="20px" height="20px" />
      </Button>
    </div>
  )
}

export default SelectedServicesView
