import React from 'react'
import { Button, SLogo, SvgCashInflowImage } from 'ui'
import Heading from 'ui/atoms/heading/Heading'
import { COLORS } from 'constants/colors'
import Paragraph from 'ui/atoms/paragraph/Paragraph'
import { Appointment } from 'core/generated'
import { getNumberMonthAndYearFromDate } from 'components/utils/misc'

const PaymentConfirmed = ({ appointment, type, closePaymentPage }: {
  appointment?: Appointment,
  type?: string,
  closePaymentPage?: () => void
}) => {
  const visitBookingSite = () => {
    window.open(`${process.env.REACT_APP_SPLICE_BOOKING_LINK}${appointment?.salon?.business?.bookingUrlIdentifier}`, '_blank')
  }
  return (
    <main className='fixed top-0 left-0 w-full h-screen bg-grey-100 flex items-center justify-center'>
      <div className="table-row">
        <div className="table-cell align-middle">
          <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
            <div className="flex flex-col items-center space-y-6">
              <div className='w-full max-w-[250px]'>
                <SvgCashInflowImage width="100%" height="100%" />
              </div>
              <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>Payment made</Heading>
              <Paragraph size="b5" weight='medium' className='w-full text-center max-w-[400px]' color={COLORS.GREY[400]}>
                {appointment && type === 'deposit' ? `Your appointment at ${appointment?.salon?.business?.name} on ${getNumberMonthAndYearFromDate(appointment?.startAt)}, is confirmed upon deposit payment. A confirmation SMS and email will be sent shortly.` : 'Your payment is being processed'}
              </Paragraph>
              <div className='w-full flex flex-col space-y-4'>
                <Button variant='primary' rounded='lg' size='md' fontSize='b5' fontWeight='semiBold' onClick={() => closePaymentPage()}>View Receipt</Button>
                <Button variant='secondary' rounded='lg' size='md' fontSize='b5' fontWeight='semiBold' onClick={visitBookingSite}>Book Appointment</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default PaymentConfirmed