import React, { useEffect, useMemo, useState } from 'react';
import { useSalonCache } from 'hooks/useSalonCache';
import { formatDateStringToOriginalDate, formatDateToPickLateNight, getDayMonthAndNumberFromDateString, getEndOfDate, getStartOfDate } from 'components/utils/misc';
import { CreateAppointmentDetailsProps, ICalendarEventProps } from 'ui/organism/calendar/types';
import { useToast } from 'hooks/useToast';
import { Appointment, ClosedPeriod, StaffBlockedTime, User } from 'core/generated';
import { useModal } from 'hooks/useModal';
import { PAGE_ROUTES } from 'constants/routes';
import ToastWrapper from 'ui/molecules/toastWrapper/PlainToast';
import { ToastProps } from 'ui/atoms/toast/types';
import Layout from 'ui/layout/Layout';
import { COLORS } from 'constants/colors';
import Calendar from 'ui/organism/calendar/Calendar';
import Heading from 'ui/atoms/heading/Heading';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import Button from 'ui/atoms/button/Button';
import UnassignedAppointmentList from '../components/uicomponents/appointmentComponents/UnassignedAppointmentList';
import DeleteAppointmentModal from '../components/uicomponents/appointmentComponents/modals/DeleteAppointmentModal';
import SaveAppointmentModal from '../components/uicomponents/appointmentComponents/modals/SaveAppointmentModal';
import CancelAppointmentModal from '../components/uicomponents/appointmentComponents/modals/CancelAppointmentModal';
import SuccessModal from '../components/modals/SuccessModal';
import BlockTimeModal from '../components/uicomponents/appointmentComponents/modals/BlockTimeModal';
import BusinessClosePeriodModal from '../components/uicomponents/appointmentComponents/modals/BusinessClosePeriodModal';
import ViewAppointmentModal from 'components/modals/ViewAppointmentModal';
import AddAppointmentModal from 'components/modals/AddAppointmentModal';
import { formatAppointmentsToCalendarEvents, formatBlockedTimeDataToCalendarEvents, formatClosedPeriodDataToCalendarEvents, formatTimeOffDataToCalendarEvents, groupToDates, pickClosedPeriodForSpecificDate } from '../components/uicomponents/appointmentComponents/utils';
import { organizeUnassignedAppointmentsByDate } from '../components/modals/utils';
import axios from 'axios';
import { API_ERRORS } from 'constants/errors';
import ActionCable from "actioncable";
import NoShowAppointmentModal from '../components/uicomponents/appointmentComponents/modals/NoShowAppointmentModal';
import { SvgClose } from 'ui';
import { canPerformAction } from 'components/utils/permission';
import { PERMISSION_CONSTANTS } from 'constants/permission';
import moment from 'moment-timezone';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserCache } from 'hooks';
import { useAppointments, useDeleteAppointment, useRevertAppointmentCancellation, useUpdateAppointmentStatus } from 'api/useAppointments';
import { useClosedPeriods, useGetStaffBlockedTimes, useGetStaffList, useGetStaffTimeOff } from 'api/useStaff';
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader';

const AppointmentPage = () => {
  const navigate = useNavigate();
  const { getSalonFieldValue, getSalonData } = useSalonCache();
  const { getUserData } = useUserCache()
  const countryString = localStorage.getItem('country');
  const businessCountry = countryString ? JSON.parse(countryString) : null
  const currentDateTimeZone = moment().tz(businessCountry?.timezone || 'Africa/Lagos');
  const token = localStorage.getItem('token');
	const webSocketUrl = process.env.REACT_APP_WEB_SOCKET_URL + "?token=" + token as string;

  // Get the date components in the local time zone
  const currentYear = currentDateTimeZone.year();
  const currentMonth = currentDateTimeZone.month(); // 0-based index for month
  const currentDay = currentDateTimeZone.date();
  // Create a new Date object using the local time components
  const currentBusinessDate = new Date(Date.UTC(currentYear, currentMonth, currentDay));

  const salon = getSalonData();
  const salonId = getSalonFieldValue('id');
  const [startDate, setStartDate] = useState<string>(getStartOfDate(currentBusinessDate.toISOString()));
  const [endDate, setEndDate] = useState<string>(getEndOfDate(currentBusinessDate.toISOString()));
  const [currentView, setCurrentView] = useState<string | null>(null);
  const [createAppointmentDetails, setCreateAppointmentDetails] = useState<CreateAppointmentDetailsProps | null>(null);
  const [events, setEvents] = useState<ICalendarEventProps[]>([]);
  const [appointmentId, setAppointmentId] = useState<string | null>(null);
  const [blockedTime, setBlockedTime] = useState<StaffBlockedTime | null>(null);
  const [showClosedPeriodOverlay, setShowClosedPeriodOverlay] = useState(true);
  const [closedPeriodId, setClosedPeriodId] = useState<string | null>(null);
  const [closedPeriod, setClosedPeriod] = useState<ClosedPeriod | null>(null);
  const [editClosedPeriod, setEditClosedPeriod] = useState<ClosedPeriod | null>(null);
  const [staffBlockedTimeData, setBlockedTimeData] = useState<StaffBlockedTime[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [appointmentServiceId, setAppointmentServiceId] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cancelType, setCancelType] = useState<string>('service');
  const { toast, addToast } = useToast();
  const params = useParams();
  const appointmentIdInRoute = params.appointmentId as string;
  const user: User = JSON.parse(localStorage.getItem('userData'))
  const country = JSON.parse(localStorage.getItem('country') as string);
  const timezone = country?.timezone || 'Africa/Lagos'
  const changeCalendarView = (view: string) => {
    setCurrentView(view);
  };
  const actions = {
    addAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.addAppointment),
    deleteAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.deleteAppointment),
    rescheduleAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.rescheduleAppointment),
    cancelAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.cancelAppointment),
    markNoShow: canPerformAction(PERMISSION_CONSTANTS?.calendar?.markNoShow),
    addBlockTime: canPerformAction(PERMISSION_CONSTANTS?.calendar?.addBlockTime),
    editClosedPeriod: canPerformAction(PERMISSION_CONSTANTS?.calendar?.editClosedPeriod),
    applyDiscounts: canPerformAction(PERMISSION_CONSTANTS?.calendar?.applyDiscounts),
    applyPromo: canPerformAction(PERMISSION_CONSTANTS?.calendar?.applyPromo),
    viewAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.viewAppointment),
    editBlockedTime: canPerformAction(PERMISSION_CONSTANTS?.calendar?.editBlockTime),
    canViewAllCalendar: canPerformAction(PERMISSION_CONSTANTS?.calendar?.canViewAllCalendar),
    canCheckInOrCheckOutAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.checkInOrOut),
    editPrices: canPerformAction(PERMISSION_CONSTANTS?.calendar?.editPrices),
  }

  const {
    loading: updateAppointmentStatusIsLoading,
    updateAppointmentStatus
  } = useUpdateAppointmentStatus()

  const {
    loading: appointmentCancellationIsLoading,
    revertAppointmentCancellation
  } = useRevertAppointmentCancellation()

  const {
    loading: deleteAppointmentIsLoading,
    deleteAppointment
  } = useDeleteAppointment()

  const {
    loading: isUnassignedAppointmentsLoading,
    data: unassignedAppointmentsData,
    refetch: refetchUnassignedAppointments
  } = useAppointments({
    salonId,
    status: 'pending'
  })
  const unassignedAppointments = useMemo(() => unassignedAppointmentsData?.appointments, [unassignedAppointmentsData])
  const organizedUnassignedAppointments = useMemo(() => {
    if (actions?.viewAppointment) {
      return organizeUnassignedAppointmentsByDate(unassignedAppointments || []);
    } else {
      return [];
    }
  }, [unassignedAppointments])

  const {
    loading: loadingAppointments,
    data: appointmentsData,
    refetch: refetchAppointments
  } = useAppointments({
    salonId,
    startDate,
    endDate
  })
  const appointmentData = useMemo(() => {
    return formatAppointmentsToCalendarEvents(appointmentsData?.appointments || [], currentView, timezone)
  }, [appointmentsData])

  const {
    data: staffTimeOffData,
    refetch: refetchStaffTimeOff
  } = useGetStaffTimeOff({
    salonId,
  })
  const timeOffData = useMemo(() => {
    return formatTimeOffDataToCalendarEvents(staffTimeOffData?.staffTimeOffs || [], timezone);
  }, [staffTimeOffData])

  const {
    data: blockTimeData,
    refetch: refetchBlockTime
  } = useGetStaffBlockedTimes({
    salonId,
    startDate,
    endDate
  })
  const blockedTimeData = useMemo(() => {
    return formatBlockedTimeDataToCalendarEvents(blockTimeData?.staffBlockedTimes || [], timezone);
  }, [blockTimeData])

  const {
    data: businessClosedPeriodData,
    refetch: refetchClosedPeriods
  } = useClosedPeriods({
    salonId,
  })

  const closedPeriodData = useMemo(() => {
    const closedPeriodsDatesGroup = groupToDates(businessClosedPeriodData?.closedPeriods)
    const formatClosedPeriodData = () => formatClosedPeriodDataToCalendarEvents(closedPeriodsDatesGroup || []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const closedPeriodData = currentView && currentView === 'timeGridWeek' ? formatClosedPeriodData() : [];

    if (currentView && currentView === 'timeGridWeek') {
      setClosedPeriod(null);
    } else {
      if (closedPeriodsDatesGroup && businessClosedPeriodData && Array.isArray(closedPeriodsDatesGroup) && closedPeriodsDatesGroup.length > 0) {
        const getClosedPeriodForDayView = pickClosedPeriodForSpecificDate(closedPeriodsDatesGroup, businessClosedPeriodData?.closedPeriods, startDate);

        if (getClosedPeriodForDayView) {
          setClosedPeriod(getClosedPeriodForDayView)
        } else {
          setClosedPeriod(null)
        }
      }
    }
    return closedPeriodData;
  }, [businessClosedPeriodData, currentView, startDate, endDate])

  const changeDates = ({ start, end }: {
    start: string,
    end: string,
  }) => {
    setStartDate(getStartOfDate(start));
    setEndDate(getEndOfDate(formatDateToPickLateNight(end)));
  }

  const {
    loading: isLoading,
    data: staffListData,
    refetch: refetchStaffList
  } = useGetStaffList({
    salonId: salon?.id,
    startDate: formatDateStringToOriginalDate(startDate, 'start'),
    endDate: formatDateStringToOriginalDate(endDate, 'end'),
    active: true
  })
  const staff = useMemo(() => staffListData?.staffManagement?.staffs, [staffListData])

  useEffect(() => {
    refetchCalendarData();
    setShowClosedPeriodOverlay(true)
  }, [currentView, startDate, endDate]);

  useEffect(() => {
    if (appointmentIdInRoute && appointmentIdInRoute !== PAGE_ROUTES.CALENDAR.replace('/', '')) {
      setAppointmentId(appointmentIdInRoute);
      openViewAppointmentModal();
    }
  }, [appointmentIdInRoute])

  useEffect(() => {
    refetchCalendarData()
  }, [])

  useEffect(() => {
    setEvents([...appointmentData, ...timeOffData, ...blockedTimeData, ...closedPeriodData]);
  }, [appointmentData, timeOffData, blockedTimeData, closedPeriodData])

  const refetchCalendarData = async () => {
    setEditClosedPeriod(null)
    refetchUnassignedAppointments()
    refetchAppointments()
    refetchStaffTimeOff()
    refetchBlockTime()
    refetchClosedPeriods()
  }

  useEffect(() => {
    refetchAppointments()
  }, [refetchAppointments])

  React.useEffect(() => {
    const cable = ActionCable.createConsumer(webSocketUrl);
    cable.subscriptions.create(
      { channel: "PendingAppointmentsChannel", salon_id: getSalonFieldValue("id") },
      {
        connected() {
          console.log("Connected to Action Cable Appointments");
        },
        received: (data) => {
          // handle notification here
          const appointments = data.appointments as Appointment[]
          if (Array?.isArray(appointments)) {
            // const mergedAndOrganizedAppointments = organizeUnassignedAppointmentsByDate(appointments);
            // setOrganizedUnassignedAppointments(mergedAndOrganizedAppointments);
            refetchUnassignedAppointments();
          }
        },
      }
    );
  }, []);

  // const markAsNoShow = async () => {
  //   if (!appointmentId) return;
  //   try {
  //     const payload = {
  //       appointmentStatus: "no_show",
  //       id: appointmentId,
  //       salonId,
  //     }

  //     await axios.post(
  //       '/graphql',
  //       {
  //         query: print(UpdateAppointmentStatusDocument),
  //         variables: { input: { ...payload } },
  //       },
  //       { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
  //     ).then(({ data }) => {
  //       if (data?.data?.updateAppointmentStatus?.status === 200) {
  //         closeViewAppointmentModal();
  //         refetchCalendarData();
  //         addToast({
  //           message: "Appointment updated successfully",
  //           variant: 'success',
  //         })
  //       }

  //       if (data?.errors?.length) {
  //         const message = data?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
  //         addToast && addToast({
  //           variant: 'error',
  //           message,
  //         })
  //       }


  //       if (data?.data?.updateAppointmentStatus?.errors?.length) {
  //         const message = data?.data?.updateAppointment?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
  //         addToast && addToast({
  //           variant: 'error',
  //           message,
  //         })
  //       }
  //     })
  //   } catch (checkoutAppointmentError) {
  //     if (axios.isAxiosError(checkoutAppointmentError)) {
  //       const message = checkoutAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
  //       addToast({
  //         message,
  //         variant: 'error',
  //       })
  //     }
  //   }
  // }

  const revertAppointmentCancellationAsync = async () => {
    if (!appointmentId) return;
    try {
      const payload = {
        appointmentId
      }
      revertAppointmentCancellation({
        variables: { input: { ...payload } }
      }).then(({ data }) => {
        if (data?.revertAppointmentCancellation?.status === 200) {
          refetchCalendarData();
          closeViewAppointmentModal();
          addToast({
            message: "Appointment updated successfully",
            variant: 'success',
          })
        }
        if (data?.revertAppointmentCancellation?.errors?.length) {
          const message = data?.revertAppointmentCancellation?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          addToast && addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (error) {
      // const message = error?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
      addToast({
        message: 'An error occured',
        variant: 'error'
      })
    }
  }

  const checkInOrOutAppointmentAsync = async ({
    appointmentId, status, applyCancellationFee, chargeCustomerWallet
  }: {
    appointmentId: string,
    status: string,
    applyCancellationFee: boolean,
    chargeCustomerWallet: boolean
  }) => {
    try {
      const payload = {
        appointmentStatus: status,
        id: appointmentId,
        salonId,
        applyCancellationFee,
        chargeCustomerWallet
      }

      updateAppointmentStatus({
        variables: { input: { ...payload } }
      }).then(({ data }) => {
        if (data?.updateAppointmentStatus?.status === 200) {
          refetchCalendarData();
          closeViewAppointmentModal();
          closeCancelAppointmentModal();
          closeNoShowAppointmentModal()
          addToast({
            message: "Appointment updated successfully",
            variant: 'success',
          })
        }

        if (data?.updateAppointmentStatus?.errors?.length) {
          const message = data?.updateAppointmentStatus?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          addToast && addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (checkoutAppointmentError) {
      if (axios.isAxiosError(checkoutAppointmentError)) {
        const message = checkoutAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  };

  const deleteAppointmentAsync = async (id: string | null) => {
    if (!id) return;
    const userId = user?.id
    try {
      deleteAppointment({
        variables: {
          input: {
            salonId,
            id,
            userId: userId
          }
        },
      }).then(({ data }) => {
        const clientDetailsData = data?.deleteAppointment;
        if (clientDetailsData) {
          refetchCalendarData();
          closeViewAppointmentModal();
          addToast({
            message: "Appointment removed successfully",
            variant: 'success',
          })
        } else {
          const message = API_ERRORS.APPOINTMENT_DELETION_FAILED;
          addToast({
            message,
            variant: 'error',
          })
        }
      })
    } catch (deleteAppointmentError) {
      if (axios.isAxiosError(deleteAppointmentError)) {
        const message = deleteAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_DELETION_FAILED;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const {
    isVisible: isAddAppointmentModalVisible,
    openModal: openAddAppointmentModal,
    closeModal: closeAddAppointmentModal,
  } = useModal();
  const {
    isVisible: successModalIsVisible,
    openModal: openSuccessModal,
    closeModal: closeSuccessModal,
  } = useModal();
  const {
    isVisible: isViewAppointmentModalVisible,
    openModal: openViewAppointmentModal,
    closeModal: closeViewAppointmentModal,
  } = useModal();
  const {
    isVisible: isBlockTimeModalVisible,
    openModal: openBlockTimeModal,
    closeModal: closeBlockTimeModal,
  } = useModal();
  const {
    isVisible: isCancelAppointmentModalVisible,
    openModal: openCancelAppointmentModal,
    closeModal: closeCancelAppointmentModal,
  } = useModal();
  const {
    isVisible: isNoShowAppointmentModalVisible,
    openModal: openNoShowAppointmentModal,
    closeModal: closeNoShowAppointmentModal
  } = useModal()
  const {
    isVisible: isDeleteAppointmentModalVisible,
    openModal: openDeleteAppointmentModal,
    closeModal: closeDeleteAppointmentModal,
  } = useModal();
  const {
    isVisible: isSaveAppointmentModalVisible,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    openModal: openSaveAppointmentModal,
    closeModal: closeSaveAppointmentModal,
  } = useModal();
  const {
    isVisible: isBusinessTimeModalVisible,
    openModal: openBusinessTimeModal,
    closeModal: closeBusinessTimeModal,
  } = useModal();

  const initiateCloseAddAppointmentModal = (action: string) => {
    setCreateAppointmentDetails(null)
    closeAddAppointmentModal();
    if (action === 'addedAppointment') {
      openSuccessModal();
    }
  }

  const initiateCloseViewAppointmentModal = (action: string) => {
    setAppointmentId(null);
    closeViewAppointmentModal();
    if (appointmentIdInRoute && appointmentIdInRoute !== PAGE_ROUTES.CALENDAR.replace('/', '')) {
      navigate(PAGE_ROUTES.CALENDAR, {
        replace: true,
      });
    }
    if (action === 'updatedAppointment') {
      refetchCalendarData();
    }
  }

  const openAppointmentPopoverDialog = (serviceId: string, appointmentId: string) => {
    setAppointmentId(appointmentId.includes('T') ? appointmentId.split("T")[0] : appointmentId);
    setAppointmentServiceId(serviceId);
    openViewAppointmentModal();
  }

  const initiateEditBlockedTimeProcess = (id: string) => {
    // get block time from blockTimes
    const blockTime = blockTimeData?.staffBlockedTimes?.find((blockTime) => blockTime?.id === id);
    if (!blockTime) {
      addToast({
        message: "Block time doesn't exist",
        variant: 'error',
      })
      return;
    }
    setBlockedTime(blockTime);
    openBlockTimeModal();
  }

  const initiateEditClosedPeriodProcess = (id: string) => {
    // get closedPeriod by id from closedPeriods
    const closedPeriod = businessClosedPeriodData?.closedPeriods?.find((closedPeriod) => closedPeriod?.id === id)
    if (!closedPeriod) {
      addToast({
        message: "Closed period doesn't exist",
        variant: 'error',
      })
      return;
    }
    setEditClosedPeriod(closedPeriod);
    openBusinessTimeModal();
  }

  const initiateCancelAppointment = () => {
    setCancelType('appointment')
    closeViewAppointmentModal();
    openCancelAppointmentModal();
  }

  const initiateNoShowAppointment = () => {
    setCancelType('appointment')
    closeViewAppointmentModal();
    openNoShowAppointmentModal();
  }

  const initiateDeleteAppointment = () => {
    closeViewAppointmentModal();
    openDeleteAppointmentModal();
  }

  const finalizeAppointmentCancellation = (action: string, applyCancellationFee = false, chargeCustomerWallet = false) => {
    if (action === 'cancel') {
      checkInOrOutAppointmentAsync({
        appointmentId: appointmentId as string, status: 'cancelled', applyCancellationFee, chargeCustomerWallet
      })
    } else if (action === 'no_show') {
      checkInOrOutAppointmentAsync({
        appointmentId: appointmentId as string, status: 'no_show', applyCancellationFee, chargeCustomerWallet
      })
    }
    else {
      closeCancelAppointmentModal();
      closeNoShowAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const finalizeAppointmentDeletion = (action: string) => {
    if (action === 'delete') {
      deleteAppointmentAsync(appointmentId)
      closeDeleteAppointmentModal();
    } else {
      closeDeleteAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const closeSaveAppointment = (action: string) => {
    if (action === 'save') {
      closeSaveAppointmentModal();
    } else {
      closeSaveAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const closeSuccessModalAndReloadCalendar = () => {
    closeSuccessModal();
    refetchCalendarData();
  }

  const closeBlockTimeViewModal = () => {
    setBlockedTime(null);
    closeBlockTimeModal();
  }

  const closeModalsAndReFreshPage = () => {
    closeBusinessTimeModal();
    setClosedPeriodId(null)
    setClosedPeriod(null)
    setEditClosedPeriod(null)
    refetchCalendarData();
  }

  const formatStaffBasedOffPermission = () => {
    if (actions?.canViewAllCalendar) {
      return staff;
    }
    return staff?.filter((staffMember) => staffMember?.id === user?.salonStaff?.id);
  }

  return (
    <Layout
      pageTitle="Calendar"
    >
      {loadingAppointments ? <ContentLoader /> : null}
      <ToastWrapper toast={toast as ToastProps} />
      <div className='flex flex-col xl:flex-row w-full h-full'>
        <div className="w-full h-fit max-h-[300px] xl:max-h-[100%] flex flex-col xl:max-w-[200px]">
          <UnassignedAppointmentList openAppointment={openAppointmentPopoverDialog} unassignedAppointments={organizedUnassignedAppointments} isLoading={isUnassignedAppointmentsLoading} />
        </div>
        <div className='relative w-full h-full'>
          <Calendar
            openAddAppointmentModal={openAddAppointmentModal}
            openBlockTimeModal={openBlockTimeModal}
            openAppointment={openAppointmentPopoverDialog}
            events={events}
            currentView={currentView}
            changeCalendarView={changeCalendarView}
            changeDates={changeDates}
            staff={formatStaffBasedOffPermission()}
            initiateEditBlockedTimeProcess={initiateEditBlockedTimeProcess}
            setCreateAppointmentDetails={setCreateAppointmentDetails}
            initiateEditClosedPeriodProcess={initiateEditClosedPeriodProcess}
            actions={actions}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            refreshCalendarData={refetchCalendarData}
          />
          {currentView === 'resourceTimeGridDay' && closedPeriod && showClosedPeriodOverlay ? (
            <div className='absolute inset-0 top-[55px] flex justify-center items-center bg-black/3 backdrop-blur-[2px] z-[40]'>
              <div className="w-full xl:border-none shadow-medium py-12 px-9 max-w-[400px]">
                <div className="flex flex-col items-center space-y-6">
                  <Heading variant='h1' size='h9' weight='bold' color={COLORS.BLACK}>
                    Business is closed
                  </Heading>
                  <Paragraph size="b6" weight='medium' className='w-full text-center' color={COLORS.GREY[400]}>
                    {getDayMonthAndNumberFromDateString(closedPeriod?.startAt)} - {getDayMonthAndNumberFromDateString(closedPeriod?.endAt)}
                  </Paragraph>
                  {actions?.editClosedPeriod ?
                    <Button
                      variant="primary"
                      size='md'
                      rounded='md'
                      fontSize='b5'
                      onClick={() => {
                        setShowClosedPeriodOverlay(false);
                        openBusinessTimeModal()
                      }}
                    >
                      Edit closed business
                    </Button> : null}
                  <Button
                    variant='text'
                    size='none'
                    type='button'
                    className='w-fit'
                    fontSize='b4'
                    onClick={() => {
                      setShowClosedPeriodOverlay(false);
                    }}
                  >
                    <SvgClose width='24px' height='24px' />
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <AddAppointmentModal
        isVisible={isAddAppointmentModalVisible}
        closeModal={initiateCloseAddAppointmentModal}
        addToast={addToast}
        salonId={salonId}
        createAppointmentDetails={createAppointmentDetails}
      />
      <SuccessModal
        isVisible={successModalIsVisible}
        closeModal={closeSuccessModalAndReloadCalendar}
      />
      <ViewAppointmentModal
        isVisible={isViewAppointmentModalVisible}
        closeModal={initiateCloseViewAppointmentModal}
        cancelAppointment={initiateCancelAppointment}
        deleteAppointment={initiateDeleteAppointment}
        salonId={salonId}
        addToast={addToast}
        appointmentId={appointmentId}
        // markAsNoShow={markAsNoShow}
        markAsNoShow={initiateNoShowAppointment}
        refetchCalendarData={refetchCalendarData}
        revertCancellation={revertAppointmentCancellationAsync}
      />
      <CancelAppointmentModal
        isVisible={isCancelAppointmentModalVisible}
        closeModal={finalizeAppointmentCancellation}
        appointmentId={appointmentId}
        salonId={salonId}
      />
      <NoShowAppointmentModal
        isVisible={isNoShowAppointmentModalVisible}
        closeModal={finalizeAppointmentCancellation}
        appointmentId={appointmentId}
        salonId={salonId}
      />
      <DeleteAppointmentModal
        isVisible={isDeleteAppointmentModalVisible}
        closeModal={finalizeAppointmentDeletion}
      />
      <SaveAppointmentModal
        isVisible={isSaveAppointmentModalVisible}
        closeModal={closeSaveAppointment}
      />
      <BlockTimeModal
        isVisible={isBlockTimeModalVisible}
        closeModal={closeBlockTimeViewModal}
        addToast={addToast}
        refetchCalendarData={refetchCalendarData}
        salonId={salonId}
        blockedTime={blockedTime}
        user={user}
      />
      <BusinessClosePeriodModal
        isVisible={isBusinessTimeModalVisible}
        closeModal={closeModalsAndReFreshPage}
        closedPeriod={editClosedPeriod || closedPeriod}
        closedPeriodId={closedPeriodId}
      />
    </Layout>
  )
}

export default AppointmentPage
