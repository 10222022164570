import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { AddSaleProps, AppointmetSplitPaymentInput, SaleInput, SelectedProduct, SelectedService, SplitPaymentsInput } from './types';
import type { DatePickerProps } from 'antd';
import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import {
  AppointmentPreview,
  Client,
  Product,
  Service
} from 'core/generated'
import { APPOINTMENT_SPLIT_AND_WALLET_PAYMENT_METHODS, SALES_PAYMENT_METHODS } from './constants'
import {
  convertFullDateStringToDate,
  convertFullDateStringToTime,
  convertOptionsToCommaSeparatedString,
  convertProductsToCommaSeparatedString,
  convertSaleProductsToCommaSeparatedString,
  convertServicesToCommaSeparatedStringWithQuantity,
  formatInToPrice,
  formatNumber,
  getClientFullnameTextForAvatar,
  getClientTextForAvatar,
  getHoursAndMinutesString,
  pickDefaultTime,
  sanitizePhoneNumber,
  sortProducts,
  sortServices,
  validateDecimalInput,
  validatePhoneNumber
} from '../utils/misc'
import { MultiSelectOption } from 'ui/molecules/multiselect/types'
import {
  convertServicesToAppointmentStaffService,
  formatBankAccountsToSelectField,
  formatClientToSelectFieldAppointmentCreation,
  formatPosTerminalsToSelectField,
  formatStaffToSelectField
} from '../utils/utils'
import Heading from 'ui/atoms/heading/Heading'
import Paragraph from 'ui/atoms/paragraph/Paragraph'
import { COLORS } from 'constants/colors'
import { FormatNumber } from 'ui/atoms/formatNumber/FormatNumber'
import { DEFAULT_CURRENCY } from 'constants/currency'
import { NameAvatar, SvgClose, SvgPencil, SvgRefresh, SvgTrash, ToggleOnly } from 'ui'
import { Modal } from 'ui/templates/modal/Modal'
import Button from 'ui/atoms/button/Button'
import { SvgArrowBack } from 'ui'
import { SvgChevronLeft } from 'ui'
import { FormLabel } from 'ui/atoms/formLabel'
import { FormHelperText } from 'ui/atoms/helperText/FormHelperText'
import { API_ERRORS, ERRORS } from 'constants/errors'
import CollapseRow from 'ui/organism/collapseRow/CollapseRow'
import { REGEX_PATTERNS } from 'constants/pattern'
import FormPhone from 'ui/molecules/input/FormPhone'
import Input from 'ui/molecules/input/Input'
import {
  getHelpTextForCharacterLeft,
  getHelperTextForReactHookFormErrors
} from '../utils/form'
import MultiSelect from 'ui/molecules/multiselect/multiselect'
import { SvgPlus } from 'ui'
import SearchTerm from 'ui/organism/debounceQuery/SearchTerm'
import FormTextarea from 'ui/molecules/input/FormTextarea'
import SelectInput from 'ui/molecules/input/SelectInput'
import SingleSelect from 'ui/molecules/singleSelect/SingleSelect'
import { useSalonCache } from 'hooks/useSalonCache'
import { logger } from 'core/logger'
import {
  SvgCheckMarkGreen,
  SvgEdit,
  SvgGreyMinus,
  SvgGreyPlus,
} from 'ui'
import ViewAppointmentShimmer from '../uicomponents/appointmentComponents/modals/ViewAppointmentShimmer';
import { formatSaleStatusPill } from '../uicomponents/salesComponents/StatusUtils';
import { Checkbox, ContactLink } from 'ui';
import ProceedSalePaymentInfo from '../uicomponents/salesComponents/ProceedSalePaymentInfo';
import { useModal } from 'hooks';
import { useCreateSale, useGetSale, useUpdateSale } from 'api/useSales';
import axios from 'axios';
import { useAppointmentPreview } from 'api/useAppointments';
import { useGetClient, useGetClientsForSalesAndAppointments } from 'api/useClient';
import { useGetProducts, useGetServices } from 'api/useCatalogue';
import { useGetPosDevices, useGetTransferAccounts } from 'api/useMoney';
import { useGetStaffList } from 'api/useStaff';
import { PAYMENT_METHODS, PAYMENT_METHODS_OFFLINE_ONLY } from 'components/uicomponents/bookingSales/constants';
import CreditWalletBalance from './CreditWalletBalance';

const AddSaleModal = (props: AddSaleProps) => {
  const { getSalonFieldValue, getSalonData } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const salon = getSalonData()
  const [newClient, setNewClient] = useState(false)
  const [selectedServices, setSelectedServices] = useState<SelectedService[]>(
    []
  )
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
    []
  )
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('')
  const [countryCode, setCountryCode] = useState('')
  const [callingCode, setCallingCode] = useState('')
  const [enablePromo, setEnablePromo] = useState(false)
  const [enableAddOns, setEnableAddons] = useState(false)
  const [enableDiscount, setEnableDiscount] = useState(false)
  const [discountType, setDiscountType] = useState('')
  const [isDiscountApplied, setIsDiscountApplied] = useState(false)
  const [showServiceSelection, setShowServiceSelection] = useState(false)
  const [showProductSelection, setShowProductSelection] = useState(false)
  const [filteredServices, setFilteredServices] = useState<Service[]>([]);
  const [editServicePrice, setEditServicePrice] = useState<string | null>(null)
  const [serviceNewPrice, setServiceNewPrice] = useState<string | null>(null)
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [splitPayments, setSplitPayments] = useState<SplitPaymentsInput[]>([])
  const [client, setClient] = useState<Client | null>(null)
  const [showPaymentSelection, setShowPaymentSelection] = useState(false)
  const [showClientSelection, setShowClientSelection] = useState(false)
  const [creditWallet, setCreditWallet] = useState(false)
  const [deductWallet, setDeductWallet] = useState<boolean>(false)
  const [amountToBeChecked, setAmountToBeChecked] = useState<number>(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [promoCode, setPromoCode] = useState<{
    promoType: string
    promoValue: number
  } | null>(null)
  const [appointmentSummary, setAppointmentSummary] =
    useState<AppointmentPreview | null>(null)
  let searchTimeout: ReturnType<typeof setTimeout>;
  const dateFormat = 'YYYY/MM/DD'
  const [appointmentHasNoClient, setAppointmentHasNoClient] = useState<boolean>(false)

  const {
    openModal: openCreditWalletDialogModal,
    closeModal: closeCreditWalletDialogModal,
    isVisible: isCreditWalletDialogModalVisible
  } = useModal()
  const country = JSON.parse(localStorage.getItem('country'));
  const APPOINTMENT_PAYMENT_METHODS =
    country?.payoutsSupported === true && country?.collectionsSupported === true
      ? PAYMENT_METHODS
      : PAYMENT_METHODS_OFFLINE_ONLY;
  const {
    control,
    handleSubmit,
    reset,
    watch,
    register,
    setValue,
    formState: { errors }
  } = useForm<SaleInput>({
    defaultValues: {
      newClient: false
    }
  })

  const {
    loading: createSaleIsLoading,
    createSale
  } = useCreateSale()

  const {
    loading: updateSaleIsLoading,
    updateSale
  } = useUpdateSale()

  const {
    loading: previewAppointmentPriceIsLoading,
    appointmentPreview
  } = useAppointmentPreview()

  const {
    data: saleData,
    loading: saleIsLoading,
    refetch
  } = useGetSale(props?.saleId)
  const sale = useMemo(() => saleData?.sale, [saleData?.sale])

  useEffect(() => {
    if (props?.saleId) {
      refetch()
    }
  }, [props?.saleId, refetch])

  const {
    isVisible: isDialogModalVisible,
    openModal: openDialogModal,
    closeModal: closeDialogModal,
  } = useModal();

  const {
    data: clientsData,
    refetch: refetchClients
  } = useGetClientsForSalesAndAppointments({
    salonId,
    first: 10
  })
  const clients = useMemo(() => clientsData?.salonClients?.nodes, [clientsData])
  const clientsOptions = useMemo(() => formatClientToSelectFieldAppointmentCreation(clients), [clients])

  const {
    data: productsData,
    refetch: refetchProducts
  } = useGetProducts({
    salonId,
    isPurchasable: true
  });
  const products = useMemo(() => {
    setFilteredProducts(sortProducts(productsData?.products))
    return productsData?.products
  }, [productsData])

  const {
    data: servicesData,
    refetch: refetchServices
  } = useGetServices(salonId)
  const services = useMemo(() => {
    setFilteredServices(sortServices(servicesData?.services))
    return servicesData?.services
  }, [servicesData])

  const {
    data: bankTransfersData,
    refetch: refetchBankTransfers
  } = useGetTransferAccounts(salonId)
  const bankTransfers = useMemo(() => bankTransfersData?.transferAccounts, [bankTransfersData])
  const transfersOptions = useMemo(() => formatBankAccountsToSelectField(bankTransfers), [bankTransfers])

  const {
    data: posTerminalsData,
    refetch: refetchPosTerminals
  } = useGetPosDevices(salonId)
  const posTerminals = useMemo(() => posTerminalsData?.posDevices, [posTerminalsData])
  const posTerminalsOptions = useMemo(() => formatPosTerminalsToSelectField(posTerminals), [posTerminals])

  const {
    data: staffListData,
    refetch: refetchStaffList
  } = useGetStaffList({
    salonId,
    active: true
  })
  const staff = useMemo(() => staffListData?.staffManagement?.staffs, [staffListData])
  const staffMembersOptions = useMemo(() => formatStaffToSelectField(staff), [staff])

  useEffect(() => {
    if (salonId) {
      refetchClients();
      refetchProducts();
      refetchServices();
      refetchBankTransfers();
      refetchPosTerminals();
      refetchStaffList();
    }
  }, [salonId, refetchClients, refetchProducts, refetchServices, refetchBankTransfers, refetchPosTerminals, refetchStaffList]);

  const finalizeDialogModalAction = (action: string) => {
    closeDialogModal()
    if (action === 'proceed') {
      addSale(watch())
    }
  }

  const {
    data: clientData,
    loading: clientLoading,
    refetch: refetchClient
  } = useGetClient({
    clientId: client?.id,
    salonId
  })
  const salonClient = useMemo(() => clientData?.salonClient, [clientData])
  useEffect(() => {
    if (salonClient) {
      setClient(salonClient)
    }
  }, [salonClient])

  const getClients = async (q: string) => {
    if (q) {
      refetchClients({
        q,
        salonId,
        first: 10
      })
    }
  }

  const addClient = (id: string) => {
    const _client = clients?.find((client) => client.id === id)
    setValue('clientId', { label: _client?.firstName, value: _client?.id } as unknown as [])
    setShowClientSelection(false)
    setNewClient(false)
  }

  useEffect(() => {
    if (watch('clientId')) {
      const clientIdField = watch('clientId') as unknown as {
        label: string;
        value: string;
      };
      if (clientIdField?.value === 'new-client') {
        setNewClient(true);
        setClient(null)
        setValue('clientName', '');
        setValue('clientEmail', '');
        setValue('clientPhone', '');
        setValue('newClient', true);
      } else {
        setValue('newClient', false);
        const client = clients.find((client) => client?.id === clientIdField?.value) as Client;
        if (client) {
          setClient(client)
          setValue('clientName', `${client?.firstName} ${client?.lastName}`);
          setValue('clientEmail', client?.email || "");
          setValue('clientPhone', client?.phone);
          setCallingCode(client?.callingCode || '');
          setCountryCode(client?.countryCode || '');
        }
      }
    }
  }, [watch('clientId')])

  useEffect(() => {
    if (watch('services')) {
      const serviceSelectedFromOptions =
        Array?.isArray(services) &&
        services
          ?.filter((service) => {
            const serviceId = service?.id
            const existsInServicesField = watch('services')?.some(
              (serviceSelected: MultiSelectOption) =>
                serviceSelected?.value === serviceId
            )
            const existsInSelectedServices = selectedServices.some(
              (selectedService) => selectedService.id === serviceId
            )

            if (!existsInServicesField && existsInSelectedServices) {
              // Return null to indicate that this service should be removed
              setSelectedServices((prevSelectedServices) =>
                prevSelectedServices.filter((s) => s.id !== serviceId)
              )
              return false
            }
            return existsInServicesField && !existsInSelectedServices
          })
          .filter((service) => service !== null)
      if (
        Array?.isArray(serviceSelectedFromOptions) &&
        serviceSelectedFromOptions?.length
      ) {
        setSelectedServices((prevSelectedServices) => [
          ...prevSelectedServices,
          ...serviceSelectedFromOptions
        ])
      }
    }
  }, [watch('services')])

  useEffect(() => {
    if (watch('products')) {
      const productSelectedFromOptions = products
        .filter((product) => {
          const productId = product?.id
          const existsInProductsField = watch('products')?.some(
            (selectedProduct: MultiSelectOption) =>
              selectedProduct?.value === productId
          )
          const existsInSelectedProducts = selectedProducts.some(
            (selectedProduct) => selectedProduct.id === productId
          )

          if (!existsInProductsField && existsInSelectedProducts) {
            // Return null to indicate that this service should be removed
            setSelectedProducts((prevSelectedProducts) =>
              prevSelectedProducts.filter((p) => p.id !== productId)
            )
            return false
          }
          return existsInProductsField && !existsInSelectedProducts
        })
        .filter((product) => product !== null)
      if (
        Array?.isArray(productSelectedFromOptions) &&
        productSelectedFromOptions?.length
      ) {
        setSelectedProducts((prevSelectedProducts) => [
          ...prevSelectedProducts,
          ...productSelectedFromOptions
        ])
      }
    }
  }, [watch('products')])

  // validate promo
  useEffect(() => {
    const clientId = watch('clientId') as unknown as {
      label: string
      value: string
    }
    const clientPhone = watch('clientPhone')

    if (
      clientId?.value &&
      clientId?.value !== undefined &&
      debouncedSearchQuery &&
      ((clientId?.value === 'new_client' && clientPhone !== '') ||
        clientId?.value !== 'new_client')
    ) {
      previewPrice()
    }
  }, [debouncedSearchQuery, watch('clientId'), watch('clientPhone'), watch('saleDate')])

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode)
    setValue('clientPhone', _phoneNumber)
  }

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setValue('saleDate', dateString as string)
  }

  const getClientDetails = (input: SaleInput) => {
    if (sale) {
      return {
        firstName: sale?.client?.firstName,
        lastName: sale?.client?.lastName || "",
        email: sale?.client?.email,
        phone: sale?.client?.phone,
        callingCode: sale?.client?.callingCode,
        countryCode: sale?.client?.countryCode,
      }
    }
    const clientId = input?.clientId as unknown as {
      label: string
      value: string
    }

    if (clientId?.value === 'new-client') {
      return {
        firstName: input?.clientName?.split(' ')[0],
        lastName: input?.clientName?.split(' ')[1],
        email: input?.clientEmail,
        phone: input?.clientPhone,
        callingCode,
        countryCode
      }
    }
    // check client details from the list of clients
    const client = clients?.find(
      (client) => client?.id === clientId?.value
    ) as Client
    return {
      firstName: client?.firstName,
      lastName: client?.lastName,
      email: client?.email,
      phone: client?.phone,
      callingCode: client?.callingCode,
      countryCode: client?.countryCode,
    }
  }

  const validateForm = (input: SaleInput) => {
    if (!input?.saleDate) {
      props?.addToast && props?.addToast({ message: 'Date is required', variant: 'error' })
      return false;
    }
    if (input?.clientId === undefined || input?.clientId === null) {
      props?.addToast && props?.addToast({ message: 'Client is required', variant: 'error' })
      return false;
    }
    if (!selectedServices?.length && !selectedProducts?.length) {
      props?.addToast && props?.addToast({ message: 'Kindly select a service or a product', variant: 'error' })
      return false;
    }

    if (input?.hasMadePayment && !input?.paymentMethod && showPaymentSelection) {
      props?.addToast && props?.addToast({ message: 'Please select payment method', variant: 'error' })
      return false;
    }

    if (input?.hasMadePayment && input?.paymentMethod === 'pos' && posTerminals?.length && !input?.paymentMethodId) {
      props?.addToast && props?.addToast({ message: 'Please select a pos device', variant: 'error' })
      return false;
    }

    if (input?.hasMadePayment && input?.paymentMethod === 'bank_transfer' && bankTransfers?.length && !input?.paymentMethodId) {
      props?.addToast && props?.addToast({ message: 'Please select a bank account', variant: 'error' })
      return false;
    }

    return true
  }

  const addSale = async (input: SaleInput) => {
    // validate form
    const checkIfFormIsReadyToGo = validateForm(input);
    if (!checkIfFormIsReadyToGo) {
      return;
    }
    const pn = validatePhoneNumber(input?.clientPhone, countryCode);

    if (!pn.valid && !appointmentHasNoClient && !sale) {
      // If invalid, trigger an error
      props?.addToast && props?.addToast({
        message: 'Invalid phone number',
        variant: 'error',
      })
      return;
    }
    // setCreateSaleIsLoading(true)
    const saleDate = input?.saleDate.replace(/\//g, '-')
    const saleDateTime = `${saleDate}T${pickDefaultTime()}}`
    // const client = getClientDetails(input)

    if (input?.hasMadePayment && !showPaymentSelection) {
      setShowPaymentSelection(true)
      return;
    }

    let appointmentSplitPayments: AppointmetSplitPaymentInput[] = [];
    let saleStatus = 'unpaid';
    let paymentMethodsInput = {
      paymentMethod: input?.paymentMethod,
      paymentMethodId: input?.paymentMethodId,
      amountClientPaid: 0
    }
    const inputAmount = parseFloat(input?.amountClientPaid?.toString().replace(/,/g, "")) || 0;

    if (showPaymentSelection && input?.paymentMethod === 'client_wallet' && +inputAmount > client?.walletBalance && !creditWallet) {
      setAmountToBeChecked(inputAmount)
      setDeductWallet(true)
      openCreditWalletDialogModal()
      return;
    }

    if (input?.paymentMethod === 'split') {
      // check if any splitPaymentMode is client_wallet
      const splitPaymentMode = splitPayments?.find((splitPayment) => splitPayment?.paymentMethod === 'client_wallet')
      const walletSplitAmount = parseFloat(splitPaymentMode?.amount?.toString().replace(/,/g, ""))
      if (splitPaymentMode && walletSplitAmount > client?.walletBalance && !creditWallet) {
        setAmountToBeChecked(walletSplitAmount)
        setDeductWallet(true)
        openCreditWalletDialogModal()
        return;
      }
    }

    if (input?.paymentMethod === 'split') {
      let totalAmount = 0;
      splitPayments?.map((splitPayment) => {
        totalAmount += parseFloat(splitPayment?.amount?.toString().replace(/,/g, ""))
      })
      if (totalAmount > appointmentSummary?.totalPaid && !creditWallet) {
        setAmountToBeChecked(totalAmount)
        openCreditWalletDialogModal()
        return;
      }
    }

    if (sale && !sale?.amountClientPaid && !input?.hasMadePayment) {
      saleStatus = 'unpaid';
    } else if (input?.hasMadePayment || sale) {
      const totalPaid = appointmentSummary?.totalPaid || 0;
      const saleAmount = sale?.amountClientPaid || 0;

      if (sale) {
        if (!creditWallet && saleAmount === 0 && inputAmount > totalPaid) {
          setAmountToBeChecked(inputAmount)
          openCreditWalletDialogModal()
          return;
        }

        if (!creditWallet && saleAmount > 0 && (inputAmount + saleAmount) > totalPaid) {
          setAmountToBeChecked(inputAmount)
          openCreditWalletDialogModal()
          return;
        }

        if (saleAmount === 0 && inputAmount > 0 && inputAmount < totalPaid) {
          saleStatus = 'partially_paid';
          paymentMethodsInput = {
            ...paymentMethodsInput,
            amountClientPaid: inputAmount
          }
        } else if (saleAmount === 0 && inputAmount === totalPaid) {
          saleStatus = 'paid';
          paymentMethodsInput = {
            ...paymentMethodsInput,
            amountClientPaid: inputAmount
          }
        } else if (saleAmount > 0 && (inputAmount + saleAmount) === totalPaid) {
          saleStatus = 'paid';
          paymentMethodsInput = {
            ...paymentMethodsInput,
            amountClientPaid: inputAmount
          }
        } else if (saleAmount > 0 && (inputAmount + saleAmount) < totalPaid) {
          saleStatus = 'partially_paid';
          paymentMethodsInput = {
            ...paymentMethodsInput,
            amountClientPaid: inputAmount
          }
        }

        if (saleStatus !== 'unpaid') {
          appointmentSplitPayments = [
            {
              splitPaymentMethodId: input?.paymentMethodId || null,
              splitPaymentMode: input?.paymentMethod,
              amount: inputAmount,
            },
          ];
        }
      } else {
        if (inputAmount > totalPaid && !creditWallet) {
          setAmountToBeChecked(inputAmount)
          openCreditWalletDialogModal()
          return
        }
        if (inputAmount === totalPaid) {
          saleStatus = 'paid';
          paymentMethodsInput = {
            ...paymentMethodsInput,
            amountClientPaid: inputAmount
          }
        } else if (inputAmount > 0 && inputAmount < totalPaid) {
          saleStatus = 'partially_paid';
          paymentMethodsInput = {
            ...paymentMethodsInput,
            amountClientPaid: inputAmount
          }
        }
        if (saleStatus !== 'unpaid') {
          appointmentSplitPayments = [
            {
              splitPaymentMethodId: input?.paymentMethodId || null,
              splitPaymentMode: input?.paymentMethod,
              amount: inputAmount,
            },
          ];
        }
      }
      if (input?.paymentMethod === 'split') {
        appointmentSplitPayments = splitPayments?.map((payment) => {
          return {
            splitPaymentMethodId: payment?.paymentMethodId,
            splitPaymentMode: payment?.paymentMethod,
            amount: parseFloat(payment?.amount?.toString().replace(/,/g, "")),
          }
        })

        // get sum of appointmentSplitPayments
        const sumOfSplitPayments = appointmentSplitPayments?.reduce((acc, payment) => {
          return acc + payment?.amount
        }, 0)

        const highestSplitPayment = appointmentSplitPayments?.reduce((acc, payment) => {
          if (payment?.amount > acc?.amount) {
            return payment
          }
          return acc
        }, appointmentSplitPayments[0])

        if (sumOfSplitPayments >= totalPaid) {
          paymentMethodsInput = {
            paymentMethodId: highestSplitPayment?.splitPaymentMethodId,
            paymentMethod: highestSplitPayment?.splitPaymentMode,
            amountClientPaid: sumOfSplitPayments,
          };
          saleStatus = 'paid';
        } else {
          paymentMethodsInput = {
            paymentMethodId: highestSplitPayment?.splitPaymentMethodId,
            paymentMethod: highestSplitPayment?.splitPaymentMode,
            amountClientPaid: sumOfSplitPayments,
          };
          saleStatus = 'partially_paid';
        }
      }
    }

    const inputData = {
      client: !appointmentHasNoClient ? {
        firstName: input?.clientName?.split(' ')[0],
        lastName: input?.clientName?.split(' ')[1] || ' ',
        email: input?.clientEmail,
        phone: input?.clientPhone,
        callingCode,
        countryCode,
      } : null,
      startAt: saleDateTime,
      services: selectedServices.length
        ? convertServicesToCommaSeparatedStringWithQuantity(selectedServices)
        : [],
      products: selectedProducts.length
        ? convertSaleProductsToCommaSeparatedString(selectedProducts)
        : [],
      staff: input?.staff
        ? convertOptionsToCommaSeparatedString(input?.staff)
        : [],
      note: input?.note,
      promoCode: debouncedSearchQuery,
      saleStatus,
      ...(input?.hasMadePayment ? {
        ...paymentMethodsInput
      } : {
        amountClientPaid: 0,
      }),
      ...(input?.addOnAmount
        ? {
          addOnAmount: parseFloat(
            input?.addOnAmount.toString().replace(',', '')
          )
        }
        : {}),
      addOnReason: input?.addOnReason,
      discountType,
      splitPayments: appointmentSplitPayments,
      discountValue: input.discountValue ? Number(input.discountValue) : null
    }

    setDeductWallet(false)
    setCreditWallet(false)

    if (sale) {
      updateSale({
        variables: {
          input: { ...inputData, salonId: props?.salonId, id: sale?.id }
        }
      }).then(({ data }) => {
        if (
          data?.updateSale?.status === 201
        ) {
          props?.addToast &&
            props.addToast({
              message: 'Sale updated successfully',
              variant: 'success'
            })
          resetFormInput()
          props.closeModal('updatedSale', data?.updateSale?.sale)
        }

        if (data?.updateSale?.errors?.length) {
          const message =
            data?.updateSale?.errors[0]?.message ||
            API_ERRORS.SALE_UPDATE_FAILED
          props?.addToast &&
            props.addToast({
              variant: 'error',
              message
            })
        }
      }).catch((addAppointmentError) => {
        if (axios.isAxiosError(addAppointmentError)) {
          const message =
            addAppointmentError?.response?.data?.message ||
            API_ERRORS.SALE_UPDATE_FAILED
          props?.addToast &&
            props.addToast({
              message,
              variant: 'error'
            })
          logger.error(`Failed to add a sale: ${message}`)
          logger.exception(addAppointmentError as Error)
        }
      })
    } else {
      createSale({
        variables: {
          input: { ...inputData, salonId: props?.salonId }
        }
      }).then(({ data }) => {
        if (
          data?.createSale?.status === 201 &&
          data?.createSale?.sale
        ) {
          props?.addToast &&
            props.addToast({
              message: 'Sale created successfully',
              variant: 'success'
            })
          resetFormInput()
          props.closeModal('addedSale', data?.createSale?.sale)
        }

        if (data?.createSale?.errors?.length) {
          const message =
            data?.createSale?.errors[0]?.message ||
            API_ERRORS.SALE_CREATION_FAILED
          props?.addToast &&
            props.addToast({
              variant: 'error',
              message
            })
        }
      }).catch((error) => {
        const message =
          error?.message ||
          API_ERRORS.SALE_CREATION_FAILED
        props?.addToast &&
          props.addToast({
            message,
            variant: 'error'
          })
        logger.error(`Failed to add a sale: ${message}`)
        logger.exception(error as Error)
      })
    }
  }

  const previewPrice = async () => {
    const client = getClientDetails({
      clientId: watch('clientId'),
      clientName: watch('clientName'),
      clientEmail: watch('clientEmail'),
      clientPhone: watch('clientPhone')
    } as SaleInput)

    const startDate = new Date().toISOString()
    let startAt = `${startDate?.split('T')[0]}T${pickDefaultTime()}`

    if (watch('saleDate')) {
      startAt = `${watch('saleDate').replace(/\//g, '-')}T${pickDefaultTime()}`
    }

    if (!selectedServices?.length && !selectedProducts?.length) {
      return;
    }

    const appointmentData = {
      client: !appointmentHasNoClient ? {
        phone: sale ? sale?.client?.phone : client?.phone,
        countryCode: sale ? sale?.client?.countryCode : client?.countryCode
      } : null,
      services: convertServicesToAppointmentStaffService(
        selectedServices,
        startAt
      ),
      ...(selectedProducts.length && {
        products: convertProductsToCommaSeparatedString(selectedProducts)
      }),
      ...(debouncedSearchQuery ? { promoCode: debouncedSearchQuery } : {}),
      paymentMethod: "unpaid",
      discountType,
      discountValue: Number(watch('discountValue'))
    }

    appointmentPreview({
      variables: { input: { ...appointmentData, salonId: props?.salonId } }
    }).then(({ data }) => {
      const appointmentSummary = data?.appointmentPreview?.data
      if (appointmentSummary) {
        setAppointmentSummary(appointmentSummary)
        if (!sale) {
          setValue('amountClientPaid', appointmentSummary?.totalPaid)
        }
        if (sale && sale?.saleStatus === 'unpaid') {
          setValue('amountClientPaid', appointmentSummary?.totalPaid)
        }

        if (appointmentSummary?.appointmentPromo) {
          setPromoCode({
            promoType: 'amount',
            promoValue: appointmentSummary?.appointmentPromo?.amount
          })
        }

        if (debouncedSearchQuery && !appointmentSummary?.appointmentPromo) {
          props?.addToast && props?.addToast({
            variant: 'error',
            message: 'Promo code could not be applied'
          })
        }
      }
    }).catch((error) => {
      // just catch it
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const searchClient = (e: any) => {
    // Clear any previously set timeout to avoid multiple calls
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout to delay the execution of getClients
    searchTimeout = setTimeout(() => {
      getClients(e);
    }, 500);
  };

  useEffect(() => {
    previewPrice()
  }, [
    selectedServices,
    selectedProducts,
    debouncedSearchQuery,
    watch('paymentMethod'),
    watch('addOnAmount'),
    watch('clientId'), watch('clientPhone'), sale?.id
  ])

  const closeAddSaleModal = () => {
    if (showProductSelection || showServiceSelection) {
      setShowProductSelection(false);
      setShowServiceSelection(false);
    } else if (showPaymentSelection) {
      setShowPaymentSelection(false)
    } else if (showClientSelection) {
      setShowClientSelection(false);
    } else {
      props.closeModal('addedSale')
      resetFormInput()
    }
  }

  const getStaffDetails = (id: string) => {
    return staff.find((staff) => staff?.id === id)
  }

  useEffect(() => {
    if (sale && staff && staff?.length && props?.isVisible) {
      const saleStaff = sale?.saleStaff?.map((staffMember) => {
        const staffDetails = getStaffDetails(staffMember?.salonStaffId);
        return {
          label: staffDetails?.user?.fullName,
          value: staffDetails?.id
        }
      })
      // get all the servicesId and quantity in sale?.salesServices
      const saleServices = sale.saleServices?.map((service) => {
        return {
          id: service?.serviceId,
          price: service?.price,
          quantity: service?.quantity
        }
      })

      // setSelectedServices, get quantity and id in saleServices, check the id in services, then add quantity then save
      const exisitingServices = saleServices?.map((service) => {
        const serviceDetails = services?.find((serviceDetail) => serviceDetail?.id === service?.id)
        return {
          ...serviceDetails,
          servicePrice: service?.price,
          quantity: service?.quantity
        }
      })
      setSelectedServices(exisitingServices as unknown as [])

      // products
      const saleProducts = sale.saleProducts?.map((product) => {
        return {
          id: product?.productId,
          quantity: product?.quantity
        }
      })

      // setProducts
      const exisitingProducts = saleProducts?.map((product) => {
        const productDetails = products?.find((productDetail) => productDetail?.id === product?.id)
        return {
          ...productDetails,
          quantity: product?.quantity
        }
      })
      setSelectedProducts(exisitingProducts as unknown as [])

      setValue('saleDate', convertFullDateStringToDate(sale?.startAt))
      setValue('saleTime', convertFullDateStringToTime(sale?.startAt as string))
      setValue('clientId', {
        label: `${sale?.client?.firstName} ${sale?.client?.lastName}`,
        value: sale?.client?.id
      } as unknown as [])
      setValue('clientPhone', sale?.client?.phone || '')
      setValue('clientEmail', sale?.client?.email || '')
      setValue('clientName', sale?.client?.firstName + " " + sale?.client?.lastName)
      setValue('note', sale?.note || '')
      setValue('staff', saleStaff as unknown as [])
      setValue('addOnReason', sale?.addOnReason || '')
      setValue('addOnAmount', sale?.addOnAmount || 0)
      // setValue('hasMadePayment', sale?.paymentMethod ? true)
      setIsDiscountApplied(sale?.saleDiscount && sale?.saleDiscount > 0 ? true : false)
      if (sale?.saleDiscount && sale?.saleDiscount > 0) {
        setValue('discountType', 'fixed_value')
        setDiscountType('fixed_value')
        setValue('discountValue', sale?.saleDiscount)
      }
      setEnableAddons(sale?.addOnAmount && sale?.addOnAmount > 0 ? true : false)
      setEnablePromo(sale?.salePromo && sale?.salePromo?.amount > 0 ? true : false)
      setEnableDiscount(sale?.discountAmount && sale?.discountAmount > 0 ? true : false)
      if (sale?.totalPaid && sale?.amountClientPaid && sale?.amountClientPaid > 0) {
        setValue("amountClientPaid", sale?.totalPaid - sale?.amountClientPaid)
      } else {
        setValue("hasMadePayment", false)
      }
      previewPrice()
    }
  }, [sale, staff, props?.isVisible])

  const resetFormInput = () => {
    setValue('saleDate', '')
    setValue('clientId', [])
    setValue('clientPhone', '')
    setValue('clientEmail', '')
    setValue('clientName', '')
    setValue('note', '')
    setValue('services', [])
    setValue('staff', [])
    setValue('products', [])
    setValue('addOnReason', '')
    setValue('addOnAmount', 0)
    setValue('paymentMethod', '')
    setValue('discountType', '')
    setValue('discountValue', 0)
    setSelectedServices([])
    setSelectedProducts([])
    setEnableAddons(false)
    setNewClient(false)
    setEnablePromo(false)
    setAppointmentSummary(null)
    setIsDiscountApplied(false)
    setDiscountType('')
    setEnableDiscount(false)
    setValue("amountClientPaid", 0)
    setValue("paymentMethodId", "")
    setValue("hasMadePayment", false)
    setAppointmentHasNoClient(false)
    setShowProductSelection(false)
    setShowServiceSelection(false)
    setShowPaymentSelection(false)
    setShowClientSelection(false)
    setEditServicePrice(null)
    setServiceNewPrice(null)
    setSplitPayments([])
  }

  const removeService = (index?: number) => {
    const selectedServicesWatched = watch('services')
    if (!Array.isArray(selectedServicesWatched) || typeof index !== 'number')
      return
    if (selectedServicesWatched?.length && selectedServicesWatched?.[index]) {
      selectedServicesWatched.splice(index, 1)
    }
    setValue('services', selectedServicesWatched)

    if (selectedServices?.length && selectedServices?.[index]) {
      selectedServices.splice(index, 1)
    }
    setSelectedServices(selectedServices)
    previewPrice()
  }

  const removeProduct = (index?: number) => {
    const selectedProductsWatched = watch('products')
    if (!Array.isArray(selectedProductsWatched) || typeof index !== 'number')
      return
    if (selectedProductsWatched?.length && selectedProductsWatched?.[index]) {
      selectedProductsWatched.splice(index, 1)
    }
    setValue('products', selectedProductsWatched)

    if (selectedProducts?.length && selectedProducts?.[index]) {
      selectedProducts.splice(index, 1)
    }
    setSelectedProducts(selectedProducts)
    previewPrice()
  }

  const getServiceSummary = () => {
    return getServiceSummaryUtils(
      selectedServices,
      services,
      selectedProducts,
      products,
      removeService,
      removeProduct
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeTime = (value: any, dateString: string) => {
    setValue('saleTime', dateString)
  }

  const modifyCart = (
    id: string,
    type: 'add' | 'remove',
    inventory: 'product' | 'service'
  ) => {
    // modify cart for services
    if (inventory === 'service') {
      let remove = false
      const serviceSelectedFromOptions = selectedServices.map((service) => {
        if (service?.id === id) {
          const quantity = service?.quantity || 1
          if (type === 'add') {
            return {
              ...service,
              quantity: quantity + 1
            }
          }
          if (type === 'remove') {
            if (quantity > 1) {
              return {
                ...service,
                quantity: quantity - 1
              }
            } else {
              remove = true
            }
          }
        }
        return service
      })
      if (remove) {
        // get serive index from watch("services") via id
        const serviceIndex = watch('services')?.findIndex(
          (serviceSelected: MultiSelectOption) => serviceSelected?.value === id
        )
        removeService(serviceIndex)
        return
      }
      setSelectedServices(serviceSelectedFromOptions)
    }

    // modify cart for products
    if (inventory === 'product') {
      let remove = false
      const productSelectedFromOptions = selectedProducts.map((product) => {
        if (product?.id === id) {
          const quantity = product?.quantity || 1
          if (type === 'add') {
            const newQuantity = quantity + 1
            if (newQuantity > product?.stockCount) {
              // flag error stockCount
              props?.addToast &&
                props.addToast({
                  variant: 'error',
                  message: `You only have ${product?.stockCount} in stock`,
                })
              return {
                ...product,
                quantity: newQuantity
              }
            } else {
              return {
                ...product,
                quantity: newQuantity
              }
            }
          }
          if (type === 'remove') {
            if (quantity > 1) {
              return {
                ...product,
                quantity: quantity - 1
              }
            } else {
              remove = true
            }
          }
        }
        return product
      })

      if (remove) {
        // get serive index from watch("services") via id
        const productIndex = watch('products')?.findIndex(
          (productSelected: MultiSelectOption) => productSelected?.value === id
        )
        removeProduct(productIndex)
        return
      }
      setSelectedProducts(productSelectedFromOptions)
    }
  }

  const applyDiscount = async () => {
    await previewPrice()
    setIsDiscountApplied(true)
  }

  const getServiceSummaryUtils = (
    selectedServices: SelectedService[],
    services: Service[],
    selectedProducts: SelectedProduct[],
    products: Product[],
    servicesCanBeRemoved?: (index?: number) => void,
    productsCanBeRemoved?: (index?: number) => void
  ) => {
    if (
      (Array.isArray(selectedProducts) && selectedProducts.length > 0) ||
      (Array.isArray(selectedServices) && selectedServices.length > 0)
    ) {
      return (
        <div className='flex flex-col p-3 space-y-2 border border-grey-20 rounded-md'>
          {appointmentSummary ? (
            <div className='w-full flex flex-col space-y-2 py-3'>
              <div className='flex justify-between items-center'>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                  Subtotal
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                  {formatInToPrice(
                    ((appointmentSummary?.totalServicesAmount as number) +
                      appointmentSummary?.totalProductsAmount) as number
                  )}
                </Paragraph>
              </div>

              {isDiscountApplied && (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Discount
                  </Paragraph>
                  <div className='flex items-center space-x-4'>
                    <Paragraph size='b4' color={COLORS.GREY[300]}>-{formatInToPrice(appointmentSummary.discountAmount || 0)}</Paragraph>
                    {!sale || (sale && !sale?.amountClientPaid || sale?.saleDiscount === 0) ?
                      <>
                        <span
                          className='mr-3'
                          style={{ marginRight: '10px', cursor: 'pointer' }}
                          onClick={() => {
                            setIsDiscountApplied(false)
                            setEnableDiscount(true)
                          }}
                        >
                          <SvgEdit width='15px' height='15px' />
                        </span>
                        <span
                          className='text-red-600 cursor-pointer'
                          onClick={() => {
                            setIsDiscountApplied(false)
                            setEnableDiscount(false)
                            setValue('discountValue', 0)
                            setDiscountType('')
                          }}
                        >
                          <SvgTrash width='15px' height='15px' />
                        </span>
                      </> : null}
                  </div>
                </div>
              )}

              {salon?.isTaxVisible ?
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Tax
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    {DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.taxAmount} />
                  </Paragraph>
                </div> : null}

              {appointmentSummary?.appointmentPromo ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Promo code
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    -{DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.taxAmount} />
                  </Paragraph>
                </div>
              ) : null}

              {!sale ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                    Total
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                    {DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.totalPaid} />
                  </Paragraph>
                </div>
              ) : sale && sale?.amountClientPaid && sale?.amountClientPaid < appointmentSummary?.totalPaid ? (
                <>
                  <div className='flex justify-between items-center'>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                      Amount paid
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                      {DEFAULT_CURRENCY}
                      <FormatNumber value={sale?.amountClientPaid} />
                    </Paragraph>
                  </div>

                  <div className='flex justify-between items-center border-t border-grey-100 py-2'>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                      Balance
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                      {DEFAULT_CURRENCY}
                      <FormatNumber value={appointmentSummary?.totalPaid - sale?.amountClientPaid} />
                    </Paragraph>
                  </div>
                </>
              ) : (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                    Total
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                    {DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.totalPaid} />
                  </Paragraph>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )
    }
    return null
  }

  useEffect(() => {
    if (watch('hasMadePayment')) {
      if (!sale) {
        setValue('amountClientPaid', appointmentSummary?.totalPaid || 0)
      } else {
        if (appointmentSummary && sale?.amountClientPaid) {
          setValue('amountClientPaid', appointmentSummary?.totalPaid - sale?.amountClientPaid)
        } else {
          setValue('amountClientPaid', (sale?.totalPaid || 0) - (sale?.amountClientPaid || 0))
        }
      }
    } else {
      if (!sale) {
        setValue('amountClientPaid', 0)
        setValue('paymentMethod', '')
      }
    }
  }, [watch('hasMadePayment')])

  useEffect(() => {
    if (appointmentHasNoClient) {
      setValue('clientEmail', '')
      setValue('clientPhone', '')
      setValue('clientName', '')
      setValue('clientId', []);
      previewPrice()
    }
  }, [appointmentHasNoClient])


  const searchItem = (search: string) => {
    // if showProducts is true
    if (showProductSelection) {
      if (search === '') {
        setFilteredProducts(sortProducts(products));
        return;
      }
      const filteredProducts = products.filter((product) => {
        return product.name.toLowerCase().includes(search.toLowerCase());
      });
      setFilteredProducts(sortProducts(filteredProducts));
    } else {
      if (search === '') {
        setFilteredServices(sortServices(services));
        return;
      }
      const filteredServices = services?.filter((service) => {
        return service.name.toLowerCase().includes(search.toLowerCase());
      });
      setFilteredServices(sortServices(filteredServices));
    }
  }

  const addService = (id: string) => {
    // check service in services
    const service = services?.find((service) => service.id === id);
    // check if service exist in selected services
    const isServiceExist = selectedServices.find((service) => service.id === id);
    if (isServiceExist && service) {
      // removeService
      const newSelectedServices = selectedServices.filter(
        (service) => service.id !== id
      );
      setSelectedServices(newSelectedServices);
      return
    }
    if (service && !isServiceExist) {
      // addService
      const newService = { ...service, quantity: 1, servicePrice: service?.price } as unknown as SelectedService;
      setSelectedServices([...selectedServices, newService]);
      return;
    }
  }

  const addProduct = (id: string) => {
    // check product in products
    const product = products.find((product) => product.id === id);
    // check if product exist in selected products
    const isProductExist = selectedProducts.find((product) => product.id === id);
    if (isProductExist && product) {
      // removeProduct
      const newSelectedProducts = selectedProducts.filter(
        (product) => product.id !== id
      );
      setSelectedProducts(newSelectedProducts);
      return
    }
    if (product && !isProductExist) {
      // addProduct
      const newProduct = { ...product, cartQuantity: 1, };
      setSelectedProducts([...selectedProducts, newProduct]);
      return;
    }
  }

  const updateServicePrice = (type: string) => {
    if (type === 'save' && serviceNewPrice && editServicePrice) {
      // check service
      const service = selectedServices.find((service) => service.id === editServicePrice);
      if (service) {
        const newSelectedServices = selectedServices.map((service) => {
          if (service.id === editServicePrice) {
            const price = serviceNewPrice?.replaceAll(",", '')
            return {
              ...service,
              servicePrice: +price,
            };
          }
          return service;
        });
        setSelectedServices(newSelectedServices);
      }
      setEditServicePrice(null);
      setServiceNewPrice(null);
    } else {
      setEditServicePrice(null);
      setServiceNewPrice(null);
    }
  }

  const removeSplitPayment = (index: number) => {
    const newSplitPaymentMethods = splitPayments.filter((paymentMethod, i) => i !== index);
    setSplitPayments(newSplitPaymentMethods);
  }

  const onChangeSplitPaymentMethod = (index: number) => (payment: MultiSelectOption) => {
    const newSplitPaymentMethods = splitPayments.map((paymentMethod, i) => {
      if (i === index) {
        return {
          ...paymentMethod,
          paymentMethod: payment?.value,
        };
      }
      return paymentMethod;
    });
    setSplitPayments(newSplitPaymentMethods);
  }

  const onChangeSplitPaymentMethodId = (index: number) => (payment: MultiSelectOption) => {
    const newSplitPaymentMethods = splitPayments.map((paymentMethod, i) => {
      if (i === index) {
        return {
          ...paymentMethod,
          paymentMethodId: payment?.value,
        };
      }
      return paymentMethod;
    });
    setSplitPayments(newSplitPaymentMethods);
  }

  const addMoreSplitPaymentMethod = () => {
    const newSplitPaymentMethods = [
      ...splitPayments,
      {
        paymentMethod: null,
        paymentMethodId: null,
        amount: 0,
      },
    ];
    setSplitPayments(newSplitPaymentMethods);
  }

  const onChangeSplitPaymentAmount = (index: number, value: number | string) => {
    const newSplitPaymentMethods = splitPayments.map((paymentMethod, i) => {
      if (i === index) {
        return {
          ...paymentMethod,
          amount: value,
        };
      }
      return paymentMethod;
    });
    setSplitPayments(newSplitPaymentMethods);
  }

  const getPaymentMethodSelectedValue = (value?: string) => {
    if (!value) return [];

    const paymentMethod = APPOINTMENT_PAYMENT_METHODS.find(
      (paymentMethod) => paymentMethod.value === value
    );
    return [paymentMethod];
  }

  const getPaymentMethodIdSelectedValue = (value?: string, type?: string) => {
    if (!value) return [];

    if (type === 'pos' && Array?.isArray(posTerminalsOptions)) {
      const paymentMethod = posTerminalsOptions.find(
        (paymentMethod) => paymentMethod?.value === value
      );
      return [paymentMethod];
    }

    if (type === 'bank' && Array?.isArray(transfersOptions)) {
      const paymentMethod = transfersOptions.find(
        (paymentMethod) => paymentMethod?.value === value
      );
      return [paymentMethod];
    }

    return []
  }

  const getSplitPaymentSum = () => {
    let sum = 0;
    splitPayments.forEach((paymentMethod) => {
      sum += parseFloat(paymentMethod?.amount?.toString().replace(/,/g, ""));
    });
    return sum;
  }

  const showSaveButton = () => {
    if (showPaymentSelection && watch('hasMadePayment')) {
      if (watch('paymentMethod') !== 'split' && watch('paymentMethod') !== 'unpaid' && watch('amountClientPaid') && Number(watch('amountClientPaid')?.toString()?.replaceAll(",", "")) < appointmentSummary?.totalPaid) {
        return (
          <Button
            variant='primary'
            className=''
            disabled={
              createSaleIsLoading || previewAppointmentPriceIsLoading || updateSaleIsLoading
            }
            loading={
              createSaleIsLoading || previewAppointmentPriceIsLoading || updateSaleIsLoading
            }
            size='md'
            rounded='lg'
            type='button'
            onClick={openDialogModal}
          >
            {sale ? 'Save Sale' : 'Add Sale'}
          </Button>
        )
      } else if (watch('paymentMethod') === 'split' && getSplitPaymentSum() < appointmentSummary?.totalPaid) {
        return (
          <Button
            variant='primary'
            className=''
            disabled={
              createSaleIsLoading || previewAppointmentPriceIsLoading || updateSaleIsLoading
            }
            loading={
              createSaleIsLoading || previewAppointmentPriceIsLoading || updateSaleIsLoading
            }
            size='md'
            rounded='lg'
            type='button'
            onClick={openDialogModal}
          >
            {sale ? 'Save Sale' : 'Add Sale'}
          </Button>
        )
      } else {
        return (
          <Button
            variant='primary'
            className=''
            disabled={
              createSaleIsLoading || previewAppointmentPriceIsLoading || updateSaleIsLoading
            }
            loading={
              createSaleIsLoading || previewAppointmentPriceIsLoading || updateSaleIsLoading
            }
            size='md'
            rounded='lg'
            type="submit"
          >
            {sale ? 'Save Sale' : 'Add Sale'}
          </Button>
        )
      }
    }

    return null
  }

  return (
    <>
      <Modal
        show={props.isVisible}
        closeModal={closeAddSaleModal}
        variant='right'
      >
        {props?.saleId && saleIsLoading ?
          <ViewAppointmentShimmer />
          :
          <form
            onSubmit={handleSubmit(addSale)}
            className='w-full relative space-y-6 my-[80px]'
            autoComplete='off'
          >
            <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
              <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
                <Button
                  variant='text'
                  size='none'
                  type='button'
                  className='w-fit'
                  fontSize='b4'
                  onClick={closeAddSaleModal}
                >
                  <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
                </Button>
              </div>
              <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
                <Button
                  variant='icon'
                  size='square'
                  type='button'
                  rounded='md'
                  className='w-fit'
                  fontSize='b4'
                  onClick={closeAddSaleModal}
                >
                  <SvgChevronLeft width='24px' height='24px' />
                </Button>
                <Paragraph size='b3' weight='bold'>
                  {sale ? 'Edit Sale' : 'Add Sale'}
                </Paragraph>
              </div>
            </div>
            {!showServiceSelection && !showProductSelection && !showPaymentSelection && !showClientSelection ?
              <>
                <div className='w-full flex flex-col px-6 py-4 space-y-6'>
                  <Heading variant='h1' size='h9' weight='semiBold' className='flex gap-3 items-center'>
                    {sale ? 'Edit Sale' : 'Add Sale'} {sale ? formatSaleStatusPill(sale?.saleStatus || '') : null}
                  </Heading>
                  <Paragraph size='b4'>{sale ? 'Modify an existing sale' : 'Create a new sale for a client'}</Paragraph>
                </div>
                {sale && sale?.amountClientPaid && appointmentSummary?.totalPaid && sale?.amountClientPaid > 0 ?
                  <div className='w-full px-6'>
                    <div className='w-full flex p-4 rounded-md bg-green-75 space-x-4 items-start'>
                      <SvgCheckMarkGreen width='24px' height='24px' />
                      <div className='w-full flex flex-col'>
                        <Paragraph
                          size='b4'
                          color={COLORS.GREEN[850]}
                          weight='semiBold'
                        >
                          {sale?.client?.firstName +
                            ' ' +
                            sale?.client?.lastName}
                        </Paragraph>
                        {sale?.client?.firstName?.toLowerCase() !== 'walkin' ?
                          <ContactLink
                            variant='tel'
                            value={`${sale?.client?.callingCode}${sale?.client?.phone}`}
                            label={`${sale?.client?.callingCode}${sale?.client?.phone}`}
                          /> : null}
                        <Paragraph size="b4" color={COLORS.GREEN[850]}>
                          Amount paid: {formatInToPrice(sale?.amountClientPaid)}
                        </Paragraph>
                        <Paragraph size="b4" color={COLORS.GREEN[850]}>
                          Balance: {formatInToPrice(appointmentSummary?.totalPaid - sale?.amountClientPaid)}
                        </Paragraph>
                      </div>
                    </div>
                  </div> : null}
                <div className='w-full px-6 pt-2 pb-4 space-y-6'>
                  <div className='w-full flex flex-col xl:flex-row gap-4'>
                    <div className='w-full xl:w-1/2'>
                      <Controller
                        control={control}
                        name='saleDate'
                        render={({ field: { value }, formState: { errors } }) => {
                          // @ts-expect-error services is of type array but the error payload will be an object with "message" property
                          const errorMessage: string = errors?.appointmentDate?.message
                          const dateValue = value || null
                          return (
                            <div className='flex flex-col space-y-2'>
                              <FormLabel htmlFor='appointment-date'>Date</FormLabel>
                              <DatePicker
                                className='splice-time-input'
                                value={
                                  dateValue ? dayjs(dateValue, dateFormat) : sale ? dayjs(convertFullDateStringToDate(sale?.startAt), dateFormat) : undefined
                                }
                                onChange={onChangeDate}
                                format={dateFormat}
                              />
                              {errorMessage && (
                                <FormHelperText variant='error'>
                                  {errorMessage}
                                </FormHelperText>
                              )}
                            </div>
                          )
                        }}
                      />
                    </div>
                    <div className='w-full xl:w-1/2'>
                      <Controller
                        control={control}
                        name='saleTime'
                        render={({
                          // field: { value },
                          formState: { errors }
                        }) => {
                          // @ts-expect-error services is of type array but the error payload will be an object with "message" property
                          const errorMessage: string = errors?.appointmentTime?.message
                          // const timeValue = value || null;
                          return (
                            <div className='flex flex-col space-y-2'>
                              <FormLabel htmlFor='appointment-time'>Time</FormLabel>
                              <TimePicker
                                disabled={true}
                                className='splice-time-input'
                                use12Hours
                                format='h:mm a'
                                value={dayjs(sale ? convertFullDateStringToTime(
                                  sale?.startAt as string
                                ) : pickDefaultTime(), 'HH:mm')}
                                onChange={() => onChangeTime}
                              />
                              {errorMessage && (
                                <FormHelperText variant='error'>
                                  {errorMessage}
                                </FormHelperText>
                              )}
                            </div>
                          )
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col space-y-2">
                    <FormLabel htmlFor="appointment-date">
                      Client<span className="text-red-300">*</span>
                    </FormLabel>
                    {watch('clientName') && !appointmentHasNoClient ?
                      <div className="w-full flex justify-between items-center space-x-2 border border-dashed border-grey-100/80 bg-[#16796F05] py-3 px-4 font-semibold">
                        <div className='flex space-x-4 items-start'>
                          <NameAvatar name={getClientFullnameTextForAvatar(watch('clientName'))}
                            size='sm' green={true} />
                          <div className='flex flex-col space-y-1'>
                            <Paragraph size="b5" color={COLORS.GREY[900]}>
                              {watch('clientName')}
                            </Paragraph>
                            <Paragraph size="b6" color={COLORS.GREY[300]}>
                              {watch('clientEmail')} • {watch('clientPhone')}
                            </Paragraph>
                          </div>
                        </div>
                        <Button
                          variant="text"
                          size="none"
                          rounded="none"
                          type="button"
                          className="text-grey-300 font-semibold"
                          onClick={() => {
                            setShowClientSelection(true);
                          }}
                        >
                          <span>Click to change</span>
                          <SvgRefresh width="20px" height="20px" />
                        </Button>
                      </div>
                      :
                      <Button
                        variant="text"
                        size="lg"
                        rounded="none"
                        type="button"
                        className="border border-dashed border-green-300 text-green-300 font-semibold"
                        onClick={() => {
                          setShowClientSelection(true);
                        }}
                        disabled={appointmentHasNoClient}
                      >
                        <SvgPlus width="24px" height="24px" />
                        <span>Add new or select existing</span>
                      </Button>}
                    <div
                      className="w-full flex space-x-2 cursor-pointer"
                      onClick={() => {
                        setAppointmentHasNoClient(!appointmentHasNoClient);
                      }}
                    >
                      <Checkbox isChecked={appointmentHasNoClient} />
                      <span className="text-b5 text-grey-900">
                        Leave blank for walk-ins
                      </span>
                    </div>
                    {/* <div className='w-fit flex space-x-3'>
                      {client ? (
                        <>
                          <Button
                            variant="light"
                            size="xs"
                            rounded="lg"
                            type="button"
                            className="border border-grey-100 px-2"
                            onClick={openViewClientModal}
                          >
                            View Client
                          </Button>
                        </>
                      ) : null}
                    </div> */}
                  </div>
                  <div className='w-full flex flex-col space-y-3'>
                    <div className='w-full flex justify-between items-center'>
                      <Paragraph
                        size="b5"
                        color={COLORS.GREY[300]}
                      > Add services </Paragraph>

                      {selectedServices?.length ?
                        <Button
                          variant="light"
                          size="xs"
                          rounded="lg"
                          type="button"
                          className="border border-grey-100 text-grey-300 font-semibold"
                          onClick={() => {
                            setShowServiceSelection(true);
                          }}
                        >
                          <SvgPlus width="14px" height="14px" />
                          Add service
                        </Button> : null}
                    </div>
                    {!selectedServices || Array?.isArray(selectedServices) && !selectedServices?.length ?
                      <div className='w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex items-center justify-between'>
                        <Paragraph
                          size="b5"
                          color={COLORS.GREY[300]}
                        > No Services added</Paragraph>
                        <Button
                          variant="light"
                          size="xs"
                          rounded="lg"
                          type="button"
                          className="border border-grey-100 text-grey-300 font-semibold"
                          onClick={() => {
                            setShowServiceSelection(true);
                          }}
                        >
                          <SvgPlus width="14px" height="14px" />
                          Add service
                        </Button>
                      </div> : null}
                    {Array?.isArray(selectedServices) && selectedServices?.length ? selectedServices?.map((service, index) => {
                      const quantity = service?.quantity || 1;
                      return (
                        <div className='w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex items-center justify-between' key={index}>
                          <div className='w-full max-w-[30%]'>
                            <Paragraph
                              size="b5"
                              color={COLORS.GREY[300]}
                              weight="bold"
                            > {service?.name} </Paragraph>
                            <Paragraph size="b6" color={COLORS.GREY[300]}>
                              {getHoursAndMinutesString(service?.duration)}
                            </Paragraph>
                          </div>

                          <div className="w-[30%] flex space-x-2">
                            <Button
                              variant="icon"
                              size="none"
                              type="button"
                              className="border border-grey-125"
                              onClick={() =>
                                modifyCart(service?.id, 'add', 'service')
                              }
                            >
                              <SvgGreyPlus width="22px" height="22px" />
                            </Button>
                            <div className="flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center">
                              <Paragraph
                                size="b6"
                                weight="normal"
                                color={COLORS.BLACK}
                              >
                                {service?.quantity || 1}
                              </Paragraph>
                            </div>
                            <Button
                              variant="icon"
                              size="none"
                              type="button"
                              className="border border-grey-125"
                              onClick={() =>
                                modifyCart(service?.id, 'remove', 'service')
                              }
                            >
                              <SvgGreyMinus width="22px" height="22px" />
                            </Button>
                          </div>
                          <div className='w-full max-w-[30%] flex justify-end space-x-3'>
                            {!editServicePrice || (editServicePrice && editServicePrice !== service?.id) ?
                              <>
                                <Paragraph
                                  size="b4"
                                  color={COLORS.GREY[300]}
                                  className=""
                                >
                                  {DEFAULT_CURRENCY}
                                  <FormatNumber
                                    value={service?.servicePrice * quantity}
                                  />
                                </Paragraph>
                                <span
                                  className="text-b5 cursor-pointer"
                                  onClick={() => {
                                    setEditServicePrice(service?.id);
                                    setServiceNewPrice(formatNumber(service?.servicePrice))
                                  }}
                                  role='button'
                                >
                                  <SvgPencil width="20px" height="20px" />
                                </span>
                                <span
                                  className="text-red-600 text-b5 cursor-pointer"
                                  onClick={() => addService(service?.id)}
                                  role='button'
                                >
                                  <SvgTrash width="14px" height="14px" />
                                </span>
                              </> : null}
                            {editServicePrice && editServicePrice === service?.id ?
                              <div className="w-full flex items-center space-x-2">
                                <input
                                  className="w-full background-none border-b border-grey-400 font-semibold text-b4 text-grey-200 focus:outline-none placeholder:text-grey-100"
                                  type="text"
                                  value={formatNumber(serviceNewPrice)}
                                  onChange={(e) => {
                                    setServiceNewPrice(e.target.value);
                                  }}
                                  placeholder="20,000"
                                />
                                <span
                                  className="text-b5 cursor-pointer"
                                  onClick={() => updateServicePrice('save')}
                                  role='button'
                                >
                                  <SvgCheckMarkGreen width="20px" height="20px" />
                                </span>
                                <span
                                  className="text-b5 cursor-pointer"
                                  onClick={() => updateServicePrice('save')}
                                  role='button'
                                >
                                  <SvgClose width="20px" height="20px" />
                                </span>
                              </div> : null}
                          </div>
                        </div>
                      )
                    }) : null}
                  </div>
                  <div className='w-full flex flex-col space-y-3'>
                    <div className='w-full flex justify-between items-center'>
                      <Paragraph
                        size="b5"
                        color={COLORS.GREY[300]}
                      > Add products </Paragraph>

                      {selectedProducts?.length ?
                        <Button
                          variant="light"
                          size="xs"
                          rounded="lg"
                          type="button"
                          className="border border-grey-100 text-grey-300 font-semibold"
                          onClick={() => {
                            setShowProductSelection(true);
                          }}
                        >
                          <SvgPlus width="14px" height="14px" />
                          Add product
                        </Button> : null}
                    </div>
                    {!selectedProducts || Array?.isArray(selectedProducts) && !selectedProducts?.length ?
                      <div className='w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex items-center justify-between'>
                        <Paragraph
                          size="b5"
                          color={COLORS.GREY[300]}
                        > No Products added</Paragraph>
                        <Button
                          variant="light"
                          size="xs"
                          rounded="lg"
                          type="button"
                          className="border border-grey-100 text-grey-300 font-semibold"
                          onClick={() => {
                            setShowProductSelection(true);
                          }}
                        >
                          <SvgPlus width="14px" height="14px" />
                          Add product
                        </Button>
                      </div> : null}
                    {Array?.isArray(selectedProducts) && selectedProducts?.length ? selectedProducts?.map((product) => {
                      const quantity = product?.quantity || 1;
                      return (
                        <div className='w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex items-center'>
                          <div className='w-full max-w-[30%]'>
                            <Paragraph
                              size="b5"
                              color={COLORS.GREY[300]}
                              weight="bold"
                            > {product?.name} </Paragraph>
                          </div>
                          <div className="w-[30%] flex space-x-2">
                            <Button
                              variant="icon"
                              size="none"
                              type="button"
                              className="border border-grey-125"
                              onClick={() =>
                                modifyCart(product?.id, 'add', 'product')
                              }
                            >
                              <SvgGreyPlus width="22px" height="22px" />
                            </Button>
                            <div className="flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center">
                              <Paragraph
                                size="b6"
                                weight="normal"
                                color={COLORS.BLACK}
                              >
                                {product?.quantity || 1}
                              </Paragraph>
                            </div>
                            <Button
                              variant="icon"
                              size="none"
                              type="button"
                              className="border border-grey-125"
                              onClick={() =>
                                modifyCart(product?.id, 'remove', 'product')
                              }
                            >
                              <SvgGreyMinus width="22px" height="22px" />
                            </Button>
                          </div>
                          <div className='w-full max-w-[40%] flex justify-end space-x-3'>
                            <Paragraph
                              size="b4"
                              color={COLORS.GREY[300]}
                              className=""
                            >
                              {DEFAULT_CURRENCY}
                              <FormatNumber
                                value={product?.retailPrice * quantity}
                              />
                            </Paragraph>
                            <span
                              className="text-red-600 text-b5 cursor-pointer"
                              onClick={() => addProduct(product?.id)}
                              role='button'
                            >
                              <SvgTrash width="14px" height="14px" />
                            </span>
                          </div>
                        </div>
                      )
                    }) : null}
                  </div>
                  {getServiceSummary()}
                  <CollapseRow title='Staff' initialState={sale ? false : true}>
                    <div className='w-full flex flex-col space-y-2'>
                      <Controller
                        control={control}
                        name='staff'
                        render={({
                          field: { onChange, value },
                          formState: { errors }
                        }) => {
                          const errorMessage = errors?.staff?.message
                          return (
                            <>
                              <FormLabel htmlFor='staff'>Add Staff</FormLabel>
                              <MultiSelect
                                selected={value || []}
                                options={staffMembersOptions}
                                setSelected={onChange}
                              />
                              {errorMessage && (
                                <FormHelperText variant='error'>
                                  {errorMessage}
                                </FormHelperText>
                              )}
                            </>
                          )
                        }}
                      />
                    </div>
                  </CollapseRow>
                  <div className='flex space-x-4'>
                    {!enableAddOns ? (
                      <Button
                        variant='light'
                        size='xs'
                        rounded='lg'
                        type='button'
                        className='border border-grey-100'
                        onClick={() => {
                          setEnableAddons(true)
                        }}
                      >
                        Add extra charge
                        <SvgPlus width='14px' height='14px' />
                      </Button>
                    ) : null}
                    {!enablePromo ? (
                      <Button
                        variant='light'
                        size='xs'
                        rounded='lg'
                        type='button'
                        className='border border-grey-100'
                        onClick={() => {
                          setEnablePromo(true)
                        }}
                      >
                        Add promo code
                        <SvgPlus width='14px' height='14px' />
                      </Button>
                    ) : null}
                    {!enableDiscount ? (
                      <Button
                        variant='light'
                        size='xs'
                        rounded='lg'
                        type='button'
                        className='border border-grey-100'
                        onClick={() => {
                          setEnableDiscount(true)
                        }}
                      >
                        Add discount
                        <SvgPlus width='14px' height='14px' />
                      </Button>
                    ) : null}
                  </div>
                  {enableAddOns ? (
                    <div className='w-full flex space-x-4'>
                      <div className='w-1/2'>
                        <Input
                          name='addOnReason'
                          label='Extra charge'
                          id='addons'
                          type='text'
                          placeholder='Extra charge reason'
                          control={control}
                          error={errors.addOnReason}
                        />
                      </div>
                      <Controller
                        control={control}
                        name='addOnAmount'
                        rules={{
                          required: watch('addOnReason')
                            ? ERRORS.ADDON_AMOUNT_REQUIRED
                            : false
                        }}
                        render={({ field }) => (
                          <div className='w-1/2 flex flex-col space-y-2'>
                            <FormLabel htmlFor='adds-on-amount'>
                              Amount
                            </FormLabel>
                            <input
                              className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                              type='text'
                              {...field}
                              value={formatNumber(field.value)}
                              onChange={(e) =>
                                field.onChange(formatNumber(e.target.value))
                              }
                              placeholder='amount'
                            />
                            {errors?.addOnAmount?.message && (
                              <FormHelperText variant='error'>
                                {errors.addOnAmount.message}
                              </FormHelperText>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  ) : null}
                  {enablePromo ? (
                    <div className='w-1/2 pr-2'>
                      <SearchTerm
                        placeholder='Enter Promo code'
                        label='Promo code'
                        showIcon={false}
                        setDebouncedSearchQuery={setDebouncedSearchQuery}
                      />
                    </div>
                  ) : null}
                  {enableDiscount && !isDiscountApplied ? (
                    <div className='flex justify-between items-center'>
                      <div>
                        <Paragraph size='b4' color={COLORS.GREY[300]}>
                          Discount
                        </Paragraph>
                      </div>
                      <div className='flex w-1/2 border rounded-lg'>
                        <div className='pl-2 py-2 rounded-l '>
                          <Button
                            rounded='sm'
                            size='sm'
                            type='button'
                            variant='light'
                            className={
                              discountType === 'percentage'
                                ? 'border-2 text-green-500'
                                : ''
                            }
                            onClick={() => setDiscountType('percentage')}
                          >
                            %
                          </Button>
                        </div>
                        <div className=' py-2'>
                          <Button
                            rounded='sm'
                            size='sm'
                            type='button'
                            variant='light'
                            className={
                              discountType === 'fixed_value'
                                ? 'border-2 text-green-500'
                                : ''
                            }
                            onClick={() => setDiscountType('fixed_value')}
                          >
                            {DEFAULT_CURRENCY}
                          </Button>
                        </div>
                        <div className='py-2 flex-1'>
                          <Input
                            control={control}
                            type='number'
                            id='discount'
                            name='discountValue'
                            className='h-5 py-4 border-0'
                            rules={{
                              pattern: REGEX_PATTERNS.NUMBER
                            }}
                          />
                        </div>
                        <div className='py-2 rounded-r'>
                          <Button
                            rounded='sm'
                            size='sm'
                            type='button'
                            variant='transparent'
                            onClick={() => applyDiscount()}
                            disabled={!discountType}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <Controller
                    control={control}
                    name='note'
                    render={({
                      field: { onChange, onBlur, name, ref, value },
                      formState: { errors }
                    }) => {
                      const maxLength = 400
                      const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                        errors?.note?.message as string
                      )
                      const helperTexts = []

                      helperTexts.push(getHelpTextForCharacterLeft(maxLength, value))
                      if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts)

                      return (
                        <FormTextarea
                          type='text'
                          id='about'
                          label='Note'
                          placeholder='Add a note'
                          {...{
                            ref,
                            name,
                            value,
                            onChange,
                            onBlur,
                            maxLength
                          }}
                          helperText={helperTexts}
                        />
                      )
                    }}
                  />

                  <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-3 py-5'>
                    {sale && (sale?.paymentMethod === 'unpaid' || sale?.amountClientPaid <= appointmentSummary?.totalPaid) ?
                      <>
                        <div className='w-full'>
                          <Controller
                            control={control}
                            name='hasMadePayment'
                            render={({ field: { onChange, value } }) => {
                              return (
                                <div
                                  className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                                  onClick={() => onChange(!value)}
                                >
                                  <div className="flex items-center space-x-2">
                                    <FormLabel htmlFor='hasMadePayment'>
                                      Client has made payment
                                    </FormLabel>
                                  </div>
                                  <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                    <ToggleOnly isChecked={value} />
                                  </div>
                                </div>
                              )
                            }}
                          />
                        </div>
                      </>
                      : null}
                  </div>
                </div>
              </> : null}
            {showServiceSelection ?
              <div className='w-full flex flex-col p-4 gap-y-3'>
                <div className='w-full flex justify-between items-center'>
                  <Paragraph size="b3" color={COLORS.GREY[300]}>
                    Select service
                  </Paragraph>

                  <div className="w-2/5">
                    <SearchTerm
                      placeholder='Search.'
                      showIcon={false}
                      setDebouncedSearchQuery={searchItem}
                    />
                  </div>
                </div>
                <div className='w-full flex flex-col space-y-3'>
                  {filteredServices && Array?.isArray(filteredServices) && filteredServices?.length ? filteredServices?.map((service) => {
                    const isChecked = selectedServices?.some((selectedService) => selectedService?.id === service?.id);
                    return (
                      <div className="w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex justify-between items-start cursor-pointer" onClick={() => addService(service?.id)}>
                        <div className='flex space-x-2 items-start'>
                          <Checkbox isChecked={isChecked} />
                          <div className='flex flex-col space-y-1'>
                            <Paragraph size="b5" color={COLORS.GREY[300]}>
                              {service?.name}
                            </Paragraph>
                            <Paragraph size="b6" color={COLORS.GREY[300]}>
                              {getHoursAndMinutesString(service?.duration)}
                            </Paragraph>
                          </div>
                        </div>

                        <Paragraph size="b5" color={COLORS.GREY[300]} weight='bold'>
                          {formatInToPrice(service?.price)}
                        </Paragraph>
                      </div>
                    )
                  }) : null}
                </div>
              </div> : null}
            {showProductSelection ?
              <div className='w-full flex flex-col p-4 gap-y-3'>
                <div className='w-full flex justify-between items-center'>
                  <Paragraph size="b3" color={COLORS.GREY[300]}>
                    Select product
                  </Paragraph>

                  <div className="w-2/5">
                    <SearchTerm
                      placeholder='Search.'
                      showIcon={false}
                      setDebouncedSearchQuery={searchItem}
                    />
                  </div>
                </div>
                <div className='w-full flex flex-col space-y-3'>
                  {filteredProducts && Array?.isArray(filteredProducts) && filteredProducts?.length ? filteredProducts?.map((product) => {
                    const isChecked = selectedProducts?.some((selectedProduct) => selectedProduct?.id === product?.id);
                    return (
                      <div className="w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex justify-between items-start cursor-pointer" onClick={() => addProduct(product?.id)}>
                        <div className='flex space-x-2 items-start'>
                          <Checkbox isChecked={isChecked} />
                          <div className='flex flex-col space-y-1'>
                            <Paragraph size="b5" color={COLORS.GREY[300]}>
                              {product?.name}
                            </Paragraph>
                            <Paragraph size="b6" color={COLORS.GREY[300]}>
                              {product?.stockCount} item(s) left
                            </Paragraph>
                          </div>
                        </div>

                        <Paragraph size="b5" color={COLORS.GREY[300]} weight='bold'>
                          {formatInToPrice(product?.retailPrice)}
                        </Paragraph>
                      </div>
                    )
                  }) : null}
                </div>
              </div> : null}
            {showPaymentSelection ?
              <>
                <div className="w-full flex flex-col items-start px-6 py-4 space-y-6">
                  <div className="w-full xl:w-1/2 flex flex-col space-y-4">
                    <Heading variant="h1" size="h9" weight="semiBold">
                      {sale ? 'Edit Sale' : 'Create Sale'}
                    </Heading>
                    <Paragraph size="b4">
                      Select payment method and enter amount collected
                    </Paragraph>
                  </div>

                  <div className="w-full flex flex-col gap-6">
                    <div className='w-full border border-green-300 shadow-md flex flex-col space-y-3 py-3 px-4 rounded-md'>
                      <div className='w-full flex items-center justify-between'>
                        <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[300]}>
                          Total
                        </Paragraph>

                        <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[900]}>
                          {formatInToPrice(appointmentSummary?.totalPaid)}
                        </Paragraph>
                      </div>
                      {sale && sale?.amountClientPaid > 0 ?
                        <div className='w-full flex items-center justify-between'>
                          <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[300]}>
                            Outstanding Balance
                          </Paragraph>

                          <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[900]}>
                            {formatInToPrice(appointmentSummary?.totalPaid - sale?.amountClientPaid)}
                          </Paragraph>
                        </div> : null}
                    </div>

                    <div className='w-full flex flex-col space-y-2'>
                      <Paragraph size="b4">
                        Select payment method
                      </Paragraph>
                      <div className='w-full flex flex-wrap gap-4'>
                        {Array.isArray(APPOINTMENT_SPLIT_AND_WALLET_PAYMENT_METHODS) && APPOINTMENT_SPLIT_AND_WALLET_PAYMENT_METHODS.length
                          ? APPOINTMENT_SPLIT_AND_WALLET_PAYMENT_METHODS.map(
                            (paymentMethod) => {
                              const Icon = paymentMethod?.icon;
                              const activePaymentMethod = watch('paymentMethod') === paymentMethod?.value;
                              const isDisabled = false;

                              return (
                                <div
                                  className="flex"
                                  onClick={() => !isDisabled ? setValue('paymentMethod', paymentMethod?.value) : null}
                                  key={paymentMethod?.value}
                                >
                                  <div className={`flex flex-col items-center justify-center w-[165px] h-[95px] ${activePaymentMethod ? 'bg-green-300/10' : 'bg-grey-50/70'} border ${activePaymentMethod ? 'border-green-300' : 'border-grey-100'} rounded-md ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} text-b5 ${activePaymentMethod ? 'text-green-300' : 'text-grey-300'} space-y-2`}>
                                    <Icon height="24px" width="24px" color={activePaymentMethod ? COLORS.GREEN[300] : COLORS.GREY[300]} />
                                    <span className={`flex flex-col items-center justify-center ${activePaymentMethod ? 'text-green-300' : 'text-grey-400'} text-b4 whitespace-nowrap`}>
                                      {paymentMethod?.label}
                                      {paymentMethod?.value === 'client_wallet' && client ? (
                                        <span className={`${activePaymentMethod ? 'text-green-300' : 'text-grey-400'} text-b4 whitespace-nowrap`}>
                                          {formatInToPrice(client?.walletBalance || 0)}
                                        </span>
                                      ) : null}
                                    </span>

                                  </div>
                                </div>
                              );
                            }
                          )
                          : null}
                      </div>
                    </div>

                    {watch('paymentMethod') !== 'unpaid' && watch('paymentMethod') !== 'split' ?
                      <>
                        {watch('paymentMethod') === 'pos' &&
                          posTerminals &&
                          posTerminals.length ? (
                          <SelectInput
                            name="paymentMethodId"
                            id="transfers"
                            label="Choose your preferred POS for payment"
                            rules={{
                              required: ERRORS.POS_REQUIRED,
                            }}
                            control={control}
                            error={errors.paymentMethodId}
                            options={posTerminalsOptions}
                            placeholder="Select Point-of-Sale Terminal"
                          />
                        ) : null}
                        {watch('paymentMethod') === 'bank_transfer' &&
                          bankTransfers &&
                          bankTransfers.length ? (
                          <SelectInput
                            name="paymentMethodId"
                            id="pos"
                            label="Choose your preferred Bank for payment"
                            control={control}
                            rules={{
                              required: ERRORS.TRANSFER_ACCOUNT_REQUIRED,
                            }}
                            error={errors.paymentMethodId}
                            options={transfersOptions}
                            placeholder="Select Bank"
                          />
                        ) : null}
                        <div className='w-full flex flex-col space-y-2'>
                          <Controller
                            control={control}
                            name='amountClientPaid'
                            rules={{ required: watch('hasMadePayment') ? 'Please enter a price' : false }}
                            render={({ field, formState: { errors } }) => (
                              <div className='w-full flex flex-col space-y-2'>
                                <FormLabel htmlFor='price'>How much did the client pay</FormLabel>
                                <input
                                  className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                                  type='text'
                                  {...field}
                                  value={validateDecimalInput(field.value)}
                                  onChange={(e) => field.onChange(formatNumber(e.target.value))}
                                  placeholder='Enter price'
                                  max={appointmentSummary?.totalPaid}
                                />
                                {errors?.amountClientPaid?.message && (
                                  <FormHelperText variant='error'>
                                    {errors.amountClientPaid.message}
                                  </FormHelperText>
                                )}
                              </div>
                            )}
                          />
                          {sale && sale?.amountClientPaid > 0 ?
                            <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[300]} className='pt-2'>
                              Outstanding balance: {formatInToPrice(appointmentSummary?.totalPaid - sale?.amountClientPaid - parseFloat(watch('amountClientPaid')?.toString().replace(/,/g, "")) || 0)}
                            </Paragraph> :
                            <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[300]} className='pt-2'>
                              Outstanding balance: {formatInToPrice(appointmentSummary?.totalPaid - parseFloat(watch('amountClientPaid')?.toString().replace(/,/g, "")) || 0)}
                            </Paragraph>}
                        </div>
                      </>
                      : null}
                    {watch('paymentMethod') && watch('paymentMethod') === 'split' ?
                      <>
                        <div className='w-full flex flex-col space-y-3'>
                          {Array.isArray(splitPayments) && splitPayments?.map((payment, index) => {
                            return (
                              <div className="w-full flex flex-col gap-3" key={index}>
                                <div className='w-full flex items-center gap-3'>
                                  <div className='w-full flex flex-col space-y-2'>
                                    <FormLabel htmlFor='amount'>
                                      Payment method {index + 1}
                                    </FormLabel>
                                    <SingleSelect
                                      selected={getPaymentMethodSelectedValue(payment?.paymentMethod) || []}
                                      options={APPOINTMENT_PAYMENT_METHODS?.filter((paymentMethod) => paymentMethod?.value !== 'unpaid')}
                                      setSelected={onChangeSplitPaymentMethod(index)}
                                      placeholder='Select payment method'
                                    />
                                  </div>

                                  {/* amount input */}
                                  <div className='w-full flex flex-col space-y-2'>
                                    <FormLabel htmlFor='amount'>
                                      Amount to collect
                                    </FormLabel>
                                    <div className='w-full flex space-x-3 px-3 py-2 rounded-md border border-grey-100'>
                                      <Paragraph size='b3' weight='bold'>
                                        {country?.currency?.symbol}
                                      </Paragraph>
                                      <input
                                        className="w-full background-none font-semibold text-b4 text-grey-900 focus:outline-none placeholder:text-grey-300"
                                        type="text"
                                        value={validateDecimalInput(payment?.amount)}
                                        onChange={(e) => {
                                          onChangeSplitPaymentAmount(index, e.target.value);
                                        }}
                                        placeholder="Enter amount"
                                      />
                                    </div>
                                  </div>
                                  <span
                                    className="text-red-600 text-b5 cursor-pointer"
                                    onClick={() => removeSplitPayment(index)}
                                    role='button'
                                  >
                                    <SvgTrash width="14px" height="14px" />
                                  </span>
                                </div>
                                {payment?.paymentMethod === 'pos' && posTerminals?.length ? (
                                  <div className='flex flex-col space-y-2'>
                                    <FormLabel htmlFor='paymentMethodIdPos'>
                                      Choose your preferred POS for payment
                                    </FormLabel>
                                    <SingleSelect
                                      selected={getPaymentMethodIdSelectedValue(payment?.paymentMethodId, 'pos') || []}
                                      options={posTerminalsOptions}
                                      setSelected={onChangeSplitPaymentMethodId(index)}
                                      placeholder='Select Point-of-Sale Terminal'
                                    />
                                  </div>
                                ) : null}
                                {payment?.paymentMethod === 'bank_transfer' && bankTransfers?.length ? (
                                  <div className='flex flex-col space-y-2'>
                                    <FormLabel htmlFor='paymentMethodIdBank'>
                                      Choose your preferred Bank for payment
                                    </FormLabel>
                                    <SingleSelect
                                      selected={getPaymentMethodIdSelectedValue(payment?.paymentMethodId, 'bank') || []}
                                      options={transfersOptions}
                                      setSelected={onChangeSplitPaymentMethodId(index)}
                                      placeholder='Select Bank'
                                    />
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                          {splitPayments && splitPayments?.length < 2 ?
                            <Button
                              variant='light'
                              size='xs'
                              rounded='lg'
                              type="button"
                              className='w-fit border border-grey-100'
                              onClick={addMoreSplitPaymentMethod}
                            >
                              Add payment method
                              <SvgPlus width="14px" height="14px" />
                            </Button> : null}
                        </div>
                      </> : null}
                  </div>
                </div>
              </> : null}
            {showClientSelection ?
              <div className='w-full flex flex-col p-4 space-y-3'>
                <div className='w-full flex flex-col space-y-3'>
                  <Paragraph size="b3" weight='bold' color={COLORS.GREY[900]}>
                    {!newClient ? 'Select Client' : 'Add Client'}
                  </Paragraph>

                  {!newClient ?
                    <div className="w-full">
                      <SearchTerm
                        placeholder='Search'
                        showIcon={false}
                        setDebouncedSearchQuery={getClients}
                      />
                    </div> : null}
                  {!newClient ?
                    <Button
                      variant="text"
                      size="lg"
                      rounded="none"
                      type="button"
                      className="border border-dashed border-grey-300 text-grey-300 font-semibold"
                      onClick={() => {
                        setNewClient(true);
                        setValue('clientId', {
                          label: 'New Client',
                          value: 'new-client'
                        } as unknown as [])
                      }}
                      disabled={appointmentHasNoClient}
                    >
                      <SvgPlus width="24px" height="24px" />
                      <span>Add new client</span>
                    </Button>
                    : <div className="w-full flex flex-col gap-y-4">
                      <Input
                        name="clientName"
                        label="Client name"
                        id="clientName"
                        type="text"
                        placeholder="Enter client name"
                        control={control}
                        rules={{
                          required: newClient && !appointmentHasNoClient ? ERRORS.NAME_REQUIRED : false,
                        }}
                        error={errors.clientName}
                      />
                      <div className="w-full flex gap-x-4">
                        <div className="w-full xl:w-1/2">
                          <FormPhone
                            country={{
                              disabled: false,
                              onSelect: (code, country: string) => {
                                setCallingCode(code);
                                setCountryCode(country);
                              },
                              value: countryCode,
                            }}
                            phone={{
                              name: 'clientPhone',
                              type: 'text',
                              placeholder: '9151930463',
                              rules: {
                                required: !appointmentHasNoClient ? ERRORS.PHONE_REQUIRED : false,
                                pattern: REGEX_PATTERNS.NUMBER,
                                onChange: (e) => {
                                  _sanitizePhoneNumber(e.target.value);
                                },
                                disabled: false,
                              },
                              register,
                              id: 'phone-number',
                              label: 'Phone Number',
                            }}
                            helperText={getHelperTextForReactHookFormErrors(
                              errors?.clientPhone?.message as string
                            )}
                          />
                        </div>
                        <div className="w-full xl:w-1/2">
                          <Input
                            name="clientEmail"
                            label="Client email"
                            id="clientEmail"
                            type="email"
                            placeholder="email@acme.com"
                            control={control}
                            rules={{
                              pattern: REGEX_PATTERNS.EMAIL,
                            }}
                            error={errors.clientEmail}
                          />
                        </div>
                      </div>
                      <div className="w-full flex flex-col lg:flex-row gap-4">
                        <Button
                          variant="primary"
                          className=""
                          size="lg"
                          rounded="lg"
                          type="button"
                          onClick={() => {
                            setShowClientSelection(false)
                          }}
                        >
                          Create and add to sale
                        </Button>
                        <Button
                          variant="secondary"
                          className=""
                          size="lg"
                          rounded="lg"
                          type="button"
                          onClick={() => {
                            setValue('clientEmail', '')
                            setValue('clientName', '')
                            setValue('clientPhone', '')
                            setNewClient(false)
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>}
                </div>
                {!newClient ?
                  <div className='w-full flex flex-col py-4 space-y-3'>
                    {clients && Array?.isArray(clients) && clients?.length ? clients?.map((eachClient) => {
                      const isChecked = client && client?.id === eachClient?.id
                      return (
                        <div className="w-full border border-grey-20/40 rounded-sm py-3 px-4 flex justify-between items-center cursor-pointer" onClick={() => addClient(eachClient?.id)}>
                          <div className='flex space-x-4 items-start'>
                            <NameAvatar name={getClientTextForAvatar(eachClient)}
                              size='sm' green={isChecked} />
                            <div className='flex flex-col space-y-1'>
                              <Paragraph size="b5" color={COLORS.GREY[900]}>
                                {eachClient?.firstName} {eachClient?.lastName}
                              </Paragraph>
                              <Paragraph size="b6" color={COLORS.GREY[300]}>
                                {eachClient?.email} • {eachClient?.callingCode}{eachClient?.phone}
                              </Paragraph>
                            </div>
                          </div>

                          <Checkbox isChecked={isChecked} />
                        </div>
                      )
                    }) : null}
                  </div> : null}
              </div> : null}
            <div className='w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer'>
              <div className='w-full flex justify-between py-4 px-8'>
                <div className='bg-grey-50 py-2 px-4 rounded-md border border-grey-20 hidden xl:flex justify-center items-center'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>
                    TOTAL: {DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.totalPaid} />
                  </Paragraph>
                </div>
                {!showProductSelection && !showServiceSelection && !showClientSelection ?
                  <div className='flex space-x-2'>
                    <Button
                      variant="text"
                      size="sm"
                      type="button"
                      rounded="lg"
                      fontWeight="semiBold"
                      onClick={() => {
                        props?.closeModal('close');
                        reset();
                      }}
                      className="w-fit text-red-500"
                    >
                      Cancel
                    </Button>
                    {!sale ?
                      <Button
                        variant='secondary'
                        className=''
                        disabled={
                          createSaleIsLoading || previewAppointmentPriceIsLoading || updateSaleIsLoading
                        }
                        loading={
                          createSaleIsLoading || previewAppointmentPriceIsLoading || updateSaleIsLoading
                        }
                        size='md'
                        rounded='lg'
                        onClick={() => {
                          setValue('hasMadePayment', false)
                        }}
                      >
                        Save as unpaid
                      </Button> : null}
                    {!showPaymentSelection && !sale ?
                      <Button
                        variant='primary'
                        className=''
                        disabled={
                          createSaleIsLoading || previewAppointmentPriceIsLoading || updateSaleIsLoading
                        }
                        loading={
                          createSaleIsLoading || previewAppointmentPriceIsLoading || updateSaleIsLoading
                        }
                        size='md'
                        rounded='lg'
                        onClick={() => {
                          setValue('hasMadePayment', true)
                        }}
                      >
                        Continue to pay
                      </Button>
                      : null}
                    {!showPaymentSelection && sale ? <Button
                      variant='primary'
                      className=''
                      disabled={
                        createSaleIsLoading || previewAppointmentPriceIsLoading || updateSaleIsLoading
                      }
                      loading={
                        createSaleIsLoading || previewAppointmentPriceIsLoading || updateSaleIsLoading
                      }
                      size='md'
                      rounded='lg'
                    >
                      {watch('hasMadePayment') ? "Continue to pay" : "Save Sale"}
                    </Button> : null}
                    {showSaveButton()}
                  </div> : null}

                {showProductSelection || showServiceSelection ?
                  <div className="flex space-x-2">
                    <Button
                      variant="primary"
                      className=""
                      size="md"
                      rounded="lg"
                      type="button"
                      onClick={() => {
                        setShowProductSelection(false)
                        setShowServiceSelection(false)
                      }}
                    >
                      Add
                    </Button>
                  </div> : null}
              </div>
            </div>
            <CreditWalletBalance isVisible={isCreditWalletDialogModalVisible} closeModal={closeCreditWalletDialogModal} setCreditWallet={setCreditWallet} totalPrice={appointmentSummary?.totalPaid || 0} amountClientPaid={amountToBeChecked} setDeductWallet={setDeductWallet} walletBalance={client?.walletBalance} />
          </form>
        }
      </Modal>
      <ProceedSalePaymentInfo
        isVisible={isDialogModalVisible}
        closeModal={finalizeDialogModalAction}
        amountClientPaid={sale ? watch('amountClientPaid') : null}
      />
    </>
  )
}

export default AddSaleModal
