import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWellness = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={24} height={24} rx={4} fill="#27B925" fillOpacity={0.05} />
    <path
      d="M17.134 9.548A5.693 5.693 0 1 1 15.7 7.68l1.24-1.24a.438.438 0 1 1 .619.618l-5.25 5.25a.438.438 0 1 1-.62-.619l1.516-1.516a2.188 2.188 0 1 0 .978 1.7.437.437 0 1 1 .875-.049 3.063 3.063 0 1 1-1.224-2.277l1.244-1.244a4.806 4.806 0 1 0 1.265 1.622.438.438 0 0 1 .79-.377Z"
      fill="#27B925"
    />
  </svg>
);
export default SvgWellness;
