/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import WelcomeMessage from '../components/uicomponents/WelcomeMessage'
import Layout from 'ui/layout/Layout'
import Button from 'ui/atoms/button/Button'
import { endOfDay, startOfDay } from 'date-fns';
import { useModal } from 'hooks/useModal'
import { useUserCache } from 'hooks/useUserCache'
import { useSalonCache } from 'hooks/useSalonCache'
import { useToast } from 'hooks/useToast'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Appointment, AppointmentService, DeleteAppointmentDocument, Sale, TopSeller, UpdateAppointmentStatusDocument, User, UserDocument } from 'core/generated'
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper'
import { ToastProps } from 'ui/atoms/toast/types'
import { Menu, Transition } from '@headlessui/react'
import { SvgCartBag, SvgCheckMarkGreen, SvgCreditCard, SvgEmailOutline, SvgSalon, SvgSelectDropDown, SvgTime, SvgUploadCloud, SvgUser } from 'ui'
import { COLORS } from 'constants/colors'
import Heading from 'ui/atoms/heading/Heading'
import Paragraph from 'ui/atoms/paragraph/Paragraph'
import Anchor from 'ui/atoms/anchor/Anchor'
import { SvgCharmMenuKebab } from 'ui'
import { calculateOnboardingPercentage, convertChecklistInToAnArray, convertOnboardingChecklistToAnObject, copyTextToClipboard, formatDateToOriginalDate, formatInToPrice, getLabelName, getTimeFromISODate, limitString, progressBarNumber } from '../components/utils/misc'
import Chart from 'react-google-charts'
import HomeEmptyCard from '../components/uicomponents/homeComponents/HomeEmptyCard'
import { createAppointmentSummaryGroup, createTopSellersGroup, topSellersColors } from '../components/uicomponents/homeComponents/utils'
import HomeCardsShimmer from '../components/uicomponents/homeComponents/HomeCardsShimmer'
import { PAGE_ROUTES } from 'constants/routes'
import { convertAppointmentServicesNamesToString, convertServicesNamesToStringForBooking } from '../components/uicomponents/bookingSales/utils'
import AddAppointmentModal from '../components/modals/AddAppointmentModal'
import AddSaleModal from '../components/modals/AddSale'
import SuccessModal from '../components/modals/SuccessModal'
import DeleteAppointmentModal from '../components/uicomponents/appointmentComponents/modals/DeleteAppointmentModal'
import ViewSaleReceiptModal from '../components/modals/ViewSaleReceiptModal'
import ViewAppointmentModal from '../components/modals/ViewAppointmentModal';
import { fetchSales, fetchTodayProjectedSale, fetchTopSellers, fetchUnassignedAppointments } from '../components/modals/utils'
import { API_ERRORS } from 'constants/errors';
import axios from 'axios';
import { SvgArrowRight, SvgCalendar, SvgClose, SvgCopy, SvgInfo, SvgRoundEye, SvgSale, SvgUsers, CalendarPicker, NameAvatar } from 'ui';
import AddClientModal from '../components/modals/AddClientModal';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
import { PERMISSION_CONSTANTS } from 'constants/permission';
import { canPerformAction, canShowPage } from '../components/utils/permission';
import { useNavigate } from 'react-router-dom';
import { useAppointments, useDeleteAppointment, useUpdateAppointmentStatus } from 'api/useAppointments';
import { useProjectedSales, useTopSellers } from 'api/useSales';
import { ImportClientModal } from 'modules/clients/pages/Clients';
import { AddServiceModal, ImportServiceModal } from './Inventory';
import { useSalonHours } from 'api/useSalon';
import { AddEditStaffModal, UploadStaffModal } from './Staff';
import { useOnboardingChecklist } from 'api/useAccount';
import CreateLocation from 'components/modals/CreateLocation';
import SetupBookingLink from 'components/modals/SetupBookingLink';
import PaymentInformation from 'components/modals/PaymentInformation';
import AddBusinessHours from 'components/modals/AddBusinessHours';
import SvgLink from 'assets/icons/Link';
import VerifyEmailNoticeModal from 'components/modals/VerifyEmailNoticeModal';

const Dashboard = () => {
  const navigate = useNavigate();
  const spliceBookingLink = process.env.REACT_APP_SPLICE_BOOKING_LINK
  const { getBusinessData, skipSetUp, checkBusinessSetUp, getUserData } = useUserCache();
  const { getSalonData } = useSalonCache();
  const user: User = getUserData();
  const salon = getSalonData();
  const salonId = salon?.id;
  const branchName = salon?.branchName;
  const { addToast, toast } = useToast();
  const [appointmentId, setAppointmentId] = useState<string | null>(null);
  const [salonBranchName, setSalonBranchName] = useState(branchName);
  const [selectedSale, setSelectedSale] = useState<Sale | null>(null)
  const business = getBusinessData();
  const [businessIdentifier, setBusinessIdentifier] = useState<string | null>(business?.bookingUrlIdentifier || null)
  const checkIfSetupIsSkipped = checkBusinessSetUp();
  const [unassignedAppointmentsData, setUnassignedAppointmentsData] = useState<Appointment[]>([])
  const [unassignedAppointmentIsLoading, setUnassignedAppointmentIsLoading] = useState(false)
  const {
    isVisible,
    openModal,
    closeModal,
  } = useModal();
  const [actions, setActions] = useState({
    addAppointment: false,
    addSale: false,
    addClient: false,
    viewAppointment: false,
    setupProfile: false,
  })

  const serviceActions = {
    addService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service?.add}`),
    editService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service?.edit}`),
    deleteService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service.delete}`),
    viewService: canPerformAction(`Catalogue::Service::${PERMISSION_CONSTANTS.service.view}`),
  }

  const {
    loading: updateAppointmentStatusIsLoading,
    updateAppointmentStatus
  } = useUpdateAppointmentStatus()

  const {
    loading: deleteAppointmentIsLoading,
    deleteAppointment
  } = useDeleteAppointment()

  const startDateTime = formatDateToOriginalDate(startOfDay(new Date()), "start");
  const endDateTime = formatDateToOriginalDate(endOfDay(new Date()), "end");
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>([
    startDateTime ? new Date(startDateTime) : new Date(), // Fallback to current date if null
    endDateTime ? new Date(endDateTime) : new Date()      // Fallback to current date if null
  ]);
  const [salesData, setSalesData] = useState<Sale[]>([]);
  const [topSellersData, setTopSellersData] = useState<TopSeller[]>([])
  const [isSalesLoading, setIsSalesLoading] = useState(false);
  const showNewBookingInitialLoadingShimmer = unassignedAppointmentIsLoading && !unassignedAppointmentsData;

  const startDate = selectedDates ? formatDateToOriginalDate(selectedDates[0], "start") : null;
  const endDate = selectedDates ? formatDateToOriginalDate(selectedDates[1], "end") : null;

  const {
    data: hoursData,
    loading: hoursLoading,
    refetch: refetchSalonHours,
  } = useSalonHours(salonId)
  const salonHours = useMemo(() => hoursData?.salonHours, [hoursData])

  const {
    data: onboardingCheckListData,
    refetch: refetchOnboardingCheckListData,
  } = useOnboardingChecklist()
  const onboardingChecklist = useMemo(() => onboardingCheckListData?.onboardingChecklist, [onboardingCheckListData])
  const checklist = convertOnboardingChecklistToAnObject(onboardingChecklist)
  useEffect(() => {
    refetchOnboardingCheckListData()
  }, [refetchOnboardingCheckListData, salonId])

  const {
    data: projectedSales,
    refetch: refetchProjectedSales
  } = useProjectedSales({
    salonId,
    startDate,
    endDate
  });
  const totalProjectedSales = useMemo(() => {
    if (projectedSales?.salonProjectedSales) {
      return projectedSales.salonProjectedSales;
    }
    return 0;
  }, [projectedSales]);

  const {
    data: topSellers,
    refetch: refetchTopSellers
  } = useTopSellers({
    salonId,
    startDate,
    endDate
  })
  const sellersChartData = useMemo(() => {
    if (topSellers?.topSellers) {
      return createTopSellersGroup(topSellers.topSellers);
    }
  }, [topSellers]);
  const {
    loading: todayAppointmentIsLoading,
    data: appointmentsData,
    refetch: refetchAppointments
  } = useAppointments({
    salonId,
    startDate,
    endDate
  });
  const todayAppointmentServices = useMemo(() => {
    if (appointmentsData?.appointments) {
      return appointmentsData.appointments;
    }
    return [];
  }, [appointmentsData])

  const getUnassignedAppointmentAsync = async () => {
    setUnassignedAppointmentIsLoading(true)
    const getAppointmentServicesData = await fetchUnassignedAppointments({ salonId, status: "pending" });
    setUnassignedAppointmentIsLoading(false)
    setUnassignedAppointmentsData(getAppointmentServicesData)
  }

  const showSalesInitialLoadingShimmer = isSalesLoading && !salesData;
  const showInitialLoadingShimmer = todayAppointmentIsLoading;
  useEffect(() => {
    if (business) {
      setBusinessIdentifier(business?.bookingUrlIdentifier || ''); // Set businessIdentifier if it exists
      if (business?.isAccountSetupComplete === false) {
        openModal();
      }
    }
  }, [checkIfSetupIsSkipped, business]);

  const goToAccountSetup = () => {
    closeModal();
    navigate(PAGE_ROUTES.ACCOUNT_SETUP)
  }

  useEffect(() => {
    if (!canPerformAction('Home::ViewPage')) {
      navigate(PAGE_ROUTES.CALENDAR)
    }
  }, [])

  const initiateDeleteAppointment = () => {
    closeViewAppointmentModal();
    openDeleteAppointmentModal();
  }

  const finalizeAppointmentDeletion = (action: string) => {
    if (action === 'delete') {
      deleteAppointmentAsync(appointmentId)
      closeDeleteAppointmentModal();
    } else {
      closeDeleteAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const initiateCancelAppointment = () => {
    closeViewAppointmentModal();
    openCancelAppointmentModal();
  }

  const {
    isVisible: isAddAppointmentModalVisible,
    openModal: openAddAppointmentModal,
    closeModal: closeAddAppointmentModal,
  } = useModal();

  const {
    isVisible: isAddClientModalVisible,
    openModal: openAddClientModal,
    closeModal: closeAddClientModal,
  } = useModal();

  const {
    isVisible: isBookingLinkSetupModalVisible,
    openModal: openBookingLinkSetupModal,
    closeModal: closeBookingLinkSetupModal,
  } = useModal();

  const {
    isVisible: isPaymentInformationModalVisible,
    openModal: openPaymentInformationModal,
    closeModal: closePaymentInformationModal,
  } = useModal();

  const {
    isVisible: isServiceModalVisible,
    openModal: openAddServiceModal,
    closeModal: closeAddServiceModal
  } = useModal()

  const {
    isVisible: isImportServiceModalVisible,
    openModal: openImportServiceModal,
    closeModal: closeImportServiceModal
  } = useModal()

  const {
    openModal: openImportClientModal,
    closeModal: closeImportClientModal,
    isVisible: isImportClientModalVisible
  } = useModal()

  const {
    isVisible: isUploadStaffModalVisible,
    openModal: openUploadStaffModal,
    closeModal: closeUploadStaffModal,
  } = useModal();

  const {
    isVisible: isViewAppointmentModalVisible,
    openModal: openViewAppointmentModal,
    closeModal: closeViewAppointmentModal,
  } = useModal();

  const {
    isVisible: isAddSaleModalVisible,
    openModal: openAddSaleModal,
    closeModal: closeAddSaleModal,
  } = useModal();

  const {
    isVisible: isAddOrImportClientModalVisible,
    openModal: openAddOrImportClientModal,
    closeModal: closeAddOrImportClientModal,
  } = useModal();

  const {
    isVisible: isAddOrImportServiceModalVisible,
    openModal: openAddOrImportServiceModal,
    closeModal: closeAddOrImportServiceModal,
  } = useModal();

  const {
    isVisible: isAddOrImportStaffModalVisible,
    openModal: openAddOrImportStaffModal,
    closeModal: closeAddOrImportStaffModal,
  } = useModal();

  const {
    isVisible: isAddSalonModalVisible,
    openModal: openAddSalonModal,
    closeModal: closeAddSalonModal
  } = useModal()

  const {
    isVisible: isVerifyEmailModalVisible,
    openModal: openVerifyEmailModal,
    closeModal: closeVerifyEmailModal
  } = useModal()

  const {
    isVisible: isAddSalonHoursModalVisible,
    openModal: openAddSalonHoursModal,
    closeModal: closeAddSalonHoursModal
  } = useModal()

  const {
    isVisible: isAddStaffModalVisible,
    openModal: openAddStaffModal,
    closeModal: closeAddStaffModal,
  } = useModal();

  const {
    isVisible: successModalIsVisible,
    openModal: openSuccessModal,
    closeModal: closeSuccessModal,
  } = useModal();

  const {
    isVisible: isDeleteAppointmentModalVisible,
    openModal: openDeleteAppointmentModal,
    closeModal: closeDeleteAppointmentModal,
  } = useModal();

  const {
    isVisible: isCancelAppointmentModalVisible,
    openModal: openCancelAppointmentModal,
    closeModal: closeCancelAppointmentModal,
  } = useModal();

  const {
    isVisible: isSaleReceiptModalVisible,
    openModal: openSaleReceiptModal,
    closeModal: closeSaleReceiptModal,
  } = useModal();

  const initiateSaleReceipt = (sale: Sale) => {
    setSelectedSale(sale);
    openSaleReceiptModal();
  }

  const initiateCloseAddAppointmentModal = (action: string) => {
    closeAddAppointmentModal();
    if (action === 'addedAppointment') {
      openSuccessModal();
    }
  }

  const initiateCloseAddSaleModal = (action: string, sale?: Sale | null) => {
    closeAddSaleModal();
    refetchHome();
    if (action === 'addedSale' && sale) {
      initiateSaleReceipt(sale);
    }
  }

  const closeSuccessModalAndReloadCalendar = () => {
    closeSuccessModal();
    refetchHome();
  }

  useEffect(() => {
    if (salonId) {
      refetchHome()
    }
  }, [salonId])

  useEffect(() => {
    setActions({
      addAppointment: canPerformAction(`Home::${PERMISSION_CONSTANTS.appointment.add}`),
      addSale: canPerformAction(`Home::${PERMISSION_CONSTANTS.sale.add}`),
      addClient: canPerformAction(`Home::${PERMISSION_CONSTANTS.client.add}`) || canPerformAction(`Client::${PERMISSION_CONSTANTS.client.add}`),
      viewAppointment: canPerformAction(PERMISSION_CONSTANTS.calendar.viewAppointment),
      setupProfile: canPerformAction(`Home::${PERMISSION_CONSTANTS.account.setup}`) && (user?.id === user?.business?.addedById || user?.salonStaff?.role === 'owner')
    })
  }, [])

  const staffActions = {
    create: canPerformAction(PERMISSION_CONSTANTS.staff?.add) || canPerformAction(PERMISSION_CONSTANTS.staff?.edit),
    update: canPerformAction(PERMISSION_CONSTANTS.staff?.edit),
    delete: canPerformAction(PERMISSION_CONSTANTS.staff?.delete),
  };

  const initiateViewAppointment = (appointmentId: string) => {
    setAppointmentId(appointmentId);
    openViewAppointmentModal();
  }

  const initiateCloseViewAppointmentModal = (action: string) => {
    setAppointmentId(null);
    closeViewAppointmentModal();
    if (action === 'updatedAppointment') {
      refetchHome();
    }
  }

  const getTodayAppointmentList = () => {
    if (showInitialLoadingShimmer) {
      return <HomeCardsShimmer />
    }

    if (todayAppointmentServices && Array.isArray(todayAppointmentServices) && todayAppointmentServices?.filter(serviceAppointment => serviceAppointment?.appointmentStatus === 'pending' || serviceAppointment?.appointmentStatus === 'confirmed').length > 0) {
      return (todayAppointmentServices?.filter(serviceAppointment => serviceAppointment?.appointmentStatus === 'pending' || serviceAppointment?.appointmentStatus === 'confirmed')?.slice(0, 3) || []).map((appointment) => (
        <div className='w-full flex items-start justify-between gap-3 cursor-pointer' onClick={() => actions?.viewAppointment ? initiateViewAppointment(appointment?.id) : null} key={appointment?.id}>
          <div className='flex gap-3'>
            {appointment?.client && appointment?.client?.firstName ? <NameAvatar
              name={
                appointment?.client?.firstName?.charAt(0)?.toLocaleUpperCase() +
                appointment?.client?.lastName?.charAt(0)?.toLocaleUpperCase()
              }
            /> : null}
            <div className='flex flex-col'>
              <Paragraph size='b5'>{`${appointment?.client?.firstName} ${appointment?.client?.lastName}`}</Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[300]}>{getTimeFromISODate(appointment?.startAt)} · {limitString(appointment?.appointmentServices?.map(({ name }) => name).join(', '), 25)}</Paragraph>
            </div>
          </div>
          <SvgRoundEye width="24px" height="24px" />
        </div>
      ));
    }
    return <HomeEmptyCard variant='appointment' title="No appointments. Visit your calendar to add some appointments for today" />;
  }

  const getUnassignedBookingList = () => {
    if (showNewBookingInitialLoadingShimmer) {
      return <HomeCardsShimmer />
    }

    if (unassignedAppointmentsData && Array.isArray(unassignedAppointmentsData) && unassignedAppointmentsData?.length > 0) {
      return (unassignedAppointmentsData?.slice(0, 3) || []).map((booking, index) => (
        <div className='w-full flex items-start space-x-4' key={index}>
          <Paragraph size='b5' weight='bold' color={COLORS.GREY[300]}>{`${index + 1}`}</Paragraph>
          <div className='w-full flex flex-col'>
            <Paragraph size='b5'>{`${booking?.client?.firstName} ${booking?.client?.lastName}`}</Paragraph>
            <Paragraph size='b5' color={COLORS.GREY[300]}>{booking?.appointmentServices ? limitString(convertAppointmentServicesNamesToString(booking?.appointmentServices), 35) : 'No service'}</Paragraph>
          </div>
        </div>
      ));
    }

    return <HomeEmptyCard variant="appointment" title="No bookings to assign. Share your booking link with customers" />
  }

  const goToCalendarPage = () => {
    navigate(PAGE_ROUTES.CALENDAR)
  }

  const goToSalesPage = () => {
    navigate(PAGE_ROUTES.SALES)
  }

  const goToBookingSiteSettings = () => {
    navigate(`${PAGE_ROUTES.SETTINGS}?tab=booking`)
  }

  const refetchHome = () => {
    refetchAppointments()
    refetchProjectedSales()
    refetchTopSellers()
  }

  const data = createAppointmentSummaryGroup(todayAppointmentServices || []);

  const copyBookingLink = () => {
    copyTextToClipboard(`${spliceBookingLink}${businessIdentifier}`);
    addToast({
      variant: "success",
      message: 'Booking link copied to clipboard',
    });
  };

  const percentageValue = calculateOnboardingPercentage(checklist)

  const groupCompleted = convertChecklistInToAnArray(checklist)

  const checkInOrOutAppointmentAsync = async ({
    appointmentId, status, paymentMethod,
  }: {
    appointmentId: string,
    status: string,
    paymentMethod?: string,
  }) => {
    try {
      const payload = {
        appointmentStatus: status,
        id: appointmentId,
        salonId,
      }

      updateAppointmentStatus({
        variables: { input: { ...payload } }
      }).then(({ data }) => {
        if (data?.updateAppointmentStatus?.status === 200) {
          refetchHome();
          closeViewAppointmentModal();
          addToast({
            message: "Appointment updated successfully",
            variant: 'success',
          })
        }

        if (data?.updateAppointmentStatus?.errors?.length) {
          const message = data?.updateAppointmentStatus?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          addToast && addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (checkoutAppointmentError) {
      if (axios.isAxiosError(checkoutAppointmentError)) {
        const message = checkoutAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  };

  const deleteAppointmentAsync = async (id: string | null) => {
    if (!id) return;
    try {
      deleteAppointment({
        variables: {
          input: {
            salonId,
            id
          }
        },
      }).then(({ data }) => {
        const clientDetailsData = data?.deleteAppointment;
        if (clientDetailsData) {
          refetchHome();
          closeViewAppointmentModal();
          addToast({
            message: "Appointment removed successfully",
            variant: 'success',
          })
        } else {
          const message = API_ERRORS.APPOINTMENT_DELETION_FAILED;
          addToast({
            message,
            variant: 'error',
          })
        }
      })
    } catch (deleteAppointmentError) {
      if (axios.isAxiosError(deleteAppointmentError)) {
        const message = deleteAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_DELETION_FAILED;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  }
  return (
    <>
      <Layout
        pageTitle="Home"
        setSalonBranchName={setSalonBranchName}
      >
        <ToastWrapper toast={toast as ToastProps} />
        <div className='w-full flex flex-col-reverse xl:flex-row gap-4'>
          <div className='w-full'>
            <div className="w-full flex justify-between items-center py-4 px-6 border-b border-grey-50">
              <Heading variant={'h1'} size='h11' weight='bold'>Home</Heading>
              <div className='flex space-x-4 items-center relative'>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-green-300/10 px-4 py-2 text-b5 font-semibold text-green-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                      <span>Booking link</span>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50 w-[292px]">
                      <div className='flex-col w-full space-y-2 bg-white shadow-md max-w-[292px] p-3'>
                        <div className='flex w-full space-x-3'>
                          <div className='w-[24px] h-[24px] bg-grey-50 rounded-full flex justify-center items-center'>
                            <SvgCopy width="16px" height="16px" />
                          </div>
                          <div className='flex flex-col justify-start items-start'>
                            <Paragraph size='b6' color={COLORS.GREY[300]} className="text-center">Shareable booking site link</Paragraph>
                            <Paragraph size='b5' color={COLORS.GREY[900]} weight='semiBold' className="text-center">{business?.name}</Paragraph>
                          </div>
                        </div>
                        <div className='w-full border border-grey-50 rounded-md p-3 overflow-x-scroll'>
                          <Paragraph size='b4' color={COLORS.GREY[400]} className="">{spliceBookingLink + "" + businessIdentifier}</Paragraph>
                        </div>
                        <div className="flex space-x-4">
                          <Button
                            variant='light'
                            size='md'
                            rounded='md'
                            type="button"
                            onClick={copyBookingLink}
                          >
                            Copy
                          </Button>
                          <Anchor
                            to={`${spliceBookingLink}${businessIdentifier}`}
                            target="_blank"
                          >
                            <Button
                              variant='primary'
                              className=''
                              size='md'
                              rounded='md'
                              type="button"
                            >
                              Share
                            </Button>
                          </Anchor>
                        </div>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

                {actions?.addAppointment || actions?.addSale ?
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-green-300 px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                        <span>Add</span>
                        <SvgSelectDropDown width="10px" height="10px" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                        <div className="flex flex-col p-4 space-y-4">
                          {actions?.addAppointment ?
                            <Menu.Item>
                              <span className="text-b4 cursor-pointer" onClick={openAddAppointmentModal}>New Appointment</span>
                            </Menu.Item>
                            : null}
                          {actions?.addSale ?
                            <Menu.Item>
                              <span className="text-b4 cursor-pointer" onClick={openAddSaleModal}>New Sale</span>
                            </Menu.Item>
                            : null}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  : null}
              </div>
            </div>
            <div className='w-full flex py-2 px-6 justify-end'>
              <CalendarPicker {...{ selectedDates, setSelectedDates }} />
            </div>
            <div className='w-full flex flex-col space-y-6 p-6'>
              <div className='w-full flex flex-col md:flex-row gap-4'>
                <div className='w-full flex border border-grey-100 p-4 space-x-4 rounded-md'>
                  <div className='w-[48px] h-[48px] bg-grey-50 rounded-full flex justify-center items-center'>
                    <SvgCalendar width="24px" height="24px" />
                  </div>
                  <div className='flex flex-col'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>Total appointments</Paragraph>
                    <Paragraph size='h8'>{todayAppointmentServices?.length}</Paragraph>
                  </div>
                </div>

                <div className='w-full flex border border-grey-100 p-4 space-x-4 rounded-md'>
                  <div className='w-[48px] h-[48px] bg-grey-50 rounded-full flex justify-center items-center'>
                    <SvgSale width="24px" height="24px" />
                  </div>
                  <div className='flex flex-col'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>Projected sales</Paragraph>
                    <Paragraph size='h8'>{formatInToPrice(totalProjectedSales || 0)}</Paragraph>
                  </div>
                </div>
              </div>
              <div className='w-full flex flex-col gap-4'>
                {canShowPage('Reports::') ?
                  <div className="w-full flex flex-col xl:flex-row gap-4">
                    <div className='flex flex-col w-full xl:w-7/12 border border-grey-100 p-4 rounded-md space-y-3 overflow-y-scroll h-[280px]'>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>TOTAL APPOINTMENTS</Paragraph>
                      {todayAppointmentServices && todayAppointmentServices.length ? (
                        <Doughnut data={data} options={
                          {
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                position: 'right',
                              },
                              title: {
                                display: false,
                                text: 'Total Appointments'
                              }
                            }
                          }
                        } />
                      ) : (
                        <HomeEmptyCard variant='appointment' title="No appointments. Visit your calendar to add some appointments for today" />
                      )}
                    </div>
                    <div className='flex flex-col w-full xl:w-5/12 border border-grey-100 p-4 rounded-md space-y-3 h-[280px]'>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>TOP SELLERS</Paragraph>
                      {topSellers && topSellers?.topSellers?.length ? (
                        <>
                          <div className='flex flex-wrap gap-3'>
                            {topSellers?.topSellers?.map((seller, index) => (
                              <div key={index} className='flex space-x-2 items-center'>
                                <span style={{
                                  backgroundColor: topSellersColors[index], // Use backgroundColor instead of bgColor
                                }} className={`w-[12px] h-[12px] rounded-sm`}></span>
                                <Paragraph size='b5' color={COLORS.GREY[300]}>{seller.name}</Paragraph>
                              </div>
                            ))}
                          </div>
                          <div className='w-full h-[150px]'>
                            <Doughnut data={sellersChartData} options={
                              {
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                  legend: {
                                    display: false,
                                    position: 'top',
                                  },
                                  title: {
                                    display: false,
                                    text: 'Top Sellers'
                                  }
                                }
                              }
                            }
                              height="100%"  // Make sure height and width are managed by the container
                              width="100%" />
                          </div>
                        </>
                      ) : (
                        <HomeEmptyCard variant='appointment' title="Top seller data is empty. Visit your calendar to add some appointments for today" />
                      )}
                    </div>
                  </div> : null}
                <div className='flex flex-col w-full border border-grey-100 p-4 rounded-md space-y-6 h-[280px]'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>UPCOMING APPOINTMENTS</Paragraph>
                  {getTodayAppointmentList()}
                </div>
              </div>
            </div>
            {actions?.addAppointment || actions?.addClient || actions?.addSale ?
              <div className='w-full flex xl:hidden flex-col border border-grey-100 p-4 gap-4 rounded-md'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>QUICK ACTIONS</Paragraph>
                <div className='w-full flex flex-col lg:flex-row gap-6'>
                  {actions?.addAppointment ?
                    <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 bg-grey-10 rounded-md cursor-pointer' onClick={openAddAppointmentModal}>
                      <div className='flex space-x-3 items-center'>
                        <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                          <SvgCalendar width="16px" height="16px" />
                        </div>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Add new appointment</Paragraph>
                      </div>
                      <SvgArrowRight width="16px" height="16px" />
                    </div>
                    : null}
                  {actions?.addSale ?
                    <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 bg-grey-10 rounded-md cursor-pointer' onClick={openAddSaleModal}>
                      <div className='flex space-x-3 items-center'>
                        <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                          <SvgSale width="16px" height="16px" />
                        </div>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Add new sale</Paragraph>
                      </div>
                      <SvgArrowRight width="16px" height="16px" />
                    </div>
                    : null}
                  {actions?.addSale ?
                    <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 bg-grey-10 rounded-md cursor-pointer' onClick={openAddClientModal}>
                      <div className='flex space-x-3 items-center'>
                        <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                          <SvgUsers width="16px" height="16px" />
                        </div>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Add new client</Paragraph>
                      </div>
                      <SvgArrowRight width="16px" height="16px" />
                    </div>
                    : null}
                </div>
              </div>
              : null}
          </div>
          <div className='w-full xl:min-h-screen xl:max-w-[400px] space-y-4 xl:bg-[#F3F6F6] xl:p-4'>
            {percentageValue !== 100 ?
              <div className='w-full flex flex-col bg-[#FFF] xl:border-t-4 border-grey-100 gap-4 shadow-md rounded-sm'>
                <div className='w-full flex flex-col space-y-2 p-3 border-b-2 border-grey-100 text-orange-300'>
                  <SvgInfo width='25px' height='25px' />
                  <div className='flex flex-col space-y-2'>
                    <Heading
                      variant='h2'
                      size='h11'
                      weight='semiBold'
                      color={COLORS.ORANGE[300]}
                    >
                      You haven’t completed setup yet
                    </Heading>
                    <Paragraph size='b6' color={COLORS.GREY[300]}>
                      Finish setup to unlock the full experience. You can cancel and continue later in settings.
                    </Paragraph>
                  </div>

                  <div className='w-full flex-col pt-3 space-y-2'>
                    <div className='w-full h-[5px] flex gap-3 bg-grey-100 rounded-full'>
                      {percentageValue > 0 ? <div className={`h-[5px] rounded-full bg-[#DC6803]`} style={{ width: `${percentageValue}%`, maxWidth: "100%" }}></div> : null}
                    </div>
                    <div className='flex justify-between'>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>{percentageValue}% complete</Paragraph>
                      <Paragraph size='b4' color={COLORS.GREY[300]}>{groupCompleted}</Paragraph>
                    </div>
                  </div>
                </div>
                {actions?.setupProfile ?
                  <div className='w-full flex flex-col gap-6 p-4'>
                    {!checklist?.salon && canPerformAction(PERMISSION_CONSTANTS?.settings?.editBusinessInfo) ?
                      <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 cursor-pointer' onClick={openAddSalonModal}>
                        <div className='flex space-x-3 items-center'>
                          <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                            <SvgSalon width="16px" height="16px" />
                          </div>
                          <Paragraph size='b5' color={COLORS.GREY[300]}>{getLabelName(onboardingChecklist?.checklistItems, 'salon')}</Paragraph>
                        </div>
                        <SvgArrowRight width="16px" height="16px" />
                      </div>
                      : null}
                    {!checklist?.email && canPerformAction(PERMISSION_CONSTANTS?.settings?.editBusinessInfo) ?
                      <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 cursor-pointer' onClick={openVerifyEmailModal}>
                        <div className='flex space-x-3 items-center'>
                          <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                            <SvgEmailOutline width="16px" height="16px" />
                          </div>
                          <Paragraph size='b5' color={COLORS.GREY[300]}>{getLabelName(onboardingChecklist?.checklistItems, 'email_verified')}</Paragraph>
                        </div>
                        <SvgArrowRight width="16px" height="16px" />
                      </div>
                      : null}
                    {checklist?.salon ?
                      <>
                        {!checklist?.hours && canPerformAction(PERMISSION_CONSTANTS?.settings?.businessHours) ?
                          <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 cursor-pointer' onClick={openAddSalonHoursModal}>
                            <div className='flex space-x-3 items-center'>
                              <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                                <SvgTime width="16px" height="16px" />
                              </div>
                              <Paragraph size='b5' color={COLORS.GREY[300]}>{getLabelName(onboardingChecklist?.checklistItems, 'hours')}</Paragraph>
                            </div>
                            <SvgArrowRight width="16px" height="16px" />
                          </div>
                          : null}
                        {/* {actions?.addClient && !checklist?.client ?
                          <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 cursor-pointer' onClick={openAddOrImportClientModal}>
                            <div className='flex space-x-3 items-center'>
                              <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                                <SvgUsers width="16px" height="16px" />
                              </div>
                              <Paragraph size='b5' color={COLORS.GREY[300]}>{getLabelName(onboardingChecklist?.checklistItems, 'client')}</Paragraph>
                            </div>
                            <SvgArrowRight width="16px" height="16px" />
                          </div>
                          : null} */}
                        {serviceActions?.addService && !checklist?.service ?
                          <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 cursor-pointer' onClick={openAddOrImportServiceModal}>
                            <div className='flex space-x-3 items-center'>
                              <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                                <SvgCartBag width="16px" height="16px" />
                              </div>
                              <Paragraph size='b5' color={COLORS.GREY[300]}>{getLabelName(onboardingChecklist?.checklistItems, 'service')}</Paragraph>
                            </div>
                            <SvgArrowRight width="16px" height="16px" />
                          </div>
                          : null}
                        {staffActions?.create && salonHours?.length && !checklist?.staff ?
                          <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 cursor-pointer' onClick={openAddOrImportStaffModal}>
                            <div className='flex space-x-3 items-center'>
                              <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                                <SvgUser width="16px" height="16px" />
                              </div>
                              <Paragraph size='b5' color={COLORS.GREY[300]}>{getLabelName(onboardingChecklist?.checklistItems, 'staff')}</Paragraph>
                            </div>
                            <SvgArrowRight width="16px" height="16px" />
                          </div>
                          : null}
                        {canPerformAction(PERMISSION_CONSTANTS?.settings?.publicUrl) && (!checklist?.bookingSiteLink) ?
                          <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 cursor-pointer' onClick={openBookingLinkSetupModal}>
                            <div className='flex space-x-3 items-center'>
                              <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                                <SvgSalon width="16px" height="16px" />
                              </div>
                              <Paragraph size='b5' color={COLORS.GREY[300]}>{getLabelName(onboardingChecklist?.checklistItems, 'booking_site_link')}</Paragraph>
                            </div>
                            <SvgArrowRight width="16px" height="16px" />
                          </div>
                          : null}
                      </> : null}
                  </div> : null}
              </div> : null}

            {actions?.addAppointment || actions?.addClient || actions?.addSale ?
              <div className='hidden w-full xl:flex flex-col bg-white border-t-4 border-grey-100 gap-4 shadow-md rounded-sm'>
                <div className='w-full flex flex-col space-y-2 p-3 border-b-2 border-grey-100'>
                  <Paragraph size='b5' color={COLORS.GREY[900]}>Quick Actions</Paragraph>
                  <Paragraph size='b6' color={COLORS.GREY[300]}>Add appointments, sales, or clients quickly with a single tap.</Paragraph>
                </div>
                <div className='w-full flex flex-col gap-6 p-4'>
                  {actions?.addAppointment ?
                    <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 bg-[#FCFDFD] rounded-md cursor-pointer' onClick={openAddAppointmentModal}>
                      <div className='flex space-x-3 items-center'>
                        <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                          <SvgCalendar width="16px" height="16px" />
                        </div>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Add new appointment</Paragraph>
                      </div>
                      <SvgArrowRight width="16px" height="16px" />
                    </div>
                    : null}
                  {actions?.addSale ?
                    <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 bg-[#FCFDFD] rounded-md cursor-pointer' onClick={openAddSaleModal}>
                      <div className='flex space-x-3 items-center'>
                        <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                          <SvgSale width="16px" height="16px" />
                        </div>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Add new sale</Paragraph>
                      </div>
                      <SvgArrowRight width="16px" height="16px" />
                    </div>
                    : null}
                  {actions?.addSale ?
                    <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 bg-[#FCFDFD] rounded-md cursor-pointer' onClick={openAddClientModal}>
                      <div className='flex space-x-3 items-center'>
                        <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                          <SvgUsers width="16px" height="16px" />
                        </div>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Add new client</Paragraph>
                      </div>
                      <SvgArrowRight width="16px" height="16px" />
                    </div>
                    : null}
                  {canPerformAction(PERMISSION_CONSTANTS?.settings?.publicUrl) ?
                    <div className='flex justify-between items-center w-full lg:max-w-[390px] px-3 py-2 bg-[#FCFDFD] rounded-md cursor-pointer' onClick={goToBookingSiteSettings}>
                      <div className='flex space-x-3 items-center'>
                        <div className='w-[28px] h-[28px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                          <SvgLink width="26px" height="26px" />
                        </div>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Manage booking site</Paragraph>
                      </div>
                      <SvgArrowRight width="16px" height="16px" />
                    </div>
                    : null}
                </div>
              </div>
              : null}
          </div>
        </div >
      </Layout>
      <AddAppointmentModal
        isVisible={isAddAppointmentModalVisible}
        closeModal={initiateCloseAddAppointmentModal}
        addToast={addToast}
        salonId={salonId}
      />
      <AddSaleModal
        isVisible={isAddSaleModalVisible}
        closeModal={initiateCloseAddSaleModal}
        addToast={addToast}
        salonId={salonId}
      />
      <AddClientModal
        isVisible={isAddClientModalVisible}
        closeModal={() => {
          closeAddClientModal()
          refetchOnboardingCheckListData()
        }}
        addToast={addToast}
        salonId={salonId}
        businessId=''
      />
      <SuccessModal
        isVisible={successModalIsVisible}
        closeModal={closeSuccessModalAndReloadCalendar}
      />
      <ViewAppointmentModal
        isVisible={isViewAppointmentModalVisible}
        closeModal={initiateCloseViewAppointmentModal}
        cancelAppointment={initiateCancelAppointment}
        deleteAppointment={initiateDeleteAppointment}
        salonId={salonId}
        addToast={addToast}
        appointmentId={appointmentId}
        checkInOrOutAppointment={checkInOrOutAppointmentAsync}
      />
      <DeleteAppointmentModal
        isVisible={isDeleteAppointmentModalVisible}
        closeModal={finalizeAppointmentDeletion}
      />
      <ViewSaleReceiptModal sale={selectedSale} salonId={salonId} closeModal={() => {
        closeSaleReceiptModal(), setSelectedSale(null)
      }} isVisible={isSaleReceiptModalVisible} addToast={addToast} />
      <ImportClientModal
        isVisible={isImportClientModalVisible}
        closeModal={() => {
          closeImportClientModal()
          refetchOnboardingCheckListData()
        }}
        addToast={addToast}
      />
      <AddServiceModal
        isVisible={isServiceModalVisible}
        closeModal={() => {
          closeAddServiceModal()
          refetchOnboardingCheckListData()
        }}
        actions={serviceActions}
        addToast={addToast}
      />
      <CreateLocation
        isVisible={isAddSalonModalVisible}
        closeModal={() => {
          closeAddSalonModal()
          refetchOnboardingCheckListData()
        }}
      />
      <SetupBookingLink
        isVisible={isBookingLinkSetupModalVisible}
        closeModal={() => {
          closeBookingLinkSetupModal()
          refetchOnboardingCheckListData()
        }}
      />
      <AddBusinessHours
        isVisible={isAddSalonHoursModalVisible}
        closeModal={() => {
          closeAddSalonHoursModal()
          refetchOnboardingCheckListData()
        }}
      />
      <PaymentInformation
        isVisible={isPaymentInformationModalVisible}
        checklist={checklist}
        closeModal={() => {
          closePaymentInformationModal()
          refetchOnboardingCheckListData()
        }}
        refetch={refetchOnboardingCheckListData}
      />
      <VerifyEmailNoticeModal
        isVisible={isVerifyEmailModalVisible}
        closeModal={() => {
          closeVerifyEmailModal()
          refetchOnboardingCheckListData()
        }}
        addToast={addToast}
      />
      <ImportServiceModal
        isVisible={isImportServiceModalVisible}
        closeModal={() => {
          closeImportServiceModal()
          refetchOnboardingCheckListData()
        }}
        addToast={addToast}
      />
      <AddEditStaffModal
        staff={null}
        closeModal={closeAddStaffModal}
        isVisible={isAddStaffModalVisible}
        action={'create'}
        salonHours={salonHours}
      />
      <UploadStaffModal
        isVisible={isUploadStaffModalVisible}
        closeModal={closeUploadStaffModal}
      />
      {isAddOrImportClientModalVisible ?
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50">
          <div className="table-row">
            <div className="table-cell align-middle">
              <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:min-w-[500px]">
                <div className="flex flex-col items-center space-y-6">
                  <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>Add/Import Clients</Heading>
                  <div className='w-full flex flex-col space-y-3'>
                    <div onClick={() => {
                      closeAddOrImportClientModal()
                      openAddClientModal()
                    }} className='w-full cursor-pointer border border-grey-100/70 hover:border-green-300 rounded-md flex flex-col py-4 justify-center items-center'>
                      <div className='w-[40px] h-[40px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                        <SvgUsers width="20px" height="20px" />
                      </div>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Add client</Paragraph>
                    </div>

                    <div onClick={() => {
                      closeAddOrImportClientModal()
                      openImportClientModal()
                    }} className='w-full cursor-pointer border border-grey-100/70 hover:border-green-300 rounded-md flex flex-col py-4 justify-center items-center'>
                      <div className='w-[40px] h-[40px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                        <SvgUploadCloud width="20px" height="20px" />
                      </div>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Import clients</Paragraph>
                    </div>
                  </div>
                  <Button
                    variant="primary"
                    size='sm'
                    rounded='md'
                    fontSize='b5'
                    className='mx-0'
                    onClick={closeAddOrImportClientModal}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div> : null}

      {isAddOrImportServiceModalVisible ?
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50">
          <div className="table-row">
            <div className="table-cell align-middle">
              <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:min-w-[500px]">
                <div className="flex flex-col items-center space-y-6">
                  <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>Add/Import Services</Heading>
                  <div className='w-full flex flex-col space-y-3'>
                    <div onClick={() => {
                      closeAddOrImportServiceModal()
                      openAddServiceModal()
                    }} className='w-full cursor-pointer border border-grey-100/70 hover:border-green-300 rounded-md flex flex-col py-4 justify-center items-center'>
                      <div className='w-[40px] h-[40px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                        <SvgCartBag width="20px" height="20px" />
                      </div>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Add service</Paragraph>
                    </div>

                    <div onClick={() => {
                      closeAddOrImportServiceModal()
                      openImportServiceModal()
                    }} className='w-full cursor-pointer border border-grey-100/70 hover:border-green-300 rounded-md flex flex-col py-4 justify-center items-center'>
                      <div className='w-[40px] h-[40px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                        <SvgUploadCloud width="20px" height="20px" />
                      </div>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Import services</Paragraph>
                    </div>
                  </div>
                  <Button
                    variant="primary"
                    size='sm'
                    rounded='md'
                    fontSize='b5'
                    className='mx-0'
                    onClick={closeAddOrImportServiceModal}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div> : null}

      {isAddOrImportStaffModalVisible ?
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50">
          <div className="table-row">
            <div className="table-cell align-middle">
              <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:min-w-[500px]">
                <div className="flex flex-col items-center space-y-6">
                  <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>Add/Import Staff</Heading>
                  <div className='w-full flex flex-col space-y-3'>
                    <div onClick={() => {
                      closeAddOrImportStaffModal()
                      openAddStaffModal()
                    }} className='w-full cursor-pointer border border-grey-100/70 hover:border-green-300 rounded-md flex flex-col py-4 justify-center items-center'>
                      <div className='w-[40px] h-[40px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                        <SvgUser width="20px" height="20px" />
                      </div>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Add staff</Paragraph>
                    </div>

                    <div onClick={() => {
                      closeAddOrImportStaffModal()
                      openUploadStaffModal()
                    }} className='w-full cursor-pointer border border-grey-100/70 hover:border-green-300 rounded-md flex flex-col py-4 justify-center items-center'>
                      <div className='w-[40px] h-[40px] bg-grey-50 text-grey-300 rounded-full flex justify-center items-center'>
                        <SvgUploadCloud width="20px" height="20px" />
                      </div>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Import staff</Paragraph>
                    </div>
                  </div>
                  <Button
                    variant="primary"
                    size='sm'
                    rounded='md'
                    fontSize='b5'
                    className='mx-0'
                    onClick={closeAddOrImportStaffModal}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div> : null}
    </>
  )
}

export default Dashboard
