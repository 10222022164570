import { useWorkflowForm, useWorkflowFormAnswers } from 'api/useForms'
import { getNumberMonthAndYearFromDate } from 'components/utils/misc'
import { COLORS } from 'constants/colors'
import React, { useMemo } from 'react'
import { Button, Heading, Modal, Paragraph, Pill, SvgArrowBack, SvgFormsCompleted, SvgFormsSent, SvgNote } from 'ui'

const ViewClientForm = ({ isVisible, closeModal, selectedForm }) => {
  const {
    data
  } = useWorkflowForm({
    id: selectedForm?.id,
  })
  const form = useMemo(() => data?.data, [data?.data])

  const getAnswerText = (key: string) => {
    const answerObject = selectedForm?.answers;
    if (answerObject && answerObject[key]) {
      return answerObject[key] || '-';
    }

    return '-'
  }
  return selectedForm && (
    <Modal show={isVisible} closeModal={closeModal} variant='right'>
      <div className='w-full relative my-[80px]'>
        <div className='fixed top-0 bg-white w-full max-w-[850px] flex justify-between items-start border-b border-grey-100 px-6 py-5 z-10'>
          <div className='flex space-x-2 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
            </Button>
          </div>
        </div>
        <div className="w-full min-h-[400px] h-full flex flex-col items-start gap-4">
          <div className='w-full flex justify-between items-center gap-4 px-8 py-4 border-b border-grey-100/60'>
            <div className='flex flex-col items-start'>
              <Heading variant="h1" size='h9' weight='bold' color={COLORS.GREY['900']}>{form?.title}</Heading>
              <Paragraph size='b4' color={COLORS.GREY[400]}>Date completed: {getNumberMonthAndYearFromDate(selectedForm?.date_completed)} </Paragraph>
            </div>
            {selectedForm?.status === 'sent' ?
              <Pill variant='primary'>
                <SvgFormsSent width="28px" height="28px" />
                <span>Sent</span>
              </Pill> :
              <Pill variant='success'>
                <SvgFormsCompleted width="28px" height="28px" />
                <span>Completed</span>
              </Pill>}
          </div>

          <div className='w-full flex flex-col py-6 px-10 space-y-6'>
            {Array?.isArray(form?.sections) && form?.sections?.length ? form?.sections?.map((section) => {
              return (
                <div className='w-full bg-white border border-[#E9E9E9BF] flex flex-col space-y-3 rounded-md'>
                  <div className='w-full flex py-3 px-6 items-center bg-[#E2E8E933] justify-between'>
                    <div className='w-full flex flex-col'>
                      <Paragraph size='b4' weight='bold' className=''>
                        {section?.title}
                      </Paragraph>
                      <Paragraph size='b5' weight='bold' color={COLORS.GREY[300]} className=''>
                        {section?.description}
                      </Paragraph>
                    </div>
                  </div>
                  <div className='w-full grid grid-cols-2 gap-6 p-6'>
                    {Array?.isArray(section?.inputs) && section?.inputs?.length ? section?.inputs?.map((input) => {
                      return (
                        <div className='w-full flex flex-col space-y-2'>
                          <Paragraph size='b4' weight='bold' color={COLORS.GREY[400]} className=''>
                            {input?.label} {input?.is_required ? '*' : null}
                          </Paragraph>
                          <Paragraph size='b4' weight='bold' color={COLORS.GREY[900]} className=''>
                            {selectedForm?.answers ? `${getAnswerText(input?.key)}` : '-'}
                          </Paragraph>
                        </div>
                      )
                    }) : null}
                  </div>
                </div>
              )
            }) : null}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ViewClientForm