import React, { useEffect, useMemo, useState } from 'react'
import { RedeemVoucherPageProps } from './types'
import { Button, Heading, Paragraph, SvgCreditCardWhite, SvgDropDown, SvgLocationPin, Table } from 'ui'
import { Business, Client } from 'core/generated';
import { Listbox, Transition } from '@headlessui/react';
import { formatInToPrice, limitString } from 'components/utils/misc';
import { COLORS } from 'constants/colors';
import { ACTVITY_MEMBERSHIP_TABLE_HEADERS, CUSTOMER_MEMBERSHIP_ACTIVITY_TAB, CUSTOMER_MEMBERSHIP_ACTIVITY_TABS, MOBILE_ACTIVITY_MEMBERSHIP_TABLE_HEADERS } from './constants';
import { useModal } from 'hooks';
import { useClientMembership } from 'api/usePublic';
import MembershipDetailsModal from './modals/MembershipDetailsModal';
import WalletDetailsModal from './modals/WalletDetailsModal';

const ClientMembershipDashboard = ({
  customerInfo,
  addToast,
}: RedeemVoucherPageProps) => {
  const [selected, setSelected] = useState<Business | null>();
  const [client, setClient] = useState<Client | null>();
  const [businesses, setBusinesses] = useState<Business[]>([])
  const [activityAndRewardActiveTab, setActivityAndRewardActiveTab] = useState(CUSTOMER_MEMBERSHIP_ACTIVITY_TAB.ACTIVITY)
  const {
    isVisible: isMembershipDetailsModal,
    closeModal: closeMembershipDetailsModal,
    openModal: openMembershipDetailsModal
  } = useModal();
  const {
    isVisible: isWalletDetailsModal,
    closeModal: closeWalletDetailsModal,
    openModal: openWalletDetailsModal
  } = useModal();

  const {
    data: checkPhoneNumberData,
    refetch: searchClient,
  } = useClientMembership({
    salonId: Array?.isArray(selected?.salons) && selected?.salons?.length ? selected?.salons[0]?.id : null,
  });

  useEffect(() => {
    if (customerInfo && Array?.isArray(customerInfo?.businesses) && customerInfo?.businesses?.length) {
      setBusinesses(customerInfo?.businesses)
      setSelected(customerInfo?.businesses[0])
      getClientMembershipDetails()
    }
  }, [customerInfo])

  const onChange = (business: Business) => {
    setSelected(business)
  }

  const getClientMembershipDetails = async () => {
    const result = await searchClient({
      salonId: Array?.isArray(selected?.salons) && selected?.salons?.length ? selected?.salons[0]?.id : null,
      countryCode: customerInfo?.countryCode,
      phone: customerInfo?.phone,
    })

    setClient(result?.data?.clientSearch)
  }

  useEffect(() => {
    if (selected && selected?.id) {
      getClientMembershipDetails()
    }
  }, [selected])
  return (
    <>
      <div className='w-full flex flex-col space-y-10 p-0 md:p-4 xl:p-10'>
        <div className='w-full flex flex-col py-8 px-0 md:px-3 xl:px-6 md:border border-grey-50 rounded-lg space-y-12'>
          <div className='w-full flex flex-col px-4 md:px-0 space-y-6'>
            <div className='w-full flex justify-between border-b border-grey-50 md:border-0 pb-6'>
              <div className='flex flex-col space-y-6'>
                <Heading variant='h1' size='h8' weight='bold'>Hello, {customerInfo?.firstName}</Heading>
              </div>
              <div className='flex gap-4'>
                {Array.isArray(businesses) && businesses.length > 0 ? (
                  <Listbox value={selected} onChange={onChange}>
                    <div className='hidden xl:inline-block relative mb-1'>
                      <Listbox.Button className='relative w-full md:min-w-[200px] cursor-pointer rounded-full bg-white border border-grey-100 py-4 px-3 flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
                        <div className='flex items-center space-x-2'>
                          <span className='text-grey-300'>
                            <SvgLocationPin width="16px" height="16px" />
                          </span>
                          <span className='inline truncate'>
                            {limitString(selected?.name, 15) || 'Select Business'}
                          </span>
                          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                            <SvgDropDown width='24px' height='24px' aria-hidden='true' />
                          </span>
                        </div>
                      </Listbox.Button>
                      <Transition
                        as={React.Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                      >
                        <Listbox.Options
                          className='absolute max-h-60 w-full z-[9] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                          style={{
                            top: 60,
                            bottom: 'auto'
                          }}
                        >
                          {businesses.map((business, businessIdx) => (
                            <Listbox.Option
                              key={businessIdx}
                              className={({ active }) =>
                                `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-grey-900 text-white' : 'text-grey-900'
                                }`
                              }
                              value={business}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                      }`}
                                  >
                                    {business?.name}
                                  </span>
                                  {selected ? (
                                    <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-grey-900'></span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                ) : null}
                {Array.isArray(businesses) && businesses.length > 0 ? (
                  <Listbox value={selected} onChange={onChange}>
                    <div className='relative xl:hidden mb-1'>
                      <Listbox.Button style={{
                        width: '180px'
                      }} className='relative w-full cursor-pointer rounded-full bg-white border border-grey-100 p-3 flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
                        <div className='flex items-center space-x-2'>
                          <span className='text-grey-300'>
                            <SvgLocationPin width="16px" height="16px" />
                          </span>
                          <span className='inline truncate'>
                            {limitString(selected?.name, 15) || 'Select Business'}
                          </span>
                          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                            <SvgDropDown width='24px' height='24px' aria-hidden='true' />
                          </span>
                        </div>
                      </Listbox.Button>
                      <Transition
                        as={React.Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                      >
                        <Listbox.Options
                          className='absolute max-h-60 w-full z-[9] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                          style={{
                            top: 60,
                            bottom: 'auto'
                          }}
                        >
                          {businesses.map((business, businessIdx) => (
                            <Listbox.Option
                              key={businessIdx}
                              className={({ active }) =>
                                `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-grey-900 text-white' : 'text-grey-900'
                                }`
                              }
                              value={business}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                      }`}
                                  >
                                    {business?.name}
                                  </span>
                                  {selected ? (
                                    <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-grey-900'></span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                ) : null}
                {/* <Button
                  variant='primary'
                  className=''
                  size='md'
                  rounded='lg'
                >
                  Change Membership
                </Button> */}
              </div>
            </div>
            <div className='w-full grid grid-cols-1 xl:grid-cols-4 gap-2 md:gap-4 border border-grey-50 md:border-0 rounded-md'>
              <div className='w-full max-w-[400px] h-[132px] flex flex-col justify-between rounded-md py-3 px-4 bg-green-300'>
                <div className='w-full flex justify-between items-start'>
                  <SvgCreditCardWhite width="40px" height="40px" />
                  <Button
                    variant='text'
                    className='text-white underline'
                    size='none'
                    rounded='none'
                    onClick={openWalletDetailsModal}
                  >
                    View details
                  </Button>
                </div>

                <div className='w-full flex flex-col'>
                  <Paragraph size='b4' color={COLORS.GREY[100]} weight='semiBold'>
                    Wallet balance
                  </Paragraph>
                  <Paragraph size='b2' color={COLORS.WHITE} weight='semiBold'>
                    {formatInToPrice(client?.walletBalance, selected?.country?.currency?.symbol || '₦')}
                  </Paragraph>
                </div>
              </div>

              {client?.clientMembership ? <>
                <div className='w-full max-w-[400px] h-[132px] flex flex-col justify-between rounded-md py-3 px-4 bg-green-300'>
                  <div className='w-full flex justify-between items-start'>
                    <SvgCreditCardWhite width="40px" height="40px" />
                    <Button
                      variant='text'
                      className='text-white underline'
                      size='none'
                      rounded='none'
                      onClick={openMembershipDetailsModal}
                    >
                      View details
                    </Button>
                  </div>

                  <div className='w-full flex flex-col'>
                    <Paragraph size='b4' color={COLORS.GREY[100]} weight='semiBold'>
                      Membership wallet balance
                    </Paragraph>
                    <Paragraph size='b2' color={COLORS.WHITE} weight='semiBold'>
                      {formatInToPrice(client?.membershipWalletBalance, selected?.country?.currency?.symbol || '₦')}
                    </Paragraph>
                  </div>
                </div>

                <div className='w-full max-w-[400px] h-[132px] flex flex-col justify-between rounded-md py-3 px-4 border border-grey-100/70'>
                  <div className='w-full flex flex-col'>
                    <Paragraph size='b4' color={COLORS.GREEN[300]} weight='semiBold'>
                      Current membership
                    </Paragraph>
                    <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold'>
                      Name of current membership
                    </Paragraph>
                  </div>

                  <Paragraph size='b1' color={COLORS.GREY[900]} weight='semiBold'>
                    {client?.clientMembership ? client?.clientMembership?.membership?.name : "-"}
                  </Paragraph>
                </div>

                <div className='w-full max-w-[400px] h-[132px] flex flex-col justify-between rounded-md py-3 px-4 border border-grey-100/70'>
                  <div className='w-full flex flex-col'>
                    <Paragraph size='b4' color={COLORS.GREEN[300]} weight='semiBold'>
                      Validity period
                    </Paragraph>
                    <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold'>
                      Membership is valid for
                    </Paragraph>
                  </div>

                  <Paragraph size='b1' color={COLORS.GREY[900]} weight='semiBold'>
                    {client?.clientMembership ? `${client?.clientMembership?.membership?.validityPeriod} Months` : "-"}
                  </Paragraph>
                </div> </> : null}
            </div>
          </div>
        </div>

        <div className='w-full flex flex-col border border-grey-100/60'>
          <div className='w-full flex border-l border-t border-r border-grey-50'>
            {Array?.isArray(CUSTOMER_MEMBERSHIP_ACTIVITY_TABS) && CUSTOMER_MEMBERSHIP_ACTIVITY_TABS?.length ? CUSTOMER_MEMBERSHIP_ACTIVITY_TABS.map((tab, index) => {
              return (
                <Button
                  key={index}
                  variant={activityAndRewardActiveTab === tab.key ? 'light' : 'text'}
                  className='text-grey-300 w-fit focus:outline-none'
                  size='md'
                  onClick={() => setActivityAndRewardActiveTab(tab.key)}
                >
                  {tab.title}
                </Button>
              )
            }) : null}
          </div>
          {activityAndRewardActiveTab === CUSTOMER_MEMBERSHIP_ACTIVITY_TAB.ACTIVITY ?
            <Table headers={ACTVITY_MEMBERSHIP_TABLE_HEADERS} mobileHeaders={MOBILE_ACTIVITY_MEMBERSHIP_TABLE_HEADERS} rows={[]} hasBorder={true} /> : null}
        </div>
      </div>
      <MembershipDetailsModal
        isVisible={isMembershipDetailsModal}
        closeModal={closeMembershipDetailsModal}
        client={client}
        currency={selected?.country?.currency?.symbol || '₦'}
      />
      <WalletDetailsModal
        isVisible={isWalletDetailsModal}
        closeModal={closeWalletDetailsModal}
        client={client}
        currency={selected?.country?.currency?.symbol || '₦'}
      />
    </>
  )
}

export default ClientMembershipDashboard
