import { gql, useMutation, useQuery } from "@apollo/client";
import { LoginApiResponseData, RegisterApiResponseData } from "./types/auth";
import { Country, WaitlistEntry } from "core/generated";

const BUSINESS_LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      status
      token
      user {
        id
        email
        firstName
        lastName
        emailVerified
        phoneVerified
        business {
          priceHidden
          headerImageUrl
          logoUrl
          name
          rcNumber
          phone
          callingCode
          countryCode
          bookingUrlIdentifier
          bvn
          id
          addedById
          cancellationFee
          cancellationFeeType
          noShowFee
          about
          cancellationPolicy
          acceptsOnlineBookings
          allowServiceStaffSelection
          isAppointmentLinkSetup
          isAccountSetupComplete
          addons {
            expiryDate
            subscribed
            price
            addon {
              createdAt
              featureList
              id
              name
              updatedAt
            }
          }
          socials {
            id
            siteName
            username
          }
          payoutAccount {
            accountNumber
            bankName
            accountName
          }
          products {
            id
            name
          }
          services {
            id
            name
          }
          packages {
            id
            name
            isDiscountApplicable
          }
          salons {
            id
            address
            bearsProcessingFee
            branchName
            callingCode
            city
            depositLinkValidityHours
            depositType
            depositValue
            email
            intervalMinutes
            isDepositRequired
            locationType
            phone
            state
            status
            onboardingStatus
            taxInclusivePricing
            totalNumberOfClients
            isTaxVisible
            wallet {
              id
              balance
              availableBalance
              currency
            }
          }
          roles {
            createdAt
            id
            name
            updatedAt
            authorizations {
              createdAt
              description
              id
              name
              updatedAt
              addon {
                createdAt
                name
                updatedAt
              }
            }
          }
          country {
            name
            code
            countryCode
            emojiFlag
            collectionsSupported
            payoutsSupported
            timezone
            utcOffset
            currency {
              code
              symbol
            }
          }
        }
        phone
        callingCode
        salonStaff {
          role
          salonId
          canServeCustomers
          id
          level
          staffRole {
            createdAt
            id
            name
            updatedAt
            authorizations {
              createdAt
              description
              id
              name
              updatedAt
            }
            authorizedPermissions {
              createdAt
              description
              enabled
              id
              name
              updatedAt
            }
          }
        }
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useBusinessLogin = () => {
  const [login, options] = useMutation<{
    login: LoginApiResponseData;
  }>(BUSINESS_LOGIN_MUTATION);

  return {
    login,
    ...options,
  };
};

const REGISTER = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      status
      token
      user {
        id
        firstName
        lastName
        email
        business {
          priceHidden
          id
          headerImageUrl
          logoUrl
          name
          rcNumber
          isAccountSetupComplete
          phone
          countryCode
          callingCode
          country {
            name
            code
            emojiFlag
            collectionsSupported
            payoutsSupported
            timezone
            utcOffset
            currency {
              code
              symbol
            }
          }
        }
      }
      errors {
        message
        property
      }
    }
  }
`

export const useRegister = () => {
  const [register, options] = useMutation<{
    register: RegisterApiResponseData;
  }>(REGISTER);

  return {
    register,
    ...options,
  };
};

const INVITATION_CODE_DETAILS = gql`
query InvitationCodeDetails($invitationCode: String!) {
	invitationCodeDetails(invitationCode: $invitationCode) {
		createdAt
		email
		firstName
		id
		invitationCode
		invitationCodeUsedAt
		invited
		lastName
		phone
		updatedAt
		businessName
		bookingManagement
		category
		country {
			countryCode
			code
		}
	}
}
`;

export const useInvitationCodeDetails = ({
  invitationCode,
}: {
  invitationCode: string;
}) => {
  return useQuery<{
    invitationCodeDetails: WaitlistEntry;
  }>(INVITATION_CODE_DETAILS, {
    variables: {
      invitationCode,
    },
  });
}

const COUNTRIES = gql`
  query Countries {
    countries {
      name
      code
      countryCode
      emojiFlag
      currency {
        code
        name
        symbol
      }
    }
  }
`;

export const useCountries = () => {
  return useQuery<{
    countries: Country[];
  }>(COUNTRIES);
};

const RESET_PASSWORD = gql`
mutation ResetPassword($input: ResetPasswordInput!) {
	resetPassword(input: $input) {
		clientMutationId
		status
		errors {
			message
			property
		}
	}
}
`;

export const useResetPassword = () => {
  const [resetPassword, options] = useMutation<{
    resetPassword: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(RESET_PASSWORD);

  return {
    resetPassword,
    ...options,
  };
};