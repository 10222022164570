import * as React from 'react'
import axios from 'axios'
import { Logo, Paragraph } from 'ui'
import { Heading } from 'ui'
import { useToast } from 'hooks/useToast'
import { ToastProps } from 'ui'
import { useForm } from 'react-hook-form'
import { ToastWrapper } from 'ui'
import { ERRORS } from 'constants/errors'
import { REGEX_PATTERNS } from 'constants/pattern'
import { Input } from 'ui'
import { PasswordInput } from 'ui'
import { Button } from 'ui'
import { User, UserDocument } from 'core/generated'
import { useSalonCache } from 'hooks/useSalonCache'
import { useUserCache } from 'hooks/useUserCache'
import { PAGE_ROUTES } from 'constants/routes'
import { checkIfCanPerformAction } from 'components/utils/permission'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useBusinessLogin } from 'api/useLogin'
import { useManageAuth } from '../hooks/useManageAuth'
import { LoginInput } from '../types'
import { useEffect, useMemo } from 'react'
import { USER } from 'api/useAccount'
import { useLazyQuery } from '@apollo/client'
import { COLORS } from 'constants/colors'

const Login = () => {
  const navigate = useNavigate();
  const { setAppAuthToken, getAppAuthToken, clearCurrentUserData } = useManageAuth()
  const { setBusinessData } = useUserCache();
  const { addToast, toast } = useToast()
  const { setSalonData } = useSalonCache()
  const {
    error,
    loading: isLoading,
    login,
  } = useBusinessLogin()

  const goToHome = () => {
    // navigate(PAGE_ROUTES.HOME, {
    //   replace: true,
    // });
    window.location.href = PAGE_ROUTES.HOME
  };

  const goToOtpPage = () => {
    // navigate(PAGE_ROUTES.OTP, {
    //   replace: true,
    // });
    window.location.href = PAGE_ROUTES.OTP
  };

  const goToAccountSetup = () => {
    // navigate(PAGE_ROUTES.ACCOUNT_SETUP, {
    //   replace: true,
    // });
    window.location.href = PAGE_ROUTES.ACCOUNT_SETUP
  };

  const goToCalendar = () => {
    // navigate(PAGE_ROUTES.CALENDAR, {
    //   replace: true,
    // });
    window.location.href = PAGE_ROUTES.CALENDAR
  }

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tokenFromAdmin = params.get('token');

  const [getUser, { data, called }] = useLazyQuery(USER);
  useMemo(() => {
    const currentUser: User = data?.user;

    if (currentUser) {
      localStorage.setItem('userData', JSON.stringify(currentUser))
      localStorage.setItem('role', JSON.stringify(currentUser?.salonStaff?.staffRole))
      localStorage.setItem('permissions', JSON.stringify(currentUser?.salonStaff?.staffRole?.authorizations))
      localStorage.setItem('country', JSON.stringify(currentUser?.business?.country))
      localStorage.setItem('token', tokenFromAdmin)

      if (!currentUser?.emailVerified) {
        goToOtpPage()
      } else {
        setSalonData(currentUser?.business?.salons[0])
        setBusinessData(currentUser?.business)
        checkIfCanPerformAction("Home::ViewPage", currentUser?.salonStaff?.staffRole?.authorizations) ? goToHome() : goToCalendar()
      }
    }

    return currentUser;
  }, [data?.user, setBusinessData, setSalonData]);

  useEffect(() => {
    if (tokenFromAdmin) {
      clearCurrentUserData()
      localStorage.clear()
      localStorage.setItem('token', tokenFromAdmin)
      getUser()
    }
  }, [tokenFromAdmin])

  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<LoginInput>({
    delayError: 100,
    mode: 'onChange'
  })

  const onSubmit = async (input: LoginInput) => {
    login({
      variables: {
        input: {
          email: input?.email?.toLowerCase(),
          password: input?.password
        }
      }
    }).then(({ data }) => {
      const { login } = data
      if (login.status === 200) {
        localStorage.setItem('userData', JSON.stringify(login?.user))
        localStorage.setItem('country', JSON.stringify(login?.user?.business?.country))
        localStorage.setItem('permissions', JSON.stringify(login?.user?.salonStaff?.staffRole?.authorizedPermissions))
        localStorage.setItem('role', JSON.stringify(login?.user?.salonStaff?.staffRole))
        setAppAuthToken({
          token: login.token,
        })
        
        if (login?.user?.phoneVerified || login?.user?.emailVerified) {
          if (login?.user?.business?.salons?.length) {
            setSalonData(login?.user?.business?.salons[0])
            setBusinessData(login?.user?.business)
            checkIfCanPerformAction("Home::ViewPage", login?.user?.salonStaff?.staffRole?.authorizedPermissions) ? goToHome() : goToCalendar()
          } else {
            goToAccountSetup()
          }
        } else {
          goToOtpPage()
        }
      } else {
        addToast({
          variant: 'error',
          message: login.errors[0].message
        })
      }
    })
      .catch((error) => {
        addToast({
          variant: 'error',
          message: 'An error occured, kindly try again'
        })
      })
  }

  const goToForgotPasswordPage = () => {
    navigate(PAGE_ROUTES.FORGOT_PASSWORD, {
      replace: true,
    });
  }

  const goToRegisterPage = () => {
    navigate(PAGE_ROUTES.REGISTER, {
      replace: true,
    });
  }

  return (
    <>
      <main className='flex items-center justify-center min-h-screen bg-white xl:bg-grey-100'>
        <div className='table-row'>
          <div className='table-cell align-middle'>
            <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-8 px-9 max-w-[480px] xl:w-[580px] xl:max-w-[700px] space-y-6'>
              <div className='flex flex-col items-center space-y-6'>
                <Logo />
                <Heading
                  variant='h1'
                  size='h8'
                  weight='medium'
                  className='w-full text-center'
                >
                  Login to your account
                </Heading>
              </div>
              <ToastWrapper toast={toast as ToastProps} />
              <form
                onSubmit={handleSubmit(onSubmit)}
                className='w-full space-y-6'
                autoComplete='off'
              >
                <Input
                  name='email'
                  label='Email'
                  id='email'
                  type='email'
                  placeholder='email@acme.com'
                  control={control}
                  rules={{
                    required: ERRORS.EMAIL_REQUIRED,
                    pattern: REGEX_PATTERNS.EMAIL
                  }}
                  error={errors.email}
                />
                <PasswordInput
                  name='password'
                  label='Password'
                  id='password'
                  type='password'
                  placeholder='**********'
                  control={control}
                  rules={{
                    required: ERRORS.PASSWORD_REQUIRED,
                    minLength: {
                      message: 'Password must be at least 6 characters',
                      value: 6
                    }
                  }}
                  error={errors.password}
                />

                <Button
                  variant='primary'
                  className='uppercase'
                  disabled={!isValid}
                  loading={isLoading}
                  size='lg'
                  rounded='lg'
                >
                  Log in
                </Button>
              </form>
              <Button
                variant='text'
                className='text-b5 mx-auto'
                fontWeight='bold'
                size='none'
                type='button'
                onClick={goToForgotPasswordPage}
              >
                Forgot password?
              </Button>
              <div className='w-full flex justify-center space-x-2'>
                <Paragraph size='b5' className='' color={COLORS.GREY[400]}>
                  Don't have a Splice Business account?
                </Paragraph>
                <Button
                  variant='text'
                  className='text-b5 mx-0'
                  fontWeight='bold'
                  size='none'
                  type='button'
                  onClick={goToRegisterPage}
                >
                  Register
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Login
