import { useCards, useSubscribeToAddon, useUser } from 'api/useAccount'
import { formatInToPrice } from 'components/utils/misc'
import { AddonStatus, Card, PaystackPaymentAuthorization, User } from 'core/generated'
import { useModal, useSalonCache } from 'hooks'
import React, { useMemo, useState } from 'react'
import { Button, Heading, Modal, Paragraph, SvgMasterCard, SvgVerve, SvgVisa, ToastProps } from 'ui'
import PaymentModal from './PaymentModal'
import { COLORS } from 'constants/colors'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader'

const WhatsappCreditsModal = ({
  isVisible, closeModal, addToast
}: {
  isVisible: boolean
  closeModal: () => void
  addToast: (toast: ToastProps) => void
}) => {
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const [paymentAuthorization, setPaymentAuthorization] = useState<PaystackPaymentAuthorization>(null)
  const [addons, setAddons] = useState<AddonStatus[]>([])
  const [addon, setAddon] = useState<AddonStatus>()
  const [showCards, setShowCards] = useState(false)
  const {
    isVisible: paymentModalIsvisible,
    openModal: openPaymentModal,
    closeModal: closePaymentModal,
  } = useModal();
  const {
    loading,
    data: cardsData
  } = useCards()
  const cards = useMemo(() => cardsData?.cards, [cardsData])

  const {
    loading: userLoading,
    data: userData,
    refetch
  } = useUser()

  useMemo(() => {
    const currentUser: User = userData?.user;

    if (currentUser) {
      // Store user data in localStorage
      localStorage.setItem('userData', JSON.stringify(currentUser));
      localStorage.setItem(
        'role',
        JSON.stringify(currentUser?.salonStaff?.staffRole)
      );
      setAddons(currentUser?.business?.addons);
    }

    return currentUser;
  }, [userData]);

  const {
    loading: isPaymentPageLoading,
    subscribeToAddon
  } = useSubscribeToAddon()

  const subscribeToAddonAsync = async ({ selectedAddon, card }: { selectedAddon?: AddonStatus, card?: Card }) => {
    const currentAddon = selectedAddon || addon
    subscribeToAddon({
      variables: {
        input: {
          addonId: currentAddon?.addon?.id,
          ...(card
            ? {
              cardId: card?.id,
            }
            : {}),
        },
      },
    }).then(({ data }) => {
      const { subscribeToAddon
      } = data;

      if (subscribeToAddon?.status === 200 && subscribeToAddon?.paymentAuthorization) {
        setPaymentAuthorization(subscribeToAddon?.paymentAuthorization)
        openPaymentModal()
      }

      if (subscribeToAddon?.status == 200 && !subscribeToAddon?.paymentAuthorization) {
        addToast({ message: 'Whatsapp Credits Purchased successfully', variant: 'success' })
        _closeModal()
      }

      if (subscribeToAddon?.status === 422) {
        addToast({ message: subscribeToAddon?.errors[0]?.message, variant: 'error' })
      }

      if (subscribeToAddon?.status === 404) {
        addToast({ message: 'Error using card, kindly try again', variant: 'error' })
      }
    })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((err) => {
        // addToast({ message: err.message, variant: 'error' })
      })
  }

  const confirmPayment = () => {
    addToast({ message: 'Whatsapp Credits Purchased successfully', variant: 'success' })
    closePaymentModal();
  }

  const getBranchLogo = (cardType: string) => {
    if (cardType?.toLowerCase() === 'visa') {
      return <SvgVisa width="30px" height="30px" />
    }

    if (cardType?.toLowerCase() === 'verve') {
      return <SvgVerve width="30px" height="30px" />
    }

    return <SvgMasterCard width="30px" height="30px" />
  }

  const selectAddonAndShowCards = (addon: AddonStatus) => {
    setAddon(addon)
    setShowCards(true)
  }

  const _closeModal = () => {
    setShowCards(false)
    setAddon(null)
    closeModal()
  }

  return (
    <>
      {isPaymentPageLoading ? <ContentLoader /> : null}
      <Modal
        variant='topCenter'
        show={isVisible}
        closeModal={_closeModal}
        title='Purchase Whatsapp Credits'
        subTitle='Choose preferred credits'
        showCloseButton={true}
      >
        {showCards ?
          <div className='flex flex-col py-4'>
            <div
              className='p-5 min-h-10 rounded-md mb-6 cursor-pointer max-w-[600px] border-2 border-green-300/10 hover:border-green-300 hover:shadow-sm'
              onClick={() => subscribeToAddonAsync({})}
            >
              <Paragraph color={COLORS.GREY[900]} size='b5'>
                Use new card
              </Paragraph>
            </div>
            {Array?.isArray(cards) && cards?.length ? cards?.map((card) => {
              return (
                <div
                  className='p-5 min-h-10 rounded-md mb-6 cursor-pointer max-w-[600px] border-2 border-green-300/10 hover:border-green-300 hover:shadow-sm'
                  onClick={() => subscribeToAddonAsync({ card })}
                >
                  <div className='flex items-center gap-2'>
                    <span className='rounded-md px-3 bg-green-300 w-fit'>
                      {getBranchLogo(card?.cardType?.trim())}
                    </span>
                    <div className='flex flex-col space-y-1'>
                      <Paragraph size='b4' weight='medium'>Use card  **** **** {card?.last4}</Paragraph>
                    </div>
                  </div>
                </div>
              )
            }) : null}
          </div>
          :
          <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 p-5'>
            {Array?.isArray(addons) && addons?.length ? addons?.map((addon) => {
              // check if addon?.addon?.name includes whatsapp
              if (!addon?.addon?.name?.toLowerCase()?.includes('whatsapp')) return null;
              return (
                <div className='flex flex-col space-y-6 w-full max-w-[450px] p-4 border-grey-100 hover:border-green-300/50 border rounded-md cursor-pointer'
                    onClick={() => cards && cards?.length ? selectAddonAndShowCards(addon) : subscribeToAddonAsync({ selectedAddon: addon })}>
                  <div className='flex flex-col space-y-2'>
                    <Heading variant='h2' size='h11'>
                      {addon?.addon?.name}
                    </Heading>
                  </div>
                  <div className='w-full flex items-start space-x-2'>
                    <Paragraph size='b6' className=''>
                      Price:
                    </Paragraph>

                    <Paragraph size='b6' className='' weight='bold'>
                      {formatInToPrice(addon?.unitPrice)}
                    </Paragraph>
                  </div>
                  <Button
                    variant='primary'
                    size='sm'
                    type='button'
                    rounded='lg'
                    className='w-fit'
                  >
                    Select
                  </Button>
                </div>
              )
            }) : null
            }
          </div>}
      </Modal>
      <PaymentModal
        isVisible={paymentModalIsvisible}
        closeModal={closePaymentModal}
        confirmPayment={confirmPayment}
        paymentAuthorization={paymentAuthorization || null}
        salonId={salonId}
      ></PaymentModal>
    </>
  )
}

export default WhatsappCreditsModal