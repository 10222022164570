import { useWaiveClientOutstandingFees } from 'api/useClient';
import { useCancelledAppointmentList, useClientCancelledAppointmentSummary } from 'api/useReports';
import { formatDateToOriginalDate, formatInToPrice, getDayMonthAndNumberAndTimeFromDate, getDefaultQueryDates, getNumberMonthAndYearFromDate, limitString } from 'components/utils/misc';
import { checkSalonIdFromRoute } from 'components/utils/token';
import { COLORS } from 'constants/colors';
import { CancelledAppointments, Client } from 'core/generated';
import { useSalonCache } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react'
import { Button, CalendarPicker, Heading, Modal, Paginator, Paragraph, Pill, ReportTable, SvgArrowBack, SvgChevronLeft, ToastProps } from 'ui'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader';
import FullTable from 'ui/organism/table/Table'

type ClientProps = {
  isVisible: boolean;
  closeModal: () => void;
  client: Client
  addToast: (toast: ToastProps) => void
}

export const Headers = [
  {
    label: 'Service',
    value: 'service'
  },
  {
    label: 'Price',
    value: 'price'
  },
  {
    label: 'Appointment Date',
    value: 'appointmentDate'
  },
  {
    label: 'Cancelled',
    value: 'cancelled'
  },
  {
    label: 'Outstanding Fee',
    value: 'outstandingFee'
  },
  {
    label: 'Status',
    value: 'status'
  }
]



export const Mobile_Headers = [
  {
    title: 'Service',
    key: 'service'
  },
  {
    title: 'Price',
    key: 'price'
  },
  {
    title: 'Appointment Date',
    key: 'appointmentDate'
  },
  {
    title: 'Outstanding Fee',
    key: 'outstandingFee'
  },
  {
    title: 'Cancelled',
    key: 'cancelled'
  },
  {
    title: 'Status',
    key: 'status'
  },
]

const ViewClientCancelledAppointmentsModal = ({
  isVisible,
  closeModal,
  client,
  addToast
}: ClientProps) => {
  const { waiveClientOutstandingFees, loading } = useWaiveClientOutstandingFees()
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const { getSalonFieldValue } = useSalonCache()
  const _salonId = checkSalonIdFromRoute()
  const salonId = getSalonFieldValue('id') || _salonId

  const {
    data: cancellationSummaryData,
    loading: loadingCancellationSummaryData,
    refetch: refetchCancellationSummaryData
  } = useClientCancelledAppointmentSummary({
    clientId: client?.id,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const cancelledAppointmentSummary = useMemo(() => cancellationSummaryData?.clientCancelledAppointmentSummaryReport, [cancellationSummaryData])

  const nameMap = {
    "name": "Name",
    "appointmentCancelled": "Total appointments cancelled",
    "amount": "Total appointments cancelled value",
    "totalWaived": "Total waived",
    "totalApplied": "Total applied"
  };
  const dataMap = {
    "name": client?.firstName +' '+ client?.lastName,
    "appointmentCancelled": cancelledAppointmentSummary?.totalCancelledAppointments,
    "amount": formatInToPrice(cancelledAppointmentSummary?.lostRevenue),
    "totalWaived": cancelledAppointmentSummary?.totalWaived,
    "totalApplied": cancelledAppointmentSummary?.totalApplied,
  };

  const {
    data: cancelledAppointmentData,
    loading: loadingCancelledAppointmentData,
    refetch: refetchCancelledAppointmentData
  } = useCancelledAppointmentList({
    salonId,
    clientId: client?.id,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
    first: 30, after, before
  })
  const cancelledAppointments = useMemo(() => cancelledAppointmentData?.cancelledAppointmentReport, [cancelledAppointmentData])


  const generateCancelledAppointmentListTableData = (appointments: CancelledAppointments[], countryCode?: string) => {
    return appointments?.map?.((appointment) => ({
      service: limitString(appointment?.service, 15),
      price: formatInToPrice(appointment?.price),
      appointmentDate: getNumberMonthAndYearFromDate(appointment?.appointmentDate),
      cancelled: getDayMonthAndNumberAndTimeFromDate(appointment?.cancelledAt).replace('by', ','),
      fee: formatInToPrice(appointment?.outstandingFee),
      status: appointment?.status && appointment?.status !== '-' ? (
        <Pill rounded='lg' variant={appointment?.status?.toLowerCase() === 'applied' ? 'success' : 'warning'}>{appointment?.status}</Pill>
      ) : '-'
    }))
  };
  const rows = generateCancelledAppointmentListTableData(cancelledAppointments?.nodes || [], "NG")

  const goToNext = () => {
    if (cancelledAppointments?.pageInfo?.hasNextPage) {
      const after = cancelledAppointments?.pageInfo?.endCursor
      setAfter(after);
      setBefore(null);
      refetchCancelledAppointmentData();
    }
  }

  const goToPrev = () => {
    if (cancelledAppointments?.pageInfo?.hasPreviousPage) {
      const before = cancelledAppointments?.pageInfo?.startCursor;
      setBefore(before);
      setAfter(null);
      refetchCancelledAppointmentData();
    }
  }

  useEffect(() => {
    if (isVisible) {
      refetchCancelledAppointmentData()
      refetchCancellationSummaryData()
    }
  }, [selectedDates, isVisible])

  const waiveFee = (bookingId: string) => {
    waiveClientOutstandingFees({
      variables: {
        input: {
          id: client?.id,
          bookingIds: [bookingId]
        },
      }
    }).then(({ data }) => {
      const { waiveClientOutstandingFees } = data
      if (waiveClientOutstandingFees?.status === 200) {
        addToast({
          message: 'Client Outstanding Fee Waived successfully',
          variant: 'success',
        })
        refetchCancelledAppointmentData()
        refetchCancellationSummaryData()
      }

      if (waiveClientOutstandingFees?.errors?.length) {
        addToast({
          message: waiveClientOutstandingFees?.errors[0]?.message,
          variant: 'error',
        })
      }
    })
  }

  const waiveAppointment = (id: number) => {
    if (!cancelledAppointments?.nodes?.length) return;

    const appointment = cancelledAppointments?.nodes[id]
    waiveFee(appointment?.bookingId)
  }
  return (
    <Modal
      show={isVisible}
      closeModal={closeModal}
      variant='right'
    >
      {loadingCancelledAppointmentData ? <ContentLoader /> : null}
      <div className='w-full relative my-[80px]'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>View Details</Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 py-4'>
          <div className='w-full flex flex-col space-y-6 px-6'>
            <div className='w-full flex items-center justify-between'>
              <div className="flex flex-col space-y-6">
                <Heading variant='h1' size='h9' weight='semiBold'>Cancelled appointment details</Heading>
                <Paragraph size="b4">Find information about client’s cancelled appointments </Paragraph>
              </div>
            </div>
            <div className='flex flex-col gap-6 p-3 rounded-md border border-grey-125/45'>
              {Object.entries(dataMap).map(([key, value]) => (
                nameMap[key] && value !== null && (
                  <div key={key} className="px-2 flex justify-between items-start space-x-4 bg-grey-10">
                    <div className="w-[40%] flex flex-col space-y-1 p-2">
                      <Paragraph size="b4" color="grey-300" className="text-left">
                        {nameMap[key]}
                      </Paragraph>
                    </div>
                    <div className="w-[60%]  flex flex-col space-y-1">
                      <Paragraph size="b4" color={COLORS.GREY[900]} weight="semiBold" className="text-right">
                        {value}
                      </Paragraph>
                    </div>
                  </div>
                )
              ))}
            </div>
          </div>

          <div className="pt-10">
            <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
              <Paragraph size='b4' weight='semiBold' className=''>
                Overview
              </Paragraph>
              <div className='flex space-x-2 items-center'>
                <CalendarPicker {...{ selectedDates, setSelectedDates }} />
                <Paginator pageInfo={cancelledAppointments?.pageInfo} {...{ goToNext, goToPrev }} />
              </div>
            </div>
            <FullTable
              headers={Headers}
              rows={rows}
              onClick={waiveAppointment}
              tableOptions={{
                view: false,
                edit: false,
                delete: false,
                duplicate: false,
                waive: true
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ViewClientCancelledAppointmentsModal
