import React, { useEffect, useMemo, useState } from 'react'
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { COLORS } from 'constants/colors';
import { useModal } from 'hooks/useModal';
import Heading from 'ui/atoms/heading/Heading';
import { formatInToPrice, getNumberMonthAndYearFromDate, getTimeFromDate } from '../components/utils/misc';
import { APPOINTMENT_STATUS } from '../components/uicomponents/bookingSales/constants';
import Button from 'ui/atoms/button/Button';
import PaymentModal from '../components/modals/PaymentModal';
import PaymentConfirmed from '../components/uicomponents/PaymentConfirm';
import { SvgDate, SvgLogo } from 'ui';
import { Appointment, Payment, PaystackPaymentAuthorization } from 'core/generated';
import BookingPageShimmer from '../components/uicomponents/bookingSales/BookingPageShimmer';
import FullStory from 'react-fullstory';
import { useParams } from 'react-router-dom';
import { useAppointmentSummary } from 'api/useAppointments';
import { PaystackConsumer } from 'react-paystack';

const CompletePayment = () => {
  const [paymentMadeSuccessfully, setPaymentMadeSuccessfully] = useState(false);
  const params = useParams();
  const bookingId = params.bookingId as string;

  const {
    data,
    loading: isPaymentPageLoading,
    refetch
  } = useAppointmentSummary(bookingId)
  const appointment = useMemo(() => {
    return data?.appointmentSummary?.appointment
  }, [data])
  const paymentAuthorization = useMemo(() => {
    return data?.appointmentSummary?.paymentAuthorization
  }, [data])
  const paymentDetails = useMemo(() => {
    return data?.appointmentSummary
  }, [data])
  const totalAmountPaid = useMemo(() => {
    if (data?.appointmentSummary?.appointment) {
      const payments = data?.appointmentSummary?.appointment?.payments;
      // sum up payments
      let total = 0;
      payments?.forEach((payment: Payment) => {
        total += payment?.amount;
      });
      return total;
    }
    return 0;
  }, [data])

  const {
    isVisible: paymentModalIsvisible,
    openModal: openPaymentModal,
    closeModal: closePaymentModal,
  } = useModal();

  const confirmPayment = () => {
    closePaymentModal();
    setPaymentMadeSuccessfully(true);
  }

  const handleClose = () => {
    console.log('closed')
  }

  const componentProps = {
    reference: `DPT_${appointment?.id}`,
    email: appointment?.client?.email,
    amount: paymentDetails?.amountToPay * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    metadata: {
      purpose: paymentDetails?.purpose,
      appointment_id: appointment?.id,
      business_id: appointment?.salon?.business?.id,
      payment_type: 'deposit_payment',
      processing_fee_amount: paymentDetails?.processingFeeAmount,
      custom_fields: []
    },
    text: 'Pay Deposit',
    onSuccess: () => confirmPayment(),
    onClose: handleClose
  };

  const closePaymentPage = () => {
    refetch()
    setPaymentMadeSuccessfully(false)
  }
  return (
    <>
      <main className='w-full h-screen flex flex-col space-y-12 p-4 xl:p-12'>
        <div className='flex justify-center items-end space-x-2 mt-[20px]'>
          <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Powered by</Paragraph> <SvgLogo width="90px" height="100%" />
        </div>
        {isPaymentPageLoading && (
          <div className="w-full flex flex-col xl:flex-row border border-grey-20 rounded-lg p-4 xl:p-10 max-w-[1000px] mx-auto space-y-6 xl:space-y-0 xl:space-x-[80px]">
            <BookingPageShimmer />
          </div>
        )}

        {appointment && !isPaymentPageLoading ? (
          <div className="w-full flex flex-col xl:flex-row border border-grey-20 rounded-lg p-4 xl:p-10 max-w-[1000px] mx-auto space-y-6 xl:space-y-0 xl:space-x-[80px]">
            <div className="w-full flex flex-col space-y-6 max-w-[360px]">
              <div className="flex flex-col gap-3">
                <Heading variant='h1' size='h11' weight='bold' color={COLORS.GREY['900']}>Make a deposit for your service at {appointment?.salon?.business?.name}</Heading>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Hello {appointment?.client?.firstName}, {appointment?.appointmentStatus === APPOINTMENT_STATUS.deposit_pending ? " please make deposit to guarantee your reservation." : " your reservation is confirmed. You can pay now or at the store. Enjoy your experience!"} </Paragraph>
              </div>
              <div className='flex space-x-4'>
                <SvgDate width="24px" height="24px" />
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{getNumberMonthAndYearFromDate(appointment?.startAt)} ・ {getTimeFromDate(appointment?.startAt)}</Paragraph>
              </div>
              <div className='flex flex-col p-4 space-y-2 bg-grey-25 rounded-md'>
                {appointment?.appointmentStatus === APPOINTMENT_STATUS.deposit_pending || (appointment?.totalAmountPaid === 0 || !appointment?.payments) ? (
                  <>
                    <div className='flex justify-between items-center'>
                      <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold'>Deposit required</Paragraph>
                      <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold'>{formatInToPrice(appointment?.depositAmount, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                    </div>
                    <div className='flex justify-between items-center'>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Total amount</Paragraph>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>{appointment?.totalPrice ? formatInToPrice(appointment?.totalPrice, appointment?.salon?.business?.country?.currency?.symbol) : 0}</Paragraph>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='flex justify-between items-center'>
                      <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold'>Outstanding balance</Paragraph>
                      <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold'>{appointment?.totalPaid && appointment?.totalAmountPaid > 0 ? formatInToPrice(appointment?.totalPaid - appointment?.totalAmountPaid, appointment?.salon?.business?.country?.currency?.symbol) : 0}</Paragraph>
                    </div>
                    <div className='flex justify-between items-center'>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Total amount</Paragraph>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>{appointment?.totalPaid ? formatInToPrice(appointment?.totalPaid, appointment?.salon?.business?.country?.currency?.symbol) : 0}</Paragraph>
                    </div>
                    <div className='flex justify-between items-center'>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Deposit Paid</Paragraph>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>-{formatInToPrice(totalAmountPaid, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                    </div>
                  </>
                )}
              </div>
              {appointment?.appointmentStatus === APPOINTMENT_STATUS.deposit_pending && paymentAuthorization ? (
                <PaystackConsumer {...componentProps} >
                  {({ initializePayment }) => <Button variant='secondary' rounded='none' size='sm' fontSize='b5' fontWeight='semiBold' onClick={() => initializePayment(confirmPayment, handleClose)}>Pay</Button>}
                </PaystackConsumer>
              ) : null}
            </div>

            <div className='w-full max-w-[490px] bg-white py-[28px] px-[32px] border border-grey-20 h-full flex flex-col items-start space-y-5 overflow-y-auto'>
              <Heading variant='h2' size='h10' weight='bold'>Order summary</Heading>
              <div className='w-full flex justify-between'>
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Booking ID: #{appointment?.bookingId}</Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Date: {getNumberMonthAndYearFromDate(appointment?.startAt) || "-"}</Paragraph>
                </div>
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Billed to: {appointment?.client?.firstName} {appointment?.client?.lastName}</Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[400]} className='capitalize'>Status: {appointment?.appointmentStatus}</Paragraph>
                </div>
              </div>
              <div className='w-full flex flex-col space-y-2'>
                <div className='flex justify-between items-center bg-grey-50 px-4 py-2'>
                  <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Item</Heading>
                  <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Amount</Heading>
                </div>

                {appointment?.appointmentServices?.map((service) => (
                  <div className='flex justify-between items-center px-4 py-2' key={service?.serviceId}>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>{service?.name}</Paragraph>
                    <Paragraph size='b5'>{formatInToPrice(service?.price, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                  </div>
                ))}

                {appointment?.appointmentProducts?.map((product) => (
                  <div className='flex justify-between items-center px-4 py-2' key={product?.productId}>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>{product?.name}</Paragraph>
                    <Paragraph size='b5'>{formatInToPrice(product?.price, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                  </div>
                ))}

                <div className='border-t border-grey-50'></div>
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>Subtotal</Paragraph>
                  <Paragraph size='b5'>{formatInToPrice(appointment?.totalServicesAmount as number + appointment?.totalProductsAmount as number, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                </div>
                {appointment?.salon?.isTaxVisible ?
                  <div className='flex justify-between items-center px-4 py-2'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>Tax</Paragraph>
                    <Paragraph size='b5'>{formatInToPrice(appointment?.taxAmount, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                  </div> : null}
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>Discount</Paragraph>
                  <Paragraph size='b5'>-{formatInToPrice(appointment?.appointmentPromo?.amount, appointment?.salon?.business?.country?.currency?.symbol) || 0}</Paragraph>
                </div>
                <div className='border-t border-grey-50'></div>
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b5' weight='semiBold' className='inline' color={COLORS.GREY[300]}>Total</Paragraph>
                  <Paragraph size='b5' weight='semiBold' className='inline'>{appointment?.totalPaid ? formatInToPrice(appointment?.totalPaid, appointment?.salon?.business?.country?.currency?.symbol) : 0}</Paragraph>
                </div>
                {appointment?.appointmentStatus !== APPOINTMENT_STATUS.deposit_pending && (totalAmountPaid > 0 || appointment?.payments?.length) ? (
                  <div className='flex justify-between items-center px-4 py-2'>
                    <Paragraph size='b5' className='inline' color={COLORS.GREY[300]}>Deposit Paid</Paragraph>
                    <Paragraph size='b5' className='inline' color={COLORS.GREY[900]}>{formatInToPrice(totalAmountPaid, appointment?.salon?.business?.country?.currency?.symbol)}</Paragraph>
                  </div>
                ) : null}
                {appointment?.appointmentStatus !== APPOINTMENT_STATUS.deposit_pending && (appointment?.totalAmountPaid > 0 || appointment?.payments?.length) ? (
                  <div className='flex justify-between items-center px-4 py-2'>
                    <Paragraph size='b5' weight='semiBold' className='inline' color={COLORS.GREY[300]}>Outstanding Balance</Paragraph>
                    <Paragraph size='b5' weight='semiBold' className='inline'>{appointment?.totalPrice && totalAmountPaid ? formatInToPrice(appointment?.totalPrice - totalAmountPaid, appointment?.salon?.business?.country?.currency?.symbol) : 0}</Paragraph>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        <PaymentModal
          isVisible={paymentModalIsvisible}
          closeModal={closePaymentModal}
          confirmPayment={confirmPayment}
          paymentAuthorization={paymentAuthorization || null}
          salonId={appointment?.salon?.id}
          propertyId={appointment?.id}
          propertyType='appointment'
        ></PaymentModal>
        {paymentMadeSuccessfully && (
          <PaymentConfirmed appointment={appointment} type="deposit" closePaymentPage={closePaymentPage} />
        )}
      </main>
    </>
  )
}

export default CompletePayment