import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgReceiptTaxIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m12 18.668 8-8"
      stroke="#16796F"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.667 12.001a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333ZM19.333 18.665a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Z"
      fill="#16796F"
      stroke="#16796F"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.667 28V6.667A2.667 2.667 0 0 1 9.333 4h13.334a2.667 2.667 0 0 1 2.666 2.667V28l-4-2.667L18.667 28 16 25.333 13.333 28l-2.666-2.667-4 2.667Z"
      stroke="#16796F"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgReceiptTaxIcon;
