import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal, Paginator, Paragraph, Pill, SvgArrowBack, SvgChevronLeft, SvgFormsCompleted, SvgFormsNotCompleted, SvgFormsSent, SvgFormsToBeCompleted, Table, Tabs } from 'ui';
import { FormProps } from '../types';
import { COLORS } from 'constants/colors';
import { FORM_TABS, FORMS_HISTORY_HEADERS, MOBILE_FORMS_HISTORY_HEADERS } from '../constants';
import { useFormSubmissions, useWorkflowAnalytics } from 'api/useForms';
import { getNumberMonthAndYearFromDate } from 'components/utils/misc';
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader';

const ViewForm = ({ isVisible, closeModal, workflowForm }: FormProps) => {
  const [activeTab, setActiveTab] = useState('all')
  const [page, setPage] = useState<number>(1)
  const [pageInfo, setPageInfo] = useState<{
    endCursor: string,
    hasNextPage: boolean,
    hasPreviousPage: boolean,
    startCursor: string,
  }>()
  const {
    data: workflowAnalyticsData,
  } = useWorkflowAnalytics({
    id: workflowForm?.id,
  });
  const workflowAnalytics = useMemo(() => workflowAnalyticsData?.data, [workflowAnalyticsData])
  const {
    data: formSubmissionsData,
    isLoading: loading,
    refetch,
    isFetching
  } = useFormSubmissions({
    id: workflowForm?.id || null,
    status: activeTab === 'all' ? '' : activeTab || '',
    limit: 30,
    page
  })
  const forms = useMemo(() => {
    const pagination = formSubmissionsData?.data?.pagination;
    if (pagination) {
      setPageInfo({
        endCursor: +pagination?.page > 1 ? (+pagination?.page - 1).toString() : "1",
        hasNextPage: +pagination?.totalPages > 0 && +pagination?.totalPages > +pagination?.page,
        hasPreviousPage: +pagination?.totalPages > 0 && +pagination?.page > +pagination?.totalPages,
        startCursor: +pagination?.page > 1 ? (+pagination?.page - 1).toString() : "1",
      })
    }
    return formSubmissionsData?.data
  }, [formSubmissionsData])


  const goToNext = () => {
    if (forms?.pagination) {
      if (+forms?.pagination?.page === +forms?.pagination?.totalPages) return
      if (+forms?.pagination?.page < +forms?.pagination?.totalPages) {
        setPage(+forms?.pagination?.page + 1)
        refetch()
      }
    }
  }

  const goToPrev = () => {
    if (forms?.pagination) {
      if (+forms?.pagination?.page === 1) return
      if (+forms?.pagination?.page > 1) {
        setPage(+forms?.pagination?.page - 1)
        refetch()
      }
    }
  }

  useEffect(() => {
    if (activeTab && activeTab !== '') {
      refetch()
    }
  }, [activeTab, refetch, workflowForm?.id])

  const rows = Array?.isArray(forms?.data) && forms?.data?.length ? forms?.data?.map((form) => {
    return {
      formName: form?.clientName,
      email: form?.clientEmail,
      dateCompleted: getNumberMonthAndYearFromDate(form?.createdAt),
      status: form?.status ? (
        <Pill variant={form?.status === 'sent' ? 'primary' : 'success'}>{form?.status}</Pill>
      ) : '-'
    }
  }) : [];
  return (
    <>
      {loading ? <ContentLoader /> : null}
      <Modal
        show={isVisible}
        closeModal={closeModal}
        variant="right"
      >
        <div className="w-full relative my-[80px]">
          <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
            <div className="w-full hidden xl:flex space-x-2 px-6 py-5 items-start">
              <Button
                variant="text"
                size="none"
                type="button"
                className="w-fit"
                fontSize="b4"
                onClick={closeModal}
              >
                <SvgArrowBack width="24px" height="24px" /> <span>Back</span>
              </Button>
            </div>
            <div className="w-full flex xl:hidden space-x-[69px] p-4 items-center">
              <Button
                variant="icon"
                size="square"
                type="button"
                rounded="md"
                className="w-fit"
                fontSize="b4"
                onClick={closeModal}
              >
                <SvgChevronLeft width="24px" height="24px" />
              </Button>
              <Paragraph size="b3" weight="bold">
                Back
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col p-4 space-y-10'>
            <div className='w-full flex items-center space-x-3'>
              <Paragraph size='b1' weight='bold' className=''>
                {workflowForm?.title}
              </Paragraph>
              <Pill variant='success'>Active</Pill>
            </div>
            <div className='w-full flex flex-col xl:flex-row gap-4 items-start'>
              <div className='flex w-full p-3 space-x-3 border border-[#F5F5F5]'>
                <SvgFormsSent width="28px" height="28px" />
                <div>
                  <Paragraph size='b5' className='mb-1'>Sent</Paragraph>
                  <Paragraph size='b1' weight='bold'>
                    {workflowAnalytics?.totalSubmissions}
                  </Paragraph>
                </div>
              </div>
              <div className='flex w-full p-3 space-x-3 border border-[#F5F5F5]'>
                <SvgFormsCompleted width="28px" height="28px" />
                <div>
                  <Paragraph size='b5' className='mb-1'>Completed</Paragraph>
                  <Paragraph size='b1' weight='bold'>
                    {workflowAnalytics?.completedSubmissions}
                  </Paragraph>
                </div>
              </div>
            </div>
            <div className='border-t border-b border-grey-100 pt-1'>
              <div className='flex w-full justify-between items-center'>
                <Tabs
                  tabs={FORM_TABS}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  variant='underline'
                />
                <Paginator pageInfo={pageInfo} {...{ goToNext, goToPrev }} />
              </div>
              <Table
                headers={FORMS_HISTORY_HEADERS}
                mobileHeaders={MOBILE_FORMS_HISTORY_HEADERS}
                rows={rows}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ViewForm