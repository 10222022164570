import { cleanUpText } from 'components/utils/misc'
import { COLORS } from 'constants/colors'
import React from 'react'
import { Heading, Paragraph } from 'ui'
import { BusinessBanner } from 'core/generated'

const Banner = ({ banners }: {
  banners: BusinessBanner[]
}) => {
  const severityColors = {
    error: {
      background: 'bg-red-300/10',
      border: 'border-red-300/20',
      text: COLORS.RED[300],
    },
    warning: {
      background: 'bg-orange-300/10',
      border: 'border-orange-300/20',
      text: COLORS.ORANGE[300],
    },
    success: {
      background: 'bg-green-300/10',
      border: 'border-green-300/20',
      text: COLORS.GREEN[300],
    },
  }
  
  const getSeverityColors = (messageType: string) => {
    return severityColors[messageType?.toLowerCase()] || severityColors.warning
  }
  return (
    <>
      {Array?.isArray(banners) && banners?.length ? banners?.map((banner) => {
        const { background, border, text } = getSeverityColors(banner?.messageType)

        return (
          <div key={banner?.id} className={`w-full flex flex-col ${background} rounded-md p-3 border ${border} shadow-md`}>
            <Heading
              variant="h2"
              size="h11"
              weight="semiBold"
              color={text}
            >
              {cleanUpText(banner?.messageType)}
            </Heading>
            <Paragraph size='b5' color={text} weight='bold'>
              {banner?.message}
            </Paragraph>
          </div>
        )
      }) : null}
    </>
  )
}

export default Banner