import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactToPrint from "react-to-print";
import { SPLICE_RECEIPT_LINK } from 'constants/information';
import { cleanUpText, copyTextToClipboard, formatInToPrice, getDayMonthAndNumberAndTimeFromDate } from '../../../utils/misc';
import { ReceiptPrintViewProps, ReceiptProps } from '../types';
import { useSalonCache } from 'hooks/useSalonCache';
import { useUserCache } from 'hooks/useUserCache';
import { Modal } from 'ui/templates/modal/Modal';
import Heading from 'ui/atoms/heading/Heading';
import Button from 'ui/atoms/button/Button';
import { SvgClose } from 'ui';
import { COLORS } from 'constants/colors';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { SvgCheckMarkGreen } from 'ui';
import { SvgUser } from 'ui';
import { SvgFluentPrint28Regular } from 'ui';
import ReceiptPrintView from './ReceiptPrintView';
import { APPOINTMENT_PAYMENT_METHOD } from '../../bookingSales/constants';
import { AppointmentPriceCard } from '../../../modals/AppointmentPriceBreakDown';
import axios from 'axios';
import { EmailAppointmentReceiptDocument, Payment } from 'core/generated';
import { API_ERRORS } from 'constants/errors';
import { useModal, useToast } from 'hooks';
import AddAppointmentModal from '../../../modals/AddAppointmentModal';
import SuccessModal from '../../../modals/SuccessModal';
import { ToastProps, ToastWrapper } from 'ui';
import { SvgWhiteDiamond } from 'ui';
import { useEmailAppointmentReceipt } from 'api/useAppointments';

class ComponentToPrint extends React.Component<ReceiptPrintViewProps> {
  render() {
    const { appointment, business, hasMadePayment } = this.props;
    return (
      <ReceiptPrintView appointment={appointment} business={business} hasMadePayment={hasMadePayment} />
    );
  }
}

const ViewReceiptModal = (props: ReceiptProps) => {
  const [appointmentId, setAppointmentId] = useState<string | null>(props?.appointment?.id)
  const { getSalonData } = useSalonCache();
  const salon = getSalonData();
  const { getBusinessData } = useUserCache();
  const business = getBusinessData();
  const componentRef = useRef<ComponentToPrint>(null);
  const { openModal: openAddAppointmentModal, closeModal: closeAddAppointmentModal, isVisible: isAddAppointmentModalVisible } = useModal()
  const { toast, addToast } = useToast()
  const {
    isVisible: successModalIsVisible,
    openModal: openSuccessModal,
    closeModal: closeSuccessModal,
  } = useModal();

  const {
    loading: isLoading,
    emailAppointmentReceipt
  } = useEmailAppointmentReceipt()

  const copyLink = () => {
    copyTextToClipboard(`${SPLICE_RECEIPT_LINK}${props?.appointment?.bookingId}`);
    props?.addToast && props.addToast({
      variant: "success",
      message: 'Receipt link copied to clipboard',
    });
  };

  const closeSuccessModalAndReloadCalendar = () => {
    closeSuccessModal();
    props?.refetchCalendarData && props.refetchCalendarData()
    // refetchCalendarData();
  }

  const openAddAppointmentModal_ = () => {
    _closeModal()
    props.closeViewReceiptModal()
    openAddAppointmentModal()
  }

  const _closeModal = () => {
    props.closeModal()
  }

  const initiateCloseAddAppointmentModal = (action: string) => {
    closeAddAppointmentModal();
    if (action === 'addedAppointment') {
      openSuccessModal();
    }
  }

  const totalAmountPaid = useMemo(() => {
    if (props?.appointment) {
      const payments = props?.appointment?.payments;
      // sum up payments
      let total = 0;
      payments?.forEach((payment: Payment) => {
        total += payment?.amount;
      });
      return total;
    }
    return 0;
  }, [props?.appointment])

  console.log({
    totalAmountPaid
  })

  useEffect(() => {
    if (props?.appointment?.id) {
      setAppointmentId(props?.appointment?.id)
    }
  }, [props?.appointment?.id])

  const emailReceiptToCustomer = async () => {
    if (!props?.appointment?.client?.email || props?.appointment?.client?.email === '') {
      props?.addToast && props?.addToast({
        message: "Client does not have an email address",
        variant: 'error',
      })
      return
    }
    try {
      emailAppointmentReceipt({
        variables: { input: { appointmentId: props?.appointment?.id } }
      }).then(({ data }) => {
        if (data?.emailAppointmentReceipt?.status === 200) {
          props?.addToast && props.addToast({
            message: "Email sent successfully",
            variant: 'success',
          })
        }
        if (data?.emailAppointmentReceipt?.errors?.length) {
          const message = data?.emailAppointmentReceipt?.errors[0]?.message || API_ERRORS.FAILED_TO_MAIL_CUSTOMER;
          props?.addToast && props?.addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (createProductError) {
      if (axios.isAxiosError(createProductError)) {
        const message = createProductError?.response?.data?.message || API_ERRORS.FAILED_TO_MAIL_CUSTOMER;
        props?.addToast && props.addToast({
          message,
          variant: 'error',
        })
      }
    }
  }
  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <Modal
        title=""
        show={props.isVisible}
        closeModal={_closeModal}
      >
        {props.appointment && (
          <div className='w-full flex flex-col space-y-[40px]'>
            <div className='w-full space-y-4'>
              <div className="flex justify-between items-center">
                <Heading variant='h1' size='h8' weight='bold'>Transaction Details</Heading>
                <Button
                  variant='icon'
                  size='square'
                  rounded='md'
                  type='button'
                  className='hidden xl:flex max-w-[50px]'
                  onClick={_closeModal}
                >
                  <SvgClose width="24px" height="24px" />
                </Button>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Booking ID: #{props?.appointment?.bookingId}</Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Date: {getDayMonthAndNumberAndTimeFromDate(props.appointment?.startAt) || "-"}</Paragraph>
                </div>
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Billed to: {props.appointment?.client?.firstName} {props.appointment?.client?.lastName}</Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Status: {cleanUpText(props.appointment?.appointmentStatus)}</Paragraph>
                </div>
              </div>
            </div>
            {props.appointment && props?.hasMadePayment && props?.hasMadePayment !== 'null' ? (
              <div className='w-full px-6'>
                <div className="w-full flex p-4 rounded-md bg-green-75 space-x-4 items-start">
                  <SvgCheckMarkGreen width="24px" height="24px" />
                  <div className="w-full flex flex-col space-y-4 ">
                    <Paragraph size="b4" color={COLORS.GREEN[850]} weight="semiBold">{props.appointment?.client?.firstName} {props.appointment?.client?.lastName} has made {props?.hasMadePayment === 'full' ? 'full payment' : 'a deposit'}</Paragraph>
                    <AppointmentPriceCard appointment={props?.appointment} />
                  </div>
                </div>
              </div>
            ) : (
              <div className='w-full px-6'>
                <div className="w-full flex p-4 rounded-md bg-green-75 space-x-4 items-start">
                  <SvgUser width="24px" height="24px" />
                  <div className="w-full flex flex-col space-y-4 ">
                    <Paragraph size="b4" color={COLORS.GREEN[850]} weight="semiBold">Client: {props.appointment?.client?.firstName} {props.appointment?.client?.lastName}</Paragraph>
                  </div>
                </div>
              </div>
            )}
            {props?.appointment && props?.appointment?.milestone ? (
              <div className='w-full px-6 my-4'>
                <div className='w-full flex p-4 rounded-md bg-[#E4B91F] space-x-4 items-center
                '>
                  <SvgWhiteDiamond width="30px" height="30px" />
                  <div className='w-full flex flex-col space-y-4 '>
                    <Paragraph
                      size='b4'
                      color={COLORS.GREY[800]}
                      weight='semiBold'
                      className='flex'
                    >
                      <span>{
                        props?.appointment?.milestone?.customerReceivesType === 'percentage'
                          ? `${props?.appointment?.milestone?.customerReceives}%`
                          : `${formatInToPrice(props?.appointment?.milestone?.customerReceives)?.replace(".00", "")}`
                      } - </span>
                      <span>Reward discount in use</span>
                    </Paragraph>
                  </div>
                </div>
              </div>
            ) : null}
            <div className='flex flex-col space-y-2 px-4'>
              <div className='flex justify-between items-center bg-grey-50 px-4 py-2'>
                <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Item</Heading>
                <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Amount</Heading>
              </div>

              {props.appointment?.appointmentServices?.map((service) => {
                const priceSlashed = props?.appointment?.appointmentVoucher && props?.appointment?.appointmentVoucher?.packageVoucher?.redemptionMode === 'services' && props?.appointment?.appointmentVoucher?.packageVoucher?.voucherServices?.find((voucherService) => voucherService?.serviceId === service?.id)
                return (
                  <div className='flex justify-between items-center px-4 py-2' key={service?.id}>
                    <Paragraph size='b4' color={COLORS.GREY[300]}>{service?.name} x{service?.quantity}</Paragraph>
                    <Paragraph size='b4' className={`${priceSlashed ? 'line-through' : ''}`}>{formatInToPrice(service?.price)}</Paragraph>
                  </div>
                )
              })}

              {props.appointment?.appointmentProducts?.map((product) => (
                <div className='flex justify-between items-center px-4 py-2' key={product?.id}>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>{product?.name} x{product?.quantity}</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(product?.price)}</Paragraph>
                </div>
              ))}

              <div className='border-t border-grey-50'></div>
              {!props?.appointment?.milestone && props?.appointment?.discountType ? (
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Discount</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(props.appointment.discountAmount || 0)}</Paragraph>
                </div>
              ) : null}
              {props?.appointment?.milestone && props?.appointment?.milestone?.customerReceivesType === 'percentage' ? (
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph
                    size='b4'
                    color={COLORS.GREY[400]}
                    className='flex space-x-3'
                  >
                    <span>Reward discount -</span>
                    <span>{
                      props?.appointment?.milestone?.customerReceivesType === 'percentage'
                        ? `${props?.appointment?.milestone?.customerReceives}%`
                        : `${formatInToPrice(props?.appointment?.milestone?.customerReceives)?.replace(".00", "")}`
                    }</span>
                  </Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(props.appointment.discountAmount || 0)}</Paragraph>
                </div>
              ) : null}

              {props?.appointment?.totalServicesAmount ? (
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Subtotal</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(props?.appointment?.totalServicesAmount as number + props?.appointment?.totalProductsAmount as number)}</Paragraph>
                </div>
              ) : null}

              {props?.appointment?.tipAmount ? (
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Tip</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(props?.appointment?.tipAmount)}</Paragraph>
                </div>
              ) : null}
              {props?.appointment?.outstandingFee && props.appointment?.outstandingFee > 0 ?
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Outstanding cancellation fee</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(props?.appointment?.outstandingFee)}</Paragraph>
                </div> : null}

              {props?.appointment?.addOnAmount ? (
                <div className='flex justify-between px-4 py-2 items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[400]} weight="bold">Extra charge: <span className='text-b5 font-semibold'>{props?.appointment?.addOnReason}</span></Paragraph>
                  <Paragraph size='b4' weight="bold">{formatInToPrice(props?.appointment?.addOnAmount)}</Paragraph>
                </div>
              ) : null}
              {props?.appointment?.appointmentPromo ? (
                <div className='flex justify-between px-4 py-2 items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Discount</Paragraph>
                  <Paragraph size='b4'>-{formatInToPrice(props?.appointment?.appointmentPromo?.amount)}</Paragraph>
                </div>
              ) : null}
              {props?.appointment?.membership && props?.appointment?.membership?.amount > 0 ? (
                <div className="flex justify-between px-4 py-2 items-center">
                  <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[300]}>
                    Membership Discount <span className="font-bold text-green-300">{props?.appointment?.membership?.rewardPercentage}%</span>
                  </Paragraph>
                  <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[900]}>
                    {/* sum up membershipDiscountAmount in selected services */}
                    {formatInToPrice(props?.appointment?.membership?.amount)}
                  </Paragraph>
                </div>
              ) : null}
              {props?.appointment?.processingFeeAmount && ((props?.appointment?.totalAmountPaid - props?.appointment?.processingFeeAmount) === props?.appointment?.totalPaid || !props?.appointment?.depositAmount) ? (
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Processing Fee</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(props?.appointment?.processingFeeAmount)}</Paragraph>
                </div>
              ) : null}
              {props?.appointment?.depositAmount && props?.appointment?.depositPaymentMethod === APPOINTMENT_PAYMENT_METHOD.unpaid && props?.appointment?.appointmentStatus === 'deposit_pending' || props?.appointment?.appointmentStatus === 'deposit_abandoned' ? (
                <div className='flex justify-between px-4 py-2 items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Deposit Required</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(props?.appointment?.depositAmount)}</Paragraph>
                </div>
              ) : null}
              {salon?.isTaxVisible ? (
                <div className='flex justify-between px-4 py-2 items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Tax</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(props?.appointment?.taxAmount)}</Paragraph>
                </div>
              ) : null}
              <div className='border-t border-grey-50'></div>
              <div className='flex justify-between px-4 py-2 items-center'>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight="bold">Total</Paragraph>
                <Paragraph size='b4' weight='semiBold' className='inline'>{totalAmountPaid ? formatInToPrice(totalAmountPaid + props?.appointment?.tipAmount || 0) : 0}</Paragraph>
              </div>
              {props?.appointment?.paymentMethod === APPOINTMENT_PAYMENT_METHOD.unpaid && props?.appointment?.depositAmount && props?.appointment?.depositPaymentMethod !== APPOINTMENT_PAYMENT_METHOD.unpaid && props?.appointment?.appointmentStatus !== 'deposit_pending' && props?.appointment?.appointmentStatus !== 'deposit_abandoned' ? (
                <div className='flex justify-between px-4 py-2 items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Deposit Paid</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(props?.appointment?.depositAmount)}</Paragraph>
                </div>
              ) : null}
              {props?.appointment?.processingFeeAmount && props?.appointment?.totalAmountPaid === props?.appointment?.depositAmount + props?.appointment?.processingFeeAmount ? (
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Processing Fee</Paragraph>
                  <Paragraph size='b4'>{formatInToPrice(props?.appointment?.processingFeeAmount)}</Paragraph>
                </div>
              ) : null}
              {totalAmountPaid && props?.appointment?.totalPaid && props?.appointment?.paymentMethod === APPOINTMENT_PAYMENT_METHOD.unpaid && props?.appointment?.totalPaid - totalAmountPaid !== 0 && props?.appointment?.depositAmount && props?.appointment?.appointmentStatus !== 'deposit_pending' && props?.appointment?.appointmentStatus !== 'deposit_abandoned' ? (
                <div className="w-full flex flex-col px-4 py-2 space-y-2 border-t border-grey-100">
                  <div className='flex justify-between items-center'>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight="bold">Outstanding Balance</Paragraph>
                    <Paragraph size='b4' weight="bold">{formatInToPrice(props?.appointment?.totalPaid - totalAmountPaid)}</Paragraph>
                  </div>
                </div>
              ) : null}
            </div>

            <div className='flex justify-between items-center space-y-2 px-4'>
              <ReactToPrint
                trigger={() => <Button variant='text' size='sm' fontSize='b5' fontWeight='semiBold'>
                  <SvgFluentPrint28Regular width='20px' height='20px' /> Print receipt
                </Button>}
                content={() => componentRef.current}
              />
              <div className='flex space-x-4'>
                <Button variant='secondary' rounded='md' size='sm' fontSize='b5' fontWeight='semiBold' onClick={copyLink}>
                  Copy link
                </Button>
                <Button variant='primary' rounded='md' size='sm' fontSize='b5' fontWeight='semiBold' onClick={emailReceiptToCustomer} disabled={isLoading} loading={isLoading}>
                  Email
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className='flex justify-end' style={{ marginTop: '50px' }}>
          <Button variant='text' className='text-red-500' onClick={_closeModal}>Cancel</Button>
          <Button variant='primary' rounded='md' size='sm' fontSize='b5' fontWeight='semiBold' onClick={openAddAppointmentModal_}>Rebook appointment</Button>
        </div>
      </Modal >
      {props.isVisible ? (
        <ComponentToPrint ref={componentRef}
          appointment={props.appointment}
          business={business}
          hasMadePayment={props.hasMadePayment}
        />
      ) : null}
      <AddAppointmentModal isVisible={isAddAppointmentModalVisible} closeModal={initiateCloseAddAppointmentModal} appointmentId={appointmentId} salonId={salon?.id} addToast={addToast} />
      <SuccessModal isVisible={successModalIsVisible} closeModal={closeSuccessModalAndReloadCalendar} />
    </>
  )
}

export default ViewReceiptModal