import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClientNoVouchers = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M20 38.552c9.65 0 17.474-4.517 17.474-10.089S29.65 18.374 20 18.374 2.526 22.891 2.526 28.464c0 5.571 7.824 10.088 17.474 10.088Z"
      fill="#F5F5F5"
    />
    <path
      d="m8.791 30.94-4.257-2.458 4.257-2.458 4.258 2.458L8.79 30.94Z"
      fill="#E0E0E0"
    />
    <path
      d="m12.516 31.54-3.162-1.824 3.162-1.826 3.162 1.826-3.162 1.825ZM19.565 36.363c2.564 0 4.643-1.2 4.643-2.68 0-1.481-2.079-2.681-4.643-2.681-2.564 0-4.643 1.2-4.643 2.68 0 1.481 2.079 2.681 4.643 2.681ZM32.528 32.097 14.49 21.714l2.421-1.398L34.95 30.699l-2.42 1.398Z"
      fill="#E0E0E0"
    />
    <path
      d="m32.41 29.244 1.085.63s-.547-1.747-.24-3.504c.114-.664.355-.993.44-1.17.087-.177.74-1.273.16-1.778-.578-.506-1.925.5-1.865 3.206.03.886.17 1.765.42 2.616Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m32.41 29.244 1.085.63s-.547-1.747-.24-3.504c.114-.664.355-.993.44-1.17.087-.177.74-1.273.16-1.778-.578-.506-1.925.5-1.865 3.206.03.886.17 1.765.42 2.616Z"
      fill="#000"
    />
    <path
      d="M32.914 29.577a.04.04 0 0 1-.036-.027c-.004-.01-.414-1.148-.392-2.972.021-1.825.985-2.81.994-2.818a.04.04 0 0 1 .056 0 .04.04 0 0 1 0 .056c-.01.009-.95.966-.971 2.765-.02 1.8.383 2.934.387 2.945a.04.04 0 0 1-.001.03.039.039 0 0 1-.023.02.027.027 0 0 1-.014 0Z"
      fill="#fff"
    />
    <path
      d="m32.72 29.407 1.792 1.055s.82-.122.758-.894c-.063-.772-.396-.954.02-1.294.415-.339.522-.361.502-.694-.02-.333-.244-.48-.349-.61-.105-.13-.4-.407-.193-.808.208-.401.487-.79.072-.973-.248-.11-.665.028-.942.32-.277.292-.504.504-.766.572-.263.068-.808.073-.79.525.018.45.136.607.026.808-.21.378-1.09.581-.13 1.993Z"
      fill="#16796F"
    />
    <path
      d="M33.446 29.876a.04.04 0 0 1-.027-.01.04.04 0 0 1-.013-.026c0-.027-.196-2.694 1.656-4.445a.04.04 0 0 1 .054.057c-1.825 1.725-1.634 4.355-1.631 4.382a.04.04 0 0 1-.036.042h-.003Z"
      fill="#fff"
    />
    <path
      d="M33.766 27.587a.038.038 0 0 1-.038-.037 1.536 1.536 0 0 0-.56-1.081.039.039 0 0 1 .002-.043.039.039 0 0 1 .055-.01 1.58 1.58 0 0 1 .579 1.146.039.039 0 0 1-.035.027l-.003-.002ZM33.634 28.042a.038.038 0 0 1-.026-.01.04.04 0 0 1 0-.055c.02-.022.48-.523 1.558-.375a.04.04 0 0 1 .033.044.037.037 0 0 1-.015.026.038.038 0 0 1-.03.007c-1.03-.141-1.484.346-1.488.35a.04.04 0 0 1-.032.013Z"
      fill="#fff"
    />
    <path
      d="M32.96 15.7 24.44 1.858a.808.808 0 0 0-1.08-.285L15.748 5.76 32.96 15.7Z"
      fill="#16796F"
    />
    <path
      d="M32.96 15.7 15.748 5.76v14.986a1.435 1.435 0 0 0 .72 1.244l15.292 8.828a.805.805 0 0 0 1.2-.698V15.7Z"
      fill="#16796F"
    />
    <g opacity={0.3} fill="#fff">
      <path d="M32.96 15.7 24.44 1.858a.808.808 0 0 0-1.08-.285L15.748 5.76 32.96 15.7Z" />
      <path d="M32.96 15.7 15.748 5.76v14.986a1.435 1.435 0 0 0 .72 1.244l15.292 8.828a.805.805 0 0 0 1.2-.698V15.7Z" />
    </g>
    <path
      d="M32.56 15.862 24.035 2.018a.808.808 0 0 0-1.075-.285L15.343 5.92l17.217 9.942Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="M32.56 15.862 24.035 2.018a.808.808 0 0 0-1.075-.285L15.343 5.92l17.217 9.942Z"
      fill="#fff"
    />
    <path
      d="M32.56 15.862 15.343 5.92v14.987a1.44 1.44 0 0 0 .72 1.244l15.288 8.827a.804.804 0 0 0 1.208-.696l.001-14.42Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="M32.56 15.862 15.343 5.92v14.987a1.44 1.44 0 0 0 .72 1.244l15.288 8.827a.804.804 0 0 0 1.208-.696l.001-14.42Z"
      fill="#fff"
    />
    <path
      d="m16.062 22.151 15.29 8.827a.792.792 0 0 0 .778.013l-9.126-13.74-7.66 3.656a1.439 1.439 0 0 0 .718 1.244Z"
      fill="#16796F"
    />
    <path
      opacity={0.7}
      d="m16.062 22.151 15.29 8.827a.792.792 0 0 0 .778.013l-9.126-13.74-7.66 3.656a1.439 1.439 0 0 0 .718 1.244Z"
      fill="#fff"
    />
    <path
      d="m15.343 5.922 7.455 12.708 9.761-2.767-17.216-9.94Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m15.343 5.922 7.455 12.708 9.761-2.767-17.216-9.94Z"
      fill="#fff"
    />
    <path
      d="M30.67 10.382v6.016l-7.872 2.23-5.521-9.41v-6.57l13.393 7.734Z"
      fill="#F0F0F0"
    />
    <path
      d="M8.791 30.489 5.44 28.553V23.96l3.351 1.935v4.595Z"
      fill="#16796F"
    />
    <path
      d="M8.791 30.489v-4.595l3.352-1.935v4.595L8.79 30.488Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="M8.791 30.489 5.44 28.553V23.96l3.351 1.935v4.595Z"
      fill="#000"
    />
    <path
      opacity={0.7}
      d="M8.791 30.489v-4.595l3.352-1.935v4.595L8.79 30.488Z"
      fill="#000"
    />
    <path
      d="m8.791 25.707-3.61-2.085 3.61-2.085 3.612 2.085-3.612 2.085Z"
      fill="#16796F"
    />
    <path
      opacity={0.1}
      d="m8.791 25.707-3.61-2.085 3.61-2.085 3.612 2.085-3.612 2.085Z"
      fill="#000"
    />
    <path
      d="m6.75 22.715 3.612 2.085.47-.271-3.611-2.086-.47.272Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m6.75 22.715 3.612 2.085.47-.271-3.611-2.086-.47.272Z"
      fill="#fff"
    />
    <path d="m8.791 26.428-3.61-2.085v-.721l3.61 2.085v.721Z" fill="#16796F" />
    <path d="M8.791 26.428v-.72l3.612-2.086v.721L8.79 26.428Z" fill="#16796F" />
    <path
      opacity={0.3}
      d="m8.791 26.428-3.61-2.085v-.721l3.61 2.085v.721Z"
      fill="#000"
    />
    <path
      opacity={0.5}
      d="M8.791 26.428v-.72l3.612-2.086v.721L8.79 26.428Z"
      fill="#000"
    />
    <path
      d="m7.22 29.585-.47-.27V24.53l.47.27v4.786ZM10.362 29.585l.47-.27V24.53l-.47.27v4.786Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="m7.22 29.585-.47-.27V24.53l.47.27v4.786Z"
      fill="#000"
    />
    <path
      opacity={0.4}
      d="m10.362 29.585.47-.27V24.53l-.47.27v4.786Z"
      fill="#000"
    />
    <path
      d="M10.832 22.715 7.221 24.8l-.47-.271 3.61-2.086.471.272Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="M10.832 22.715 7.221 24.8l-.47-.271 3.61-2.086.471.272Z"
      fill="#fff"
    />
    <path
      d="m12.516 31.167-2.449-1.414v-3.356l2.45 1.413v3.357Z"
      fill="#16796F"
    />
    <path
      d="M12.516 31.167V27.81l2.448-1.413v3.357l-2.448 1.413Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="m12.516 31.167-2.449-1.414v-3.356l2.45 1.413v3.357Z"
      fill="#000"
    />
    <path
      opacity={0.7}
      d="M12.516 31.167V27.81l2.448-1.413v3.357l-2.448 1.413Z"
      fill="#000"
    />
    <path
      d="M12.516 27.674 9.877 26.15l2.64-1.523 2.638 1.523-2.639 1.524Z"
      fill="#16796F"
    />
    <path
      opacity={0.1}
      d="M12.516 27.674 9.877 26.15l2.64-1.523 2.638 1.523-2.639 1.524Z"
      fill="#000"
    />
    <path
      d="m11.025 25.488 2.638 1.523.344-.198-2.639-1.523-.343.198Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m11.025 25.488 2.638 1.523.344-.198-2.639-1.523-.343.198Z"
      fill="#fff"
    />
    <path d="m12.516 28.2-2.639-1.523v-.527l2.64 1.524v.527Z" fill="#16796F" />
    <path d="M12.516 28.2v-.526l2.639-1.524v.527L12.516 28.2Z" fill="#16796F" />
    <path
      opacity={0.3}
      d="m12.516 28.2-2.639-1.523v-.527l2.64 1.524v.527Z"
      fill="#000"
    />
    <path
      opacity={0.5}
      d="M12.516 28.2v-.526l2.639-1.524v.527L12.516 28.2Z"
      fill="#000"
    />
    <path
      d="m11.368 30.506-.343-.197v-3.496l.343.198v3.495ZM13.664 30.506l.344-.197v-3.496l-.344.198v3.495ZM14.007 25.488l-2.639 1.523-.343-.198 2.638-1.523.344.198Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m14.007 25.488-2.639 1.523-.343-.198 2.638-1.523.344.198Z"
      fill="#fff"
    />
    <path
      d="m15.225 14.306 1.44 2.439 1.637-.876c.32-.172.672-.28 1.034-.315l-1.008 2.021-1.613.787a.757.757 0 0 1-.918-.192c-.182-.222-.384-.584-.572-1.172a95.729 95.729 0 0 1-.785-2.547l.304-.47.481.325Z"
      fill="#FFA8A7"
    />
    <path
      d="m18.916 30.645.013-.113c.08-.657.546-2.8.48-3.76l1.422-5.407-2.338-.393s-.96 3.467-1.079 5.36c-.065 1.04.019 2.874.106 4.312.45.167.945.168 1.396 0Z"
      fill="#455A64"
    />
    <path
      d="m18.117 33.29.64-.941s.08-1.036.156-1.704c-.45.168-.946.17-1.396.004.072 1.175.146 2.087.146 2.087l.454.554Z"
      fill="#FFA8A7"
    />
    <path
      d="m19.265 21.103-.387.045c-.32 1.44.683 2.11.683 2.11s-.692 2.216-1.002 3.847c-.15.793.217 1.25.69 1.505.107-.688.192-1.39.16-1.84l1.421-5.407-1.565-.26Z"
      fill="#37474F"
    />
    <path
      d="M18.778 32.505a.941.941 0 0 0-.384.48.606.606 0 0 1-.277.305s.064-.282-.05-.37c-.113-.086-.513-.4-.555.043l-.027.277s-.53.576-.79.702c-.258.125-.992.11-.812.78.18.67 1.444.344 1.86-.019.415-.363 1.434-.283 1.42-.768-.014-.485-.353-1.041-.385-1.43Z"
      fill="#263238"
    />
    <path
      d="M18.76 32.349a.942.942 0 0 0-.387.484.614.614 0 0 1-.28.307s.066-.283-.049-.372c-.114-.089-.516-.4-.56.043l-.026.28s-.534.579-.794.705-1 .11-.819.786c.182.675 1.454.347 1.872-.019.419-.365 1.445-.285 1.43-.773-.015-.488-.354-1.05-.387-1.441Z"
      fill="#37474F"
    />
    <path
      d="M17.874 33.6a.039.039 0 0 1-.036-.027.66.66 0 0 0-.405-.451.037.037 0 0 1-.013-.026l.001-.015a.038.038 0 0 1 .02-.023.038.038 0 0 1 .028-.002.732.732 0 0 1 .444.508.038.038 0 0 1-.014.026.038.038 0 0 1-.013.008l-.012.002ZM17.643 33.815a.037.037 0 0 1-.037-.027.66.66 0 0 0-.39-.443.04.04 0 0 1-.027-.048.036.036 0 0 1 .008-.013.038.038 0 0 1 .04-.012.733.733 0 0 1 .442.494c.003.01.002.02-.002.029a.039.039 0 0 1-.023.019l-.01.001ZM17.414 34.046a.04.04 0 0 1-.037-.028.653.653 0 0 0-.391-.443.039.039 0 0 1-.018-.061.04.04 0 0 1 .026-.014c.005 0 .01 0 .015.002a.734.734 0 0 1 .443.509.039.039 0 0 1-.014.026.039.039 0 0 1-.013.007l-.011.002Z"
      fill="#F0F0F0"
    />
    <path
      d="M16.664 33.796c-.26.126-1 .11-.819.786.118.435.688.453 1.19.313.033-.199.024-.403-.026-.598-.101-.377-.345-.501-.345-.501Z"
      fill="#FAFAFA"
    />
    <path
      d="M23.864 27.63c.062.202.19.377.364.496a.61.61 0 0 1 .222.348s-.255-.136-.37-.05c-.114.088-.515.394-.102.549l.26.097s.418.662.472.944c.055.283-.152.988.543.988.694 0 .708-1.305.465-1.8-.242-.496.1-1.46-.373-1.572-.472-.112-1.098.07-1.481 0Z"
      fill="#263238"
    />
    <path
      d="M22.79 27.413a9.575 9.575 0 0 1-.426-.126 9.92 9.92 0 0 0-1.392-.338s1.092-2.677 1.332-4.097c.24-1.42-.327-2.382-.327-2.382l-3.156.56c-.24 1.453.982 2.196.982 2.196-.048-.012-.698 2.128-.95 3.77-.253 1.64 1.188 1.693 1.879 1.68.33-.006 1.175-.006 1.968-.005.26-.578.174-1.023.09-1.258Z"
      fill="#455A64"
    />
    <path
      d="M23.76 27.615a9.297 9.297 0 0 1-.969-.202c.085.235.167.68-.09 1.257l1.67.004c-.001-.819-.61-1.059-.61-1.059Z"
      fill="#FFA8A7"
    />
    <path
      d="M23.708 27.607a.948.948 0 0 0 .367.5c.113.087.192.21.224.349 0 0-.256-.137-.372-.049-.115.088-.52.395-.104.551l.263.099s.42.666.475.95c.054.284-.153.995.546.995.7 0 .713-1.314.468-1.812-.244-.499.1-1.47-.375-1.583-.475-.113-1.105.073-1.492 0Z"
      fill="#37474F"
    />
    <path
      d="M24.085 29.095a.038.038 0 0 1-.037-.03.038.038 0 0 1 .005-.028.73.73 0 0 1 .607-.297.039.039 0 0 1 .021.02c.002.005.004.01.004.015a.038.038 0 0 1-.01.028.038.038 0 0 1-.012.009.037.037 0 0 1-.015.003.655.655 0 0 0-.53.263.04.04 0 0 1-.032.017ZM24.24 29.376a.04.04 0 0 1-.021-.007.038.038 0 0 1-.012-.052.734.734 0 0 1 .592-.299.038.038 0 0 1 .038.038.038.038 0 0 1-.036.04.668.668 0 0 0-.544.275.037.037 0 0 1-.017.005ZM24.4 29.656a.038.038 0 0 1-.034-.02.037.037 0 0 1-.005-.019c0-.007.002-.014.006-.02a.732.732 0 0 1 .607-.296.038.038 0 0 1 .025.035c0 .01-.003.02-.01.027a.04.04 0 0 1-.027.013.659.659 0 0 0-.53.262.038.038 0 0 1-.032.018Z"
      fill="#F0F0F0"
    />
    <path
      d="M24.56 30.007c.055.284-.153.995.547.995.45 0 .616-.547.61-1.068a1.465 1.465 0 0 0-.583-.13c-.392 0-.574.203-.574.203Z"
      fill="#FAFAFA"
    />
    <path
      d="M22.16 12.12s.014-.729.218-.743c.204-.015.056.502.056.502s.213-.141.294-.014c.08.127-.394.335-.394.335l-.174-.08Z"
      fill="#263238"
    />
    <path
      d="M17.89 13.548s.144 1.207 1.976.392c0 0-1.738 3.206 1.38 3.206 1.368 0 2.293-.67 2.108-1.799 0 0 .766-.017.8-.631 0 0-.939.41-1.347-.518-.408-.928.152-1.845-.543-2.216 0 0-.388-1.233-2.749-.576a.744.744 0 0 0-.71-.072c-.416.16-.523.895-.416 1.452.106.556-.009.996-.5.762Z"
      fill="#263238"
    />
    <path
      d="m21.697 15.8.37.1v2.447l-.37 1.18c.172.456.31.923.415 1.399a3.645 3.645 0 0 1-1.894.545c-1.1 0-1.726-.497-1.726-.497l-.139-1.63s-.815-.52-.415-1.56c.4-1.04 1.4-2.23 1.4-2.23h.36s.835.502 2 .245Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="m21.697 15.8.37.1v2.447l-.37 1.18c.172.456.31.923.415 1.399a3.645 3.645 0 0 1-1.894.545c-1.1 0-1.726-.497-1.726-.497l-.139-1.63s-.815-.52-.415-1.56c.4-1.04 1.4-2.23 1.4-2.23h.36s.835.502 2 .245Z"
      fill="#000"
    />
    <path
      d="m21.148 15.658.55.141s-.571.35-.785 1.481a.259.259 0 0 1-.327.2l-1.477-.434a.258.258 0 0 1-.15-.374c.183-.325.493-.847.743-1.12h.492l.954.106Z"
      fill="#FFA8A7"
    />
    <path d="m21.148 15.658-1.282-.096V13.94h1.282v1.718Z" fill="#FFA8A7" />
    <path
      d="M19.866 15.491a2.061 2.061 0 0 0 1.178-1.12v-.431h-1.178v1.551Z"
      fill="#F28F8F"
    />
    <path
      d="M19.567 11.84s-1.172.069-1.027 2.074c.067.926.284 1.406.997 1.37.81-.038 1.957-.594 1.882-2.18-.075-1.587-1.852-1.264-1.852-1.264Z"
      fill="#FFA8A7"
    />
    <path
      d="M21.148 13.94s-.32.085-.32-.376c0 0-1.428-.097-1.744-1.514 0 0 .141-.424 1.032-.424.892 0 1.642.788 1.41 1.872l-.378.442Z"
      fill="#263238"
    />
    <path d="M19.4 13.51v.688l-.404-.123.403-.566Z" fill="#F28F8F" />
    <path
      d="M19.866 13.723a.14.14 0 1 0 0-.282.14.14 0 0 0 0 .282ZM19.083 13.485a.141.141 0 0 1-.168.139.14.14 0 1 1 .168-.14ZM18.771 13.193l.275-.16s-.105-.11-.21-.044a.194.194 0 0 0-.065.204Z"
      fill="#263238"
    />
    <path
      d="M19.434 14.427a.72.72 0 0 0 .452-.121s.03.24-.169.284c-.199.043-.283-.163-.283-.163Z"
      fill="#F28F8F"
    />
    <path
      d="M21.034 13.992a.772.772 0 0 1 .064-.34c.053-.12.148-.24.312-.244.36-.01.408.337.404.46-.013.324-.276.674-.764.688l-.016-.564ZM21.945 15.866s-.684.61-.367 1.485c.317.875.96 1.96 1.142 2.349 0 0-1.535.174-1.92.213l.218 1.005s2.185.044 2.655-.128c.47-.173.688-.588.442-1.232-.184-.48-.985-2.22-1.372-3.056a1.12 1.12 0 0 0-.692-.602l-.106-.034Z"
      fill="#FFA8A7"
    />
    <path
      d="M10.305 12.235v7.375a.813.813 0 0 0 .407.704l9.14 5.267a.366.366 0 0 0 .549-.32V17.9a1.023 1.023 0 0 0-.512-.886l-8.939-5.15a.431.431 0 0 0-.645.372Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="M10.305 12.235v7.375a.813.813 0 0 0 .407.704l9.14 5.267a.366.366 0 0 0 .549-.32V17.9a1.023 1.023 0 0 0-.512-.886l-8.939-5.15a.431.431 0 0 0-.645.372Z"
      fill="#000"
    />
    <path
      d="M10.105 12.31v7.374a.813.813 0 0 0 .407.704l9.14 5.267a.366.366 0 0 0 .55-.32v-7.362a1.025 1.025 0 0 0-.513-.887l-8.938-5.15a.43.43 0 0 0-.646.374Z"
      fill="#16796F"
    />
    <path d="m13.622 13.59.173-.088.351.202-.155.1-.37-.214Z" fill="#16796F" />
    <path d="m13.622 22.18.37.213v-8.59l-.37-.213v8.59Z" fill="#16796F" />
    <path
      opacity={0.4}
      d="m13.622 22.18.37.213v-8.59l-.37-.213v8.59Z"
      fill="#fff"
    />
    <path
      d="m15.381 14.415-.368-.213s-.072.106-.124.623c-.043.431-1.61-.48-.95-1.308.415-.52 1.66.483 1.442.898ZM20.8 19.913c-.2.01-.4.01-.6 0-.116-.022-.491-.386-.591-.4-.1-.015-.356.146-.16.757.195.611.48.89.924.823.445-.065.64-.174.64-.174l-.213-1.006Z"
      fill="#FFA8A7"
    />
    <path
      d="M20.8 19.913a3.626 3.626 0 0 0-.4-.277v.444l.4-.167Z"
      fill="#FFA8A7"
    />
    <path
      d="m10.105 16.169 10.096 5.829v-.467l-10.096-5.829v.467Z"
      fill="#16796F"
    />
    <path
      opacity={0.4}
      d="m10.105 16.169 10.096 5.829v-.467l-10.096-5.829v.467Z"
      fill="#fff"
    />
    <path
      d="M13.74 17.817s-.419-1.415-.092-1.527c.263-.09.53.15.434.676a9.645 9.645 0 0 0-.117 1.02.178.178 0 0 0 .218-.029.18.18 0 0 0 .042-.066c.16-.442.512-1.133.302-1.661-.15-.376-.78-.885-1.254-.679-.472.207-.296 1.234.21 2.234 0 0 .094.225.258.032Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M13.74 17.817s-.419-1.415-.092-1.527c.263-.09.53.15.434.676a9.645 9.645 0 0 0-.117 1.02.178.178 0 0 0 .218-.029.18.18 0 0 0 .042-.066c.16-.442.512-1.133.302-1.661-.15-.376-.78-.885-1.254-.679-.472.207-.296 1.234.21 2.234 0 0 .094.225.258.032Z"
      fill="#fff"
    />
    <path
      d="M13.849 18.488s.419 1.415.092 1.527c-.263.09-.53-.15-.434-.676.057-.337.096-.677.117-1.019a.18.18 0 0 0-.218.029.179.179 0 0 0-.041.066c-.16.443-.513 1.133-.303 1.661.15.376.781.885 1.254.678.473-.206.297-1.233-.21-2.233 0-.001-.093-.227-.257-.033Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M13.849 18.488s.419 1.415.092 1.527c-.263.09-.53-.15-.434-.676.057-.337.096-.677.117-1.019a.18.18 0 0 0-.218.029.179.179 0 0 0-.041.066c-.16.443-.513 1.133-.303 1.661.15.376.781.885 1.254.678.473-.206.297-1.233-.21-2.233 0-.001-.093-.227-.257-.033Z"
      fill="#fff"
    />
    <path
      d="m14.008 18.806-.475-.275a.423.423 0 0 1-.212-.366v-.493a.221.221 0 0 1 .333-.192l.406.234a.465.465 0 0 1 .232.4v.526a.19.19 0 0 1-.284.166Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="m14.008 18.806-.475-.275a.423.423 0 0 1-.212-.366v-.493a.221.221 0 0 1 .333-.192l.406.234a.465.465 0 0 1 .232.4v.526a.19.19 0 0 1-.284.166Z"
      fill="#fff"
    />
    <path d="m20.202 21.997.199-.118v-.48l-.2.132v.466Z" fill="#16796F" />
  </svg>
);
export default SvgClientNoVouchers;
