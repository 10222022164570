import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClip = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 75 93"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M66.018 5.293A23.695 23.695 0 0 0 48.738.117a23.715 23.715 0 0 0-15.923 8.489L.487 48.106a2.347 2.347 0 0 0 .372 3.246c.98.8 2.421.68 3.253-.277l32.328-39.5A18.906 18.906 0 0 1 54.128 4.89a18.9 18.9 0 0 1 14.633 11.977 18.904 18.904 0 0 1-3.054 18.66L26.285 83.684C16.175 95.23-.996 81.246 8.332 68.98l39.312-48.047a4.448 4.448 0 0 1 6.043-.36 4.45 4.45 0 0 1 .816 6L22.77 65.404a2.342 2.342 0 0 0 .469 3.105c.934.762 2.289.695 3.14-.152L58.16 29.544c7.359-9.578-6.25-20.688-14.141-11.578L4.69 66.013c-13.016 17.266 10.939 36.844 25.234 20.64l39.407-48.171a23.631 23.631 0 0 0 5.199-17.277 23.618 23.618 0 0 0-8.512-15.91v-.002Z"
      fill="#000"
    />
  </svg>
);
export default SvgClip;
