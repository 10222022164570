import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import axios from 'axios'
import { convertStaffOptionsToCommaSeparatedStringForServices, formatNumber, formatServiceCustomPriceInToSelectOptions, getFullTime, getHoursAndMinutes } from '../utils/misc';
import { DEFAULT_CURRENCY } from 'constants/currency';
import { API_ERRORS, ERRORS } from 'constants/errors';
import { Modal } from 'ui/templates/modal/Modal';
import { FORM_ERRORS, PRICE_TYPE_REQUIREMENTS } from '../uicomponents/accountSetupComponents/constants';
import Input from 'ui/molecules/input/Input';
import { REGEX_PATTERNS } from 'constants/pattern';
import { FormLabel } from 'ui/atoms/formLabel';
import { FormHelperText } from 'ui/atoms/helperText/FormHelperText';
import SelectInput from 'ui/molecules/input/SelectInput';
import { SERVICE_PRICE_TYPES } from './constants';
import { YES_OR_NO_SETTINGS } from 'constants/information';
import Checkbox from 'ui/atoms/checkbox/Checkbox';
import Button from 'ui/atoms/button/Button';
import { AddServiceModalProps, ServiceInput } from './types';
import { MultiSelect, MultiSelectOption, ToggleOnly } from 'ui';
import { formatCategoriesToSelectFieldCreation, formatStaffToSelectField } from '../utils/utils';
import { SvgPlus } from 'ui';
import SingleSelect from 'ui/molecules/singleSelect/SingleSelect';
import { useGetStaffList } from 'api/useStaff';
import { useCreateCategory, useCreateService, useDeleteService, useGetCategories } from 'api/useCatalogue';

export const AddServiceModal = (props: AddServiceModalProps) => {
  const [serviceCustomPrices, setServiceCustomPrices] = useState<{ price: number, staff: MultiSelectOption[] }[]>([])
  const [allowCustomPrice, setAllowCustomPrice] = useState(false);
  const [allowServiceExtraTime, setAllowServiceExtraTime] = useState(false);
  const [newCategory, setNewCategory] = useState(false)
  const [showCustomButton, setShowCustomButton] = useState(false)
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ServiceInput>({
    defaultValues: {
      isDepositRequired: false,
      customerCanSchedule: true
    }
  });
  const [typeSign, setTypeSign] = useState<string | null>("-")
  const [deleteServiceisLoading, setDeleteServiceIsLoading] = useState(false)

  const {
    data: categoriesData,
    refetch: refetchCategories
  } = useGetCategories()
  const categories = useMemo(() => categoriesData?.categories, [categoriesData])
  const categoriesOptions = useMemo(() => formatCategoriesToSelectFieldCreation(categories), [categories])

  useEffect(() => {
    if (props?.service) {
      const { hours, minutes } = getHoursAndMinutes(props?.service?.duration || 0);
      const { hours: extraTimeHours, minutes: extraTimeMinutes } = getHoursAndMinutes(props?.service?.extraTime || 0)
      if (props?.service?.extraTime && props?.service?.extraTime > 0) {
        setAllowServiceExtraTime(true)
      }
      setValue('name', props?.service.name || '');
      setValue('description', props?.service.description || '');
      setValue('category', props?.service?.category?.name || '');
      setValue('price', props?.service.price || 0);
      setValue('duration', {
        hours,
        minutes,
      });
      setValue('extraTime', {
        hours: extraTimeHours,
        minutes: extraTimeMinutes,
      });
      setValue('pricingType', props?.service.pricingType || '');
      setValue('depositValue', props?.service?.depositValue || undefined);
      setValue('depositType', props?.service?.depositType || '');
      setValue("isDepositRequired", props?.service?.isDepositRequired || false);
    }
  }, [props?.service, setValue])

  const {
    data: staffListData,
    refetch: refetchStaffList
  } = useGetStaffList({
    salonId: props?.salonId,
    active: true
  })
  const staff = useMemo(() => staffListData?.staffManagement?.staffs, [staffListData])
  const staffMembersOptions = useMemo(() => formatStaffToSelectField(staff), [staff])

  useEffect(() => {
    refetchStaffList()
    refetchCategories()
  }, [props?.salonId, refetchStaffList, refetchCategories ])

  useEffect(() => {
    if (props?.service && Array?.isArray(categories) && categories?.length) {
      const serviceCategory = props?.service?.category?.id;
      const checkCategory = categories.find((cate) => cate?.id === serviceCategory);
      if (checkCategory) {
        setValue('categoryId', {
          label: checkCategory?.name,
          value: checkCategory?.id
        } as unknown as [])
      }
    }

  }, [props?.service, categories])

  useEffect(() => {
    if (props.service && Array?.isArray(staff) && staff?.length) {
      // get staff ids in staff that matches props.service?.salonStaffIds
      const staffIds = staff.filter(staff => {
        const staffService = props?.service?.serviceStaffs?.find(
          serviceStaff => serviceStaff?.salonStaff?.id === staff.id
        )
        return staffService
      })
      if (staffIds?.length) {
        setShowCustomButton(true)
      }
      setValue('salonStaffIds', formatStaffToSelectField(staffIds) as []);

      // checkif service?.serviceStaffs price are different
      const serviceStaffs = props?.service?.serviceStaffs;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let differentPriceStaffs: any = [];
      if (serviceStaffs) {
        if (serviceStaffs.length > 1) {
          differentPriceStaffs = serviceStaffs.filter((serviceStaff) => serviceStaff.price !== props?.service?.price && serviceStaff?.price);
        }
      }
      if (Array?.isArray(differentPriceStaffs) && differentPriceStaffs?.length) {
        setAllowCustomPrice(true);

        setServiceCustomPrices(formatServiceCustomPriceInToSelectOptions(differentPriceStaffs));
      }
    } else {
      setValue('salonStaffIds', [])
    }
  }, [props.service, staff])



  const enableOrDisableCustomPrice = () => {
    setAllowCustomPrice(!allowCustomPrice)
    if (Array?.isArray(serviceCustomPrices) && serviceCustomPrices?.length === 0) {
      setServiceCustomPrices([
        { price: 0, staff: [] }
      ])
    }
  }

  const addMoreCustomPrice = () => {
    setServiceCustomPrices([
      ...serviceCustomPrices,
      { price: 0, staff: [] }
    ])
  }

  const changeCustomPrice = (index: number, value: string | number) => {
    // Create a new array where the object at the specified index is updated
    const newCustomPrices = serviceCustomPrices.map((item, i) =>
      i === index ? { ...item, price: value as number } : item
    );

    // Update the state with the new array
    setServiceCustomPrices(newCustomPrices);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeCustomPriceStaff = (index: number) => (value: any) => {
    const newCustomPrices = [...serviceCustomPrices]
    newCustomPrices[index].staff = value
    setServiceCustomPrices(newCustomPrices)
  }

  const closeServiceModal = () => {
    props.closeModal();
    resetFormInput();
    props.refetch && props.refetch();
    props.setRefetchService && props.setRefetchService(true)
    props.removeSelectedServiceFromState && props.removeSelectedServiceFromState()
  }

  const resetFormInput = () => {
    setValue('name', '');
    setValue('description', '');
    setValue('categoryId', []);
    setValue('category', '');
    setValue('pricingType', '');
    setValue('price', 0);
    setValue('salonStaffIds', []);
    setValue('duration', {
      hours: 0,
      minutes: 0,
    });
    setValue('extraTime', {
      hours: 0,
      minutes: 0,
    });
    setValue('depositValue', undefined);
    setValue('depositType', '');
    setValue("isDepositRequired", false);
    setTypeSign("-");
    setServiceCustomPrices([])
    setAllowCustomPrice(false)
    setAllowServiceExtraTime(false)
    setShowCustomButton(false)
  }

  const {
    loading: createCategoryLoading,
    createCategory,
  } = useCreateCategory()

  const {
    loading,
    createService
  } = useCreateService()

  const {
    deleteService
  } = useDeleteService()

  const addServiceSubmit = async (input: ServiceInput) => {
    const duration = getFullTime(input?.duration?.minutes?.toString(), input?.duration?.hours?.toString());
    const categoryIdField = input.categoryId as unknown as {
      label: string;
      value: string;
    };
    let categoryId = categoryIdField?.value;

    if (newCategory) {
      try {
        const { data } = await createCategory({
          variables: {
            input: {
              name: input?.category
            }
          }
        });

        if (data?.createCategory?.status === 200 && data?.createCategory?.category) {
          refetchCategories()
          categoryId = data?.createCategory?.category?.id;
        } else {
          throw new Error('Failed to create category');
        }
      } catch (error) {
        props?.addToast({
          message: error?.message,
          variant: 'error'
        });
        return; // Exit the function if category creation fails
      }
    }
    const extraTime = getFullTime(
      input.extraTime?.minutes?.toString(),
      input.extraTime?.hours?.toString()
    )

    const salonStaffIds = input?.salonStaffIds ? convertStaffOptionsToCommaSeparatedStringForServices(input?.salonStaffIds as unknown as MultiSelectOption[], input.price || 0, serviceCustomPrices) : [];
    const serviceDataInput = {
      name: input.name,
      description: input.description,
      price: input?.price ? parseFloat(input?.price.toString().replace(',', '')) : 0,
      duration,
      categoryId,
      extraTime,
      pricingType: input?.pricingType,
      depositType: input?.depositType ? input.depositType : null,
      depositValue: input?.depositValue ? Number(input.depositValue) : null,
      isDepositRequired: input?.isDepositRequired,
      customerCanSchedule: true,
      salonStaffIds,
      isPublic: input?.isPublic,
      ...(props?.servicesCount === 0 ? { onboardingStep: 'created_service' } : {})
    };

    try {
      createService({
        variables: {
          input: { ...serviceDataInput, id: props?.service?.id, salonId: props?.salonId }
        }
      }).then(({ data }) => {
        if (data?.createService?.status === 200) {
          closeServiceModal();
          props?.addToast && props.addToast({
            message: 'Service added successfully',
            variant: 'success',
          })
        } else if (data?.createService?.errors?.length) {
          const message = data?.createService?.errors[0]?.message || API_ERRORS.BUSINESS_SERVICES_CREATE_FAILED;
          props?.addToast && props.addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (createServiceError) {
      if (axios.isAxiosError(createServiceError)) {
        const message = createServiceError?.response?.data?.message || API_ERRORS.BUSINESS_SERVICES_CREATE_FAILED;
        props?.addToast && props.addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  useEffect(() => {
    if (watch('depositType')) {
      if (watch('depositType') === 'fixed_value') {
        setTypeSign(DEFAULT_CURRENCY)
      } else if (watch('depositType') === 'percentage') {
        setTypeSign('%')
      }
    } else {
      setTypeSign("-")
    }
  }, [watch('depositType')])

  useEffect(() => {
    if (watch('categoryId')) {
      const categoryIdField = watch('categoryId') as unknown as {
        label: string;
        value: string;
      };
      if (categoryIdField?.value === 'new-cate') {
        setNewCategory(true);
      } else {
        setNewCategory(false);
      }
    }
  }, [watch('categoryId')])

  useEffect(() => {
    const salonStaffIds = watch('salonStaffIds');
    if (Array?.isArray(salonStaffIds) && salonStaffIds?.length > 0) {
      setShowCustomButton(true);
    } else {
      setShowCustomButton(false);
    }
  }, [watch('salonStaffIds')]);

  const deleteServiceAsync = async () => {
    deleteService({
      variables: { input: { id: props?.service?.id } },
    }).then(response => {
      setDeleteServiceIsLoading(false)
      if (response?.data?.deleteService?.status === 200) {
        closeServiceModal()
        props?.addToast({
          variant: "success",
          message: "Service deleted successfully",
        });
      }

      if (response?.data?.deleteService?.errors) {
        const message = response?.data?.deleteService?.errors[0]?.message || API_ERRORS.BUSINESS_SERVICES_DELETE_FAILED;
        props?.addToast({
          variant: 'error',
          message,
        })
      }
    }).catch(deleteServiceError => {
      setDeleteServiceIsLoading(false)
      const message = deleteServiceError?.response?.data?.message || API_ERRORS.BUSINESS_SERVICES_DELETE_FAILED;
      props?.addToast({
        variant: "error",
        message,
      });
    })
  }
  return (
    <Modal
      title={
        props.service ? 'Edit service' : 'Add a new service'
      }
      subTitle={
        props.service ? 'Edit the details of this service' : 'Add a new service to your salon'
      }
      show={props.isVisible}
      closeModal={
        props.service ? closeServiceModal : props.closeModal
      }
    >
      <form onSubmit={handleSubmit(addServiceSubmit)} className="w-full mt-6 space-y-6" autoComplete='off'>
        <Input name="name" label="Service name" id="service-name" type="text" placeholder='E.g Manicure' control={control} rules={{
          required: FORM_ERRORS.SERVICE_NAME_REQUIRED,
        }} error={errors.name} />
        <Input name="description" label="Description" id="description" type="text" placeholder='A brief description of this service' control={control} error={errors.description} />
        <div className='w-full flex flex-col xl:flex-row gap-2'>
          <div className="w-full xl:w-1/2 flex flex-col space-y-3">
            <Controller
              control={control}
              name="categoryId"
              render={({
                field: { onChange, value },
                formState: { errors },
              }) => {
                const errorMessage = errors?.categoryId?.message;
                return (
                  <>
                    <FormLabel htmlFor="service">
                      Select a category
                    </FormLabel>
                    <SingleSelect
                      selected={value || []}
                      options={categoriesOptions}
                      setSelected={onChange}
                    />
                    {errorMessage && (
                      <FormHelperText variant="error">
                        {errorMessage}
                      </FormHelperText>
                    )}
                  </>
                );
              }}
              rules={{
                required: "Select a category",
              }}
            />
            {newCategory && (
              <Input
                name='category'
                label='Category'
                id='category'
                type='text'
                placeholder='Enter a category'
                control={control}
                rules={{
                  required: newCategory && FORM_ERRORS.CATEGORY_REQUIRED
                }}
                error={errors.category}
              />
            )}
          </div>
          <Controller
            control={control}
            name="salonStaffIds"
            render={({
              field: { onChange, value },
              formState: { errors },
            }) => {
              const errorMessage = errors?.salonStaffIds?.message;
              return (
                <div className="w-full xl:w-1/2 flex flex-col space-y-2">
                  <FormLabel htmlFor="staff">
                    Add Staff
                  </FormLabel>
                  <MultiSelect
                    selected={value as unknown as [] || []}
                    options={staffMembersOptions}
                    setSelected={onChange}
                  />
                  {errorMessage && (
                    <FormHelperText variant="error">
                      {errorMessage}
                    </FormHelperText>
                  )}
                </div>
              );
            }}
          />
        </div>

        <div className="w-full flex flex-col xl:flex-row gap-2">
          <div className="w-full xl:w-1/2 flex flex-col space-y-3">
            <div className='w-full flex space-x-2'>
              <Controller
                control={control}
                name='price'
                rules={{ required: 'Please enter a price' }}
                render={({ field, formState: { errors } }) => (
                  <div className='w-full flex flex-col space-y-2'>
                    <FormLabel htmlFor='price'>Price</FormLabel>
                    <input
                      className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                      type='text'
                      {...field}
                      value={formatNumber(field.value)}
                      onChange={(e) => field.onChange(formatNumber(e.target.value))}
                      placeholder='Enter price'
                    />
                    {errors?.price?.message && (
                      <FormHelperText variant='error'>
                        {errors.price.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
              <div className="w-30%">
                <SelectInput
                  name='pricingType'
                  id='price-type'
                  label='Price Type'
                  control={control}
                  rules={{
                    required: ERRORS.PRICE_TYPE_REQUIRED
                  }}
                  value={watch('pricingType')}
                  error={errors.pricingType}
                  options={SERVICE_PRICE_TYPES}
                  placeholder='Select Price Type'
                />
              </div>
            </div>
            {showCustomButton ? (
              <div className='w-full flex flex-col space-y-4'>
                <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                  <div className='flex' onClick={enableOrDisableCustomPrice}>
                    <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                      <Checkbox isChecked={allowCustomPrice} />
                      <span className='text-grey-900 whitespace-nowrap'>
                        Set custom price
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {allowCustomPrice ? (
              <div className="flex flex-col space-y-3">
                <FormLabel htmlFor='isDepositRequired'>
                  Custom price
                </FormLabel>
                {Array?.isArray(serviceCustomPrices) && serviceCustomPrices?.map((serviceCustomPrice, index) => {
                  return (
                    <div className='w-full flex gap-2'>
                      <input
                        className='w-full max-w-[40%] flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                        type='text'
                        value={formatNumber(serviceCustomPrice.price)}
                        onChange={(e) => changeCustomPrice(index, formatNumber(e.target.value))}
                        placeholder='Enter price'
                      />
                      <div className="w-full max-w-[60%]">
                        <MultiSelect
                          selected={serviceCustomPrice?.staff || []}
                          options={watch('salonStaffIds') as []}
                          setSelected={onChangeCustomPriceStaff(index)}
                        />
                      </div>
                    </div>
                  )
                })}

                <Button
                  variant='light'
                  size='xs'
                  rounded='lg'
                  type="button"
                  className='w-fit border border-grey-100'
                  onClick={addMoreCustomPrice}
                >
                  Add more
                  <SvgPlus width="14px" height="14px" />
                </Button>
              </div>
            ) : null}
          </div>
          <div className='w-full xl:w-1/2 space-y-2'>
            <div className='w-full flex flex-col space-y-2'>
              <FormLabel htmlFor="duration">Duration</FormLabel>
              <div className='w-full flex items-center gap-x-4'>
                <Input name="duration.hours" id="duration-hours" type="text" placeholder='Hour(s)' control={control} rules={{
                  pattern: REGEX_PATTERNS.NUMBER,
                  required: !watch('duration.minutes') && ERRORS.TIME_REQUIRED
                }} />
                <span className='text-b5 text-grey-900'>:</span>
                <Input name="duration.minutes" id="duration-minutes" type="text" placeholder='Minutes' control={control} rules={{
                  pattern: REGEX_PATTERNS.NUMBER,
                  required: !watch('duration.hours') && ERRORS.TIME_REQUIRED
                }} />
              </div>
              {errors?.duration?.hours || errors?.duration?.minutes ? (
                <FormHelperText key={ERRORS.TIME_REQUIRED} variant="error">
                  {ERRORS.TIME_REQUIRED}
                </FormHelperText>
              ) : null}
            </div>
            <div className='w-full flex flex-col space-y-4'>
              <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                <div className='flex' onClick={() => { setAllowServiceExtraTime(!allowServiceExtraTime) }}>
                  <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                    <Checkbox isChecked={allowServiceExtraTime} />
                    <span className='text-grey-900 whitespace-nowrap'>
                      Add extra time in-between
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {allowServiceExtraTime ? (
              <div className='w-full flex flex-col space-y-2'>
                <FormLabel htmlFor='extraTime'>Extra time</FormLabel>
                <div className='w-full flex items-center gap-x-4'>
                  <Input name="extraTime.hours" id="extraTime-hours" type="text" placeholder='Hour(s)' control={control} rules={{
                    pattern: REGEX_PATTERNS.NUMBER,
                    required: !watch('extraTime.minutes') && ERRORS.TIME_REQUIRED
                  }} />
                  <span className='text-b5 text-grey-900'>:</span>
                  <Input name="extraTime.minutes" id="extraTime-minutes" type="text" placeholder='Minutes' control={control} rules={{
                    pattern: REGEX_PATTERNS.NUMBER,
                    required: !watch('duration.hours') && ERRORS.TIME_REQUIRED
                  }} />
                </div>
                {allowServiceExtraTime && errors?.extraTime?.hours || errors?.extraTime?.minutes ? (
                  <FormHelperText key={ERRORS.TIME_REQUIRED} variant="error">
                    {ERRORS.TIME_REQUIRED}
                  </FormHelperText>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <Controller
          control={control}
          name='isPublic'
          render={({ field: { onChange, value } }) => {
            return (
              <div
                className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                onClick={() => onChange(!value)}
              >
                <FormLabel htmlFor='isPublic'>
                  Allow clients to book service via booking site
                </FormLabel>
                <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                  <ToggleOnly isChecked={value} />
                </div>
              </div>
            )
          }}
        />
        <Controller
          control={control}
          name='isDepositRequired'
          render={({ field: { onChange, value } }) => {
            return (
              <div
                className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                onClick={() => onChange(!value)}
              >
                <FormLabel htmlFor='isDepositRequired'>
                  Is deposit required to book this service?
                </FormLabel>
                <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                  <ToggleOnly isChecked={value} />
                </div>
              </div>
            )
          }}
        />
        {watch('isDepositRequired') && (
          <>
            <div className='w-full flex items-center gap-x-4'>
              <SelectInput name="depositType" id="depositValue-type" label="How much deposit is required?" control={control} rules={{
                required: ERRORS.DEPOSIT_TYPE_REQUIRED,
              }} error={errors.depositType} options={PRICE_TYPE_REQUIREMENTS} placeholder="Select Requirement" />
              <Controller
                control={control}
                name="depositValue"
                rules={{ required: 'How much deposit is required for this service?' }}
                render={({ field, formState: { errors } }) => (
                  <div className='w-full flex flex-col mt-7'>
                    <div className="flex w-full">
                      <input
                        className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-tl-lg rounded-bl-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                        type="text"
                        {...field}
                        value={formatNumber(field.value)}
                        onChange={(e) => field.onChange(formatNumber(e.target.value))}
                        placeholder="10"
                      />
                      <div className="px-5 py-3 bg-grey-20 rounded-tr-lg rounded-br-lg text-grey-900">{typeSign}</div>
                    </div>
                    {errors?.depositValue?.message && (
                      <FormHelperText variant="error">
                        {errors.depositValue.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>
          </>
        )}
        <div className='space-y-6'>
          <Button
            variant='primary'
            className=''
            disabled={loading || deleteServiceisLoading || createCategoryLoading}
            loading={loading || deleteServiceisLoading || createCategoryLoading}
            size='lg'
            rounded='lg'
          >
            Save
          </Button>
          <Button
            variant='text'
            className={`mx-auto ${props?.service && 'text-red-500'}`}
            size='none'
            type='button'
            fontWeight="semiBold"
            disabled={loading || deleteServiceisLoading || createCategoryLoading}
            loading={loading || deleteServiceisLoading || createCategoryLoading}
            onClick={props?.service ? deleteServiceAsync : closeServiceModal}
          >
            {props?.service ? 'Delete' : 'Cancel'}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
