import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgShortKeyPreferences = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M40 30.592H0v.02h40v-.02ZM8.479 31.54H4.132v.02h4.347v-.02ZM20.08 31.106h-1.637v.02h1.637v-.02ZM14.503 31.106h-2.396v.02h2.396v-.02ZM34.313 31.337h-5.067v.02h5.067v-.02ZM19.138 31.337h-1.143v.02h1.143v-.02ZM10.32 31.54H9.253v.02h1.067v-.02ZM35.647 31.337h-.68v.02h.68v-.02ZM18.96 27.024H3.513a.457.457 0 0 1-.456-.457V4.853a.457.457 0 0 1 .456-.453H18.96a.457.457 0 0 1 .457.457v21.71a.456.456 0 0 1-.457.457ZM3.513 4.416a.437.437 0 0 0-.436.437v21.714a.437.437 0 0 0 .436.437H18.96a.438.438 0 0 0 .437-.437V4.853a.438.438 0 0 0-.437-.437H3.513ZM36.265 27.024H20.817a.458.458 0 0 1-.457-.457V4.853a.457.457 0 0 1 .457-.453h15.448a.457.457 0 0 1 .455.453v21.714a.457.457 0 0 1-.455.457ZM20.817 4.416a.438.438 0 0 0-.437.437v21.714a.438.438 0 0 0 .437.437h15.448a.438.438 0 0 0 .437-.437V4.853a.438.438 0 0 0-.437-.437H20.817Z"
      fill="#E6E6E6"
    />
    <path d="M32.973 6.845H15.142V17.21h17.83V6.845Z" fill="#E6E6E6" />
    <path d="M33.214 6.845H15.382V17.21h17.832V6.845Z" fill="#F5F5F5" />
    <path d="M32.78 16.723V7.331H15.816v9.392h16.966Z" fill="#fff" />
    <path d="M32.782 16.723V7.331h-.183v9.392h.183Z" fill="#E6E6E6" />
    <path d="M33.035 13.804H15.562v.653h17.473v-.653Z" fill="#F5F5F5" />
    <path d="M20.24 23.689h-2.928v6.492h2.928v-6.492Z" fill="#E6E6E6" />
    <path d="M30.763 30.18h-12.89v.412h12.89v-.411Z" fill="#E6E6E6" />
    <path d="M20.24 30.18h11.086V23.69H20.239v6.492Z" fill="#F5F5F5" />
    <path
      d="M21.391 26.617h8.784v-2.129h-8.784v2.129ZM21.391 29.38h8.784v-2.128h-8.784v2.129Z"
      fill="#E6E6E6"
    />
    <path
      d="M27.1 25.406h-2.634a.129.129 0 0 0-.129.13v.034c0 .07.058.128.13.128H27.1a.129.129 0 0 0 .129-.128v-.035a.129.129 0 0 0-.13-.129ZM27.1 28.171h-2.634a.129.129 0 0 0-.129.129v.034c0 .072.058.13.13.13H27.1a.129.129 0 0 0 .129-.13V28.3a.129.129 0 0 0-.13-.129Z"
      fill="#F5F5F5"
    />
    <path
      d="M11.47 30.592H5.233l-.86-20.694h6.237l.86 20.694Z"
      fill="#E6E6E6"
    />
    <path
      d="M12.373 30.592h-6.98l-.86-20.694h6.98l.86 20.694Z"
      fill="#F5F5F5"
    />
    <path d="m6.082 29.872-.8-19.254h5.541l.8 19.254h-5.54Z" fill="#fff" />
    <path
      d="M22.795 22.687c.08-2.185.16-5.213-.01-6.31l-.275-.213s.06.204.092 3.553c.032 3.349.071 2.473.037 2.97h.156Z"
      fill="#E0E0E0"
    />
    <path
      d="M23.308 15.447c.51-.692 1.585-1.184 2.697-1.181.437 0-1.3.804-2.004 1.692-.546.688-1.148 1.848-1.345 2.49.094-1.353.144-2.31.652-3Z"
      fill="#E0E0E0"
    />
    <path
      d="M22.284 17.25c-.469-.72-1.513-1.275-2.623-1.337-.436-.025 1.251.88 1.901 1.805.505.72 1.04 1.913 1.2 2.567-.017-1.357-.008-2.314-.478-3.035ZM22.494 14.292c-.427-.84-1.424-1.65-2.508-1.98-.427-.13 1.339 1.349 1.844 2.48.392.88.867 1.986.992 2.684.046-1.378.098-2.343-.328-3.184Z"
      fill="#E0E0E0"
    />
    <path
      d="M22.691 23.689a1.016 1.016 0 0 0 1.015-.96l.09-1.56h-2.209l.09 1.56a1.015 1.015 0 0 0 1.014.96Z"
      fill="#F0F0F0"
    />
    <path d="M21.445 21.525h2.493l.071-.647h-2.636l.072.647Z" fill="#EBEBEB" />
    <path d="M32.046 10.813h1.65v-4.48h-1.65v4.48Z" fill="#E6E6E6" />
    <path d="M32.045 6.334h-.818v4.12h.818v-4.12Z" fill="#F5F5F5" />
    <path d="M29.576 10.813h1.651v-4.48h-1.651v4.48Z" fill="#E6E6E6" />
    <path d="M29.576 6.334h-.818v4.12h.818v-4.12Z" fill="#F5F5F5" />
    <path d="M27.107 10.813h1.65v-4.48h-1.65v4.48Z" fill="#E6E6E6" />
    <path d="M27.107 6.334h-.819v4.12h.819v-4.12Z" fill="#F5F5F5" />
    <path d="M24.636 10.813h1.651v-4.48h-1.651v4.48Z" fill="#E6E6E6" />
    <path d="M24.636 6.334h-.819v4.12h.819v-4.12Z" fill="#F5F5F5" />
    <path d="M22.167 10.813h1.651v-4.48h-1.65v4.48Z" fill="#E6E6E6" />
    <path d="M22.166 6.334h-.818v4.12h.819v-4.12Z" fill="#F5F5F5" />
    <path d="M19.697 10.813h1.651v-4.48h-1.651v4.48Z" fill="#E6E6E6" />
    <path d="M19.697 6.334h-.819v4.12h.819v-4.12Z" fill="#F5F5F5" />
    <path d="M17.227 10.813h1.651v-4.48h-1.65v4.48Z" fill="#E6E6E6" />
    <path d="M17.227 6.334h-.818v4.12h.818v-4.12Z" fill="#F5F5F5" />
    <path d="M14.758 10.813h1.65v-4.48h-1.65v4.48Z" fill="#E6E6E6" />
    <path
      d="M20 34.205c8.567 0 15.511-.406 15.511-.906s-6.944-.905-15.511-.905-15.511.405-15.511.905 6.944.906 15.511.906Z"
      fill="#F5F5F5"
    />
    <path
      d="m28.193 17.562.34.52.506-.104-.382-.647-.464.231ZM29.157 13.106c.072.087.132.164.194.25.062.084.12.166.18.25a7.973 7.973 0 0 1 .624 1.073c.047.094.087.193.13.29.044.097.08.198.112.303l.025.08.013.04a.91.91 0 0 1 .037.324c-.01.132-.04.262-.086.387a3.122 3.122 0 0 1-.299.594 5.863 5.863 0 0 1-.778.96l-.35-.274c.193-.328.369-.665.527-1.011.073-.158.131-.322.174-.49a.718.718 0 0 0 .021-.194.096.096 0 0 0-.008-.04l-.013-.03-.027-.061a6.876 6.876 0 0 0-.528-.969c-.1-.16-.207-.314-.314-.47-.107-.156-.224-.312-.33-.454l.696-.558Z"
      fill="#9F5B53"
    />
    <path
      d="M28.96 12.712c.55.126 1.183 1.628 1.183 1.628l-1.103.758a9.33 9.33 0 0 1-.732-1.242c-.327-.687.092-1.273.652-1.144Z"
      fill="#16796F"
    />
    <path
      opacity={0.8}
      d="M28.96 12.712c.55.126 1.183 1.628 1.183 1.628l-1.103.758a9.33 9.33 0 0 1-.732-1.242c-.327-.687.092-1.273.652-1.144Z"
      fill="#fff"
    />
    <path
      d="m28.568 13.67.523 1.392-.052.036s-.226-.32-.471-.746v-.682Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="m28.568 13.67.523 1.392-.052.036s-.226-.32-.471-.746v-.682Z"
      fill="#fff"
    />
    <path
      d="M27.171 32.771h-.588l-.058-1.36h.588l.058 1.36ZM30.7 32.771h-.588l-.295-1.36h.587l.296 1.36Z"
      fill="#9F5B53"
    />
    <path
      d="m26.525 31.411.03.701h.588l-.03-.7h-.588ZM30.405 31.411h-.588l.152.701h.588l-.152-.7Z"
      fill="#8A4743"
    />
    <path
      d="M26.75 17.822s.668 4.492 1.033 6.174c.4 1.84 1.912 7.98 1.912 7.98h1.077s-.997-5.989-1.225-7.816c-.248-1.98-.768-6.34-.768-6.34l-2.029.002Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="M26.75 17.822s.668 4.492 1.033 6.174c.4 1.84 1.912 7.98 1.912 7.98h1.077s-.997-5.989-1.225-7.816c-.248-1.98-.768-6.34-.768-6.34l-2.029.002Z"
      fill="#000"
    />
    <path
      opacity={0.4}
      d="M27.172 19.422c.771.152.69 3.028.597 4.507-.222-1.046-.548-3.058-.77-4.486a.263.263 0 0 1 .173-.02Z"
      fill="#000"
    />
    <path
      d="M25.635 17.822s-.08 4.394-.017 6.187c.067 1.864.72 7.968.72 7.968h.995s.037-5.995.08-7.826c.048-1.996.29-6.329.29-6.329h-2.068Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="M25.635 17.822s-.08 4.394-.017 6.187c.067 1.864.72 7.968.72 7.968h.995s.037-5.995.08-7.826c.048-1.996.29-6.329.29-6.329h-2.068Z"
      fill="#000"
    />
    <path
      d="m29.087 17.258-.43.073.382.647s.3-.144.354-.424l-.306-.296Z"
      fill="#9F5B53"
    />
    <path
      d="M26.248 10.528c0 .047-.025.086-.056.086-.032 0-.056-.039-.056-.086 0-.047.024-.086.056-.086.03 0 .056.038.056.086Z"
      fill="#263238"
    />
    <path
      d="M26.227 10.614a1.706 1.706 0 0 1-.227.405.274.274 0 0 0 .227.043v-.448Z"
      fill="#892B29"
    />
    <path
      d="M26.416 10.266a.025.025 0 0 1-.016-.006.22.22 0 0 0-.199-.036.029.029 0 0 1-.02 0 .028.028 0 0 1-.01-.007.03.03 0 0 1-.007-.02c0-.003 0-.007.002-.01a.027.027 0 0 1 .015-.015.274.274 0 0 1 .258.05.027.027 0 0 1 0 .032.026.026 0 0 1-.022.012Z"
      fill="#263238"
    />
    <path
      d="M27.778 11.074c-.085.43-.169 1.218.132 1.505 0 0-.117.436-.917.436-.88 0-.419-.436-.419-.436.48-.114.467-.47.383-.804l.821-.7Z"
      fill="#9F5B53"
    />
    <path
      d="M28.025 12.729c.064-.138.132-.296-.01-.346-.16-.054-1.022-.11-1.32-.008a.307.307 0 0 0-.214.383l1.544-.03ZM29.876 32.8a.188.188 0 0 1-.141-.038.056.056 0 0 1 0-.067.064.064 0 0 1 .043-.033c.087-.019.248.092.255.096a.014.014 0 0 1 .005.007.014.014 0 0 1 .001.008.012.012 0 0 1-.004.007.747.747 0 0 1-.16.02Zm-.073-.112h-.018a.037.037 0 0 0-.025.02c-.013.022-.005.031 0 .034.025.034.145.034.233.02a.46.46 0 0 0-.19-.072v-.002Z"
      fill="#16796F"
    />
    <path
      d="M30.026 32.786h-.006c-.061-.034-.179-.16-.167-.228a.046.046 0 0 1 .045-.036.08.08 0 0 1 .06.017c.067.056.08.226.08.233 0 .003 0 .005-.002.007a.013.013 0 0 1-.005.006h-.005Zm-.118-.24H29.9c-.018 0-.02.01-.02.014-.008.04.068.136.128.182a.337.337 0 0 0-.07-.184.05.05 0 0 0-.031-.01v-.002ZM26.347 32.8c-.075 0-.148-.01-.173-.044a.054.054 0 0 1 0-.06.066.066 0 0 1 .044-.034c.097-.026.304.09.313.096a.014.014 0 0 1 .006.014.012.012 0 0 1-.004.008.97.97 0 0 1-.186.02Zm-.093-.114a.09.09 0 0 0-.027 0 .041.041 0 0 0-.027.02c-.01.017-.006.025 0 .03.027.037.175.04.286.024a.63.63 0 0 0-.232-.072v-.002Z"
      fill="#16796F"
    />
    <path
      d="M26.525 32.786h-.006c-.071-.032-.211-.16-.2-.225 0-.016.013-.035.051-.04a.096.096 0 0 1 .08.025c.076.06.09.219.092.226l-.001.007a.014.014 0 0 1-.005.006.015.015 0 0 1-.006.001h-.005Zm-.143-.24h-.01c-.024 0-.026.012-.026.016-.007.04.09.14.16.183a.32.32 0 0 0-.08-.181.069.069 0 0 0-.044-.017v-.001Z"
      fill="#16796F"
    />
    <path
      d="M30.07 32.703h.66a.052.052 0 0 1 .05.041l.116.522a.086.086 0 0 1-.086.104c-.23-.004-.342-.017-.633-.017-.178 0-.546.019-.793.019s-.279-.24-.178-.266c.453-.1.623-.236.769-.366a.14.14 0 0 1 .095-.037ZM26.587 32.703h.64a.052.052 0 0 1 .05.041l.116.522a.086.086 0 0 1-.017.073.085.085 0 0 1-.067.031c-.232-.004-.566-.017-.857-.017-.34 0-.635.019-1.034.019-.24 0-.308-.24-.207-.266.46-.101.835-.112 1.233-.356a.268.268 0 0 1 .143-.047Z"
      fill="#263238"
    />
    <path
      d="M25.748 13.914c-.233.344-.474.665-.734.984-.26.321-.537.628-.832.918l-.115.109-.03.027-.02.018-.03.026a.884.884 0 0 1-.12.08 1.077 1.077 0 0 1-.436.122 2.426 2.426 0 0 1-.693-.057 5.261 5.261 0 0 1-1.188-.418l.147-.416c.375.075.754.126 1.135.155.167.013.336.006.502-.021a.321.321 0 0 0 .132-.051v-.006l.006-.006.024-.027.094-.104c.25-.285.493-.589.728-.9.235-.311.47-.628.695-.939l.735.506Z"
      fill="#9F5B53"
    />
    <path
      d="M26.019 14.006a2.789 2.789 0 0 1-.62 1.346 15.55 15.55 0 0 1-.018-.76l.638-.586Z"
      fill="#BED3FC"
    />
    <path
      d="M27.829 10.427c-.036.583-.027.925-.32 1.224-.443.45-1.161.183-1.3-.4-.125-.523-.046-1.387.524-1.619a.795.795 0 0 1 1.096.795Z"
      fill="#9F5B53"
    />
    <path
      d="M26.698 10.04c.004.685.227 1.525.89 1.466.44-.038.773-.84.82-1.18.069-.52.08-.62-.214-.68.128-.07.175-.4-.124-.48-.416-.11-1.2-.216-.954-.47-.491.089-.614.286-.525.525-.287-.023-.464-.173-.455-.4-.476.193-.785 1.283.562 1.219Z"
      fill="#263238"
    />
    <path
      d="M27.04 10.597a.659.659 0 0 1-.223.36c-.148.12-.266.013-.263-.16 0-.156.085-.407.252-.46.166-.052.275.09.234.26Z"
      fill="#9F5B53"
    />
    <path
      d="M28.64 9.766a.56.56 0 0 0-.53-.039s.332-.447.53.039Z"
      fill="#263238"
    />
    <path
      d="M29.52 31.58c-.005 0 .11.414.11.414h1.212l-.022-.37-1.3-.044ZM26.145 31.58c-.005 0 .056.414.056.414h1.211l.033-.37-1.3-.044ZM26.543 31.262a.01.01 0 0 1-.006-.003.01.01 0 0 1-.003-.006c-.176-1.69-.59-5.778-.64-7.244-.054-1.497-.005-4.855.01-5.849 0-.006.006-.009.011-.01a.01.01 0 0 1 .01.01c-.016.994-.065 4.352-.011 5.848.052 1.466.465 5.553.64 7.242a.011.011 0 0 1-.01.012Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="M26.543 31.262a.01.01 0 0 1-.006-.003.01.01 0 0 1-.003-.006c-.176-1.69-.59-5.778-.64-7.244-.054-1.497-.005-4.855.01-5.849 0-.006.006-.009.011-.01a.01.01 0 0 1 .01.01c-.016.994-.065 4.352-.011 5.848.052 1.466.465 5.553.64 7.242a.011.011 0 0 1-.01.012Z"
      fill="#000"
    />
    <path
      d="M26.29 13.218c.08.56-1.019 1.979-1.019 1.979l-1.102-.759s.306-.783.82-1.296c.588-.582 1.216-.53 1.301.076Z"
      fill="#16796F"
    />
    <path
      opacity={0.8}
      d="M26.29 13.218c.08.56-1.019 1.979-1.019 1.979l-1.102-.759s.306-.783.82-1.296c.588-.582 1.216-.53 1.301.076Z"
      fill="#fff"
    />
    <path
      d="M25.67 12.742s-.626.707-.035 5.08h3.144c.021-.48-.282-2.836.183-5.11a8.197 8.197 0 0 0-1.052-.133 11.63 11.63 0 0 0-1.336 0c-.305.031-.607.085-.904.163Z"
      fill="#16796F"
    />
    <path
      opacity={0.8}
      d="M25.67 12.742s-.626.707-.035 5.08h3.144c.021-.48-.282-2.836.183-5.11a8.197 8.197 0 0 0-1.052-.133 11.63 11.63 0 0 0-1.336 0c-.305.031-.607.085-.904.163Z"
      fill="#fff"
    />
    <path
      d="M16.42 9.532a2.054 2.054 0 1 0-4.005-.91 2.054 2.054 0 0 0 4.006.91Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M16.42 9.532a2.054 2.054 0 1 0-4.005-.91 2.054 2.054 0 0 0 4.006.91Z"
      fill="#fff"
    />
    <path
      d="M14.207 11.74a2.66 2.66 0 0 1-2.335-1.864l.076-.024a2.58 2.58 0 0 0 2.266 1.808l-.007.08Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M14.207 11.74a2.66 2.66 0 0 1-2.335-1.864l.076-.024a2.58 2.58 0 0 0 2.266 1.808l-.007.08Z"
      fill="#fff"
    />
    <path
      d="m11.937 8.338-.08-.023a2.672 2.672 0 0 1 3.426-1.76l-.026.076a2.592 2.592 0 0 0-3.324 1.71l.004-.003Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="m11.937 8.338-.08-.023a2.672 2.672 0 0 1 3.426-1.76l-.026.076a2.592 2.592 0 0 0-3.324 1.71l.004-.003Z"
      fill="#fff"
    />
    <path
      d="M16.892 8.303a2.584 2.584 0 0 0-1.12-1.434l.041-.069c.551.337.96.863 1.154 1.48l-.075.023Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M16.892 8.303a2.584 2.584 0 0 0-1.12-1.434l.041-.069c.551.337.96.863 1.154 1.48l-.075.023Z"
      fill="#fff"
    />
    <path
      d="M16.422 15.2a2.054 2.054 0 1 0-4.006-.909 2.054 2.054 0 0 0 4.006.91Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M16.422 15.2a2.054 2.054 0 1 0-4.006-.909 2.054 2.054 0 0 0 4.006.91Z"
      fill="#fff"
    />
    <path
      d="M16.421 20.869a2.054 2.054 0 1 0-4.005-.91 2.054 2.054 0 0 0 4.005.91Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M16.421 20.869a2.054 2.054 0 1 0-4.005-.91 2.054 2.054 0 0 0 4.005.91Z"
      fill="#fff"
    />
    <path
      d="M16.421 26.538a2.054 2.054 0 1 0-4.005-.909 2.054 2.054 0 0 0 4.005.91Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M16.421 26.538a2.054 2.054 0 1 0-4.005-.909 2.054 2.054 0 0 0 4.005.91Z"
      fill="#fff"
    />
    <path
      d="M10.752 9.53a2.054 2.054 0 1 0-4.005-.908 2.054 2.054 0 0 0 4.005.909Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M10.752 9.53a2.054 2.054 0 1 0-4.005-.908 2.054 2.054 0 0 0 4.005.909Z"
      fill="#fff"
    />
    <path
      d="M10.753 15.2a2.053 2.053 0 1 0-4.005-.909 2.053 2.053 0 0 0 4.005.91Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M10.753 15.2a2.053 2.053 0 1 0-4.005-.909 2.053 2.053 0 0 0 4.005.91Z"
      fill="#fff"
    />
    <path
      d="M10.753 20.869a2.054 2.054 0 1 0-4.005-.91 2.054 2.054 0 0 0 4.005.91Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M10.753 20.869a2.054 2.054 0 1 0-4.005-.91 2.054 2.054 0 0 0 4.005.91Z"
      fill="#fff"
    />
    <path
      d="M10.753 26.538a2.054 2.054 0 1 0-4.005-.91 2.054 2.054 0 0 0 4.005.91Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M10.753 26.538a2.054 2.054 0 1 0-4.005-.91 2.054 2.054 0 0 0 4.005.91Z"
      fill="#fff"
    />
    <path
      d="m21.776 15.35-.431-.446-.46.594s.35.337.767.24l.124-.388Z"
      fill="#9F5B53"
    />
    <path d="m20.752 14.66-.197.622.33.216.46-.594-.593-.245Z" fill="#9F5B53" />
    <path
      d="M14.88 14.693a.775.775 0 0 1 1.091-.44l-.48-.25a.792.792 0 0 1-.376-1.006.776.776 0 0 1 1.067-.378l-.48-.249a.792.792 0 0 1-.376-1.007.774.774 0 0 1 1.074-.377l-2.28-1.192a.789.789 0 0 1-.4-.941.776.776 0 0 1 1.093-.44l4.8 2.51L19.12 8.9a.825.825 0 0 1 1.57-.492l1.575 4.09c.134.35.392.638.724.812l.274.143a.359.359 0 0 1 .152.487l-1.92 3.668a.362.362 0 0 1-.487.153l-.674-.352a1.582 1.582 0 0 0-.827-.171 3.84 3.84 0 0 1-1.995-.432l-2.24-1.172a.789.789 0 0 1-.392-.941Z"
      fill="#16796F"
    />
    <path
      d="M21.18 17.92a.48.48 0 0 1-.223-.054l-.674-.352a1.48 1.48 0 0 0-.765-.16 3.962 3.962 0 0 1-2.057-.445l-2.24-1.171a.911.911 0 0 1-.454-1.087.89.89 0 0 1 .615-.577.91.91 0 0 1-.373-1.128.88.88 0 0 1 .587-.506.908.908 0 0 1-.373-1.128.879.879 0 0 1 .583-.504l-1.74-.91a.91.91 0 0 1-.454-1.087.896.896 0 0 1 1.26-.508l4.568 2.39-.431-1.765a.944.944 0 0 1 1.798-.56l1.575 4.09c.124.322.361.588.667.748l.274.144a.479.479 0 0 1 .203.648l-1.92 3.67a.48.48 0 0 1-.427.252Zm-1.571-.807c.273-.002.542.063.785.188l.674.352a.24.24 0 0 0 .325-.102l1.92-3.669a.24.24 0 0 0-.102-.324l-.273-.144a1.658 1.658 0 0 1-.778-.873l-1.575-4.09a.704.704 0 0 0-1.342.42l.495 2.023a.12.12 0 0 1-.172.135l-4.8-2.51a.654.654 0 0 0-.923.374.67.67 0 0 0 .338.8l2.28 1.192a.12.12 0 1 1-.111.213.655.655 0 0 0-.903.318.671.671 0 0 0 .32.853l.48.25a.12.12 0 0 1-.111.211.655.655 0 0 0-.902.32.67.67 0 0 0 .32.852l.48.25a.12.12 0 1 1-.11.212.656.656 0 0 0-.922.374.67.67 0 0 0 .337.8l2.24 1.172a3.73 3.73 0 0 0 1.926.41c.035 0 .07-.006.104-.006v-.001Z"
      fill="#16796F"
    />
    <path
      d="M16.57 14.686a.118.118 0 0 1-.056-.014l-.594-.313a.12.12 0 0 1-.05-.162.12.12 0 0 1 .161-.05l.598.312a.12.12 0 0 1 .06.135.12.12 0 0 1-.116.092h-.003ZM16.785 13.052a.118.118 0 0 1-.056-.014l-.598-.312a.122.122 0 0 1-.065-.07.12.12 0 0 1 .13-.16.12.12 0 0 1 .046.018l.598.312a.12.12 0 0 1 .019.2.12.12 0 0 1-.074.026ZM16.998 11.42a.125.125 0 0 1-.055-.014l-.597-.313a.12.12 0 0 1-.051-.162.12.12 0 0 1 .162-.051l.598.313a.12.12 0 0 1-.056.226Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="M21.18 17.92a.48.48 0 0 1-.223-.054l-.674-.352a1.48 1.48 0 0 0-.765-.16 3.962 3.962 0 0 1-2.057-.445l-2.24-1.171a.911.911 0 0 1-.454-1.087.89.89 0 0 1 .615-.577.91.91 0 0 1-.373-1.128.88.88 0 0 1 .587-.506.908.908 0 0 1-.373-1.128.879.879 0 0 1 .583-.504l-1.74-.91a.91.91 0 0 1-.454-1.087.896.896 0 0 1 1.26-.508l4.568 2.39-.431-1.765a.944.944 0 0 1 1.798-.56l1.575 4.09c.124.322.361.588.667.748l.274.144a.479.479 0 0 1 .203.648l-1.92 3.67a.48.48 0 0 1-.427.252Zm-1.571-.807c.273-.002.542.063.785.188l.674.352a.24.24 0 0 0 .325-.102l1.92-3.669a.24.24 0 0 0-.102-.324l-.273-.144a1.658 1.658 0 0 1-.778-.873l-1.575-4.09a.704.704 0 0 0-1.342.42l.495 2.023a.12.12 0 0 1-.172.135l-4.8-2.51a.654.654 0 0 0-.923.374.67.67 0 0 0 .338.8l2.28 1.192a.12.12 0 1 1-.111.213.655.655 0 0 0-.903.318.671.671 0 0 0 .32.853l.48.25a.12.12 0 0 1-.111.211.655.655 0 0 0-.902.32.67.67 0 0 0 .32.852l.48.25a.12.12 0 1 1-.11.212.656.656 0 0 0-.922.374.67.67 0 0 0 .337.8l2.24 1.172a3.73 3.73 0 0 0 1.926.41c.035 0 .07-.006.104-.006v-.001Z"
      fill="#000"
    />
  </svg>
);
export default SvgShortKeyPreferences;
