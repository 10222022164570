import { Controller, useForm } from "react-hook-form";
import { Button, Checkbox, FormHelperText, FormLabel, FormPhone, Input, Modal, Paragraph, SelectInput, ToastProps, ToastWrapper } from "../../ui";
import { REGEX_PATTERNS } from "constants/pattern";
import { NIGERIA_STATES, PROCESSING_FEE, YES_OR_NO_SETTINGS } from "constants/information";
import { API_ERRORS, ERRORS } from "constants/errors";
import React, { useEffect, useMemo, useState } from 'react'
import axios from "axios";
import { formatNumber, sanitizePhoneNumber } from "../utils/misc";
import { PRICE_TYPE_REQUIREMENTS } from "./accountSetupComponents/constants";
import { DEPOSIT_DURATION_INTERVAL } from "constants/form";
import { getHelperTextForReactHookFormErrors } from "../utils/form";
import { COLORS } from "constants/colors";
import { TRANSFERABLE_MODULES_OPTIONS } from "./constants";
import { BusinessLocationInput, NewLocationModalProps } from "./types";
import { DuplicateSalonDocument, Salon } from "core/generated";
import { useSalonCache } from "hooks/useSalonCache";
import { useDuplicateSalon } from "api/useSalon";
import { useModal, useToast } from "hooks";
import NewLocationSuccessModal from "components/modals/NewLocationSuccessModal";
import SingleSelect from "ui/molecules/singleSelect/SingleSelect";
import { useGooglePlaceSearch } from "api/useAccount";

const NewLocation = () => {
  const {
    isVisible: successModalIsVisible,
    openModal: openSuccessModal,
    closeModal: closeSuccessModal,
  } = useModal();
  const { toast, addToast } = useToast();
  const { getSalonData } = useSalonCache();
  const salon: Salon = getSalonData();
  const [locationSearchTerm, setLocationSearchTerm] = useState('')
  const [checkedModules, setCheckedModules] = useState<string[]>([]);
  const { loading: creatingNewSalon, duplicateSalon } = useDuplicateSalon()
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<BusinessLocationInput>({
    defaultValues: {
      locationSetupHome: false,
      locationSetupSalon: true,
      taxInclusivePricing: true,
      isDepositRequired: false,
      isTaxVisible: true,
      bearsProcessingFee: false,
    },
  });
  const [countryCode, setCountryCode] = useState("");
  const [callingCode, setCallingCode] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [typeSign, setTypeSign] = useState<string | null>("-")

  useEffect(() => {
    if (salon) {
      setValue('bearsProcessingFee', salon?.bearsProcessingFee)
      setValue('isTaxVisible', salon?.isTaxVisible)
      setValue('depositType', salon?.depositType)
      setValue('depositValue', salon?.depositValue)
      setValue('depositLinkValidityHours', salon?.depositLinkValidityHours)
      setValue('isDepositRequired', salon?.isDepositRequired)
      setValue('taxInclusivePricing', salon?.taxInclusivePricing)
    }
  }, [])

  const submitData = async (input: BusinessLocationInput) => {
    let locationType;
    if (input.locationSetupSalon) {
      locationType = 'salon';
    }
    if (input.locationSetupHome) {
      locationType = 'home'
    }

    if (input.locationSetupSalon && input.locationSetupHome) {
      locationType = 'flexible'
    }
    try {
      const inputData = {
        branchName: input.branchName,
        locationType,
        city: '',
        salonId: salon?.id,
        // @ts-expect-error error expected
        address: input?.address?.value,
        state: '',
        taxInclusivePricing: input.taxInclusivePricing,
        isTaxVisible: input?.isTaxVisible || false,
        bearsProcessingFee: input?.bearsProcessingFee,
        copyItems: checkedModules,
        email: input?.email,
        phone: input?.phone,
        callingCode,
        depositType: input.depositType ? input.depositType : null,
        depositValue: input.depositValue ? Number(input?.depositValue?.toString()?.replace(/,/g, '')) : 0,
        isDepositRequired: input.isDepositRequired,
        depositLinkValidityHours: input?.depositLinkValidityHours || 0,
      }

      duplicateSalon({
        variables: {
          input: inputData
        }
      }).then(({ data }) => {

        if (data?.duplicateSalon.salon) {
          addToast({
            message: "New location created successfully",
            variant: "success"
          })
          openSuccessModal()
        }

        if (data?.duplicateSalon.errors?.length) {
          addToast({
            message: data?.duplicateSalon.errors[0].message || API_ERRORS.CREATE_SALON_FAILED,
            variant: 'error',
          })
        }
      })
    } catch (error) {
      const message = error?.message || API_ERRORS.CREATE_SALON_FAILED;
      addToast({
        message,
        variant: "error",
      });
    }
  }

  const handleCheckboxChange = (value: string) => {
    const updatedModules = checkedModules.includes(value)
      ? checkedModules.filter((module: string) => module !== value)
      : [...checkedModules, value];
    setCheckedModules(updatedModules);
  };

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode);
    setValue('phone', _phoneNumber)
  }

  const initiateCloseSuccessModal = () => {
    closeSuccessModal()
    setTimeout(() => {
      // Your code to execute after 10 seconds
      window.location.reload();
    }, 1000); // 10000 milliseconds = 10 seconds
  }

  const searchLocation = (e) => {
    setLocationSearchTerm(e)
  }

  const {
    data: googlePlacesSearchData,
    loading: googlePlacesSearchLoading,
    error: googlePlacesSearchError,
    refetch: googlePlacesSearchRefetch
  } = useGooglePlaceSearch({
    q: locationSearchTerm
  })

  const locationOptions = useMemo(() => {
    if (googlePlacesSearchData?.googlePlacesSearch) {
      return googlePlacesSearchData?.googlePlacesSearch.map((prediction) => {
        return {
          label: prediction.label,
          value: prediction?.value
        }
      })
    }
    return []
  }, [googlePlacesSearchData])

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      if (locationSearchTerm) {
        googlePlacesSearchRefetch()
      }
    }, 500)

    return () => clearTimeout(delaySearch)
  }, [locationSearchTerm])
  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <form onSubmit={handleSubmit(submitData)} className="w-full space-y-6" autoComplete='off'>
        <Input name="branchName" id="branch-name" label="Branch name" type="text" placeholder='Enter branch name here' control={control} rules={{
          pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH,
          required: ERRORS.BUSINESS_BRANCH_NAME_REQUIRED,
        }} error={errors.branchName} />
        <div className='w-full flex flex-col space-y-2'>
          <Controller
            control={control}
            name='address'
            render={({ field: { onChange, value }, formState: { errors } }) => {
              const errorMessage: string = errors?.address?.message

              return (
                <>
                  <FormLabel htmlFor='client'>Business Address</FormLabel>
                  <SingleSelect
                    // @ts-expect-error exprect error
                    selected={value || []}
                    options={locationOptions as []}
                    setSelected={onChange}
                    isLoading={googlePlacesSearchLoading}
                    onInputChange={searchLocation}
                    placeholder='Enter address'
                  />
                  {errorMessage && (
                    <FormHelperText variant='error'>
                      {errorMessage}
                    </FormHelperText>
                  )}
                </>
              )
            }}
          />
        </div>
        <div className='flex flex-col space-y-4'>
          <Paragraph size='b4'>Where do you serve clients?</Paragraph>
          <div className='flex space-x-4'>
            <Controller
              control={control}
              name="locationSetupSalon"
              render={({ field: { onChange, value } }) => {
                return (
                  <div
                    className="flex"
                    onClick={() => onChange(!value)}
                  >
                    <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                      <Checkbox
                        isChecked={value}
                      />
                      <span className="text-grey-900 whitespace-nowrap">
                        Business Address
                      </span>
                    </div>
                  </div>
                );
              }}
            />
            <Controller
              control={control}
              name="locationSetupHome"
              render={({ field: { onChange, value } }) => {
                return (
                  <div
                    className="flex"
                    onClick={() => onChange(!value)}
                  >
                    <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                      <Checkbox
                        isChecked={value}
                      />
                      <span className="text-grey-900 whitespace-nowrap">
                        Home Service
                      </span>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <Controller
          control={control}
          name="taxInclusivePricing"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <label className="text-b5 xl:text-b4 text-black dark:text-grey-500 font-normal w-full block subpixel-antialiased">
                  Are your display prices inclusive of VAT?
                </label>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) && YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS
                      .map((taxSetting: {
                        label: string;
                        value: boolean;
                      }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(taxSetting?.value)}
                            key={taxSetting?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === taxSetting?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {taxSetting?.label}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name="isTaxVisible"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <FormLabel htmlFor='isTaxVisible'>
                  Do you want to display tax in receipt?
                </FormLabel>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) && YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS
                      .map((taxSetting: {
                        label: string;
                        value: boolean;
                      }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(taxSetting?.value)}
                            key={taxSetting?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === taxSetting?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {taxSetting?.label}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name="bearsProcessingFee"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <FormLabel htmlFor='locationSetupSalon'>Do you want to bear the {PROCESSING_FEE}% online payment processing fee?</FormLabel>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) && YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS
                      .map((feeSetting: {
                        label: string;
                        value: boolean;
                      }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(feeSetting?.value)}
                            key={feeSetting?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === feeSetting?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {feeSetting?.label}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            );
          }}
        />
        <Controller
          control={control}
          name="isDepositRequired"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <FormLabel htmlFor='isDepositRequired'>Is payment required for booking?</FormLabel>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) && YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS
                      .map((isDepositRequired: {
                        label: string;
                        value: boolean;
                      }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(isDepositRequired?.value)}
                            key={isDepositRequired?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === isDepositRequired?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {isDepositRequired?.label}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            );
          }}
        />
        {watch('isDepositRequired') && (
          <>
            <div className='w-full flex items-center gap-x-4'>
              <SelectInput name="depositType" id="depositValue-type" label="How much deposit is required?" control={control} rules={{
                required: ERRORS.DEPOSIT_TYPE_REQUIRED,
              }} value={watch('depositType')} error={errors.depositType} options={PRICE_TYPE_REQUIREMENTS} placeholder="Select Requirement" />
              <Controller
                control={control}
                name="depositValue"
                rules={{ required: 'How much deposit is required?' }}
                render={({ field, formState: { errors } }) => (
                  <div className='w-full flex flex-col mt-7'>
                    <div className="flex w-full">
                      <input
                        className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-tl-lg rounded-bl-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                        type="text"
                        {...field}
                        value={formatNumber(field.value)}
                        onChange={(e) => field.onChange(formatNumber(e.target.value))}
                        placeholder="Enter percentage"
                      />
                      <div className="px-5 py-3 bg-grey-20 rounded-tr-lg rounded-br-lg text-grey-900">{typeSign}</div>
                    </div>
                    {errors?.depositValue?.message && (
                      <FormHelperText variant="error">
                        {errors.depositValue.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>

            <SelectInput name="depositLinkValidityHours" id="depositLinkValidityHours" label="Select Payment Link Duration" control={control} rules={{
              required: ERRORS.DEPOSIT_INTERVAL_REQUIRED,
            }} value={watch('depositLinkValidityHours')} error={errors.depositLinkValidityHours} options={DEPOSIT_DURATION_INTERVAL} placeholder="Select Duration" />
          </>
        )}
        <div>
          <Paragraph size='b4' color={COLORS.GREY[300]} weight="bold" className='pb-3'>Contact information</Paragraph>
          <Input name="email" label="Contact Email" id="email" type="email" placeholder='email@acme.com' control={control} rules={{
            pattern: REGEX_PATTERNS.EMAIL,
          }} error={errors.email} />
        </div>
        <FormPhone
          country={{
            disabled: false,
            onSelect: (code, country: string) => {
              setCallingCode(code);
              setCountryCode(country);
            },
            value: countryCode,
          }}
          phone={{
            name: "phone",
            type: "text",
            placeholder: "9151930463",
            rules: {
              required: ERRORS.PHONE_REQUIRED,
              pattern: REGEX_PATTERNS.NUMBER,
              onChange: (e) => {
                _sanitizePhoneNumber(e.target.value);
              },
              disabled: false,
            },
            register,
            id: "phone-number",
            label: "Contact Phone Number",
          }}
          helperText={getHelperTextForReactHookFormErrors(
            errors?.phone?.message as string
          )}
        />
        <div className='flex flex-col space-y-4'>
          <Paragraph size='b5'>Transfer items from parent salon</Paragraph>
          <div className='flex flex-wrap gap-4'>
            {TRANSFERABLE_MODULES_OPTIONS.map((option) => (
              <div className="relative flex items-center cursor-pointer text-b5 text-grey-900 space-x-2" key={option.value} onClick={() => handleCheckboxChange(option.value)}>
                <Checkbox
                  isChecked={checkedModules.includes(option.value)}
                />
                <input
                  type="checkbox"
                  className="absolute left-0 w-[24px] h-[24px] opacity-0 cursor-pointer" value={option.value} />
                <span className="text-grey-900 whitespace-nowrap">
                  {option.label}
                </span>
              </div>
            ))}
          </div>
        </div>
        <Button
          variant='primary'
          className=''
          disabled={creatingNewSalon}
          loading={creatingNewSalon}
          size='lg'
          rounded='lg'
        >
          Save
        </Button>
      </form>

      <NewLocationSuccessModal
        isVisible={successModalIsVisible}
        closeModal={initiateCloseSuccessModal}
      />
    </>
  )
}

export default NewLocation