import { COLORS } from 'constants/colors'
import React, { useMemo, useState } from 'react'
import { Button, Checkbox, FormLabel, Heading, Modal, MultiSelectOption, Paragraph, RadioButton, SvgFormsCuate, ToastProps, ToastWrapper } from 'ui'
import { useParams } from 'react-router-dom'
import { useGetPublicForm, useSubmitAnswers } from 'api/useForms'
import { FormFlowAnswers, FormSectionPublicAnswers, PublicWorkFlowForm, WorkFlowForm } from 'modules/settings/types'
import { useToast } from 'hooks'
import axios from 'axios'
import SingleSelect from 'ui/molecules/singleSelect/SingleSelect'
import { transformSectionsToAnswers } from 'modules/public/utils'
import { Business } from 'core/generated'
import FormProgressBar from 'modules/public/components/FormProgressBar'
import { BookingPersonalInformationFormInput } from '../types'
import { cleanUpText } from 'components/utils/misc'

const BookingSiteWorkFlow = ({ workFlowData, businessInfo, addToast, isWorkFlowFormModalVisible, closeModal, serviceClient, setFormAnswers }: {
  workFlowData: PublicWorkFlowForm
  businessInfo: Business
  addToast: (toast: ToastProps) => void
  isWorkFlowFormModalVisible: boolean
  closeModal: () => void
  serviceClient: BookingPersonalInformationFormInput
  setFormAnswers: (answers: FormFlowAnswers | null) => void
}) => {
  const submitAnswers = useSubmitAnswers()
  const [isFormSubmitted, setIsSubmitted] = useState(false)
  const formId = workFlowData?.id as string;
  const [answers, setAnswers] = useState<FormSectionPublicAnswers[]>([])
  const [step, setStep] = useState<number>(1);
  const getDefaultAnswer = (key: string) => {
    if (serviceClient && key) {
      const fullName = serviceClient?.fullName;
      // spread into firstname and lastname
      const [firstName, lastName] = fullName?.split(' ') || []
      // convert key snake_case to camelCase
      const camelCaseKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase())
      if (serviceClient[camelCaseKey as keyof BookingPersonalInformationFormInput]) {
        return serviceClient[camelCaseKey as keyof BookingPersonalInformationFormInput] as string
      } else if (camelCaseKey === 'firstName') {
        return firstName
      } else if (camelCaseKey === 'lastName') {
        return lastName
      } else {
        return ''
      }
    }

    return ''
  }
  const formDetails = useMemo(() => {
    // convert each sections to FormSectionPublicAnswers
    const sections = workFlowData?.sections?.map((section) => {
      const answers = section?.inputs?.map((question) => {
        return {
          question: question?.key,
          isRequired: question?.is_required,
          answer: getDefaultAnswer(question?.key)
        }
      })
      return {
        sectionKey: section?.title,
        answers
      }
    })
    setAnswers(sections)
    return workFlowData
  }, [workFlowData])
  const steps = formDetails?.sections?.length;

  const currentSection = formDetails?.sections?.find((section, index: number) => index + 1 === step);

  const onChangeAnswer = (questionIndex: number, value: string) => {
    // continue
    const newAnswers = [...answers]
    const currentSectionAnswers = newAnswers[step - 1]?.answers
    const newCurrentSectionAnswers = currentSectionAnswers?.map((answer, index) => {
      if (index === questionIndex) {
        return {
          ...answer,
          answer: value
        }
      }
      return answer
    })
    newAnswers[step - 1].answers = newCurrentSectionAnswers
    setAnswers(newAnswers)
  }

  const getAnswer = (questionIndex: number) => {
    if (!answers?.length) return ''
    const currentSectionAnswers = answers[step - 1]?.answers
    const answer = currentSectionAnswers?.find((answer, index) => index === questionIndex)
    return answer?.answer
  }

  const getSelectOptionAnswer = (questionIndex: number) => {
    if (!answers?.length) return []
    const currentSectionAnswers = answers[step - 1]?.answers
    const answer = currentSectionAnswers?.find((answer, index) => index === questionIndex)
    if (answer?.answer) {
      return [{
        label: answer?.answer,
        value: answer?.answer
      }] as unknown as MultiSelectOption[]
    }
    return []
  }

  const onChangeOptions = (questionIndex: number) => (value: MultiSelectOption) => {
    const newAnswers = [...answers]
    const currentSectionAnswers = newAnswers[step - 1]?.answers
    const newCurrentSectionAnswers = currentSectionAnswers?.map((answer, index) => {
      if (index === questionIndex) {
        return {
          ...answer,
          answer: value?.value
        }
      }
      return answer
    })
    newAnswers[step - 1].answers = newCurrentSectionAnswers
    setAnswers(newAnswers)
  }

  const handleSubmit = async () => {
    // submit
    try {
      const _answers = transformSectionsToAnswers(answers)
      // validation
      // loop through answers and check if all required fields are filled
      const requiredFieldsUnAttededTo = answers?.map((input) => {
        return input?.answers?.filter((field) => field?.isRequired && field?.answer === '')
      })
      const invalidFields = Array?.isArray(requiredFieldsUnAttededTo) && requiredFieldsUnAttededTo?.length ? requiredFieldsUnAttededTo.flat() : null
      if (invalidFields && Array?.isArray(invalidFields) && invalidFields?.length) {
        addToast({
          variant: 'error',
          message: 'Please fill all required fields'
        })
        return;
      }
      setFormAnswers(_answers)
      setIsSubmitted(true)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error?.response?.data?.message;
        addToast({
          variant: "error",
          message,
        });
      }
    }
  }

  const handleContinue = () => {
    // fetch answers in step
    const stepAnswer = answers?.find((ans, index) => index === step - 1);
    const requiredFieldsUnAttededTo = stepAnswer?.answers?.filter((field) => field?.isRequired && field?.answer === '')
    const invalidFields = Array?.isArray(requiredFieldsUnAttededTo) && requiredFieldsUnAttededTo?.length ? requiredFieldsUnAttededTo : null
    if (invalidFields && Array?.isArray(invalidFields) && invalidFields?.length) {
      const fieldNames = invalidFields.map(field => cleanUpText(field?.question) || '').join(', ');
      addToast({
        variant: 'error',
        message: `Please fill all required fields: ${fieldNames}`
      });
      return;
    }
    setStep(step + 1)
  }

  const handlePrev = () => {
    setStep(step - 1)
  }

  const convertOptionsToMultiSelect = (options: string[]) => {
    return options?.map((option) => {
      return {
        label: option,
        value: option
      }
    })
  }

  return (
    <Modal
      variant='right'
      show={isWorkFlowFormModalVisible}
      closeModal={null}
    >
      <div className='w-full min-h-screen bg-[#F3F6F6] flex flex-col'>
        <div className='w-full flex flex-col items-center py-6'>
          {businessInfo?.logoUrl ? (
            <img
              src={businessInfo?.logoUrl}
              alt='business logo'
              className='w-full max-w-[200px]'
              loading='lazy'
            />
          ) : (
            <span className='uppercase text-grey-300 text-b2 line-clamp-2'>{businessInfo?.name}</span>
          )}
        </div>

        <div className='w-full bg-white flex flex-col space-y-6 pb-6'>
          {!isFormSubmitted ?
            <div className='w-full flex flex-col-reverse'>
              <div className='w-full flex flex-col px-12 py-6 border border-grey-100/70'>
                <Heading
                  variant="h1"
                  size="h11"
                  color={COLORS.GREY[900]}
                  weight="bold"
                >
                  {formDetails?.title?.includes('form') ? formDetails?.title : `${formDetails?.title} form`}
                </Heading>
                <Paragraph size='b5' color={COLORS.GREY[300]}>
                  Please fill out the form below to complete your booking process.
                </Paragraph>
              </div>
              <div className='w-full flex justify-center py-4'>
                <FormProgressBar step={step} steps={steps} />
              </div>
            </div> : null}
          {!isFormSubmitted ?
            <div className='w-full max-w-[800px] mx-auto p-4 shadow-md bg-white'>
              <div className='w-full bg-white border border-[#E9E9E9BF] flex flex-col space-y-3 rounded-md'>
                <div className='w-full flex py-3 px-6 items-center bg-[#E2E8E933] justify-between'>
                  <div className='w-full flex flex-col'>
                    <Paragraph size='b4' weight='bold' className=''>
                      {currentSection?.title}
                    </Paragraph>
                    <Paragraph size='b5' weight='bold' color={COLORS.GREY[300]} className=''>
                      {currentSection?.description}
                    </Paragraph>
                  </div>
                </div>
                <div className='w-full flex flex-col gap-y-8 p-6'>
                  {Array?.isArray(currentSection?.inputs) && currentSection?.inputs?.length ? currentSection?.inputs?.map((question, questionIndex) => {
                    const options = Array?.isArray(question?.options) && question?.options?.length ? convertOptionsToMultiSelect(question?.options) : [];
                    return (
                      <div className='w-full flex flex-col space-y-2' key={questionIndex}>
                        <div className='w-full flex justify-between items-center'>
                          <FormLabel htmlFor={question?.key}>
                            {question?.label} {question?.is_required ? '*' : null}
                          </FormLabel>
                        </div>
                        {question?.type?.toLowerCase() === 'text' || question?.type?.toLowerCase() === 'textarea' ?
                          <input
                            className="w-full background-none font-semibold text-b5 text-grey-900 focus:outline-none placeholder:text-grey-300 border border-grey-100/70 py-3 px-4 rounded-md"
                            type="text"
                            value={getAnswer(questionIndex)}
                            onChange={(e) => {
                              onChangeAnswer(questionIndex, e.target.value);
                            }}
                            placeholder={`Enter ${question?.description}`}
                          /> : null}
                        {question?.type?.toLowerCase() === 'select' ?
                          <SingleSelect
                            selected={getSelectOptionAnswer(questionIndex)}
                            options={options}
                            placeholder={question?.description}
                            setSelected={onChangeOptions(questionIndex)}
                          /> : null}
                        {question?.type?.toLowerCase() === 'checkbox' ?
                          <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                            {Array.isArray(options) &&
                              options.length
                              ? options.map(
                                (option: {
                                  label: string;
                                  value: string;
                                }) => {
                                  return (
                                    <div
                                      className="flex"
                                      onClick={() =>
                                        onChangeAnswer(questionIndex, option?.value)
                                      }
                                      key={option?.value}
                                    >
                                      <div className="flex items-center cursor-pointer text-b5 text-white space-x-2">
                                        <Checkbox
                                          isChecked={
                                            getAnswer(questionIndex) === option?.value
                                          }
                                        />
                                        <span className="text-grey-900 whitespace-nowrap">
                                          {option?.label}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                              : null}
                          </div> : null}
                        {question?.type?.toLowerCase() === 'radio' ?
                          <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                            {Array.isArray(options) &&
                              options.length
                              ? options.map(
                                (option: {
                                  label: string;
                                  value: string;
                                }) => {
                                  return (
                                    <div
                                      className="flex"
                                      onClick={() =>
                                        onChangeAnswer(questionIndex, option?.value)
                                      }
                                      key={option?.value}
                                    >
                                      <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                        <RadioButton
                                          isChecked={
                                            getAnswer(questionIndex) === option?.value
                                          }
                                        />
                                        <span className="text-grey-900 whitespace-nowrap">
                                          {option?.label}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                              : null}
                          </div> : null}
                      </div>
                    )
                  }) : null}
                </div>
                {/* Buttons */}
                <div className='w-full flex justify-between items-center p-6 space-x-5'>
                  {step === 1 && steps === 1 && (
                    <Button disabled={submitAnswers?.isLoading} loading={submitAnswers?.isLoading} size="lg" rounded='lg' variant='primary' onClick={handleSubmit}>
                      Submit
                    </Button>
                  )}
                  {step === 1 && steps > 1 && (
                    <Button size="lg" rounded='lg' variant='primary' onClick={handleContinue}>
                      Continue
                    </Button>
                  )}
                  {step !== 1 && steps > 1 && step < steps && (
                    <>
                      <Button size="lg" rounded='lg' variant='secondary' onClick={handlePrev}>
                        Prev
                      </Button>
                      <Button size="lg" rounded='lg' variant='primary' onClick={handleContinue}>
                        Next
                      </Button>
                    </>
                  )}
                  {step !== 1 && steps > 1 && step === steps && (
                    <>
                      <Button disabled={submitAnswers?.isLoading} loading={submitAnswers?.isLoading} size="lg" rounded='lg' variant='secondary' onClick={handlePrev}>
                        Prev
                      </Button>
                      <Button disabled={submitAnswers?.isLoading} loading={submitAnswers?.isLoading} size="lg" rounded='lg' variant='primary' onClick={handleSubmit}>
                        Submit
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div> : null}

          {isFormSubmitted ?
            <div className='w-full max-w-[700px] mx-auto flex flex-col p-4 my-8 items-center justify-center bg-white py-6'>
              <div className='w-[150px] xl:w-[250px] mx-auto'>
                <SvgFormsCuate width="100%" height="100%" />
              </div>
              <div className='flex flex-col py-2 items-center space-y-3'>
                <Paragraph size='b2' weight='bold' className='w-full items-center'>{formDetails?.title?.includes('form') ? formDetails?.title : `${formDetails?.title} form`} form successfully filled</Paragraph>
              </div>
              <div className='w-full py-5'>
                <Button size="lg" rounded='lg' variant='primary' onClick={closeModal}>
                  Complete booking
                </Button>
              </div>
            </div> : null}
        </div>
      </div>
    </Modal>
  )
}

export default BookingSiteWorkFlow