import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTimeGreen = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={24} height={24} rx={4} fill="#16796F" fillOpacity={0.05} />
    <path
      d="M12 6.75A5.251 5.251 0 0 0 6.75 12 5.251 5.251 0 0 0 12 17.25 5.251 5.251 0 0 0 17.25 12 5.251 5.251 0 0 0 12 6.75Z"
      stroke="#16796F"
      strokeMiterlimit={10}
    />
    <path
      d="M12 8.5v3.938h2.625"
      stroke="#16796F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgTimeGreen;
