/* eslint-disable @typescript-eslint/no-explicit-any */
import { AddonStatus, Permission, Role } from "core/generated";
import { PERMISSION_LEVELS_LISTING, PermissionLevelList } from "../uicomponents/constants";
import { PAGE_ROUTES } from "constants/routes";
import { PagePermission, StaffPermissions } from "api/types/general";


const getStaffRole = () => {
  const staffRole = localStorage.getItem('permissions');
  if (staffRole === undefined || staffRole === 'undefined') {
    localStorage.clear()
    window.location.href = PAGE_ROUTES.LOGIN
    return false;
  }
  if (staffRole === 'null') return false;
  if (!staffRole) return false;
  if (staffRole) return JSON.parse(staffRole)
}

export const canPerformAction = (action: string) => {
  const permissions = getStaffRole();
  if (!permissions) {
    return false;
  }

  if (!Array?.isArray(permissions)) return false;

  return permissions.some(({ name, enabled }) => {
      return name === action && enabled;
  });
}

export const checkIfActionExist = (action: string) => {
  const permissions = getStaffRole();
  if (!permissions) {
    return false;
  }

  if (!Array?.isArray(permissions)) return false;

  return permissions.some(({ name, enabled }) => {
      return name === action;
  });
}

export const checkIfCanPerformAction = (action: string, permissions: Permission[]) => {
  return permissions.find(({ name, enabled }) => {
      return name === action && enabled;
  });
}

export const checkIfCanPerformActionByKey = (action: string, permissions: {
  key: string,
  enabled: boolean
  name: string
}[]) => {
  return permissions.find(({ key, enabled }) => {
      return key === action && enabled;
  });
}

export const forfietRight = (roleName: string) => {
  const staffRole = getStaffRole();
  if (!staffRole) {
    return;
  }
  return (staffRole?.name?.toLowerCase() === roleName)
} 

export const canShowPage = (action: string) => {
  const permissions = getStaffRole();
  if (!permissions) {
    return;
  }

  if (!Array?.isArray(permissions)) return false;

  // check if action includes in permissions?.name
  return permissions.some(({ name }) => {
    return name.includes(action);
  });
}

const getRoleIndex = (roleName: string) => {
  switch (roleName) {
    case 'receptionist':
      return 1;
    case 'manager':
      return 2;
    case 'owner':
      return 3;
    default:
      return 0;
  }
}

export const formatPermissionSettingsFields = ({ permissions }: { permissions: StaffPermissions[] }) => {
  // const levels = PERMISSION_LEVELS_LISTING;
  const ownerPermissionGroup = permissions?.find((role) => role?.name === 'owner')?.authorizedPermissionGroups;
  const levels = ownerPermissionGroup?.map((group) => {
    return {
      levelTitle: group?.value,
      modules: group?.permissionLabels?.map((permissionLabel) => {
        return {
          label: permissionLabel?.value,
          module: permissionLabel?.permissions?.map((permission) => permission?.name),
          // ids: permissionLabel?.permissions?.map((permission) => permission?.id),
          levels: [false, false, false, false],
          id: permissionLabel?.id
        }
      })
    }
  });
  const authorizations = permissions?.find((role) => role?.name === 'owner')?.authorizedPermissions;

  // loop through levels and roles and return an array of objects with label and value
  const roleLevels = levels.map((level) => {
    return {
      levelTitle: level?.levelTitle,
      // loop through modules
      modules: level?.modules?.map((_module) => {
        const levels = [false, false, false, false]
        if (_module?.module?.length > 0) {
          // loop through roles
          permissions?.forEach((role) => {
            const checkRole = role?.authorizedPermissions?.some((auth) => _module?.module?.includes(auth?.name) && auth?.enabled);
            const roleIndex = getRoleIndex(role?.name);
            if (checkRole) {
              levels[roleIndex] = true;
            }
          })
        }
        return {
          label: _module?.label,
          levels,
          module: _module?.module,
          id: _module?.id
        }
      })
    }
  });
  return roleLevels;
}

export const formatRoleName = (roleName: string) => {
  // switch case for roleName
  switch (roleName) {
    case 'receptionist':
      return 'Level 2';
    case 'manager':
      return 'Level 3';
    case 'owner':
      return 'Level 4';
    case 'staff':
      return 'Level 1';
    default:
      return roleName;
  }
}

export const formatLevelToRoleName = (level: string) => {
  // switch case for level
  switch (level) {
    case 'Level 2':
      return 'receptionist';
    case 'Level 3':
      return 'manager';
    case 'Level 4':
      return 'owner';
    case 'Level 1':
      return 'staff';
    default:
      return level;
  }
}

const getRoleIdViaLevel = (levelId: string) => {
  const user = JSON.parse(localStorage.getItem('userData'))
  const roles: Role[] = user?.business?.roles;
  const roleId = roles?.find((role) => role?.name === formatLevelToRoleName(levelId))?.id;
  return roleId;
}

// export const transformPermissionsToInput = (data: any) => {
//   const permissions: any = [];

//   data.forEach((level: { modules: any[]; }) => {
//     level.modules.forEach((module: { levels: any[]; ids: any; }) => {
//       module.levels.forEach((levelPermission: any, index: number) => {
//         if (levelPermission) {
//           const roleId = getRoleIdViaLevel(`Level ${index + 1}`)
//           permissions.push({
//             roleId, // Assuming roleId is derived from the level index
//             permissionIds: module.ids // Collecting all ids
//           });
//         }
//       });
//     });
//   });

//   // Merge permissions with the same roleId
//   const mergedPermissions = permissions.reduce((acc: any[], curr: { roleId: any; permissionIds: any; }) => {
//     const existing = acc.find((item: { roleId: any; }) => item.roleId === curr.roleId);
//     if (existing) {
//       existing.permissionIds = [...existing.permissionIds, ...curr.permissionIds];
//     } else {
//       acc.push(curr);
//     }
//     return acc;
//   }, []);

//   // Remove duplicates from permissionIds
//   mergedPermissions.forEach((permission: { permissionIds: Iterable<unknown> | null | undefined; }) => {
//     permission.permissionIds = [...new Set(permission.permissionIds)];
//   });

//   return mergedPermissions;
// };

export const transformPermissionsToInput = (data: PermissionLevelList[], roleIds: string[]) => {
  // Initialize results array
  const result = [];
  
  // For each role ID, create a mapping to permission IDs
  roleIds.forEach((roleId, roleIndex) => {
    // Initialize the result object with the role ID
    const roleMapping = {
      roleId: roleId,
      permissionLabelIds: []
    };
    
    // Go through each category in the data
    data.forEach(category => {
      // Then for each module in the category
      category.modules.forEach(module => {
        // Check if this role has this permission
        // roleIndex + 0 corresponds to the levels array index
        if (module.levels[roleIndex]) {
          // If it does, add the permission ID to the list
          roleMapping.permissionLabelIds.push(module.id);
        }
      });
    });
    
    // Add this role's mapping to our results
    result.push(roleMapping);
  });
  return result;
};

export const checkIfPermissionExists = (action: string, permissions: PagePermission[]) => {
  if (!Array?.isArray(permissions)) return false;

  return permissions.some(({ name }) => {
    return name === action;
  });
}

export const canPerformActionWithPermissionsPassed = (action: string, permissions: PagePermission[]) => {
  if (!permissions) {
    return false;
  }

  if (!Array?.isArray(permissions)) return false;

  return permissions.some(({ name, enabled }) => {
      return name === action && enabled;
  });
}

export const checkPageRightStatus = (module: string, action: string, permissions?: PagePermission[]): string => {
  const user = JSON.parse(localStorage.getItem('userData'))
  const addons: AddonStatus[] = user?.business?.addons;
  const addon = addons?.find((addon) => addon?.addon?.name?.toLowerCase()?.includes(module))
  const isActionPossible = permissions && Array?.isArray(permissions) ? checkIfPermissionExists(action, permissions) : checkIfActionExist(action)
  if (!addon?.subscribed && !isActionPossible) {
    return 'shouldSubscribe';
  }
  const actionCanBePerformed = permissions && Array?.isArray(permissions) ? canPerformActionWithPermissionsPassed(action, permissions) : canPerformAction(action);
  if (!actionCanBePerformed) {
    return 'hidePage';
  }

  return 'showPage'
}