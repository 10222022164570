import { useVerifyEmail } from 'api/useAccount';
import { COLORS } from 'constants/colors';
import { PAGE_ROUTES } from 'constants/routes';
import { useToast } from 'hooks';
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Paragraph, SvgCheckMarkGreen } from 'ui';
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader';

const EmailVerification = () => {
  const { addToast, toast } = useToast()
  const [seconds, setSeconds] = React.useState(10);
  const [isVerified, setIsVerified] = React.useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const token = params.id as string;
  const {
    verifyEmail,
    loading
  } = useVerifyEmail()

  useEffect(() => {
    if (token) {
      onSubmit()
    }
  }, [token])

  const onSubmit = async () => {
    verifyEmail({
      variables: {
        input: {
          token
        }
      }
    }).then(({ data }) => {
      const { verifyEmail } = data
      if (verifyEmail.status === 200) {
        setIsVerified(true)
      } else {
        addToast({
          variant: 'error',
          message: verifyEmail.errors[0].message
        })
      }
    })
      .catch((error) => {
        addToast({
          variant: 'error',
          message: 'An error occured, kindly try again'
        })
      })
  }

  React.useEffect(() => {
    if (seconds === 0) {
      navigate(PAGE_ROUTES.HOME, {
        replace: true,
      }) // Redirect to another page
      return;
    }

    if (isVerified) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [seconds, isVerified, navigate]);
  return (
    <>
      {loading ? <ContentLoader /> : null}
      {token ?
        <div className='w-full h-screen flex flex-col justify-center items-center'>
          <div className='w-full max-w-[400px] flex flex-col justify-center items-center'>
            <SvgCheckMarkGreen width="100px" height="100px" />
            <Heading variant='h1' size='h5' weight='semiBold'>Email Verification</Heading>
            <Paragraph
              size="b5"
              weight="normal"
              className="w-full"
              color={COLORS.GREY[400]}
            >
              Your email was verified you can continue using the app.
            </Paragraph>
            <Paragraph
              size="b5"
              weight="normal"
              className="w-full text-center"
              color={COLORS.GREY[400]}
            >
              You would be redirected in {seconds}.
            </Paragraph>
          </div>
        </div> : null}
    </>
  )
}

export default EmailVerification