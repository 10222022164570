import React, { useEffect, useState } from 'react'
import { PaymentModalProps } from '../uicomponents/bookingSales/types'
import Button from 'ui/atoms/button/Button'
import { COLORS } from 'constants/colors'
import { Heading, ToastProps, ToastWrapper } from 'ui'
import axios from 'axios'
import ActionCable from "actioncable";
import { DeleteAppointmentDocument } from 'core/generated'
import { useToast } from 'hooks'
import { useDeleteAppointment } from 'api/useAppointments'

const PaymentModal = (props: PaymentModalProps) => {
  const token = localStorage.getItem('token');
	const webSocketUrl = process.env.REACT_APP_WEB_SOCKET_URL + "?token=" + token as string;
  const { toast, addToast } = useToast();

  const processCancellation = () => {
    if (props?.propertyType === 'appointment') {
      deleteAppointmentAsync()
      return;
    }

    props?.closeModal()
  }

  const {
    loading: isLoading,
    deleteAppointment
  } = useDeleteAppointment()

  useEffect(() => {
		if (props?.paymentAuthorization && props?.isVisible) {
			const cable = ActionCable.createConsumer(webSocketUrl);
			cable.subscriptions.create(
				{ channel: "PaymentChannel", salon_id: props?.salonId },
				{
					connected() {
						console.log("Connected to Action Cable");
					},
					received: (data) => {
						// handle notification here
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
            const notifcation = data.notification as any;
            console.log({
              notifcation,
              reference: props?.paymentAuthorization?.reference
            })
						// Add the new message to the notifications state
            if (notifcation && notifcation?.payment_reference === props?.paymentAuthorization?.reference) {
              props?.confirmPayment()
						}
					},
				}
			);
		}
	}, [props?.isVisible]);

  const deleteAppointmentAsync = async () => {
    if (!props?.propertyId || !props?.salonId) return;
    try {
      deleteAppointment({
        variables: {
          input: {
            salonId: props?.salonId,
            id: props?.propertyId,
            isPermanentDeletion: true
          }
        },
      }).then(({ data }) => {
        const appointmentDetailsData = data?.deleteAppointment;
        if (appointmentDetailsData?.status == 200) {
          props?.closeModal();
        } else {
          addToast({
            message: 'Kindly try again',
            variant: 'error',
          })
        }
      })
    } catch (deleteAppointmentError) {
      if (axios.isAxiosError(deleteAppointmentError)) {
        addToast({
          message: 'Kindly try again',
          variant: 'error',
        })
      }
    }
  }

  return (
    props?.isVisible ? (
      <>
        <ToastWrapper toast={toast as ToastProps} />
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50">
          <div className="w-full bg-white rounded-lg shadow-medium py-12 px-9 max-w-[500px]">
            <div className="w-full flex flex-col items-center space-y-6 overflow-auto">
              <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>Make Payment</Heading>
              <iframe
                className="w-full h-[500px]"
                src={props?.paymentAuthorization?.authorizationUrl || ""}
              ></iframe>

              <div className='w-full flex flex-col lg:flex-row gap-4'>
                <Button
                  variant="secondary"
                  size='lg'
                  rounded='md'
                  fontSize='b5'
                  className='capitalize'
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={processCancellation}
                >
                  Cancel payment
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : null
  )
}

export default PaymentModal
