import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  CalendarPicker,
  Heading,
  HeadlessDropDown,
  Layout,
  Logo,
  Paginator,
  Paragraph,
  ReportTable,
  Table,
  Tabs,
  ToastWrapper
, SvgCalendar, SvgCartBag, SvgDownGrowthIndicator, SvgGrowthIndicator, SvgLocationPin, SvgPercent, SvgSale, SvgUsers } from '../ui'
import { COLORS } from 'constants/colors'
import {
  convertTextToLowercaseAndSnakeCase,
  createFileNameFromDates,
  formatDateToOriginalDate,
  formatInToNumber,
  formatInToPrice,
  getDefaultQueryDates,
  getNumberMonthAndYearFromDate,
  handleDownload,
  handleExcelDownload
} from '../components/utils/misc'
import {
  OutstandingSales,
  PromoActivity,
  SalesOverviewByProduct,
  SalesOverviewByService,
  SalesOverviewByStaffMember,
  SalonStaff,
  TotalSalesSummary,
  VoidedSalesReport} from 'core/generated'
import { useToast, useUserCache } from 'hooks'
import { checkReportsTokenFromRoute, checkSalonIdFromRoute } from '../components/utils/token'
import { useSalonCache } from 'hooks/useSalonCache'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader'
import { usePDF } from 'react-to-pdf';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { businessPerformanceSaleGraphData, customerSpendGraphData, deductiblesPieGraphData, getSumTotal, salesReportsGeneralOverviewGraphData } from '../components/uicomponents/reportGraphUtils';
import { IHeadlessOption } from 'ui/organism/headlessDropDown/types'
import EmptyAppointment from 'ui/molecules/empty/EmptyAppointment'
Chart.register(...registerables);
import { canPerformAction } from '../components/utils/permission'
import { PERMISSION_CONSTANTS } from 'constants/permission'
import ExportDataToCsvOrXlsx from '../components/uicomponents/ExportDataToCsvOrXlsx'
import { DEFAULT_CURRENCY } from 'constants/currency'
import { useExecutiveSummary, useOutstandingSalesReports, useOutstandingSalesSummary, usePromoActivity, usePromoActivitySummary, useSalesOveerviewSummary, useSalesOverviewByProduct, useSalesOverviewByService, useSalesOverviewByStaff, useTotalGeneralSales, useTotalSalesAndTotalSummarySales, useVoidedSales } from 'api/useReports'
import { useGetStaffList } from 'api/useStaff'
import AddonSubscription from 'components/uicomponents/AddonSubscription'
import { useLocation } from 'react-router-dom'

const SALES_REPORTS_TAB_NAME = {
  TOTAL_SALES: 'Total sales',
  SALES_OVERVIEW: 'Sales overview',
  OUTSTANDING_SALES: 'Outstanding Sales',
  PROMO_ACTIVITY: 'Promo Activity',
  VOIDED_SALES: 'Voided Sales'
}

const SalesReports = () => {
  const SALES_REPORTS_TABS = [
    {
      key: SALES_REPORTS_TAB_NAME.TOTAL_SALES,
      title: SALES_REPORTS_TAB_NAME.TOTAL_SALES,
      show: true,
      canAccess: canPerformAction(PERMISSION_CONSTANTS?.reports?.sales?.totalSales)
    },
    {
      key: SALES_REPORTS_TAB_NAME.SALES_OVERVIEW,
      title: SALES_REPORTS_TAB_NAME.SALES_OVERVIEW,
      show: true,
      canAccess: canPerformAction(PERMISSION_CONSTANTS?.reports?.sales?.overview)
    },
    {
      key: SALES_REPORTS_TAB_NAME.OUTSTANDING_SALES,
      title: SALES_REPORTS_TAB_NAME.OUTSTANDING_SALES,
      show: true,
      canAccess: canPerformAction(PERMISSION_CONSTANTS?.reports?.sales?.outstandingSales)
    },
    {
      key: SALES_REPORTS_TAB_NAME.VOIDED_SALES,
      title: SALES_REPORTS_TAB_NAME.VOIDED_SALES,
      show: true,
      canAccess: canPerformAction(PERMISSION_CONSTANTS?.reports?.sales?.overview)
    },
    {
      key: SALES_REPORTS_TAB_NAME.PROMO_ACTIVITY,
      title: SALES_REPORTS_TAB_NAME.PROMO_ACTIVITY,
      show: true,
      canAccess: canPerformAction(PERMISSION_CONSTANTS?.reports?.sales?.promoActivity)
    }
  ]
  // re-order APPOINTMENT_REPORTS_TABS? based off show property
  SALES_REPORTS_TABS?.sort((a, b) => {
    if (a.canAccess && !b.canAccess) return -1
    if (!a.canAccess && b.canAccess) return 1
    return 0
  })
  // remove canAccess
  SALES_REPORTS_TABS?.forEach((tab) => {
    delete tab.canAccess
  })
  const [activeTab, setActiveTab] = useState(
    SALES_REPORTS_TABS?.filter((tab) => tab.show)[0].key)

  const getTabView = () => {
    switch (activeTab) {
      case SALES_REPORTS_TAB_NAME.TOTAL_SALES:
        return canPerformAction(PERMISSION_CONSTANTS?.reports?.sales?.totalSales) ? <TotalSales /> : <AddonSubscription module='reports' addToast={null} reloadPage={null} />
      case SALES_REPORTS_TAB_NAME.OUTSTANDING_SALES:
        return canPerformAction(PERMISSION_CONSTANTS?.reports?.sales?.outstandingSales) ? <OutstandingSales_ /> : <AddonSubscription module='reports' addToast={null} reloadPage={null} />
      case SALES_REPORTS_TAB_NAME.SALES_OVERVIEW:
        return canPerformAction(PERMISSION_CONSTANTS?.reports?.sales?.overview) ? <SalesOverview /> : <AddonSubscription module='reports' addToast={null} reloadPage={null} />
      case SALES_REPORTS_TAB_NAME.VOIDED_SALES:
        return canPerformAction(PERMISSION_CONSTANTS?.reports?.sales?.overview) ? <VoidedSales /> : <AddonSubscription module='reports' addToast={null} reloadPage={null} />
      default:
        return canPerformAction(PERMISSION_CONSTANTS?.reports?.sales?.promoActivity) ? <PromoActivityReport /> : <AddonSubscription module='reports' addToast={null} reloadPage={null} />
    }
  }

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlTab = params.get('tab');

  useEffect(() => {
    if (urlTab) {
      const checkTab = SALES_REPORTS_TABS?.find((tab) => convertTextToLowercaseAndSnakeCase(tab?.key) === convertTextToLowercaseAndSnakeCase(urlTab));
      if (checkTab && checkTab?.show) {
        setActiveTab(checkTab?.key)
      }
    }
  }, [urlTab])

  const changeTab = (tab: string) => {
    setActiveTab(tab)
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', convertTextToLowercaseAndSnakeCase(tab));
    window.history.replaceState(null, '', `?${searchParams.toString()}`);
  }
  return (
    <Layout pageTitle={activeTab} pageLevel={3}>
      <Tabs
        tabs={SALES_REPORTS_TABS}
        activeTab={activeTab}
        setActiveTab={changeTab}
      />
      <div className='w-full pb-20'>{getTabView()}</div>
    </Layout>
  )
}

const TotalSales = () => {
  const { getUserData } = useUserCache()
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id');
  const user = getUserData()
  const business = user?.business;
  const salons = business?.salons;
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [salonStaffs, setSalonStaffs] = useState<SalonStaff[]>([])
  const [staffDropDownSelectedValue, setStaffDropDownSelectedValue] = useState('')
  const [salonDropDownSelectedValue, setSalonDropDownSelectedValue] = useState("")
  const { toPDF, targetRef } = usePDF({ filename: `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}__total_sales_report.pdf` });
  const [loadingTotalSaleswData, setLoadingTotalSalesData] = useState(false)
  const { toast, addToast } = useToast()
  const [exportingToPDF, setExportingToPDF] = useState(false);

  const startDate = formatDateToOriginalDate(selectedDates[0], "start")
  const endDate = formatDateToOriginalDate(selectedDates[1], "end")

  const {
    data: executiveSummaryData,
    loading: executiveSummaryLoading,
    refetch: refetchExecutiveSummaryData
  } = useExecutiveSummary(salonId, startDate, endDate)
  const executiveSummary = useMemo(() => {
    return executiveSummaryData?.executiveSummaryReport
  }, [executiveSummaryData])

  const {
    data,
    loading,
    refetch
  } = useTotalSalesAndTotalSummarySales({
    salonId,
    startDate,
    endDate
  })
  const totalSalesSummary = useMemo(() => {
    return data?.totalSalesSummaryReport
  }, [data])
  const salesSummary = useMemo(() => {
    return data?.totalSalesReport
  }, [data])

  const {
    data: staffListData,
  } = useGetStaffList({
    salonId,
    active: true
  })
  const staffMembers = useMemo(() => staffListData?.staffManagement?.staffs, [staffListData])

  useEffect(() => {
    if (Array?.isArray(staffMembers) && staffMembers?.length) {
      const firstId = staffMembers[0]?.id
      setStaffDropDownSelectedValue(firstId)
    }
  }, [staffMembers])

  const staffList =
  staffMembers?.length ? staffMembers?.map((staff) => {
    return {
      value: staff?.id,
      label: staff?.user?.firstName + ' ' + staff?.user?.lastName
    }
  }) as IHeadlessOption[] : []

  const formatTitle = (title: string) => {
    let formattedTitle =
      title.charAt(0).toUpperCase() +
      title.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')

    if (title === 'totalSales') formattedTitle = 'Sale Count'
    if (title === 'taxTotal') formattedTitle = 'Taxes'
    if (title === 'refundTotal') formattedTitle = 'Refunds'

    return formattedTitle
  }

  const breakObjectIntoArray = (data: TotalSalesSummary) => {
    return Object.entries(data).map(([key, value]) => {
      return {
        title: formatTitle(key),
        value: key !== 'totalSales' ? formatInToPrice(value as number) : value
      }
    })
  }

  const generateSalesReportTableData = (sales?: TotalSalesSummary) => {
    if (sales) {
      const { __typename, ...rest } = sales;
      const rows = breakObjectIntoArray(rest)
      return rows
    }

    return []
  }
  const rows = generateSalesReportTableData(salesSummary)

  const {
    data: totalGenenralSale,
    refetch: refetchTotalGeneralSale
  } = useTotalGeneralSales({
    salonId: salonDropDownSelectedValue,
    startDate,
    endDate,
    salonStaffId: staffDropDownSelectedValue
  })
  const locationSummary = useMemo(() => {
    return totalGenenralSale?.totalSalesGeneralOverviewSummaryReport
  }, [totalGenenralSale])

  useEffect(() => {
    if (salonDropDownSelectedValue && staffDropDownSelectedValue) {
      refetchTotalGeneralSale()
    }
  }, [salonDropDownSelectedValue, staffDropDownSelectedValue, selectedDates])

  const trendData = customerSpendGraphData(totalSalesSummary?.productsTrendGraphData || [], totalSalesSummary?.servicesTrendGraphData || []);
  const salesChartData = salesReportsGeneralOverviewGraphData(locationSummary?.grossSalesGraphData, locationSummary?.netSalesGraphData);
  const salesServiceProductVoucherChartData = businessPerformanceSaleGraphData(executiveSummary?.productsSummary, executiveSummary?.servicesSummary, executiveSummary?.vouchersSummary);
  const pieChart = deductiblesPieGraphData(locationSummary?.deductibles || [])
  const salonList = useMemo(() => {
    return salons?.length
      ? salons.map((salon) => ({
        value: salon?.id,
        label: salon?.branchName
      }))
      : [];
  }, [salons])

  useEffect(() => {
    if (Array?.isArray(salonStaffs) && salonStaffs?.length) {
      if (staffDropDownSelectedValue === '') {
        const firstId = salonStaffs[0]?.id
        setStaffDropDownSelectedValue(firstId)
      }
    }
  }, [salonStaffs])

  useEffect(() => {
    if (Array?.isArray(salonList) && salonList?.length) {
      if (salonDropDownSelectedValue === '') {
        setSalonDropDownSelectedValue(salonList[0]?.value)
      }
    }
  }, [salonList?.length])

  const exportToPDF = () => {
    // loading this function after 5 seconds
    setExportingToPDF(true)
    setTimeout(() => {
      toPDF()
      setExportingToPDF(false)
    }, 500)
  }

  useEffect(() => {
    refetchExecutiveSummaryData()
    refetch()
  }, [selectedDates])
  return (
    <div className='w-full flex flex-col' ref={targetRef}>
      <ToastWrapper toast={toast} />
      {loadingTotalSaleswData ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Total sales
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        {!exportingToPDF ?
          <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
            <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
            <CalendarPicker {...{ selectedDates, setSelectedDates }} />
            <Button
              variant='primary'
              size='sm'
              disabled={!salesSummary}
              loading={false}
              type='button'
              rounded='md'
              className='flex'
              onClick={exportToPDF}
            >
              Export report
            </Button>
          </div> : null}
      </div>
      <div className='w-full grid grid-col-1 xl:grid-cols-3 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSale width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total gross sales
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {totalSalesSummary?.totalGrossSales}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgPercent width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total taxes
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {totalSalesSummary?.totalTaxes}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSale width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total net sales
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {totalSalesSummary?.totalNetSales}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col lg:flex-row gap-4 border-t border-grey-100 p-4'>
        <div className='w-full flex flex-col p-4 space-y-4 rounded-md border border-grey-100 bg-grey-50/20'>
          <div className='w-full flex justify-between items-center'>
            <div className='flex flex-col space-y-2 w-[60%]'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Trends and Highlights
              </Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[400]} className=''>
                Find your trends and highlights across staff appointment reports here
              </Paragraph>
            </div>
          </div>

          <div className='w-full flex justify-between'>
            <div className='flex flex-col justify-end space-y-2'>
              <Paragraph size='b6' color={COLORS.GREY[300]} weight='semiBold' className='uppercase'>
                TOTAL sales
              </Paragraph>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                {totalSalesSummary?.totalGrossSales}
              </Paragraph>
            </div>

            <div className='flex flex-col space-y-2'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className='flex space-x-1 items-center justify-end'>
                <span>{totalSalesSummary?.saleTrend}%</span>
                {totalSalesSummary?.saleTrend?.toString().includes("-") ? <SvgDownGrowthIndicator width="16px" height="16px" /> : <SvgGrowthIndicator width="16px" height="16px" />}
              </Paragraph>
              <Paragraph size='b6' color={COLORS.GREY[300]} weight='light' className=''>
                VS LAST MONTH%
              </Paragraph>
            </div>
          </div>
          <div className='w-full h-[235px]'>
            <Line options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: true,
                  position: "bottom",
                  text: 'Sales Revenue',
                },
                tooltip: {
                  enabled: true,
                  mode: 'index',
                  intersect: false,
                }
              },
              scales: {
                x: {
                  type: 'category',
                },
                y: {
                  beginAtZero: true,
                  ticks: {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    callback: function (value, index, values) {
                      return DEFAULT_CURRENCY + value.toLocaleString("en-US");
                    }
                  }
                },
              },
            }} data={trendData} />
          </div>
          <div className='w-full flex gap-3 justify-center items-center'>
            <Paragraph size='b5' className='flex items-center capitalize'>
              <span className='w-[12px] h-[12px] bg-[#7EA05C] mr-2'></span>
              Services
            </Paragraph>
            <Paragraph size='b5' className='flex items-center capitalize'>
              <span className='w-[12px] h-[12px] bg-[#E4B91F] mr-2'></span>
              Products
            </Paragraph>
          </div>
        </div>
        <div className='w-full h-fit flex flex-col border border-grey-100 rounded-md'>
          <div className='w-full flex justify-between border-b border-grey-100 items-center gap-2 p-4'>
            <div className='flex flex-col space-y-2'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Summary
              </Paragraph>
              <Paragraph size='b6' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
                Find your sales summary here
              </Paragraph>
            </div>
          </div>
          <ReportTable rows={rows} />
        </div>

      </div>
      <div className='w-full flex flex-col lg:flex-row gap-4 border-t border-grey-100 p-3 md:p-6'>
        <div className='w-full flex flex-col border border-grey-100 bg-grey-50/20 rounded-md'>
          <div className='w-full flex justify-between p-6'>
            <Paragraph size='b5' weight='semiBold'>
              Sales
            </Paragraph>

            <div className='flex gap-3 items-center'>
              <Paragraph size='b5' className='flex items-center capitalize'>
                <span className='w-[12px] h-[12px] bg-[#E4B91F] mr-2'></span>
                gross sales
              </Paragraph>
              <Paragraph size='b5' className='flex items-center capitalize'>
                <span className='w-[12px] h-[12px] bg-[#CFE6C1] mr-2'></span>
                taxes
              </Paragraph>
              <Paragraph size='b5' className='flex items-center capitalize'>
                <span className='w-[12px] h-[12px] bg-[#7EA05C] mr-2'></span>
                net sales
              </Paragraph>
            </div>
          </div>
          <div className='w-full h-[300px] p-6'>
            <Bar options={
              {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    position: "bottom",
                    text: 'Gross Sales, Taxes, and Net Sales Overview'
                  }
                },
                scales: {
                  x: {
                    type: 'category',
                  },
                  y: {
                    beginAtZero: true,
                    ticks: {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      callback: function (value, index, values) {
                        return DEFAULT_CURRENCY + value.toLocaleString("en-US");
                      }
                    }
                  },
                },
              }
            } data={salesServiceProductVoucherChartData} />
          </div>
        </div>
        <div className='w-full xl:max-w-[270px] flex flex-col px-4 py-5 space-y-4 border border-grey-100 bg-grey-50/20 rounded-md'>
          <div className='flex flex-col space-y-3'>
            <div className='w-full flex items-center justify-between'>
              <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Services
              </Paragraph>

              <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                <SvgCalendar width="18px" height="18px" />
              </span>
            </div>
            <div className='w-full flex justify-between'>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                Quantity sold
              </Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                {executiveSummary?.servicesSold}
              </Paragraph>
            </div>
          </div>

          <div className='flex flex-col space-y-3'>
            <div className='w-full flex items-center justify-between'>
              <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Products
              </Paragraph>

              <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                <SvgCartBag width="20px" height="18px" />
              </span>
            </div>
            <div className='w-full flex justify-between'>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                Quantity sold
              </Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                {executiveSummary?.productsSold}
              </Paragraph>
            </div>
          </div>

          <div className='flex flex-col space-y-3'>
            <div className='w-full flex items-center justify-between'>
              <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Vouchers
              </Paragraph>

              <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                <SvgPercent width="18px" height="18px" />
              </span>
            </div>
            <div className='w-full flex justify-between'>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                Quantity sold
              </Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                {executiveSummary?.vouchersSold}
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col h-fit border-t border-grey-100 p-3 md:p-6 space-y-4'>
        <div className="w-full flex items-center justify-between">
          <div className='flex flex-col space-y-2'>
            <Paragraph size='b4' weight='semiBold' className=''>
              Location performance
            </Paragraph>
            <Paragraph size='b5' className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{
                Array?.isArray(salons) && salons?.length && salonDropDownSelectedValue
                  ? salons?.filter((salon) => salon?.id === salonDropDownSelectedValue)[0]?.branchName
                  : ""
              }
              </span>
            </Paragraph>
          </div>
          <div className='flex flex-col lg:flex-row gap-3'>
            <div className='w-[170px]'>
              <HeadlessDropDown
                options={salonList}
                selectedValue={salonDropDownSelectedValue}
                setDropDownSelectedValue={setSalonDropDownSelectedValue}
              />
            </div>
            <div className='w-[170px]'>
              <HeadlessDropDown
                options={staffList}
                selectedValue={staffDropDownSelectedValue}
                setDropDownSelectedValue={setStaffDropDownSelectedValue}
              />
            </div>
          </div>
        </div>

        <div className='w-full flex flex-col xl:flex-row gap-4'>
          <div className='w-full flex flex-col p-4 space-y-4 border border-grey-100 bg-grey-50/20 rounded-md'>
            <div className='w-full flex justify-between'>
              <Paragraph size='b5' weight='semiBold'>
                Sales
              </Paragraph>

              <div className='flex gap-3 items-center'>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#E4B91F] mr-2'></span>
                  gross sales
                </Paragraph>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#7ea05c] mr-2'></span>
                  net sales
                </Paragraph>
              </div>
            </div>
            <div className='w-full h-[300px] p-6'>
              <Bar options={
                {
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      position: "bottom",
                      text: 'Gross Sales, and Net Location Overview'
                    }
                  },
                  scales: {
                    x: {
                      type: 'category',
                      stacked: true,
                    },
                    y: {
                      beginAtZero: true,
                      stacked: true,
                      ticks: {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        callback: function (value, index, values) {
                          return DEFAULT_CURRENCY + value.toLocaleString("en-US");
                        }
                      }
                    },
                  },
                }
              } data={salesChartData} />
            </div>
          </div>
          <div className='w-full flex flex-col items-start max-w-[430px] h-fit p-5 border border-grey-100 bg-grey-50/20 rounded-md space-y-3'>
            <div className='w-full flex justify-between items-center'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Deductibles
              </Paragraph>
              <div className='flex px-3 py-1 bg-grey-50 border border-grey-100'>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>Total: {formatInToPrice(getSumTotal(locationSummary?.deductibles))}</Paragraph>
              </div>
            </div>
            {Array?.isArray(locationSummary?.deductibles) && locationSummary?.deductibles?.length ? (
              <div className='w-full max-w-[400px] flex flex-col space-y-3'>
                <Pie data={pieChart} options={
                  {
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "right", // Hide the legend
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                  }
                } />
              </div>
            ) : <EmptyAppointment />}
          </div>
        </div>
      </div>
      {exportingToPDF ?
        <div className='w-full flex items-center justify-center '>
          <Logo />
        </div> : null}
    </div>
  )
}

const VoidedSales = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const { toast, addToast } = useToast()
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const HEADINGS = [
    { label: 'Customer', value: 'client' },
    { label: 'Item', value: 'service' },
    { label: 'Quantity Sold', value: 'quantitySold' },
    { label: 'Sale Date', value: 'saleDate' },
    { label: 'Price', value: 'price' }
  ];

  const MOBILE_HEADINGS = [
    { title: 'Customer', key: 'client' },
    { title: 'Item', key: 'service' },
    { title: 'Quantity Sold', key: 'quantitySold' },
    { title: 'Sale Date', key: 'saleDate' },
    { title: 'Price', key: 'price' }
  ];

  const {
    data: voidedSalesData,
    loading: loadingVoidedSalesDataLoading,
    refetch: refetchVoidedSalesData
  } = useVoidedSales({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const voidedSales = useMemo(() => voidedSalesData?.voidedSalesReport, [voidedSalesData])

  useEffect(() => {
    refetchVoidedSalesData()
  }, [selectedDates])

  const generateVoidedSalesTableData = (
    sales: VoidedSalesReport[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    countryCode = 'NG'
  ) => {
    return sales?.map?.((sale) => ({
      customer: sale.client,
      item: sale.item,
      quantitySold: sale.quantitySold,
      saleDate: getNumberMonthAndYearFromDate(sale?.saleDate),
      price: formatInToPrice(sale?.price)
    }))
  }

  const rows = generateVoidedSalesTableData(voidedSales)

  const formatVoidedSalesCsvDownloadData = (
    sales: VoidedSalesReport[],
    countryCode = 'NG'
  ) => {
    return sales?.map?.((sale) => ({
      "Customer": sale.client,
      "Item": sale.item,
      "Sale Date": getNumberMonthAndYearFromDate(
        sale.saleDate
      ),
      "Quantity Sold": sale.quantitySold,
      "Price": formatInToPrice(sale?.price)
    }))
  }

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_voided_sales_report`;
  const _handleDownload = () => {
    if (voidedSales) {
      handleDownload(formatVoidedSalesCsvDownloadData(voidedSales), filename);
    }
  };

  const downloadExcel = () => {
    if (voidedSales) {
      handleExcelDownload(formatVoidedSalesCsvDownloadData(voidedSales), filename, 'Voided sales')
    }
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      {loadingVoidedSalesDataLoading ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Voided sales
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full hidden md:flex flex-col space-y-12 overflow-x-scroll'>
        <Table headers={HEADINGS} mobileHeaders={MOBILE_HEADINGS} rows={rows} hasFooterBg={false} hasBorder={true} />
      </div>
    </>
  )
}

const OutstandingSales_ = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const { toast, addToast } = useToast()
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const {
    data: outstandingSalesData,
    loading: loadingOutstandingSalesData,
    refetch: refetchOutstandingSalesData
  } = useOutstandingSalesReports({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
    before, after
  })
  const outstandingSales = useMemo(() => outstandingSalesData?.outstandingSalesReport, [outstandingSalesData])

  const {
    data: outstandingSalesSummaryData,
    loading: loadingOutstandingSalesSummaryData,
    refetch: refetchOutstandingSalesSummaryData
  } = useOutstandingSalesSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const outstandingSalesSummary = useMemo(() => outstandingSalesSummaryData?.outstandingSalesSummaryReport, [outstandingSalesSummaryData])
  const HEADINGS = [
    { label: 'Customer', value: 'customer' },
    { label: 'Service', value: 'service' },
    { label: 'Service Date', value: 'appointmentDate' },
    { label: 'Check-in Date', value: 'date' },
    { label: 'Price', value: 'price' }
  ];

  const MOBILE_HEADINGS = [
    { title: 'Customer', key: 'customer' },
    { title: 'Service', key: 'service' },
    { title: 'Service Date', key: 'appointmentDate' },
    { title: 'Check-in Date', key: 'date' },
    { title: 'Price', key: 'price' }
  ]

  useEffect(() => {
    refetchOutstandingSalesData()
    refetchOutstandingSalesSummaryData()
  }, [selectedDates])

  const generateOutstandingSalesTableData = (
    sales: OutstandingSales[],
    countryCode = 'NG'
  ) => {
    return sales?.map?.((sale) => ({
      customer: sale.customer,
      service: sale.service,
      appointmentDate: getNumberMonthAndYearFromDate(
        sale.serviceDate
      ),
      date: getNumberMonthAndYearFromDate(sale?.checkInDate),
      price: formatInToPrice(sale?.price)
    }))
  }

  const rows = generateOutstandingSalesTableData(outstandingSales?.nodes)

  const formatOutstandingSalesCsvDownloadData = (
    sales: OutstandingSales[],
    countryCode = 'NG'
  ) => {
    return sales?.map?.((sale) => ({
      "Customer": sale.customer,
      "Service": sale.service,
      "Service Date": getNumberMonthAndYearFromDate(
    sale.serviceDate
      ),
      "Check-in date": getNumberMonthAndYearFromDate(sale?.checkInDate),
      "Price": formatInToPrice(sale?.price)
    }))
  }

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_outstanding_sales_report`;
  const _handleDownload = () => {
    if (outstandingSales) {
      handleDownload(formatOutstandingSalesCsvDownloadData(outstandingSales?.nodes), filename);
    }
  };

  const downloadExcel = () => {
    if (outstandingSales) {
      handleExcelDownload(formatOutstandingSalesCsvDownloadData(outstandingSales?.nodes), filename, 'Outstanding sales')
    }
  }

  const goToNext = () => {
    if (outstandingSales?.pageInfo?.hasNextPage) {
      const lastCursor = outstandingSales?.pageInfo?.endCursor;
      setAfter(lastCursor)
      setBefore(null)
      refetchOutstandingSalesData()
    }
  }

  const goToPrev = () => {
    if (outstandingSales?.pageInfo?.hasPreviousPage) {
      const lastCursor = outstandingSales?.pageInfo?.startCursor;
      setBefore(lastCursor)
      setAfter(null)
      refetchOutstandingSalesData()
    }
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      {loadingOutstandingSalesData ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Outstanding sales
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-1 xl:grid-cols-2 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSale width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total outstanding sales
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {outstandingSalesSummary?.outstandingSalesCount}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSale width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Value of outstanding sales ({DEFAULT_CURRENCY})
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {outstandingSalesSummary?.outstandingSalesAmount}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        <div className='flex space-x-2'>
          <Paginator pageInfo={outstandingSales?.pageInfo} {...{ goToNext, goToPrev }} />
        </div>
      </div>
      <div className='w-full hidden md:flex flex-col space-y-12 overflow-x-scroll'>
        <Table headers={HEADINGS} mobileHeaders={MOBILE_HEADINGS} rows={rows} hasFooterBg={false} hasBorder={true} />
      </div>
    </>
  )
}

const SalesOverview = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id');
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const DROP_DOWN_OPTIONS = [
    {
      value: 'byService',
      label: 'By Service'
    },
    {
      value: 'byProduct',
      label: 'By Product'
    },
    {
      value: 'byStaffMember',
      label: 'By Staff member'
    }
  ]

  const DROP_DOWN_OPTIONS_NAME = {
    BY_SERVICE: 'byService',
    BY_PRODUCT: 'byProduct',
    BY_STAFF_MEMBER: 'byStaffMember'
  }

  const BY_SERVICE_HEADER = [
    { name: 'Service' },
    { name: 'Item sold' },
    { name: 'Gross sales' },
    { name: 'Discount' },
    { name: 'Refunds' },
    { name: 'Tax' },
    { name: 'Net sales' },
  ]

  const BY_PRODUCT_HEADER = [
    { name: 'Product' },
    { name: 'Item sold' },
    { name: 'Gross sales' },
    { name: 'Discount' },
    { name: 'Refunds' },
    { name: 'Tax' },
    { name: 'Net sales' },
  ]

  const BY_STAFF_MEMBER_HEADER = [
    { name: 'Staff Member' },
    { name: 'Services' },
    { name: 'Products' },
    { name: 'Promo Discounts' },
    { name: 'Refunds' },
    { name: 'Total sales (tax excl.)' }
  ]
  const [dropDownSelectedValue, setDropDownSelectedValue] = useState<
    string | null
  >(DROP_DOWN_OPTIONS_NAME.BY_SERVICE)
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )

  const {
    data: salesOverviewByServiceData,
    loading: salesOverviewByServiceLoading,
    refetch: refetchSalesOverviewByServiceAsync
  } = useSalesOverviewByService({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
    before,
    after
  })
  const salesOverviewByService = useMemo(() => salesOverviewByServiceData?.salesOverviewByServiceReport, [salesOverviewByServiceData])

  const {
    data: salesOverviewByProductData,
    loading: salesOverviewByProductLoading,
    refetch: refetchSalesOverviewByProductAsync
  } = useSalesOverviewByProduct({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
  })
  const salesOverviewByProduct = useMemo(() => salesOverviewByProductData?.salesOverviewByProductReport, [salesOverviewByProductData])

  const {
    data: salesOverviewByStaffData,
    loading: salesOverviewByStaffLoading,
    refetch: refetchSalesOverviewByStaffAsync
  } = useSalesOverviewByStaff({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
  })
  const salesOverviewByStaffMember = useMemo(() => salesOverviewByStaffData?.salesOverviewByStaffReport, [salesOverviewByStaffData])


  const {
    data: salesOverviewSummaryData,
    loading: salesOverviewSummaryLoading,
    refetch: refetchSalesOverviewSummary
  } = useSalesOveerviewSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const salesOverviewSummary = useMemo(() => salesOverviewSummaryData?.salesOverviewSummaryReport, [salesOverviewSummaryData])

  useEffect(() => {
    refetchSalesOverviewSummary()

    if (dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.BY_SERVICE) {
      refetchSalesOverviewByServiceAsync()
      return;
    }

    if (dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.BY_PRODUCT) {
      refetchSalesOverviewByProductAsync()
      return;
    }

    if (dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.BY_STAFF_MEMBER) {
      refetchSalesOverviewByStaffAsync()
      return;
    }

  }, [selectedDates, dropDownSelectedValue])

  const generateServicesSalesOverviewTableData = (
    services: SalesOverviewByService[]
  ) => {
    return services?.map?.((service) => {
      return {
        service: service?.service,
        totalAppointments: service?.itemSold,
        grossSales: formatInToPrice(service?.grossSales),
        discount: formatInToPrice(service?.discount),
        refunds: formatInToPrice(service?.refunds),
        tax: formatInToPrice(service?.tax),
        netSales: formatInToPrice(service?.netSales),
      };
    });
  };
  const serviceRows = generateServicesSalesOverviewTableData(salesOverviewByService?.nodes);

  const formatServicesSalesOverviewCsvDownloadData = (
    services: SalesOverviewByService[]
  ) => {
    return services?.map?.((service) => {
      return {
        "Service": service?.service,
        "Item sold": service?.itemSold,
        "Gross sales": formatInToPrice(service?.grossSales),
        "Discount": formatInToPrice(service?.discount),
        "Refunds": formatInToPrice(service?.refunds),
        "Tax": formatInToPrice(service?.tax),
        "Net sales": formatInToPrice(service?.netSales),
      };
    });
  };

  const generateProductsSalesOverviewTableData = (
    products: SalesOverviewByProduct[]
  ) => {
    return products?.map?.((product) => {
      return {
        product: product?.product,
        totalAppointments: product.itemSold,
        grossSales: formatInToPrice(product.grossSales),
        discount: formatInToPrice(product.discount),
        refunds: formatInToPrice(product?.refunds),
        tax: formatInToPrice(product?.tax),
        netSales: formatInToPrice(product.netSales),
      };
    });
  };
  const productRows = generateProductsSalesOverviewTableData(salesOverviewByProduct);

  const formatProductsSalesOverviewCsvDownloadData = (
    products: SalesOverviewByProduct[]
  ) => {
    return products?.map?.((product) => {
      return {
        "Product": product?.product,
        "Item sold": product.itemSold,
        "Gross sales": formatInToPrice(product.grossSales),
        "Discount": formatInToPrice(product.discount),
        "Refunds": formatInToPrice(product?.refunds),
        "Tax": formatInToPrice(product?.tax),
        "Net sales": formatInToPrice(product.netSales),
      };
    });
  };

  const generateStaffSalesOverviewTableData = (
    sales: SalesOverviewByStaffMember[]
  ) => {
    return sales?.map?.((staff) => ({
      staffName: staff?.staffMember,
      totalServiceSales: formatInToNumber(staff?.services),
      totalProductSales: formatInToNumber(staff?.products),
      discountTotal: formatInToPrice(staff?.promoDiscounts),
      refundTotal: formatInToPrice(staff?.refunds),
      finalTotalSales: formatInToPrice(staff?.totalSales),
    }));
  };

  const staffRows = generateStaffSalesOverviewTableData(salesOverviewByStaffMember);

  const formatStaffSalesOverviewCsvDownloadData = (
    sales: SalesOverviewByStaffMember[]
  ) => {
    return sales?.map?.((staff) => ({
      "Staff name": staff?.staffMember,
      "Services": formatInToNumber(staff?.services),
      "Products": formatInToNumber(staff?.products),
      "Promo discounts": formatInToPrice(staff?.promoDiscounts),
      "Refunds": formatInToPrice(staff?.refunds),
      "Total sales (tax excl.)": formatInToPrice(staff?.totalSales),
    }));
  };

  const serviceFilename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_sales_overview_by_service_report`;
  const productFilename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_sales_overview_by_product_report`;
  const staffFilename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_sales_overview_by_staff_member_report`;
  const _handleDownload = () => {
    if (salesOverviewByService && dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.BY_SERVICE) {
      handleDownload(formatServicesSalesOverviewCsvDownloadData(salesOverviewByService?.nodes), serviceFilename);
    }

    if (salesOverviewByProduct && dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.BY_PRODUCT) {
      handleDownload(formatProductsSalesOverviewCsvDownloadData(salesOverviewByProduct), productFilename);
    }

    if (salesOverviewByStaffMember && dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.BY_STAFF_MEMBER) {
      handleDownload(formatStaffSalesOverviewCsvDownloadData(salesOverviewByStaffMember), staffFilename);
    }
  };

  const downloadExcel = () => {
    if (salesOverviewByService && dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.BY_SERVICE) {
      handleExcelDownload(formatServicesSalesOverviewCsvDownloadData(salesOverviewByService?.nodes), serviceFilename, 'Sales overview by service');
    }

    if (salesOverviewByProduct && dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.BY_PRODUCT) {
      handleExcelDownload(formatProductsSalesOverviewCsvDownloadData(salesOverviewByProduct), productFilename, 'Sales overview by product');
    }

    if (salesOverviewByStaffMember && dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.BY_STAFF_MEMBER) {
      handleExcelDownload(formatStaffSalesOverviewCsvDownloadData(salesOverviewByStaffMember), staffFilename, 'Sales overview by staff member');
    }
  }

  const goToNext = () => {
    if (!salesOverviewByService?.pageInfo?.hasNextPage) return;
    const lastCursor = salesOverviewByService?.pageInfo?.endCursor;
    setBefore(null);
    setAfter(salesOverviewByService?.pageInfo?.endCursor);
    refetchSalesOverviewByServiceAsync();
  }

  const goToPrev = () => {
    if (!salesOverviewByService?.pageInfo?.hasPreviousPage) return;
    const lastCursor = salesOverviewByService?.pageInfo?.startCursor;
    setBefore(salesOverviewByService?.pageInfo?.startCursor);
    setAfter(null);
    refetchSalesOverviewByServiceAsync();
  }

  return (
    <>
      {salesOverviewByServiceLoading || salesOverviewByProductLoading || salesOverviewSummaryLoading || salesOverviewByStaffLoading ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Sales overview
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-1 xl:grid-cols-3 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCartBag width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Service sold
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {salesOverviewSummary?.servicesSold}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCartBag width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Product sold
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {salesOverviewSummary?.productsSold}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSale width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total gross sales ({DEFAULT_CURRENCY})
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {salesOverviewSummary?.totalGrossSales}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full grid grid-col-1 xl:grid-cols-3 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSale width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total net sales ({DEFAULT_CURRENCY})
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {salesOverviewSummary?.totalNetSales}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCartBag width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Most popular service
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {salesOverviewSummary?.mostPopularService}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCartBag width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Best selling product
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {salesOverviewSummary?.bestSellingProduct}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        <div className='flex space-x-2'>
          <div className='w-[170px]'>
            <HeadlessDropDown
              options={DROP_DOWN_OPTIONS}
              selectedValue={dropDownSelectedValue}
              setDropDownSelectedValue={setDropDownSelectedValue}
            />
          </div>
          <Paginator pageInfo={salesOverviewByService?.pageInfo} {...{ goToNext, goToPrev }} />
        </div>
      </div>
      <div className='w-full hidden md:flex flex-col space-y-12 overflow-x-scroll'>
        <div className='w-full flex flex-col h-fit border-t border-grey-100'>
          {dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.BY_SERVICE ? (
            <ReportTable rows={serviceRows} headers={BY_SERVICE_HEADER} />
          ) : dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.BY_STAFF_MEMBER ? (
            <ReportTable rows={staffRows} headers={BY_STAFF_MEMBER_HEADER} />
          ) : (
            <ReportTable rows={productRows} headers={BY_PRODUCT_HEADER} />
          )}
        </div>
      </div>
    </>
  )
}

const PromoActivityReport = () => {
  const token = checkReportsTokenFromRoute()
  const _salonId = checkSalonIdFromRoute()
  const { toast, addToast } = useToast()
  const { getSalonFieldValue } = useSalonCache()
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const salonId = getSalonFieldValue('id') || _salonId
  const Headers = [
    { name: 'Promo name' },
    { name: 'Started' },
    { name: 'Ended' },
    { name: 'Status' },
    { name: 'Times used' },
    { name: 'Customers' },
    { name: 'Sales' },
    { name: 'Promo value' }
  ]

  const {
    data: promoSummaryActivityData,
    loading: loadingPromoSummaryActivity,
    refetch: refetchPromoSummaryActivity
  } = usePromoActivitySummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
  })
  const promoActivitySummary = promoSummaryActivityData?.promoActivitySummaryReport;

  const {
    data: promoActivityData,
    loading: loadingPromoActivity,
    refetch: refetchPromoActivity
  } = usePromoActivity({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
    before, after
  })
  const promoActivity = useMemo(() => promoActivityData?.promoActivityReport, [promoActivityData])

  useEffect(() => {
    refetchPromoSummaryActivity()
    refetchPromoActivity()
  }, [selectedDates])

  const loadPromoActivityShimmer = loadingPromoSummaryActivity || loadingPromoActivity;

  const generatePromoActivitiesTableData = (promos: PromoActivity[]) => {
    return promos?.map?.((promo) => ({
      promoName: promo?.promoName,
      start: getNumberMonthAndYearFromDate(promo?.created),
      end: getNumberMonthAndYearFromDate(promo?.ended),
      isActive: promo?.status,
      timesUsed: promo?.promotionsApplied,
      customers: promo?.customers,
      totalAppointmentSales: formatInToPrice(promo?.sales),
      promoValue: promo?.promoType === 'percentage' ? `${promo?.promoValue}%` : formatInToPrice(promo?.promoValue),
    }))
  }

  const formatPromoActivitiesCsvDownloadData = (promos: PromoActivity[]) => {
    return promos?.map?.((promo) => ({
      "Promo Name": promo?.promoName,
      "Started": getNumberMonthAndYearFromDate(promo?.created),
      "Ended": getNumberMonthAndYearFromDate(promo?.ended),
      "Status": promo?.status,
      "Times used": promo?.promotionsApplied,
      "Customers": promo?.customers,
      "Sales": formatInToPrice(promo?.sales),
      "Promo value": promo?.promoType === 'percentage' ? `${promo?.promoValue}%` : formatInToPrice(promo?.promoValue),
    }))
  }

  const rows = generatePromoActivitiesTableData(promoActivity?.nodes)

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_promo_activity_report`;
  const _handleDownload = () => {
    if (promoActivity) {
      handleDownload(formatPromoActivitiesCsvDownloadData(promoActivity?.nodes), filename);
    }
  };

  const downloadExcel = () => {
    if (promoActivity) {
      handleExcelDownload(formatPromoActivitiesCsvDownloadData(promoActivity?.nodes), filename, 'Promo activity');
    }
  }

  const goToNext = () => {
    if (promoActivity?.pageInfo?.hasNextPage) {
      setAfter(promoActivity?.pageInfo?.endCursor)
      setBefore(null)
      refetchPromoActivity()
    }
  }

  const goToPrev = () => {
    if (promoActivity?.pageInfo?.hasPreviousPage) {
      setBefore(promoActivity?.pageInfo?.startCursor)
      setAfter(null)
      refetchPromoActivity()
    }
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      {loadPromoActivityShimmer ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Promos
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-1 xl:grid-cols-3 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgPercent width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total promos
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {promoActivitySummary?.totalPromos}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgPercent width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Active promos
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {promoActivitySummary?.activePromos}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgUsers width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total promo recipients
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {promoActivitySummary?.totalPromoRecipients}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        <div className='flex space-x-2'>
          <Paginator pageInfo={promoActivity?.pageInfo} {...{ goToNext, goToPrev }} />
        </div>
      </div>
      <div className='w-full hidden md:flex flex-col space-y-12 overflow-x-scroll'>
        <div className='w-full flex flex-col h-fit border-t border-grey-100'>
          <ReportTable rows={rows} headers={Headers} loading={loadPromoActivityShimmer} />
        </div>
      </div>
    </>
  )
}

export default SalesReports
