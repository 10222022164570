import { User } from "core/generated"

export const getAddonContent = (addon?: string | null, featureList?: string[]) => {
  if (!addon) {
    return null
  }

  if (addon?.toLowerCase() === 'loyalty') {
    return {
      title: 'Boost customer engagement with Loyalty Program',
      subTitle: 'Offer exclusive rewards, personalized experiences, and enhance customer loyalty, keeping them coming back to your business.',
      featuredList: [{
        name: "Increased Customer Retention",
        description: "Loyalty programs incentivize repeat visits, helping to build a loyal customer base and reduce client churn."
      }, {
        name: "Enhanced Customer Experience",
        description: "Personalized rewards make clients feel valued, fostering a deeper connection and improving overall satisfaction."
      }, {
        name: "Increased Revenue",
        description: "Encouraging frequent visits and higher spending boosts revenue, as customers aim to reach reward tiers and earn points."
      }, {
        name: "Competitive Advantage",
        description: "A unique loyalty program differentiates the salon from competitors, attracting and retaining more clients."
      }]
    }
  }

  if (addon?.toLowerCase() === 'messaging' || addon?.toLowerCase() === 'custom emails') {
    return {
      title: 'Tailor customer experiences with Custom Messaging',
      subTitle: 'Engage your customers with personalized messages, build strong connections, and increase customer retention.',
      featuredList: [{
        name: "",
        description: "Send tailored promotions based on customer preferences"
      }, {
        name: "",
        description: "Create personalized thank-you messages and birthday greetings"
      }, {
        name: "",
        description: "Offer special deals and recommendations for loyal customers"
      }, {
        name: "",
        description: "Deliver relevant content and updates directly to customers' inboxes"
      }]
    }
  }

  if (addon?.toLowerCase() === 'vouchers') {
    return {
      title: 'Delight your customers with incredible Vouchers',
      subTitle: 'Boost sales, attract new customers, and reward loyalty with versatile voucher options.',
      featuredList: [{
        name: "Attract New Customers",
        description: "Vouchers entice potential clients to try your business, increasing your customer base."
      }, {
        name: "Boost Sales",
        description: "Offering vouchers encourages higher spending, as clients are likely to purchase additional services or products."
      }, {
        name: "Promote Loyalty",
        description: "Vouchers can reward repeat clients, fostering loyalty and encouraging future visits."
      }, {
        name: "Special occassions",
        description: "Provide gift vouchers for special occasions and celebrations."
      }]
    }
  }

  if (addon?.toLowerCase() === 'sms reminders') {
    return {
      title: 'Stay connected with Automated SMS Reminders',
      subTitle: 'Keep your customers informed, reduce no-shows, and improve communication with timely reminders.',
      featuredList: [{
        name: "Reduced No-Shows",
        description: "Automated reminders decrease appointment no-shows by keeping clients informed and reminding them of their upcoming visits."
      }, {
        name: "Increased Efficiency",
        description: "Automating reminders saves time and effort for staff, allowing them to focus on providing better customer service."
      }, {
        name: "Improved Customer Experience",
        description: "Clients appreciate timely reminders, enhancing their overall experience and reducing the likelihood of missed appointments."
      }, {
        name: "Cost-Effective",
        description: "Automated SMS reminders are a cost-effective way to ensure clients keep their appointments, reducing revenue loss from no-shows."
      }]
    }
  }

  if (addon?.toLowerCase() === 'memberships') {
    return {
      title: 'Memberships',
      subTitle: '',
      featuredList: Array?.isArray(featureList) ? featureList?.map((feature) => ({
        name: '',
        description: feature
      })) : []
    }
  }

  if (addon?.toLowerCase() === 'premium reports') {
    return {
      title: 'Premium Reports',
      subTitle: '',
      featuredList: Array?.isArray(featureList) ? featureList?.map((feature) => ({
        name: '',
        description: feature
      })) : []
    }
  }
}

export const getAddon = (module: string) => {
  const userData = localStorage.getItem('userData');
  const user: User = userData ? JSON.parse(userData) : null;
  const addons = user?.business?.addons;
  const addon = addons?.find((addon) => addon?.addon?.name?.toLowerCase()?.includes(module))
  return addon;
}