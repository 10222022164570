import { formatServicesCategory } from 'components/uicomponents/bookingSales/utils';
import { formatInToPrice, getHoursAndMinutesString } from 'components/utils/misc';
import { COLORS } from 'constants/colors';
import { Service } from 'core/generated';
import React, { useRef } from 'react'
import { Checkbox, Heading, Paragraph, SearchTerm } from 'ui';

const SelectService = ({ searchItem, filteredServices, selectedServices, addService, showServiceSelection }) => {
  const modalContentRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    modalContentRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const groupedServices = formatServicesCategory(filteredServices);

  // move to top on entry of this page
  React.useEffect(() => {
    if (showServiceSelection) {
      scrollToTop();
    }
  }, [showServiceSelection])
  return (
    <div className='w-full flex flex-col p-4 gap-y-3' ref={modalContentRef}>
      <div className='w-full flex justify-between items-center'>
        <Paragraph size="b3" color={COLORS.GREY[300]}>
          Select service
        </Paragraph>

        <div className="w-2/5">
          <SearchTerm
            placeholder='Search.'
            showIcon={false}
            setDebouncedSearchQuery={searchItem}
          />
        </div>
      </div>
      <div className='w-full flex flex-col space-y-3'>
        {filteredServices && Array?.isArray(filteredServices) && filteredServices?.length ? groupedServices?.map((serviceCategory) => {
          return (
            <div className="w-full flex flex-col" key={serviceCategory?.name}>
              <div className="w-full py-2 px-4 flex justify-between items-center cursor-pointer">
                <div className="flex space-y-2 flex-col">
                  <Heading variant='h3' size='b4' weight='bold' className='capitalize' color={COLORS.GREY[900]}>
                    {serviceCategory?.name} ({serviceCategory?.services?.length})
                  </Heading>
                </div>
              </div>
              <div className="w-full flex flex-col space-y-3">
                {serviceCategory?.services?.map((service: Service) => {
                  const isChecked = selectedServices?.some((selectedService) => selectedService?.id === service?.id);
                  return (
                    <div className="w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex justify-between items-start cursor-pointer" onClick={() => addService(service?.id)}>
                      <div className='flex space-x-2 items-start'>
                        <Checkbox isChecked={isChecked} />
                        <div className='flex flex-col space-y-1'>
                          <Paragraph size="b5" color={COLORS.GREY[300]}>
                            {service?.name}
                          </Paragraph>
                          <Paragraph size="b6" color={COLORS.GREY[300]}>
                            {getHoursAndMinutesString(service?.duration)}
                          </Paragraph>
                        </div>
                      </div>

                      <Paragraph size="b5" color={COLORS.GREY[300]} weight='bold'>
                        {formatInToPrice(service?.price)}
                      </Paragraph>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        }) : null}
      </div>
    </div>
  )
}

export default SelectService