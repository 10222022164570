import React, { useEffect, useMemo, useState } from 'react'
import { CalendarPicker, Heading, HeadlessDropDown, Layout, Paginator, Paragraph, Pill, ReportTable, Tabs, ToastWrapper } from '../ui';
import { COLORS } from 'constants/colors';
import { convertTextToLowercaseAndSnakeCase, createFileNameFromDates, formatDateToOriginalDate, formatInToPrice, formatSnakeCaseText, getDayMonthAndNumberAndTimeFromDate, getDefaultQueryDates, getNumberMonthAndYearFromDate, getNumberMonthYearAndTimeFromDate, handleDownload, handleExcelDownload } from '../components/utils/misc';
import { checkReportsTokenFromRoute, checkSalonIdFromRoute } from '../components/utils/token';
import { useSalonCache } from 'hooks/useSalonCache';
import axios from 'axios';
import { AppointmentList, AppointmentListConnection, AppointmentListReportDocument, AppointmentListSummaryReport, AppointmentListSummaryReportDocument, AppointmentOverviewByService, AppointmentOverviewByStaff, AppointmentOverviewReportByServiceDocument, AppointmentOverviewReportByStaffDocument, AppointmentOverviewSummaryReport, AppointmentOverviewSummaryReportDocument, CancelledAppointmentReportDocument, CancelledAppointments, CancelledAppointmentsConnection, CancelledAppointmentSummaryReport, CancelledAppointmentSummaryReportDocument, DeletedAppointmentReportDocument } from 'core/generated';
import { useToast } from 'hooks/useToast';
import { SvgCalendar, SvgCreditCard, SvgDownGrowthIndicator, SvgGrowthIndicator, SvgInfo, SvgIonTimeOutline, SvgLocationPin, SvgSale, SvgUser } from 'ui';
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { appointmentTrendCountGraphData, customerSpendGraphData } from '../components/uicomponents/reportGraphUtils';
import ExportDataToCsvOrXlsx from '../components/uicomponents/ExportDataToCsvOrXlsx';
import { DEFAULT_CURRENCY } from 'constants/currency';
import { useAppointmentList, useAppointmentOverviewService, useAppointmentOverviewStaff, useAppointmentOverviewSummary, useAppointmentReportSummary, useCancelledAppointmentList, useCancelledAppointmentSummary, useDeletedAppointments } from 'api/useReports';
import { canPerformAction } from 'components/utils/permission';
import AddonSubscription from 'components/uicomponents/AddonSubscription';
import { useLocation } from 'react-router-dom';
Chart.register(...registerables)

const APPOINTMENT_REPORTS_TAB_NAME = {
  APPOINTMENT_OVERVIEW: "Appointment overview",
  APPOINTMENT_LIST: "Appointment list",
  CANCELLED_APPOINTMENT: "Cancelled appointment",
  DELETED_APPOINTMENT: 'Deleted appointment'
};


const AppointmentReports = () => {
  const APPOINTMENT_REPORTS_TABS = [
    {
      key: APPOINTMENT_REPORTS_TAB_NAME.APPOINTMENT_OVERVIEW,
      title: APPOINTMENT_REPORTS_TAB_NAME.APPOINTMENT_OVERVIEW,
      show: canPerformAction("Reports::Appointments::ViewOverview"),
      canAccess: canPerformAction("Reports::Appointments::ViewOverview")
    },
    {
      key: APPOINTMENT_REPORTS_TAB_NAME.APPOINTMENT_LIST,
      title: APPOINTMENT_REPORTS_TAB_NAME.APPOINTMENT_LIST,
      show: true,
      canAccess: canPerformAction("Reports::Appointments::ViewList")
    },
    {
      key: APPOINTMENT_REPORTS_TAB_NAME.CANCELLED_APPOINTMENT,
      title: APPOINTMENT_REPORTS_TAB_NAME.CANCELLED_APPOINTMENT,
      show: true,
      canAccess: true
    },
    {
      key: APPOINTMENT_REPORTS_TAB_NAME.DELETED_APPOINTMENT,
      title: APPOINTMENT_REPORTS_TAB_NAME.DELETED_APPOINTMENT,
      show: true,
      canAccess: true
    }
  ];

  // re-order APPOINTMENT_REPORTS_TABS? based off show property
  APPOINTMENT_REPORTS_TABS?.sort((a, b) => {
    if (a.canAccess && !b.canAccess) return -1
    if (!a.canAccess && b.canAccess) return 1
    return 0
  })
  // remove canAccess
  APPOINTMENT_REPORTS_TABS?.forEach((tab) => {
    delete tab.canAccess
  })

  const [activeTab, setActiveTab] = useState<string>(APPOINTMENT_REPORTS_TABS.filter((tab) => tab?.show)[0]?.key);

  const getTabView = () => {
    switch (activeTab) {
      case APPOINTMENT_REPORTS_TAB_NAME.APPOINTMENT_OVERVIEW:
        return <AppointmentOverview />
      case APPOINTMENT_REPORTS_TAB_NAME.APPOINTMENT_LIST:
        return canPerformAction("Reports::Appointments::ViewList") ? <AppointmentListReport /> : <AddonSubscription module='reports' addToast={null} reloadPage={null} />
      case APPOINTMENT_REPORTS_TAB_NAME.DELETED_APPOINTMENT:
        return <DeletedAppointmentReport />
      default:
        return <CancelledAppointmentReport />
    }
  }

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlTab = params.get('tab');

  useEffect(() => {
    if (urlTab) {
      const checkTab = APPOINTMENT_REPORTS_TABS?.find((tab) => convertTextToLowercaseAndSnakeCase(tab?.key) === convertTextToLowercaseAndSnakeCase(urlTab));
      if (checkTab && checkTab?.show) {
        setActiveTab(checkTab?.key)
      }
    }
  }, [urlTab])

  const changeTab = (tab: string) => {
    setActiveTab(tab)
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', convertTextToLowercaseAndSnakeCase(tab));
    window.history.replaceState(null, '', `?${searchParams.toString()}`);
  }

  return (
    <Layout
      pageTitle={
        activeTab
      }
      pageLevel={3}
    >
      <Tabs
        tabs={APPOINTMENT_REPORTS_TABS}
        activeTab={activeTab}
        setActiveTab={changeTab}
      />
      <div className='w-full pb-20'>
        {getTabView()}
      </div>
    </Layout>
  )
}

const AppointmentOverview = () => {
  const token = checkReportsTokenFromRoute()
  const { getSalonFieldValue } = useSalonCache()
  const _salonId = checkSalonIdFromRoute()
  const salonId = getSalonFieldValue('id') || _salonId;
  const [appointmentSummaryTrendType, setAppointmentSummaryTrendType] = useState('monetary')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { toast, addToast } = useToast()

  const DROP_DOWN_OPTIONS = [{
    value: "teamMember",
    label: "Staff"
  }, {
    value: "service",
    label: "Service"
  }]
  const DROP_DOWN_OPTIONS_NAME = {
    TEAM_MEMBER: "teamMember",
    SERVICE: "service"
  }
  const Headers = [
    { name: "Staff" },
    { name: "All" },
    { name: "No Show" },
    { name: "Cancelled" },
    { name: "Completed" },
    { name: "Value" }
  ]

  const Service_Headers = [
    { name: "Service name" },
    { name: "Appointments" },
    { name: "Value" }
  ];
  const [dropDownSelectedValue, setDropDownSelectedValue] = useState<string | null>('teamMember');
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(getDefaultQueryDates());

  const generateStaffAppointmentOverviewTableData = (
    appointments: AppointmentOverviewByStaff[]
  ) => {
    return appointments?.map?.((appointment) => ({
      staffName: appointment?.staffMember,
      totalAppointments: appointment?.all,
      totalNoShowAppointments: appointment?.noShow,
      totalCancelledAppointments: appointment?.cancelled,
      totalCompletedAppointments: appointment?.completed,
      totalCompletedAppointmentSales: appointment?.value
    }));
  };

  const generateServicesAppointmentOverviewTableData = (
    appointments: AppointmentOverviewByService[]
  ) => {
    return appointments?.map?.((appointment) => ({
      name: appointment?.service,
      appointments: appointment?.appointments,
      value: formatInToPrice(appointment?.value),
    }));
  };

  const {
    data: appointmentSummaryData,
    loading: appointmentSummaryLoading,
    refetch: refetchAppointmentSummary
  } = useAppointmentOverviewSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const appointmentSummary = useMemo(() => appointmentSummaryData?.appointmentOverviewSummaryReport, [appointmentSummaryData])

  const {
    data: appointmentStaffOverviewData,
    loading: appointmentStaffOverviewLoading,
    refetch: refetchAppointmentStaffOverview
  } = useAppointmentOverviewStaff({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const appointmentStaffOverview = useMemo(() => appointmentStaffOverviewData?.appointmentOverviewReportByStaff, [appointmentStaffOverviewData])

  const {
    data: appointmentServiceOverviewData,
    loading: appointmentServiceOverviewLoading,
    refetch: refetchAppointmentServiceOverview
  } = useAppointmentOverviewService({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const appointmentServiceOverview = useMemo(() => appointmentServiceOverviewData?.appointmentOverviewReportByService, [appointmentServiceOverviewData])
  useEffect(() => {
    refetchAppointmentSummary()
    refetchAppointmentStaffOverview()
    refetchAppointmentServiceOverview()
  }, [selectedDates, dropDownSelectedValue])

  const staffAppointmentOverviewRows = generateStaffAppointmentOverviewTableData(appointmentStaffOverview || []);
  const servicesAppointmentOverviewRows = generateServicesAppointmentOverviewTableData(appointmentServiceOverview || []);

  const formatStaffAppointmentOverviewCsvDownloadData = (
    appointments: AppointmentOverviewByStaff[]
  ) => {
    return appointments?.map?.((appointment) => ({
      "Staff": appointment?.staffMember,
      All: appointment?.all,
      "No Show": appointment?.noShow,
      Cancelled: appointment?.cancelled,
      Completed: appointment?.completed,
      "Completed Value": appointment?.value,
    }));
  };

  const formatServiceAppointmentOverviewCsvDownloadData = (
    appointments: AppointmentOverviewByService[]
  ) => {
    return appointments?.map?.((appointment) => ({
      "Service name": appointment?.service,
      "Appointments": appointment?.appointments,
      "Value": formatInToPrice(appointment?.value),
    }));
  };


  const staffFilename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_staff_appointment_overview_report`;
  const serviceFilename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_service_appointment_overview_report`;
  const _handleDownload = () => {
    if (appointmentStaffOverview && dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.TEAM_MEMBER) {
      handleDownload(formatStaffAppointmentOverviewCsvDownloadData(appointmentStaffOverview), staffFilename);
    }

    if (appointmentServiceOverview && dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.SERVICE) {
      handleDownload(formatServiceAppointmentOverviewCsvDownloadData(appointmentServiceOverview), serviceFilename);
    }
  };
  const downloadExcel = () => {
    if (appointmentStaffOverview && dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.TEAM_MEMBER) {
      handleExcelDownload(formatStaffAppointmentOverviewCsvDownloadData(appointmentStaffOverview), staffFilename, 'Staff Appointment')
    }

    if (appointmentServiceOverview && dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.SERVICE) {
      handleExcelDownload(formatServiceAppointmentOverviewCsvDownloadData(appointmentServiceOverview), serviceFilename, 'Service Appointment');
    }
  }


  const trendData = customerSpendGraphData(appointmentSummary?.appointmentProductsGraphData || [], appointmentSummary?.appointmentServiceGraphData || []);
  const trendCountData = appointmentTrendCountGraphData(appointmentSummary?.appointmentCountGraphData)
  return (
    <>
      {appointmentSummaryLoading || appointmentServiceOverviewLoading || appointmentStaffOverviewLoading ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Appointment overview
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full flex flex-col lg:flex-row gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full grid grid-cols-2 gap-3'>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgCreditCard width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='bold' className=''>
                Total appointments
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {appointmentSummary?.totalAppointments || 0}
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgCreditCard width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='bold' className=''>
                No-show appointments
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {appointmentSummary?.noShowAppointments || 0}
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgCreditCard width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='bold' className=''>
                Cancelled appointments
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {appointmentSummary?.cancelledAppointments || 0}
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgUser width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='bold' className='flex space-x-2 items-center'>
                Busiest day
                <div className='group flex relative text-grey-300'>
                  <SvgInfo width='15px' height='15px' />
                  <span className='w-[200px] group-hover:opacity-100 transition-opacity bg-grey-50 p-2 text-b6 text-grey-300 rounded-md absolute right-2 -translate-x-2 opacity-0 m-4 mx-auto z-30'>
                    This is the day with the highest number of appointments.
                  </span>
                </div>
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {appointmentSummary?.busiestDay}
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgCreditCard width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='bold' className=''>
                Completed appointments
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {appointmentSummary?.completedAppointments || 0}
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgUser width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='bold' className='flex space-x-2 items-center'>
                MVP Staff
                <div className='group flex relative text-grey-300'>
                  <SvgInfo width='15px' height='15px' />
                  <span className='w-[200px] group-hover:opacity-100 transition-opacity bg-grey-50 p-2 text-b6 text-grey-300 rounded-md absolute right-2 -translate-x-2 opacity-0 m-4 mx-auto z-30'>
                    This is the staff with the highest number of completed appointments.
                  </span>
                </div>
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {appointmentSummary?.mvpTeamMember}
              </Paragraph>
            </div>
          </div>
        </div>
        <div className='w-full flex flex-col p-4 space-y-4 rounded-md border border-grey-100 bg-grey-50/20'>
          <div className='w-full flex justify-between items-center'>
            <div className='flex flex-col space-y-2 w-[60%]'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Trends and Highlights
              </Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[400]} className=''>
                Find your trends and highlights across staff appointment reports here
              </Paragraph>
            </div>
          </div>
          {appointmentSummaryTrendType === 'monetary' ? (
            <div className='w-full flex flex-col space-y-3'>
              <div className='w-full flex justify-between'>
                <div className='flex flex-col justify-end space-y-2'>
                  <Paragraph size='b6' color={COLORS.GREY[300]} weight='semiBold' className='uppercase'>
                    TOTAL Appointments
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                    {appointmentSummary?.totalAppointments}
                  </Paragraph>
                </div>

                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className='flex space-x-1 items-center justify-end'>
                    <span>{appointmentSummary?.saleTrend}%</span>
                    {appointmentSummary?.saleTrend?.toString().includes("-") ? <SvgDownGrowthIndicator width="16px" height="16px" /> : <SvgGrowthIndicator width="16px" height="16px" />}
                  </Paragraph>
                  <Paragraph size='b6' color={COLORS.GREY[300]} weight='light' className=''>
                    VS LAST MONTH%
                  </Paragraph>
                </div>
              </div>
              <div className='w-full h-[235px]'>
                <Line options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      position: "bottom",
                      text: 'Appointments Revenue',
                    },
                    tooltip: {
                      enabled: true,
                      mode: 'index',
                      intersect: false,
                    }
                  },
                  scales: {
                    x: {
                      type: 'category',
                    },
                    y: {
                      beginAtZero: true,
                      min: 1,
                      ticks: {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        callback: function (value, index, values) {
                          return DEFAULT_CURRENCY + value.toLocaleString("en-US");
                        }
                      }
                    },
                  },
                }} data={trendData} />
              </div>
              <div className='w-full flex gap-3 justify-center items-center'>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#7EA05C] mr-2'></span>
                  Services
                </Paragraph>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#E4B91F] mr-2'></span>
                  Products
                </Paragraph>
              </div>
            </div>
          ) : (
            <div className='w-full flex flex-col space-y-3'>
              <div className='w-full flex justify-between'>
                <div className='flex flex-col justify-end space-y-2'>
                  <Paragraph size='b6' color={COLORS.GREY[300]} weight='semiBold' className='uppercase'>
                    Appointment Counts
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                    {appointmentSummary?.totalAppointments}
                  </Paragraph>
                </div>

                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className='flex space-x-1 items-center justify-end'>
                    <span>{appointmentSummary?.saleTrend}%</span>
                    {appointmentSummary?.saleTrend?.toString().includes("-") ? <SvgDownGrowthIndicator width="16px" height="16px" /> : <SvgGrowthIndicator width="16px" height="16px" />}
                  </Paragraph>
                  <Paragraph size='b6' color={COLORS.GREY[300]} weight='light' className=''>
                    VS LAST MONTH%
                  </Paragraph>
                </div>
              </div>
              <div className='w-full h-[235px]'>
                <Line options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      position: "bottom",
                      text: 'Appointment Counts',
                    },
                    tooltip: {
                      enabled: true,
                      mode: 'index',
                      intersect: false,
                    }
                  },
                  scales: {
                    x: {
                      type: 'category',
                    },
                    y: {
                      beginAtZero: true,
                      min: 1,
                    },
                  },
                }} data={trendCountData} />
              </div>
              <div className='w-full flex gap-3 justify-center items-center'>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#7EA05C] mr-2'></span>
                  Services
                </Paragraph>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#CFE6C1] mr-2'></span>
                  Products
                </Paragraph>
              </div>
            </div>
          )}
          <div className='w-full flex gap-3 justify-center items-center'>
            <span className={`w-[12px] h-[12px] ${appointmentSummaryTrendType === 'monetary' ? "bg-[#7EA05C]" : "bg-[#CFE6C1]"} rounded-full cursor-pointer`} onClick={() => setAppointmentSummaryTrendType('monetary')}></span>
            <span className={`w-[12px] h-[12px] ${appointmentSummaryTrendType === 'monetary' ? "bg-[#CFE6C1]" : "bg-[#7EA05C]"} rounded-full cursor-pointer`} onClick={() => setAppointmentSummaryTrendType('sales')}></span>
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-between border-t border-grey-100 p-3 md:p-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        <div className='flex space-x-2 items-center'>
          <div className='w-full h-fit md:w-[150px]'>
            <HeadlessDropDown options={DROP_DOWN_OPTIONS} selectedValue={dropDownSelectedValue} setDropDownSelectedValue={setDropDownSelectedValue} />
          </div>
        </div>
      </div>
      <div className="w-full hidden md:flex flex-col space-y-12 overflow-x-scroll">
        <div className='w-full flex flex-col h-fit border-t border-grey-100'>
          {dropDownSelectedValue === DROP_DOWN_OPTIONS_NAME.TEAM_MEMBER ? (
            <ReportTable rows={staffAppointmentOverviewRows} headers={Headers} hasFooterBg={false} />
          ) : (
            <ReportTable rows={servicesAppointmentOverviewRows} headers={Service_Headers} hasFooterBg={false} />
          )}
        </div>
      </div>
    </>
  )
}

const AppointmentListReport = () => {
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id')
  const salonBranchName = getSalonFieldValue('branchName');
  const salonCountryCode = getSalonFieldValue('countryCode');
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(getDefaultQueryDates());
  const { addToast, toast } = useToast()
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const Headers = [
    { name: "Created Date" },
    { name: "Scheduled Date" },
    { name: "Client" },
    { name: "Service" },
    { name: "Location" },
    { name: "Duration" },
    { name: "Status" },
    { name: "Price" }
  ];

  const {
    data: appointmentSummaryData,
    loading: loadingAppointmentSummary,
    refetch: refetchAppointmentSummary
  } = useAppointmentReportSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const appointmentSummary = useMemo(() => appointmentSummaryData?.appointmentListSummaryReport, [appointmentSummaryData])

  const {
    data: appointmentListData,
    loading: loadingAppointmentData,
    refetch: refetchAppointmentData
  } = useAppointmentList({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
    first: 30, after, before
  })
  const appointments = useMemo(() => appointmentListData?.appointmentListReport, [appointmentListData])

  const goToPrev = () => {
    if (appointments?.pageInfo?.hasPreviousPage) {
      setAfter(null)
      setBefore(appointments?.pageInfo?.startCursor)
      refetchAppointmentData()
    }
  }

  const goToNext = () => {
    if (appointments?.pageInfo?.hasNextPage) {
      setBefore(null)
      setAfter(appointments?.pageInfo?.endCursor)
      refetchAppointmentData()
    }
  }

  useEffect(() => {
    refetchAppointmentSummary()
    refetchAppointmentData()
  }, [selectedDates])

  const loadAppointmentShimmer = loadingAppointmentSummary || !appointments;

  const generateAppointmentListTableData = (
    appointments: AppointmentList[],
    branch: string,
    countryCode?: string
  ) => {
    return appointments?.map?.((appointment) => ({
      createdDate: getNumberMonthAndYearFromDate(appointment?.createdAt),
      scheduledDate: getDayMonthAndNumberAndTimeFromDate(
        appointment?.scheduledDate
      ).replace("by", ","),
      client:
        appointment?.client,
      service: appointment?.service,
      location: branch,
      duration: appointment?.duration,
      status: formatSnakeCaseText(appointment?.status),
      price: formatInToPrice(appointment?.price),
    }));
  };

  const formatAppointmentListCsvDownloadData = (
    appointments: AppointmentList[],
    branch: string,
    countryCode?: string
  ) => {
    return appointments?.map?.((appointment) => ({
      "Created Date": getNumberMonthAndYearFromDate(appointment?.createdAt),
      "Scheduled Date": getDayMonthAndNumberAndTimeFromDate(
        appointment?.scheduledDate
      ).replace("by", ","),
      Client: appointment?.client,
      Services: appointment?.service,
      Location: branch,
      Duration: appointment?.duration,
      Status: formatSnakeCaseText(appointment?.status),
      Price: formatInToPrice(appointment?.price),
    }));
  };

  const rows = generateAppointmentListTableData(appointments?.nodes || [], salonBranchName || "", salonCountryCode || "NG")
  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_appointment_list_report`;
  const _handleDownload = () => {
    if (appointments) {
      handleDownload(formatAppointmentListCsvDownloadData(appointments?.nodes, salonBranchName, salonCountryCode || "NG"), filename);
    }
  };
  const downloadExcel = () => {
    if (appointments) {
      handleExcelDownload(formatAppointmentListCsvDownloadData(appointments?.nodes, salonBranchName, salonCountryCode || "NG"), filename, 'Appointment List');
    }
  };
  return (
    <>
      <ToastWrapper toast={toast} />
      {loadAppointmentShimmer ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Appointment list
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-2 xl:grid-cols-4 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCreditCard width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total appointments
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {appointmentSummary?.totalAppointments}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgIonTimeOutline width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total duration
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {appointmentSummary?.totalDuration}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCalendar width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Busiest day
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {appointmentSummary?.busiestDay}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSale width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Most frequent service
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {appointmentSummary?.mostBookedService}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        <div className='flex space-x-2 items-center'>
          <Paginator pageInfo={appointments?.pageInfo} {...{ goToNext, goToPrev }} />
        </div>
      </div>
      <div className="w-full flex flex-col space-y-12 overflow-x-scroll">
        <div className='w-full hidden md:flex flex-col h-fit border-t border-grey-100'>
          <ReportTable rows={rows} headers={Headers} />
        </div>
      </div>
    </>
  )
}

const CancelledAppointmentReport = () => {
  const token = checkReportsTokenFromRoute()
  const { getSalonFieldValue } = useSalonCache()
  const _salonId = checkSalonIdFromRoute()
  const salonId = getSalonFieldValue('id') || _salonId
  const { addToast, toast } = useToast()
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(getDefaultQueryDates());
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const Headers = [
    { name: "Customer" },
    { name: "Phone Number" },
    { name: "Email" },
    { name: "Service" },
    { name: "Price" },
    { name: "Appointment Date" },
    { name: "Cancelled" },
    { name: "Status" },
  ]

  const {
    data: cancelledAppointmentData,
    loading: loadingCancelledAppointmentData,
    refetch: refetchCancelledAppointmentData
  } = useCancelledAppointmentList({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
    first: 30, after, before
  })
  const cancelledAppointments = useMemo(() => cancelledAppointmentData?.cancelledAppointmentReport, [cancelledAppointmentData])

  const {
    data: cancellationSummaryData,
    loading: loadingCancellationSummaryData,
    refetch: refetchCancellationSummaryData
  } = useCancelledAppointmentSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const cancelledAppointmentSummary = useMemo(() => cancellationSummaryData?.cancelledAppointmentSummaryReport, [cancellationSummaryData])

  useEffect(() => {
    refetchCancelledAppointmentData()
    refetchCancellationSummaryData()
  }, [selectedDates])

  const formatCancelledAppointmentsCsvDownloadData = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    appointments: CancelledAppointments[], countryCode?: string
  ) => {
    return appointments?.map?.((appointment) => ({
      Customer:
        appointment?.customer,
      "Phone Number":
        appointment?.phoneNumber,
      Email: appointment?.email,
      Service: appointment?.service,
      Price: formatInToPrice(appointment?.price),
      Appointment: getNumberMonthAndYearFromDate(appointment?.appointmentDate),
      Cancelled: getDayMonthAndNumberAndTimeFromDate(
        appointment?.cancelledAt
      ).replace("by", ","),
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const generateCancelledAppointmentListTableData = (appointments: CancelledAppointments[], countryCode?: string) => {
    return appointments?.map?.((appointment) => ({
      customer: CancelledAppointmentDot(appointment?.customer),
      phoneNumber: appointment?.phoneNumber,
      email: appointment?.email,
      service: appointment?.service,
      price: formatInToPrice(appointment?.price),
      appointmentDate: getNumberMonthAndYearFromDate(appointment?.appointmentDate),
      cancelled: getDayMonthAndNumberAndTimeFromDate(appointment?.cancelledAt).replace('by', ','),
      status: appointment?.status && appointment?.status !== '-' ? (
        <Pill rounded='lg' variant={appointment?.status?.toLowerCase() === 'applied' ? 'success' : 'warning'}>{appointment?.status}</Pill>
      ) : '-'
    }))
  };

  const CancelledAppointmentDot = (client: string) => {
    return (
      <div className='flex items-center space-x-2'>
        <span className="text-b2 text-red-400">
          •
        </span>
        <Paragraph size='b6' color={COLORS.GREY[900]}>
          {client}
        </Paragraph>
      </div>
    )
  }

  const rows = generateCancelledAppointmentListTableData(cancelledAppointments?.nodes || [], "NG")
  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_cancelled_appointments_report`;
  const _handleDownload = () => {
    if (cancelledAppointments) {
      handleDownload(formatCancelledAppointmentsCsvDownloadData(cancelledAppointments?.nodes, "NG"), filename);
    }
  };

  const downloadExcel = () => {
    if (cancelledAppointments) {
      handleExcelDownload(formatCancelledAppointmentsCsvDownloadData(cancelledAppointments?.nodes, "NG"), filename, 'Cancelled Appointments');
    }
  };

  const goToNext = () => {
    if (cancelledAppointments?.pageInfo?.hasNextPage) {
      const after = cancelledAppointments?.pageInfo?.endCursor
      setAfter(after);
      setBefore(null);
      refetchCancelledAppointmentData();
    }
  }

  const goToPrev = () => {
    if (cancelledAppointments?.pageInfo?.hasPreviousPage) {
      const before = cancelledAppointments?.pageInfo?.startCursor;
      setBefore(before);
      setAfter(null);
      refetchCancelledAppointmentData();
    }
  }
  return (
    <>
      <ToastWrapper toast={toast} />
      {loadingCancelledAppointmentData || loadingCancellationSummaryData ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Cancelled Appointments
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full flex gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-1/2 flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCalendar width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total cancelled appointments
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {cancelledAppointmentSummary?.totalCancelledAppointments}
            </Paragraph>
          </div>
        </div>
        <div className='w-1/2 flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCreditCard width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Lost revenue ({DEFAULT_CURRENCY})
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {formatInToPrice(cancelledAppointmentSummary?.lostRevenue)}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        <div className='flex space-x-2 items-center'>
          <Paginator pageInfo={cancelledAppointments?.pageInfo} {...{ goToNext, goToPrev }} />
        </div>
      </div>
      <div className="w-full flex flex-col space-y-12 overflow-x-scroll">
        <div className='w-full hidden md:flex flex-col h-fit border-t border-grey-100'>
          <ReportTable rows={rows} headers={Headers} />
        </div>
      </div>
    </>
  )
}

const DeletedAppointmentReport = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const { addToast, toast } = useToast()
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(getDefaultQueryDates());
  const Headers = [
    { name: "Customer" },
    { name: "Phone Number" },
    { name: "Email" },
    { name: "Service" },
    { name: "Price" },
    { name: "Appointment Date" },
    { name: "Deleted by" },
    { name: "Date deleted" }
  ];

  const {
    data: deletedAppointmentsData,
    loading: loadingDeletedAppointments,
    refetch: refetchAppointments
  } = useDeletedAppointments({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
  })
  const cancelledAppointments = useMemo(() => deletedAppointmentsData?.deletedAppointmentReport, [deletedAppointmentsData])

  useEffect(() => {
    refetchAppointments()
  }, [selectedDates])


  const formatCancelledAppointmentsCsvDownloadData = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    appointments: CancelledAppointments[], countryCode?: string
  ) => {
    return appointments?.map?.((appointment) => ({
      Customer:
        appointment?.customer,
      "Phone Number":
        appointment?.phoneNumber,
      Email: appointment?.email,
      Service: appointment?.service,
      Price: formatInToPrice(appointment?.price),
      Appointment: getNumberMonthAndYearFromDate(appointment?.appointmentDate),
      "Deleted By": appointment?.deletedBy,
      "Date Deleted": getNumberMonthYearAndTimeFromDate(appointment?.deletedAt)
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const generateCancelledAppointmentListTableData = (appointments: CancelledAppointments[], countryCode?: string) => {
    return appointments?.map?.((appointment) => ({
      customer: CancelledAppointmentDot(appointment?.customer),
      phoneNumber: appointment?.phoneNumber,
      email: appointment?.email,
      service: appointment?.service,
      price: formatInToPrice(appointment?.price),
      appointmentDate: getNumberMonthAndYearFromDate(appointment?.appointmentDate),
      deletedBy: appointment?.deletedBy,
      dateDeleted: getNumberMonthYearAndTimeFromDate(appointment?.deletedAt)
    }))
  };

  const CancelledAppointmentDot = (client: string) => {
    return (
      <div className='flex items-center space-x-2'>
        <span className="text-b2 text-red-400">
          •
        </span>
        <Paragraph size='b6' color={COLORS.GREY[900]}>
          {client}
        </Paragraph>
      </div>
    )
  }

  const rows = generateCancelledAppointmentListTableData(cancelledAppointments || [], "NG")
  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_deleted_appointments_report`;
  const _handleDownload = () => {
    if (cancelledAppointments) {
      handleDownload(formatCancelledAppointmentsCsvDownloadData(cancelledAppointments, "NG"), filename);
    }
  };

  const downloadExcel = () => {
    if (cancelledAppointments) {
      handleExcelDownload(formatCancelledAppointmentsCsvDownloadData(cancelledAppointments, "NG"), filename, 'Deleted Appointments');
    }
  };

  return (
    <>
      <ToastWrapper toast={toast} />
      {loadingDeletedAppointments ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Deleted Appointments
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full flex gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-1/2 flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCalendar width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total deleted appointments
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {rows?.length}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col space-y-12 overflow-x-scroll">
        <div className='w-full hidden md:flex flex-col h-fit border-t border-grey-100'>
          <ReportTable rows={rows} headers={Headers} />
        </div>
      </div>
    </>
  )
}

export default AppointmentReports
