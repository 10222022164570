import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgProcessingFeeIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 25c-.738 0-1.376.405-1.723 1h-5.63l2.648-5.092c.227.055.461.092.705.092 1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3c0 .679.235 1.298.616 1.801L2.113 26.54A1 1 0 0 0 3 28h7.277A1.994 1.994 0 0 0 14 27a2 2 0 0 0-2-2Zm-4-8a1 1 0 0 1 0 2 1 1 0 0 1 0-2Zm21.887 9.539-4.04-7.771A2 2 0 1 0 24 20c.075 0 .147-.014.22-.023L27.353 26h-4.537A2.995 2.995 0 0 0 20 24c-1.654 0-3 1.346-3 3s1.346 3 3 3a2.995 2.995 0 0 0 2.816-2H29a1 1 0 0 0 .887-1.461ZM20 28a1 1 0 0 1 0-2 1 1 0 0 1 0 2Zm1-20a3 3 0 0 0-.705.092L16.887 1.54A1.016 1.016 0 0 0 16 1c-.357 0-.715.207-.887.539L11.22 9.023C11.148 9.014 11.076 9 11 9a2 2 0 1 0 1.846 1.232L16 4.168l2.616 5.03A2.97 2.97 0 0 0 18 11c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3Zm0 4a1 1 0 0 1 0-2 1 1 0 0 1 0 2Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgProcessingFeeIcon;
