import * as React from 'react'
import { Logo } from 'ui/atoms/logo'
import axios from 'axios'
import Paragraph from 'ui/atoms/paragraph/Paragraph'
import { COLORS } from 'constants/colors'
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper'
import { useToast, useUserCache } from 'hooks'
import OtpInput from 'ui/molecules/input/OtpInput'
import { OTP_COUNTER } from 'constants/otpCounter'
import Button from 'ui/atoms/button/Button'
import { PAGE_ROUTES } from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import { Heading, SvgEmailSent, SvgLogo, ToastProps } from 'ui'

const VerifyEmail = () => {
  const navigate = useNavigate();

  const next = () => {
    navigate(PAGE_ROUTES.COMPLETE, { replace: true })
  }

  const logout = () => {
    localStorage.clear()
    navigate(PAGE_ROUTES.LOGIN, { replace: true })
  }
  return (
    <main className='w-full flex items-center justify-center h-screen bg-white'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full bg-white rounded-lg shadow-md border border-grey-100/50 mx-auto py-8 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-9'>
              <div className='flex flex-col items-center space-y-3'>
                <SvgLogo width="60px" height="100%" />
                <Heading
                  variant='h1'
                  size='h8'
                  weight='medium'
                  className='w-full text-center'
                >
                  Verify Email
                </Heading>
                <Paragraph
                  size='b5'
                  weight='medium'
                  className='w-full text-center max-w-[400px]'
                  color={COLORS.GREY[400]}
                >
                  We have sent you an email to complete verification to secure your account.
                </Paragraph>
              </div>

              <div className='w-full max-w-[250px] max-h-[250px] flex justify-center items-center'>
                <SvgEmailSent width="100%" height="100%" />
              </div>

              <Button
                variant='primary'
                className=''
                size='lg'
                rounded='lg'
                onClick={next}
              >
                Continue setup
              </Button>
              <Button
                variant='transparent'
                className='mx-auto'
                size='none'
                onClick={logout}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default VerifyEmail