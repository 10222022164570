import React from 'react'
import { COLORS } from 'constants/colors'
import { Button, Heading, Paragraph } from '../../ui'
import { formatInToPrice } from 'components/utils/misc'
type ActionModalProps = {
  isVisible: boolean
  closeModal: (action: 'continue' | 'back') => void
  totalPrice: number
  amountLeft?: number
  amountClientPaid: number
  setCreditWallet: (action: boolean) => void
  deductWallet?: boolean
  setDeductWallet?: (action: boolean) => void
  walletBalance?: number
}
const CreditWalletBalance = ({
  isVisible,
  closeModal,
  totalPrice,
  amountLeft,
  amountClientPaid,
  setCreditWallet,
  deductWallet,
  walletBalance,
  setDeductWallet
}: ActionModalProps) => {
  const _amountLeft = amountLeft && amountLeft > 0 && amountLeft < totalPrice;
  const _amount = _amountLeft ? amountLeft : totalPrice;
  return (
    isVisible ? (
      <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50">
        <div className="table-row">
          <div className="table-cell align-middle">
            <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
              <div className="flex flex-col items-center space-y-6">
                <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>Are you sure</Heading>
                {deductWallet ? <Paragraph size="b5" weight='normal' className='w-full text-center max-w-[400px] py-2' color={COLORS.GREY[400]}>
                  The total price for this appointment is {formatInToPrice(totalPrice)}, you’re about to enter {formatInToPrice(amountClientPaid)} which will leave a negative balance of <span className='font-bold text-red-800'><strong>-{formatInToPrice(amountClientPaid - walletBalance)}</strong></span> on the customer’s wallet
                </Paragraph> :
                  <Paragraph size="b5" weight='normal' className='w-full text-center max-w-[400px] py-2' color={COLORS.GREY[400]}>
                    The total price for this appointment is {formatInToPrice(totalPrice)}, you’re about to enter {formatInToPrice(amountClientPaid)} which will leave a credit of {formatInToPrice(amountClientPaid - _amount)} that’ll be added to the customer’s wallet
                  </Paragraph>}

                <Button
                  variant="primary"
                  size='lg'
                  rounded='lg'
                  fontSize='b5'
                  className='capitalize'
                  onClick={() => {
                    closeModal('back')
                    setCreditWallet(false)
                    setDeductWallet(false)
                  }}
                >
                  No
                </Button>
                <Button
                  variant="secondary"
                  size='lg'
                  rounded='lg'
                  fontSize='b5'
                  className='capitalize'
                  onClick={() => {
                    closeModal('continue')
                    setCreditWallet(true)
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null
  )
}

export default CreditWalletBalance