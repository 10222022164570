/* eslint-disable @typescript-eslint/no-explicit-any */
import { Appointment, ClosedPeriod, GraphData, Package, PackageVoucher, Product, Sale, Salon, SalonHour, Service, StaffHour, StaffTimeOff,
  LoyaltySalesReport,
  ClientMilestone,
  SalonStaffHour,  } from "core/generated";
import { WorkingScheduleHoursForm } from "../../pages/Staff";
import { formatSnakeCaseText, removeZInTimeStringAndExtraZeros, sanitizeClientUploadPhoneNumber } from "../utils/misc";
import { groupToDates } from "./appointmentComponents/utils";
import { LineGraphSummaryData } from "./homeComponents/types";
// import { PERMISSION_LEVELS_LISTING } from "./constants";
import { ClientInput, ConvertedTimeOffDate, HoursWeekInput, LoyaltyMilestoneInput, ProductInput, UploadStaffInput, WorkingHourOrTimeOffObj } from "./types";
import { MultiSelectOption } from "ui/molecules/multiselect";
import moment from "moment";
import { TimePicker } from "antd";

export const formatClientsData = (inputData: any): ClientInput[] => {
  return inputData.map((item) => {
    const phoneNumber = sanitizeClientUploadPhoneNumber(item["Phone Number"] || item["Phone number"] || item["phone number"] || item["Phone"] || item["phone"] || item["Mobile number"] || item["Mobile Number"] || item["Number"] || item["number"]);
    const { phone, callingCode } = getPhoneAndCallingCode(phoneNumber);

    let altPhoneNumber = null;
    if (item["Secondary Phone Number"] || item["Alt Phone Number"] || item["Alt phone number"] || item["Alt number"]) {
      const _altPhoneNumber = sanitizeClientUploadPhoneNumber(item["Secondary Phone Number"] || item["Alt Phone Number"] || item["Alt phone number"] || item["Alt number"]);
      altPhoneNumber = getPhoneAndCallingCode(_altPhoneNumber);
    }
    return {
      firstName: item["First Name"] || item["Firstname"] || item["First name"] || item["first name"] || item["firstname"],
      lastName:  item["Last Name"] || item["Lastname"] || item["Last name"] || item["last name"] || item["lastname"] || null,
      ...(item["Email Address"] && { email: item["Email Address"].toLowerCase() }),
      ...(item["email address"] && { email: item["email address"].toLowerCase() }),
      ...(item["Email address"] && { email: item["Email address"].toLowerCase() }),
      ...(item["Email"] && { email: item["Email"].toLowerCase() }),
      ...(item["email"] && { email: item["email"].toLowerCase() }),
      phone,
      callingCode,
      ...(altPhoneNumber && { altPhone: altPhoneNumber.phone, altCallingCode: altPhoneNumber.callingCode }),
    };
  });
};

export const formatServicesData = (input: any) => {
  try {
    return input.map((item) => {
      return {
        name: item["Service Name"] || item["Service name"] || item["name"] || item["Name"] || item["service name"] || item["servicename"],
        description: item["Description"] || item["description"],
        category: item["Category"] || item["category"],
        price: Number(item["Price"] || item["price"]),
        pricingType: item["Is the price fixed or from"] || item["is the price fixed or from"],
        duration: separateDurationLettersAndNumbers(item["Duration"] || item["duration"]),
        isDepositRequired: item["Is deposit required to book this service"].toLowerCase() === 'yes' ? true : false,
        depositType: item["choose deposit type"],
        depositValue: Number(item["deposit value"]),
      };
    });
  } catch (error) {
    console.log(error)
    throw new Error(`Error during data formatting: ${error}`);
  }
};

export const formatProductsData = (input: any[]): ProductInput[] => {
  return input.map((item) => {
    return {
      name: item["Product name"] || item["Product Name"] || item["product name"] || item["name"] || item["Name"],
      description: item["Description"] || item["description"],
      category: item["Category"] || item["category"],
      costPrice: Number(item["Cost price"] || item["cost price"] || item["costprice"]),
      retailPrice: Number(item["Retail price"] || item["retail price"] || item["retailprice"]),
      lowStockThreshold: Number(item["Low stock alert"] || item["low stock alert"] || item["lowstockalert"]),
      stockCount: Number(item["Stock count"] || item["stock count"] || item["stockcount"] || item["stock"]),
      expirationDate: item["Expiration date"] || item["expiration date"] || item["expiration"] || item["Expiration"] || null,
      isPurchasable: item["Allow sales of this product"].toLowerCase() === 'yes' ? true : false,
      imageUrl: ''
    };
  });
};

export const formatStaffData = (inputData: any[]): UploadStaffInput[] => {
  return inputData.map((item) => {
    const { phone, callingCode } = getPhoneAndCallingCode(sanitizeClientUploadPhoneNumber(item["Phone Number"] || item["Phone number"] || item["phone number"] || item["Phone"] || item["phone"] || item["Mobile number"] || item["Mobile Number"] || item["Number"] || item["number"]));
    return {
      firstName: item["First Name"] || item["Firstname"] || item["First name"] || item["first name"] || item["firstname"],
      lastName:  item["Last Name"] || item["Lastname"] || item["Last name"] || item["last name"] || item["lastname"] || null,
      ...(item["Email Address"] && { email: item["Email Address"].toLowerCase() }),
      ...(item["email address"] && { email: item["email address"].toLowerCase() }),
      ...(item["Email address"] && { email: item["Email address"].toLowerCase() }),
      ...(item["Email"] && { email: item["Email"].toLowerCase() }),
      ...(item["email"] && { email: item["email"].toLowerCase() }),
      phone,
      callingCode: callingCode || "234",
      specialty: item["Speciality"] || item["Speciality"],
      canServeCustomers: item["Does this Staff carry out services on clients?"].toLowerCase() === 'yes' ? true : false,
      role: 'staff',
      canLogin: false,
    };
  });
}

export type ImportFileProps = {
  modules: 'client' | 'product' | 'staff' | 'service';
  showText?: boolean;
}

type TimeInput = {
  [key: string]: HoursWeekInput;
};


type TimeOutputArray = {
  openTime: string;
  closeTime: string;
  day: string;
  id?: string;
  displaySalonHours?: string[]
}[];

export const convertSchedule = (input: TimeInput, hours: SalonHour[]): TimeOutputArray => {
  const output: TimeOutputArray = [];

  const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

  for (const day of days) {
    const hourItem = hours.filter(hour => hour.day === day);
    const dayInput = input[day];
    if (dayInput?.isAvailable) {
      if (dayInput.times.length === 1) {
        output.push({
          day,
          openTime: dayInput.times[0]?.openTime || null,
          closeTime: dayInput.times[0]?.closeTime || null,
          id: hourItem[0]?.id,
          displaySalonHours: dayInput?.slots
        });
      } else if (dayInput.times.length === 2) {
        output.push(
          {
            day,
            openTime: dayInput.times[0]?.openTime || null,
            closeTime: dayInput.times[0]?.closeTime || null,
            id: hourItem[0]?.id,
            displaySalonHours: dayInput?.slots
          },
          {
            day,
            openTime: dayInput.times[1]?.openTime || null,
            closeTime: dayInput.times[1]?.closeTime || null,
            id: hourItem[1]?.id,
          }
        );
      }
    } else {
      output.push({
        day,
        openTime: null,
        closeTime: null,
        id: hourItem[0]?.id,
        displaySalonHours: []
      });
    }
  }
  return output;
};

export const setTrueForWeekDays = (day: string) => {
  const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
  return weekDays.includes(day);
}

export const setDefaultOpenTimeForWeekDays = (day: string) => {
  const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
  return weekDays.includes(day) ? '09:00' : null;
}

export const setDefaultCloseTimeForWeekDays = (day: string) => {
  const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
  return weekDays.includes(day) ? '17:00' : null;
}

export const separateDurationLettersAndNumbers = (inputString: string) => {
  // Use regular expressions to separate letters and numbers
  const letters = inputString?.replace(/[^a-zA-Z]/g, '');
  const numbers = Number(inputString?.replace(/[^0-9]/g, ''));

  if (letters === "h" || letters === "hr" || letters === "hours" || letters === "H" || letters === "hour" || letters === "Hours") {
    return numbers * 60;
  } else if (letters === "m" || letters === "min" || letters === "mins" || letters === "M" || letters === "minute" || letters === "Minutes") {
    return numbers;
  } else {
    return 0;
  }
}

export const getPhoneAndCallingCode = (input: string) => {
  if (!input || input === "") return { phone: null, callingCode: null }
  // pick the last 10 digits of the phone number, then the rest at the start
  const phone = input.slice(-10);
  const callingCode = input.slice(0, -10);
  return { phone, callingCode };
}

export const getClientVoucherFromTable = (vouchers: PackageVoucher[], index: number) => {
  return Array.isArray(vouchers) ? vouchers[index] : undefined;
}

export const getClientAppointmentFromTable = (appointments: Appointment[], index: number) => {
  return Array.isArray(appointments) ? appointments[index] : undefined;
}

export const getClientSaleFromTable = (sales: Sale[], index: number) => {
  return Array.isArray(sales) ? sales[index] : undefined;
}

export const getClientRewardFromTable = (milestones: ClientMilestone[], index: number) => {
  return Array.isArray(milestones) ? milestones[index] : undefined;
}

export const getClientRedeemedRewardFromTable = (rewards: LoyaltySalesReport[], index: number) => {
  return Array.isArray(rewards) ? rewards[index] : undefined;
}

export const getWorkingHoursByStaffId = (days: Date[], staffId: string, hours: StaffHour[], timeOff: StaffTimeOff[], periods: ClosedPeriod[]) => {
  if (!hours) return;
  const businessCountry = JSON.parse(localStorage.getItem('country'))

  const daysOrder = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const sortedHours = [...hours]?.sort((a, b) => {
    // Compare days based on order
    const dayDiff = daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
    if (dayDiff !== 0) return dayDiff;

    // Compare openTime within the same day, nulls last
    if (!a.openTime && b.openTime) return 1;
    if (a.openTime && !b.openTime) return -1;
    if (!a.openTime && !b.openTime) return 0;

    return a.openTime.localeCompare(b.openTime);
  });

  const timeOffs = Array.isArray(timeOff) && timeOff?.length ? convertTimeOffToDateTimeOff(timeOff) : []

  const closedPeriods = groupToDates(periods)

  const mergedData = [];

  // loop through days
  for (let i = 0; i < days.length; i++) {
    const day = days[i];

    const timezone = businessCountry?.timezone || 'Africa/Lagos';
    const originalDate = moment(day).tz(timezone);
    const longDay = originalDate.format('dddd'); // 'dddd' gives the full day name

    const date = originalDate.format('YYYY-MM-DD');

    // closed period
    const checkIfBusinessIsClosed = closedPeriods.find(closed => closed.date === date);
    if (checkIfBusinessIsClosed) {
      mergedData.push([
        {
          status: checkIfBusinessIsClosed?.closedPeriod?.title,
          openTime: null,
          closeTime: null,
          type: 'closed',
          id: null,
        },
        null
      ]);
    } else {
      const dayHours = sortedHours.filter(day => day.day === longDay.toLowerCase());
      let timeOffObj = null;
      // find time off in timeOffs via date
      const checkTimeOff = timeOffs.find(timeOff => timeOff.date === date);
      if (checkTimeOff) {
        timeOffObj = {
          status: checkTimeOff?.timeOff?.title,
          id: checkTimeOff?.timeOff?.id,
          openTime: checkTimeOff?.timeOff?.startTime,
          closeTime: checkTimeOff?.timeOff?.endTime,
          type: 'off'
        };
      }

      // find the matching hour in the hours array
      const workingHoursObj = [];
      if (dayHours?.length) {
        // loop through dayHours
        for (let j = 0; j < dayHours.length; j++) {
          const dayHour = dayHours[j];
          if (dayHour && dayHour?.openTime && dayHour?.closeTime) {
            const hourObj = {
              status: "open",
              openTime: dayHour.openTime,
              closeTime: dayHour.closeTime,
              type: 'working',
              id: null,
            }
            // workingHoursObj.push(hourObj)
            const times = calculateWorkingHoursAndTimeOff(timeOffObj, hourObj)
            workingHoursObj.push(times);
          } else {
            const hourObj = {
              status: "off",
              openTime: null,
              closeTime: null,
              type: 'working',
              id: null,
            };
            const times = calculateWorkingHoursAndTimeOff(timeOffObj, hourObj)
            workingHoursObj.push(times);
          }
        }
      } else {
        const hourObj = {
          status: "off",
          openTime: null,
          closeTime: null,
          type: 'working',
          id: null,
        };
        const times = calculateWorkingHoursAndTimeOff(timeOffObj, hourObj)
        workingHoursObj.push(times);
      }
      // const times = calculateWorkingHoursAndTimeOff(timeOffObj, workingHoursObj)
      mergedData.push(workingHoursObj);
    }
  }

  
  return mergedData;
};

const calculateWorkingHoursAndTimeOff = (timeOffObj: WorkingHourOrTimeOffObj | null, workingHourObj: WorkingHourOrTimeOffObj) => {
  if (!timeOffObj) {
    return [workingHourObj, null]
  }

  if (workingHourObj && workingHourObj?.status === 'off') {
    return [workingHourObj, timeOffObj || null]
  }

  const timeOffOpenTime = compareTimes(timeOffObj.openTime, workingHourObj.openTime) ? timeOffObj.openTime : workingHourObj.openTime;
  const workingHourOpenTime = compareTimes(timeOffObj.openTime, workingHourObj.openTime) ? workingHourObj.openTime : null;
  const timeOffCloseTime = compareTimes(timeOffObj.closeTime, workingHourObj.closeTime) ? workingHourObj.closeTime : timeOffObj.closeTime;
  const workingHourCloseTime = compareTimes(timeOffObj.closeTime, workingHourObj.closeTime) ? null : workingHourObj.closeTime;

  let newWorkingHourObj = null;

  if (workingHourOpenTime || workingHourCloseTime) {
    newWorkingHourObj = {
      status: 'open',
      openTime: workingHourOpenTime || timeOffCloseTime,
      closeTime: workingHourCloseTime || timeOffOpenTime,
      type: 'working',
      id: null,
    }
  }

  const newTimeOffObj = {
    ...timeOffObj,
    openTime: timeOffOpenTime,
    closeTime: timeOffCloseTime
  }

  return [newWorkingHourObj, newTimeOffObj]
}

const compareTimes = (time1, time2) => {
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);

  // Convert times to minutes
  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;

  // Compare times
  return totalMinutes1 > totalMinutes2;
}

const convertTimeOffToDateTimeOff = (originalEvents: StaffTimeOff[]): ConvertedTimeOffDate[] => {
  const events: ConvertedTimeOffDate[] = [];
  
  // Return empty array if no events
  if (!originalEvents?.length) return [];
  
  // Process each event in originalEvents
  for (const originalEvent of originalEvents) {
    const startDate = originalEvent.startAt.split('T')[0];
    const endDate = originalEvent.endAt.split('T')[0];
    const startTime = originalEvent?.startAt?.split('T')[1];
    const endTimeOffset = originalEvent?.endAt?.split('T')[1];
    const endTime = endTimeOffset ? `${endTimeOffset.split(':')[0]}:${endTimeOffset.split(':')[1]}` : '';
    
    // Generate date range array
    const dates = generateDateRangeArray(new Date(startDate), new Date(endDate));
    
    if (Array.isArray(dates) && dates.length > 0) {
      // Process multiple dates
      for (let currentCount = 1; currentCount <= dates.length; currentCount++) {
        events.push({
          date: dates[currentCount - 1],
          timeOff: {
            id: originalEvent.id,
            title: originalEvent.title,
            startTime: currentCount === 1 ? removeZInTimeStringAndExtraZeros(startTime) : "08:00",
            endTime: currentCount === dates.length ? removeZInTimeStringAndExtraZeros(endTime) : "23:59",
          }
        });
      }
    } else {
      // Process single date
      events.push({
        date: endDate,
        timeOff: {
          id: originalEvent.id,
          title: originalEvent.title,
          startTime: removeZInTimeStringAndExtraZeros(startTime),
          endTime: removeZInTimeStringAndExtraZeros(endTime)
        }
      });
    }
  }
  
  return events;
};

export const generateDateRangeArray = (startDate: Date, endDate: Date) => {
  // Calculate the difference in milliseconds

  const differenceInTime = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to days and add 1 for inclusive counting
  const differenceInDays = (differenceInTime / (1000 * 3600 * 24)) + 1;

  // Array to store the dates
  const datesArray = [];

  // Loop through each day and push it into the array
  for (let i = 0; i < differenceInDays; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);
    datesArray.push(currentDate.toISOString().split('T')[0]);
  }

  return datesArray;
}

export const formatSalonBranchesToSelectField = (branches: Salon[]) => {
  return branches?.map((branch) => ({
    label: `${branch.branchName}`,
    value: branch.id,
  }));
};

export const formatBusinessServicesToSelectField = (
  services: Service[]
) =>
  services.map((service) => ({
    label: service.name,
    value: service.id,
  }));

export const formatBusinessProductsToSelectField = (
  services: Product[]
) =>
  services.map((product) => ({
    label: product.name,
    value: product.id,
  }));

export const formatBusinessPackagesToSelectField = (
  services: Package[]
) =>
  services.map((package_) => ({
    label: package_.name,
    value: package_.id,
  }));

export const groupBusinessSalonHoursForStaffCreation = (salonHours: SalonHour[]) => {
  // filter salonHours
  const filterHours = salonHours?.filter((hour) => hour?.openTime && hour?.closeTime);
  // Check if all hours are the same day
  const allSameDay = filterHours?.length === 7 && filterHours.every((hour, index, array) => {
    return hour?.openTime === array[0]?.openTime && hour?.closeTime === array[0]?.closeTime;
  });


  if (allSameDay) {
      return [{
          openTime: filterHours[0].openTime,
          closeTime: filterHours[0].closeTime,
          day: "everyday"
      }];
  }

  // Check if hours are consistent on weekdays or weekends
  const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday"];
  const weekends = ["saturday", "sunday"];

  const weekdaysHours = filterHours.filter(hour => weekdays.includes(hour.day));
  const weekendsHours = filterHours.filter(hour => weekends.includes(hour.day));

  const consistentWeekdays = Array?.isArray(weekdaysHours) && weekdaysHours?.length === 5 ? weekdaysHours.every(hour => hour?.openTime === weekdaysHours[0]?.openTime && hour?.closeTime === weekdaysHours[0]?.closeTime) : false;
  const consistentWeekends = Array?.isArray(weekendsHours) && weekendsHours?.length === 2 ? weekendsHours.every(hour => hour?.openTime === weekendsHours[0]?.openTime && hour?.closeTime === weekendsHours[0]?.closeTime) : false;
  const hours = [];

  if (consistentWeekdays) {
      hours.push({
          openTime: weekdaysHours[0]?.openTime,
          closeTime: weekdaysHours[0]?.closeTime,
          day: "mon-fri"
      });
  }
  if (consistentWeekends) {
      hours.push({
          openTime: weekendsHours[0]?.openTime,
          closeTime: weekendsHours[0]?.closeTime,
          day: "sat-sun"
      });
  }
  
  if (consistentWeekdays && !consistentWeekends && weekendsHours?.length) {
    return filterHours.map(hour => ( hour?.openTime && hour?.closeTime && {
      openTime: hour?.openTime,
      closeTime: hour?.closeTime,
      day: hour.day
    }));
  }

  if (!consistentWeekdays && consistentWeekends && weekdaysHours?.length) {
    return filterHours.map(hour => ( hour?.openTime && hour?.closeTime && {
      openTime: hour?.openTime,
      closeTime: hour?.closeTime,
      day: hour.day
    }));
  }

  if (!consistentWeekdays && !consistentWeekends) {
    return filterHours.map(hour => ( hour?.openTime && hour?.closeTime && {
      openTime: hour?.openTime,
      closeTime: hour?.closeTime,
      day: hour.day
    }));
  }

  return hours;
}

export const convertGroupHoursToSingleTime = (hours: WorkingScheduleHoursForm[]) => {
  const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday"];
  const weekends = ["saturday", "sunday"];
  const everyDayValue = [...weekdays, ...weekends];
  const singleLineHours = [];
  // loop through hours
  for (let i = 0; i < hours.length; i++) {
    if (hours[i]?.day?.toLowerCase() === "everyday") {
      // break it into everyday values
      for (let j = 0; j < everyDayValue.length; j++) {
        if (hours[i]?.openTime && hours[i]?.closeTime) {
          singleLineHours.push({
            day: everyDayValue[j],
            openTime: hours[i].openTime,
            closeTime: hours[i].closeTime,
            salonId: hours[i].salonId,
          });
        }
      }
    } else if (hours[i]?.day?.toLowerCase() === "mon-fri") {
      // weekdays
      for (let j = 0; j < weekdays.length; j++) {
        if (hours[i]?.openTime && hours[i]?.closeTime) {
          singleLineHours.push({
            day: weekdays[j],
            openTime: hours[i].openTime,
            closeTime: hours[i].closeTime,
            salonId: hours[i].salonId,
          });
        }
      }
    } else if (hours[i]?.day?.toLowerCase() === "sat-sun") {
      // weekends
      for (let j = 0; j < weekends.length; j++) {
        if (hours[i]?.openTime && hours[i]?.closeTime) {
          singleLineHours.push({
            day: weekends[j],
            openTime: hours[i].openTime,
            closeTime: hours[i].closeTime,
            salonId: hours[i].salonId,
          });
        }
      }
    } else {
      if (hours[i]?.openTime && hours[i]?.closeTime) {
        singleLineHours.push({
          day: hours[i]?.day?.toLowerCase(),
          openTime: hours[i].openTime,
          closeTime: hours[i].closeTime,
          salonId: hours[i].salonId,
        });
      }
    }
  }

  return singleLineHours;
}

const cleanScheduleDuplicates = (data) => {
  const seenKeys = new Set();
  
  return data
    // Step 1: Remove null/undefined entries
    .filter(entry => entry !== null && entry !== undefined)
    // Step 2: Remove duplicates based on salonId + day combination
    .filter(entry => {
      const key = `${entry.salonId}-${entry.day}`;
      if (!seenKeys.has(key)) {
        seenKeys.add(key);
        return true;
      }
      return false;
    });
}

export const generateBusinessHoursFormValues = (staffHours: StaffHour[]) => {
  const result = {}

  staffHours.forEach((hour) => {
    result[hour.day] = {
      id: null,
      openTime: hour?.openTime || null,
      closeTime: hour?.closeTime || null,
      status: hour?.status || 'closed' ,
      isAvailable: hour.openTime && hour?.closeTime ? true : false
    }
  })
  return result
}

type SummaryData = [string, number | string][]
export const createDefaultGraphDataGroup = (expenses: GraphData[]) => {
  const summaryData: SummaryData = [['Status', 'Count']]

  // loop through expenses
  for (let i = 0; i < expenses.length; i++) {
    const expense = expenses[i]
    // push ito summary data
    summaryData.push([formatSnakeCaseText(expense?.label), +expense?.value])
  }

  return summaryData
}

export const createCustomersGraph = (newClientsGraphData: GraphData[], returningClientsGraphData: GraphData[]) => {
  const summaryData: LineGraphSummaryData = [['Month', 'New', 'Returning']]

  // Create a map for services data
  const newCustomersMap = new Map();
  newClientsGraphData && newClientsGraphData?.length && newClientsGraphData.forEach(item => {
    const label = item.label;
    const value = parseFloat(item.value.replace(/[^\d.-]/g, '')) || 0;
    newCustomersMap.set(label, value);
  });

  // Create a map for products data
  const returningCustomersMap = new Map();
  returningClientsGraphData && returningClientsGraphData?.length && returningClientsGraphData.forEach(item => {
    const label = item.label;
    const value = parseFloat(item.value.replace(/[^\d.-]/g, '')) || 0;
    returningCustomersMap.set(label, value);
  });

  // Merge the two maps
  newCustomersMap.forEach((newCustomerValue, label) => {
    const returningCustomerValue = returningCustomersMap.get(label) || 0;
    summaryData.push([label, newCustomerValue, returningCustomerValue]);
  });

  return summaryData;
}

export const convertMilestonesInputForCreation = (milestone: LoyaltyMilestoneInput, services: MultiSelectOption[], products: MultiSelectOption[]) => {
  return {
      name: milestone?.name,
      customerPointsAttained: milestone?.customerPointsAttained ? Number(milestone?.customerPointsAttained.toString().replace(/,/g, '')) : 0,
      customerReceives: milestone?.customerReceives ? Number(milestone?.customerReceives.toString().replace(/,/g, '')) : 0,
      customerReceivesType: milestone?.customerReceivesType,
      customerReaches: milestone?.customerReaches,
      id: milestone?.id,
      serviceIds: Array.isArray(milestone.serviceIds) && milestone.serviceIds.length
      ? milestone.serviceIds
          .filter((service: MultiSelectOption) => services.some((s) => s.value === service.value))
          .map((service: MultiSelectOption) => service?.value)
      : [],
      productIds: Array.isArray(milestone.serviceIds) && milestone.serviceIds.length
      ? milestone.serviceIds
          .filter((product: MultiSelectOption) => products.some((s) => s.value === product.value))
          .map((product: MultiSelectOption) => product?.value)
      : [],
  };
}

export const getServicesAndProductsForAMileStone = (services: Service[], products: Product[]): MultiSelectOption[] => {
  const serviceIds = Array?.isArray(services) && services?.length ? services.map((service) => ({
    label: service.name,
    value: service.id,
  })) : [];

  const productIds = Array?.isArray(products) && products?.length ? products.map((product) => ({
    label: product.name,
    value: product.id,
  })) : [];

  return [...productIds, ...serviceIds];
}

export const convertOptionsValueToCommaString = (services: MultiSelectOption[]) => {
  if (!Array.isArray(services) || !services?.length) return '';

  if (services.length === 1) return services[0].label;

  return services.map((service) => service?.label).join(',');
}

export const checkStaffWorkingSchedule = (salonHours: SalonHour[], staffWorkingSchedule: WorkingScheduleHoursForm[] | SalonStaffHour[]) => {
  // Filter businessHour to remove entries where openTime and closeTime are not null
  const filteredBusinessHour = salonHours?.filter(hour => hour.openTime && hour.closeTime);
  const filteredStaffWorkingSchedule = staffWorkingSchedule?.filter(schedule => schedule.openTime && schedule.closeTime);

  // Check if filteredBusinessHour and filteredStaffWorkingSchedule have the same length
  if (filteredBusinessHour?.length !== filteredStaffWorkingSchedule?.length) {
    return 'custom';
  }

  const sortedWorkingHours = [...filteredStaffWorkingSchedule].sort((a, b) => a.day.localeCompare(b.day));
  const sortedBusinessHours = [...filteredBusinessHour].sort((a, b) => a.day.localeCompare(b.day));

  // Check if each filteredBusinessHour and filteredStaffWorkingSchedule have the same day, openTime and closeTime
  for (let i = 0; i < sortedWorkingHours.length; i++) {
    const businessHour = sortedBusinessHours[i];
    const staffSchedule = sortedWorkingHours[i];

    if (
      businessHour.day !== staffSchedule.day ||
      businessHour.openTime !== staffSchedule.openTime ||
      businessHour.closeTime !== staffSchedule.closeTime
    ) {
      return 'custom';
    }
  }

  return 'business';
};

export const reorderSalonHours = (hours: SalonHour[]): SalonHour[] => {
  const dayOrder: Record<string, number> = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };
  if (!Array.isArray(hours) || !hours?.length) return [];
  return [...hours]?.sort((a, b) => dayOrder[a?.day] - dayOrder[b?.day]);
};