import { Button, Modal, Paragraph, Pill, SvgArrowBack, SvgGreenSignal, SvgGreySignal, SvgRedSignal } from "ui"
import { ViewMessageLogProps } from "../types"
import { COLORS } from "constants/colors"

const ViewMessageLog = ({
  communicationLog,
  closeModal,
  isVisible
}: ViewMessageLogProps) => {
  const htmlContent = communicationLog?.body
  return (
    <Modal show={isVisible} closeModal={closeModal} variant='right'>
      <div className='relative'>
        <div className='fixed bg-white w-full flex border-b border-grey-100 cursor-pointer'>
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
            </Button>
          </div>
        </div>

        <div
          style={{ marginTop: '70px' }}
          className='w-full max-w-5xl flex flex-col space-y-6 h-fit p-6'
        >
          <Paragraph className='mb-2 flex gap-3' size='b2' weight='extraBold'>
            <span>Message details</span> <Pill
              variant={
                communicationLog?.communicationType === 'Reminder'
                  ? 'success'
                  : 'warning'
              }
              className="capitalize"
            >
              {communicationLog?.messageType}
            </Pill>
          </Paragraph>
          <Paragraph size='b4' color={COLORS.GREY[400]}>
            Find information about message here
          </Paragraph>

          <div className='w-full border border-grey-100'>
            <div className='flex justify-between p-4 mb-6'>
              <Paragraph className='' color={COLORS.GREY[200]}>
                Type
              </Paragraph>
              <Pill
                variant={
                  communicationLog?.communicationType === 'Reminder'
                    ? 'success'
                    : 'warning'
                }
                className="capitalize"
              >
                {communicationLog?.communicationType}
              </Pill>
            </div>

            <div className='flex justify-between p-4 mb-6'>
              <Paragraph color={COLORS.GREY[200]}>Status</Paragraph>
              {communicationLog?.deliveryStatus === 'sent' ? (
                <div className='flex items-center'>
                  <SvgGreenSignal width='16px' height='16px' />{' '}
                  <span className='ml-2 text-grey-900 text-b5'>Sent</span>
                </div>
              ) : communicationLog?.deliveryStatus === 'pending' ? (
                <div className='flex items-center'>
                  <SvgGreySignal width='16px' height='16px' />{' '}
                  <span className='ml-2 text-grey-900 text-b5'>Pending</span>
                </div>
              ) : communicationLog?.deliveryStatus === 'failed' ? (
                <div className='flex items-center'>
                  <SvgRedSignal width='16px' height='16px' />{' '}
                  <span className='ml-2 text-grey-900 text-b5'>Failed</span>
                </div>
              ) : null}
            </div>

            <div className='flex justify-between p-4 mb-6'>
              <Paragraph color={COLORS.GREY[200]}>Subject line</Paragraph>
              <Paragraph>{communicationLog?.subject}</Paragraph>
            </div>

            <div className='flex justify-center p-4 mb-6'>
              {/* <Paragraph className='w-[300px]' color={COLORS.GREY[200]}>Body</Paragraph> */}
              <div className='border border-grey-100 p-4'>
                <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ViewMessageLog