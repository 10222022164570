import { REST_API_ROUTES } from "constants/apiRoutes";
import queryClient from "core/graphql/rest";
import { WorkFlowFormAnalytics, WorkFlowForm, WorkFlowFormInput, WorkFlowForms, ClientFormSubsmissions, FormSubsmissions, PublicWorkFlowForm, AppointmentWorkFlowFormSubsmissions } from "modules/settings/types";
import { ApiResponse } from "./types/general";
import { useMutation, useQuery } from "react-query";

export const useWorkflowForms = () => {
  const workflowForms = async () => {
    const url = REST_API_ROUTES.WORKFLOW_FORMS;
    const { data } = await queryClient.get<ApiResponse<WorkFlowForms>>(url);
    return data;
  }

  return useQuery(['workflowForms'], workflowForms);
}

// get workflowform id
export const useWorkflowForm = ({ id }: { id: string | null }) => {
  const workflowForm = async () => {
    const url = REST_API_ROUTES.WORKFLOW_FORMS + `/${id}`;
    const { data } = await queryClient.get<ApiResponse<WorkFlowForm>>(url);
    return data;
  }

  return useQuery(['workflowForm', id], workflowForm, {
    enabled: !!id,
  });
}

// workflow analytics
export const useWorkflowAnalytics = ({ id }: { id: string | null }) => {
  const workflowAnalytics = async () => {
    const url = REST_API_ROUTES.WORKFLOW_ANALYTICS + `/${id}`;
    const { data } = await queryClient.get<ApiResponse<WorkFlowFormAnalytics>>(url);
    return data;
  }

  return useQuery(['workflowAnalytics'], workflowAnalytics, {
    enabled: !!id,
  });
}

export const useClientFormsSubmissions = ({
  clientId, startDate, endDate, limit, page, q
}: {
  clientId: string, startDate?: string, endDate?: string, limit?: number, page?: number, q?: string
  }) => {
  const clientFormsSubmission = async () => {
    const url = REST_API_ROUTES.CLIENT_FORMS_SUBMISSIONS + `/${clientId}/submissions` + `?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}&name=${q || ''}`;
    const { data } = await queryClient.get<ApiResponse<ClientFormSubsmissions>>(url);
    return data;
  }

  return useQuery(['clientSubmissions'], clientFormsSubmission, {
    enabled: !!clientId
  })
}

// form submissions
export const useFormSubmissions = ({ id, status, limit, page }: { id: string | null, status: string, limit: number, page: number }) => {
  const formSubmissions = async () => {
    if (!id) return null;
    const url = REST_API_ROUTES.WORKFLOW_FORMS_SUBMISSIONS + `/${id}?status=${status || ''}&limit=${limit}&page=${page}`;
    const { data } = await queryClient.get<ApiResponse<FormSubsmissions>>(url);
    return data;
  }

  return useQuery(['formSubmissions'], formSubmissions, {
    enabled: !!id
  });
}

// mutations
export const useCreateWorkflowForm = () => {
  const createWorkflowForm = async (args: WorkFlowFormInput) => {
    const url = REST_API_ROUTES.WORKFLOW_FORMS;
    const { data } = await queryClient.post<ApiResponse>(url, args);
    return data;
  }

  return useMutation(createWorkflowForm);
}

export const useUpdateWorkflowForm = () => {
  const updateWorkflowForm = async ({ args, id }: { args: WorkFlowFormInput, id: string }) => {
    const url = REST_API_ROUTES.WORKFLOW_FORMS + `/${id}`;
    const { data } = await queryClient.put<ApiResponse>(url, args);
    return data;
  }

  return useMutation(updateWorkflowForm);
}

export const useUpdateWorkflowFormStatus = () => {
  const updateWorkflowForm = async ({ args, id }: { args: { status: string }, id: string }) => {
    const url = REST_API_ROUTES.WORKFLOW_FORMS + `/${id}`;
    const { data } = await queryClient.put<ApiResponse>(url, args);
    return data;
  }

  return useMutation(updateWorkflowForm);
}

// delete workflow
export const useDeleteWorkflowForm = () => {
  const deleteWorkflowForm = async (id: string) => {
    const url = REST_API_ROUTES.WORKFLOW_FORMS + `/${id}`;
    const { data } = await queryClient.delete<ApiResponse>(url);
    return data;
  }

  return useMutation(deleteWorkflowForm);
}

// trigger form 
export const useTriggerWorkflowForm = () => {
  const triggerWorkflowForm = async ({
    clientId, formId
  }: {
    clientId: string, formId: string
  }) => {
    const url = REST_API_ROUTES.WORKFLOW_FORMS + `/${formId}/trigger`;
    const args = {
      client_id: clientId
    }
    const { data } = await queryClient.post<ApiResponse>(url, args);
    return data;
  }

  return useMutation(triggerWorkflowForm);
}

// answers
export const useWorkflowFormAnswers = ({ id }: { id: string | null }) => {
  const workflowFormAnswers = async () => {
    const url = REST_API_ROUTES.WORKFLOW_FORMS + `/${id}/answers`;
    const { data } = await queryClient.get<ApiResponse>(url);
    return data;
  }

  return useQuery(['workflowFormAnswers'], workflowFormAnswers, {
    enabled: !!id
  });
}

// public forms
export const useGetPublicForm = ({ id }: { id: string | null }) => {
  const publicForms = async () => {
    const url = REST_API_ROUTES.PUBLIC_FORM + `/${id}`;
    const { data } = await queryClient.get<ApiResponse<PublicWorkFlowForm>>(url);
    return data;
  }

  return useQuery(['publicForms'], publicForms, {
    enabled: !!id
  });
}

// submit answers
export const useSubmitAnswers = () => {
  const submitAnswers = async ({
    formId, answers, phone, client_id, appointmentId
  }: {
    formId: string, answers: {
      [key: string]: string;
    }, phone: string, client_id: string, appointmentId?: string
    }) => {
    const args = {
      answers: answers,
      phone,
      client_id,
      ...(appointmentId && { appointment_id: appointmentId })
    }
    const url = REST_API_ROUTES.WORKFLOW_FORMS + `/${formId}/answers`;
    const { data } = await queryClient.put<ApiResponse>(url, args);
    return data;
  }

  return useMutation(submitAnswers);
}

export const useWorkflowBeforeBooking = () => {
  const workflowBeforeBooking = async ({ id, services, phone, callingCode }: {
    id: string | null
    services: string[]
    phone: string
    callingCode: string
  }) => {
    const args = {
      services: services,
      phone: `${callingCode}${phone}`
    }
    const url = REST_API_ROUTES.WORKFLOW_FORMS_BOOKING + `/${id}`;
    const { data } = await queryClient.post<ApiResponse<PublicWorkFlowForm>>(url, args);
    return data;
  }

  return useMutation(workflowBeforeBooking);
}

export const useTriggerFormAfterAppointment = () => {
  const triggerFormAfterAppointment = async ({ businessId, services, clientId, sendCondition, appointmentId }: {
    businessId: string
    services: string[]
    clientId: string
    sendCondition: string
    appointmentId: string
  }) => {
    const args = {
      client_id: clientId,
      business_id: businessId,
      services,
      send_condition: sendCondition,
      appointment_id: appointmentId
    }
    const url = REST_API_ROUTES.WORKFLOW_FORM_AFTER_BOOKING;
    const { data } = await queryClient.post<ApiResponse>(url, args);
    return data;
  }

  return useMutation(triggerFormAfterAppointment);
}

export const useGetAppointmentForm = ({ id }: { id: string | null }) => {
  const appointmentForm = async () => {
    const url = REST_API_ROUTES.APPOINTMENT_FORMS + `/${id}`;
    const { data } = await queryClient.get<ApiResponse<AppointmentWorkFlowFormSubsmissions>>(url);
    return data;
  }

  return useQuery(['appointmentForm'], appointmentForm, {
    enabled: !!id
  });
}