import * as React from 'react';
import { LayoutProps } from './types';
import SideMenu from './SideMenu';
import Button from '../atoms/button/Button';
import Heading from '../atoms/heading/Heading';
import { useMobileSideMenu } from 'hooks/useMobileSideMenu';
import { SvgBell, SvgCarbonView, SvgChevronLeft, SvgClose, SvgMenu, SvgNotificationImage, SvgNotificationPayment, SvgNotificationReview, SvgPlusBox, SvgWorld } from 'ui';
import Paragraph from '../atoms/paragraph/Paragraph';
import { Popover, Transition } from '@headlessui/react';
import { useSalonCache } from 'hooks/useSalonCache';
import NameAvatar from '../atoms/nameAvatar/NameAvatar';
import { notificationPageRoutes } from './constants';
import { cleanUpText, getTimeElapsed, limitString } from '../../components/utils/misc';
import { COLORS } from 'constants/colors';
import NavBar from '../molecules/navBar/NavBar';
import { useEffect, useMemo, useState } from 'react';
import ActionCable from 'actioncable';
import * as Sentry from '@sentry/react';
import { useIdleTimer } from 'react-idle-timer'

if (process.env.environment === 'production') {
  Sentry.init({
    dsn: 'https://088f586533dd9dba173cba2945d5f141@o4506067549421568.ingest.sentry.io/4506071483088896',
  });
}

import {
  MarkNotificationsAsReadDocument,
  Notification,
  Salon,
  User,
} from 'core/generated';
import axios from 'axios';
import { useUserCache } from 'hooks/useUserCache';
import SalonSelection from './SalonSelection';
import { Logo } from '../atoms/logo';
import { canPerformAction } from 'components/utils/permission';
import { PERMISSION_CONSTANTS } from 'constants/permission';
import { useNavigate } from 'react-router-dom';
import { useMarkNotificationsAsRead, useNotifications, useRefreshToken, useUser, useUserAuthorizations } from 'api/useAccount';
import { useManageAuth } from 'modules/auth/hooks/useManageAuth';
import Banner from './Banner';
import { PAGE_ROUTES } from 'constants/routes';

const Layout: React.FunctionComponent<LayoutProps> = (props: LayoutProps) => {
  const navigate = useNavigate();
  const { logout } = useManageAuth()
  const { markNotificationsAsRead } = useMarkNotificationsAsRead()
  const token = localStorage.getItem('token');
  const webSocketUrl = process.env.REACT_APP_WEB_SOCKET_URL + "?token=" + token as string;
  const { getSalonFieldValue, getSalonData, setSalonData } = useSalonCache();
  const { setBusinessData } = useUserCache();
  const salon = getSalonData() as Salon;
  const { isMobileSideMenuOpen, toggleMobileSideMenu } = useMobileSideMenu();
  const [notificationCounter, setNotificationCounter] = useState<number>(0);
  const [enableNotificationQuery, setEnableNotificationQuery] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>();
  const country = JSON.parse(localStorage.getItem('country') as string);
  const counter = 0;

  const {
    data: dataUser,
    refetch: refetchUser
  } = useUser()

  const {
    data: dataPermissions,
    refetch: refetchPermission
  } = useUserAuthorizations()

  const goToOtpPage = () => {
    // navigate(PAGE_ROUTES.OTP, {
    //   replace: true,
    // });
    window.location.href = PAGE_ROUTES.OTP
  };

  useMemo(() => {
    if (dataPermissions) {
      localStorage.setItem(
        'permissions',
        JSON.stringify(dataPermissions?.auhorizations)
      );
    }
  }, [dataPermissions])

  const user = useMemo(() => {
    const currentUser: User = dataUser?.user;

    if (currentUser) {
      // Store user data in localStorage
      localStorage.setItem('userData', JSON.stringify(currentUser));
      if (!currentUser?.phoneVerified && !currentUser?.emailVerified) {
        goToOtpPage()
      }
    }

    return currentUser;
  }, [dataUser]);

  const actions = {
    appointments: canPerformAction(
      PERMISSION_CONSTANTS.calendar.viewAppointment
    ),
    payments: canPerformAction(
      'Money::' + PERMISSION_CONSTANTS.money.viewWallet ||
      'Money::' + PERMISSION_CONSTANTS.money.viewPayment
    ),
  };

  const {
    data,
    refetch
  } = useNotifications({
    salonId: salon?.id
  });

  const {
    data: refreshTokenData,
    refetch: refetchRefreshToken
  } = useRefreshToken()

  useMemo(() => {
    if (refreshTokenData?.refreshToken) {
      localStorage.setItem('token', refreshTokenData?.refreshToken)
    }
  }, [refreshTokenData])

  useEffect(() => {
    if (data && Array.isArray(data?.notifications)) {
      setNotifications(formatDataForPermission(data?.notifications));
      // check how many isRead is false in notifications
      const unreadNotifications = data?.notifications?.filter(
        (notification: Notification) => !notification.isRead
      );

      if (unreadNotifications.length > 0) {
        setNotificationCounter(unreadNotifications.length);
      }
    }
  }, [data])


  useEffect(() => {
    if (salon?.id) {
      refetch();
      refetchRefreshToken()
      refetchPermission()
      refetchUser()
    }
  }, [salon?.id, refetch, refetchRefreshToken, refetchPermission])

  const onIdle = () => {
    logout();
    window.location.href = '/';
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 48 * 60 * 60 * 1000,
    throttle: 500
  })

  React.useEffect(() => {
    if (salon) {
      const cable = ActionCable.createConsumer(webSocketUrl);
      cable.subscriptions.create(
        { channel: 'NotificationChannel', salon_id: getSalonFieldValue('id') },
        {
          connected() {
            console.log('Connected to Action Cable');
          },
          received: (data) => {
            // handle notification here
            refetch();
            const notifcation = data.notification as Notification;
            if (notifcation?.contentType === 'role') {
              refetchUser();
              return;
            }
            // Add the new message to the notifications state
            if (notifcation && notifcation?.contentType !== 'role') {
              if (
                notifcation?.contentType === 'appointment' &&
                !actions?.appointments
              )
                return;
              if (notifcation?.contentType === 'payment' && !actions?.payments)
                return;
              setNotifications((prevNotifications = []) => {
                return [notifcation, ...prevNotifications];
              });
              // add a new counter to setNotificationCounter
              setNotificationCounter((prevCounter) => prevCounter + 1);
            }
          },
        }
      );
    }
  }, []);

  const visitPage = (contentType: string, contentId?: string) => {
    let routePage = notificationPageRoutes[contentType];
    if (contentType === 'appointment') {
      routePage = `${routePage}/${contentId}`;
    }
    if (contentType === 'product') {
      routePage = `${routePage}?product=${contentId}`;
    }
    navigate(routePage, {
      replace: true,
    });
  };

  const formatDataForPermission = (data: Notification[]) => {
    // if !actions?.appointments remove all notifications where contentType === "appointment"
    if (!actions?.appointments) {
      data = data.filter(
        (notification) => notification.contentType !== 'appointment'
      );
    }
    // if !actions?.payments remove all notifications where contentType === "payment"
    if (!actions?.payments) {
      data = data.filter(
        (notification) => notification.contentType !== 'payment'
      );
    }

    return data;
  };

  const getNotificationAvatarOrIcon = (contentType: string, title: string) => {
    if (contentType === 'note') {
      return <SvgNotificationImage width="40px" height="40px" />;
    }

    if (contentType === 'review') {
      return <SvgNotificationReview width="40px" height="40px" />;
    }

    if (contentType === 'product') {
      return <SvgNotificationImage width="40px" height="40px" />;
    }

    if (contentType === 'payment') {
      return <SvgNotificationPayment width="40px" height="40px" />;
    }

    if (contentType === 'appointment') {
      const titleArray = title?.split(' ');
      const firstName = titleArray[0];
      const lastName = titleArray[1] !== 'booked' ? titleArray[1] : '';
      const initials = firstName.charAt(0) + lastName.charAt(0);
      return <NameAvatar name={initials} mobile={true} />;
    }

    return;
  };

  const markAsRead = () => {
    const ids = notifications?.map((notification) => notification.id);
    markNotificationsAsRead({
      variables: { input: { ids } }
    }).then(({ data }) => {
      const { markNotificationsAsRead } = data;
      if (markNotificationsAsRead?.status === 200) {
        setNotificationCounter(0);
      }
    })
  };

  return (
    <>
      <Sentry.ErrorBoundary>
        <main className="flex min-h-screen w-full">
          <SideMenu />
          <div className="flex flex-col justify-start items-start w-full xl:w-[calc(100%-300px)]">
            <div className="w-full flex flex-col space-y-3 min-h-[80px] xl:min-h-[120px] p-[20px] border-b border-grey-100">
              <div className='flex justify-between items-center xl:items-end'>
                <div className="w-full flex justify-between items-center">
                  <div className="flex flex-row gap-2 items-center xl:items-end">
                    <Button
                      variant="icon"
                      size="square"
                      rounded="md"
                      type="button"
                      className="flex xl:hidden"
                      onClick={
                        props.navigationType === 'back'
                          ? props.goBack
                          : toggleMobileSideMenu
                      }
                    >
                      {props.navigationType === 'back' ? (
                        <SvgChevronLeft width="24px" height="24px" />
                      ) : isMobileSideMenuOpen ? (
                        <SvgClose width="24px" height="24px" />
                      ) : (
                        <SvgMenu width="24px" height="24px" />
                      )}
                    </Button>
                    <Heading
                      variant="h1"
                      size="h11"
                      weight="bold"
                      className="flex-1 text-center xl:hidden"
                    >
                      {props.pageTitle}
                    </Heading>
                    <Logo className="hidden xl:flex max-w-[70px]" />
                    {props?.pageTitle?.toLowerCase() === 'calendar' ? (
                      <div className="py-2 px-3 flex gap-2 items-center bg-grey-50 rounded-md">
                        <SvgWorld width="16px" height="16px" />
                        <Paragraph size="b5" className="hidden md:inline">
                          {country?.timezone}
                        </Paragraph>
                        <Paragraph size="b5">{country?.utcOffset}</Paragraph>
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex-row gap-2 items-center xl:items-end">
                    {props.openModal && (
                      <Button
                        variant="icon"
                        size="none"
                        type="button"
                        className="block xl:hidden border-0"
                        onClick={props.openModal}
                      >
                        <SvgPlusBox width="36px" height="36px" />
                      </Button>
                    )}
                    <SalonSelection />
                    {canPerformAction(
                      PERMISSION_CONSTANTS?.calendar?.viewAppointment ||
                      `Money::${PERMISSION_CONSTANTS?.money?.viewPayment}`
                    )}
                    <div className="flex space-x-4">
                      <Popover className="relative">
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={` ${open ? '' : 'text-opacity-90'
                                } group flex items-center cursor-pointer rounded-md bg-white border border-grey-100 p-2.5 space-x-2 font-medium text-grey-900 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                              onClick={() => {
                                setEnableNotificationQuery(true);
                                markAsRead();
                              }}
                            >
                              <SvgBell width="24px" height="24px" />
                              <span className="hidden xl:inline">
                                Notifications
                              </span>
                              {(counter && counter > 0) ||
                                (notificationCounter && notificationCounter > 0) ? (
                                <div className="inline-block bg-red-600 text-white rounded-full px-3 py-2 text-b6">
                                  {counter || notificationCounter}
                                </div>
                              ) : null}
                            </Popover.Button>
                            <Transition
                              as={React.Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute -left-[100px] xl:-left-[70px] border border-grey-100 w-[300px] xl:w-[400px] shadow-xl rounded-md z-10 mt-3 bg-white -translate-x-1/2 transform">
                                <div className="overflow-hidden">
                                  <div className="relative flex flex-col h-[300px] md:h-[500px] max-h-[600px] overflow-y-auto">
                                    <div className="p-4">
                                      <Heading variant="h2" size="h11">
                                        Notifications
                                      </Heading>
                                    </div>
                                    <div className="w-full flex flex-col space-y-4">
                                      {Array.isArray(notifications) &&
                                        notifications?.length > 0 &&
                                        notifications?.map(
                                          (notification) =>
                                            notification && (
                                              <div
                                                className="flex items-start border-t border-grey-20 p-4 space-x-2"
                                                key={notification?.id}
                                              >
                                                <div className="">
                                                  {getNotificationAvatarOrIcon(
                                                    notification?.contentType,
                                                    notification?.title
                                                  )}
                                                </div>
                                                <div className="flex flex-col space-y-3 w-3/4">
                                                  <div className="flex flex-col space-y-1">
                                                    <Paragraph size="b5">
                                                      {notification?.title}
                                                    </Paragraph>
                                                    <Paragraph
                                                      size="b5"
                                                      color={COLORS.GREY[300]}
                                                    >
                                                      {limitString(
                                                        notification?.message,
                                                        40
                                                      )}
                                                    </Paragraph>
                                                  </div>
                                                  <div>
                                                    <Paragraph
                                                      size="b5"
                                                      weight="semiBold"
                                                      color={COLORS.GREY[300]}
                                                    >
                                                      {getTimeElapsed(
                                                        notification?.createdAt
                                                      )}
                                                    </Paragraph>
                                                  </div>
                                                </div>

                                                {notification.isActionable ? (
                                                  <Button
                                                    variant="secondary"
                                                    size="xs"
                                                    rounded="md"
                                                    type="button"
                                                    className=""
                                                    onClick={() =>
                                                      visitPage(
                                                        notification?.contentType,
                                                        notification?.contentId
                                                      )
                                                    }
                                                  >
                                                    View{' '}
                                                    <SvgCarbonView
                                                      width="14px"
                                                      height="14px"
                                                    />
                                                  </Button>
                                                ) : null}
                                              </div>
                                            )
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
              <Banner banners={user?.business?.banners} />
            </div>
            <div className="w-full h-[calc(100vh_-_80px)] xl:h-[calc(100vh_-_120px)] scroll-smooth overflow-y-scroll">
              {props.children}
            </div>
          </div>
          {isMobileSideMenuOpen && (
            <div className="fixed top-[80px] h-[calc(100vh_-_80px)] bg-white z-20 w-full xl:hidden px-4 py-10 flex flex-col justify-between overflow-y-auto space-y-20">
              <NavBar />
            </div>
          )}
        </main>
      </Sentry.ErrorBoundary>
    </>
  );
};

export default Layout;
