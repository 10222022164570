import { useMutation, useQuery, gql } from "@apollo/client";
import { AppointmentListConnection, AppointmentListSummaryReport, AppointmentOverviewByService, AppointmentOverviewByStaff, AppointmentOverviewSummaryReport, CancelledAppointments, CancelledAppointmentsConnection, CancelledAppointmentSummaryReport, ClientOverviewConnection, ClientOverviewSummaryReport, ClientRetentionConnection, ExecutiveSummaryReport, GraphData, LocationPerformanceSummary, PackagesOverviewConnection, PackagesOverviewSummaryReport, PackagesSalesConnection, Product, PromoActivityConnection, PromoActivitySummaryReport, Review, SalesOverviewByProduct, SalesOverviewByServiceConnection, SalesOverviewByStaffMember, SalesOverviewSummaryReport, StaffAppointmentSummary, StaffAppointmentSummaryConnection, StaffAppointmentSummaryType, StaffRoster, StaffRosterConnection, StaffRosterSummaryReport, StockFlowReport, StockMovementLogReport, TotalSalesGeneralOverviewSummaryReport, TotalSalesSummary, TotalSalesSummaryReport, VoidedSalesReport } from "core/generated";
import { useMemo } from "react";
import { TipsReport } from "./types/general";

const EXECUTIVE_SUMMARY = gql`
  query ExecutiveSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    executiveSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      averageRating
      productsSalesQuantity
      productsSold
      servicesSalesQuantity
      servicesSold
      totalClients
      totalRevenue
      totalSales
      voucherSalesQuantity
      vouchersSold
      productsSummary {
        grossSales
        netSales
        taxes
      }
      returningClientsGraphData {
        category
        label
        value
      }
      saleProductTrendGraphData {
        category
        label
        value
      }
      saleServiceTrendGraphData {
        category
        label
        value
      }
      servicesSummary {
        grossSales
        netSales
        taxes
      }
      vouchersSummary {
        grossSales
        netSales
        taxes
      }
      appointmentTrendGraphData {
        category
        label
        value
      }
      appointmentsPieChartData {
        category
        label
        value
      }
      newClientsGraphData {
        category
        label
        value
      }
      appointmentTrend
      saleTrend
      averageRating
    }
  }
`;

export const useExecutiveSummary = (
  salonId: string,
  startDate: string,
  endDate: string
) => {
  return useQuery<{
    executiveSummaryReport: ExecutiveSummaryReport;
  }>(EXECUTIVE_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
};

const LOCATION_PERFORMANCE_SUMMARY = gql`
  query LocationPerformanceSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    locationPerformanceSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      productsSalesQuantity
      productsSold
      servicesSalesQuantity
      servicesSold
      voucherSalesQuantity
      vouchersSold
      productsSummary {
        grossSales
        netSales
        taxes
      }
      servicesSummary {
        grossSales
        netSales
        taxes
      }
      vouchersSummary {
        grossSales
        netSales
        taxes
      }
    }
  }
`;

export const useLocationPerformanceSummary = (
  salonId: string,
  startDate: string,
  endDate: string
) => {
  const shouldSkip = useMemo(() => {
    return !salonId || salonId.trim() === '';
  }, [salonId]);

  return useQuery<{
    locationPerformanceSummaryReport: LocationPerformanceSummary;
  }>(LOCATION_PERFORMANCE_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: shouldSkip,
  });
};

const BUSINESS_PERFORMANCE_SUMMARY = gql`
  query BusinessPerformanceMonthlySummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    businessPerformanceMonthlySummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      monthlySummaryGraphData {
        category
        label
        value
      }
    }
  }
`;

export const useBusinessPerformanceSummary = (
  salonId: string,
  startDate: string,
  endDate: string
  ) => {
  return useQuery<{
    businessPerformanceMonthlySummaryReport: {
      monthlySummaryGraphData: GraphData[];
    }
  }>(BUSINESS_PERFORMANCE_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
};

const CUSTOMER_SPEND_REPORT = gql`
  query CustomerSpendReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    customerSpendReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        amount
        customer
        date
        paymentMethod
        phone
        promo
        service
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const useCustomerSpend = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
}) => {
  return useQuery<{
    customerSpendReport: {
      nodes: {
        amount: number,
        customer: string,
        date: string,
        paymentMethod: string,
        phone: string,
        promo: string,
        service: string,
      }[],
      pageInfo: {
        endCursor: string,
        hasNextPage: boolean,
        hasPreviousPage: boolean,
        startCursor: string,
      }
    };
  }>(CUSTOMER_SPEND_REPORT, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
    },
    skip: !salonId,
  });
}

const CUSTOMER_SPEND_SUMMARY = gql`
  query CustomerSpendSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    customerSpendSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      clientWithHighestSpend
      highestSpend
      totalSpend
      spendTrend
      totalSpend
      productsSpendGraphData {
        category
        label
        value
      }
      servicesSpendGraphData {
        category
        label
        value
      }
      spendGraphData {
        category
        label
        value
      }
    }
  }
`;

export const useCustomerSpendSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    customerSpendSummaryReport: {
      clientWithHighestSpend: string,
      highestSpend: number,
      totalSpend: string,
      productsSpendGraphData: GraphData[],
      servicesSpendGraphData: GraphData[],
      spendGraphData: GraphData[],
      spendTrend: number,
    };
  }>(CUSTOMER_SPEND_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const REVIEWS_REPORT = gql`
  query ReviewsReport(
    $salonId: ID!
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    reviewsReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        appointmentId
        createdAt
        id
        message
        salonId
        stars
        updatedAt
        client {
          addedById
          address
          amountSpent
          businessId
          createdAt
          deletedAt
          dob
          id
          lastVisit
          pointsEarned
          salonId
          totalTransactions
          updatedAt
          altCallingCode
          altCountryCode
          altPhone
          callingCode
          countryCode
          email
          firstName
          lastName
          phone
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const useReviewsReport = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
}) => {
  return useQuery<{
    reviewsReport: {
      nodes: Review[],
      pageInfo: {
        endCursor: string,
        hasNextPage: boolean,
        hasPreviousPage: boolean,
        startCursor: string,
      }
    };
  }>(REVIEWS_REPORT, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
    },
    skip: !salonId,
  });
}

const REVIEWS_SUMMARY = gql`
  query ReviewsSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    reviewsSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      averageRating
      reviewTrend
      totalReviews
      ratingsGraphData {
        label
        value
      }
      ratingsTrendGraphData {
        label
        value
      }
    }
  }
`;

export const useReviewSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    reviewsSummaryReport: {
      averageRating: number,
      reviewTrend: number,
      totalReviews: number,
      ratingsGraphData: GraphData[],
      ratingsTrendGraphData: GraphData[],
    };
  }>(REVIEWS_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const STOCK_ON_HAND = gql`
  query StockOnHandReport(
    $salonId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $q: String
  ) {
    stockOnHandReport(
      salonId: $salonId
      after: $after
      before: $before
      first: $first
      last: $last
      q: $q
    ) {
      nodes {
        id
        name
        category {
          id
          name
        }
        isPurchasable
        description
        stockCount
        retailPrice
        status
        costPrice
        expirationDate
        lowStockThreshold
        imageUrl
        salonId
        totalValue
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const useStockOnHand = ({
  salonId,
  after,
  before,
  first,
  last,
  q,
}: {
  salonId: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
  q?: string,
}) => {
  return useQuery<{
    stockOnHandReport: {
      nodes: Product[],
      pageInfo: {
        endCursor: string,
        hasNextPage: boolean,
        hasPreviousPage: boolean,
        startCursor: string,
      }
    };
  }>(STOCK_ON_HAND, {
    variables: {
      salonId,
      after,
      before,
      first,
      last,
      q,
    },
    skip: !salonId,
  });
}

const STOCK_ON_HAND_SUMMARY = gql`
  query StockOnHandSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    stockOnHandSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      stockAvailable
      totalRetailPrice
      totalValue
    }
  }
`;

export const useStockOnHandSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    stockOnHandSummaryReport: {
      stockAvailable: number,
      totalRetailPrice: number,
      totalValue: number,
    };
  }>(STOCK_ON_HAND_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const STOCK_FLOW = gql`
  query StockFlowReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $q: String
  ) {
    stockFlowReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
      q: $q
    ) {
      nodes {
        currentStock
        initialStock
        productName
        stockAdjusted
        stockReceived
        stockSold
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const useStockFlow = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
  q,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
  q?: string,
}) => {
  return useQuery<{
    stockFlowReport: {
      nodes: StockFlowReport[],
      pageInfo: {
        endCursor: string,
        hasNextPage: boolean,
        hasPreviousPage: boolean,
        startCursor: string,
      }
    };
  }>(STOCK_FLOW, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
      q,
    },
    skip: !salonId,
  });
}

const STOCK_FLOW_SUMMARY = gql`
  query StockFlowSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    stockFlowSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      stockAvailable
      stockReceived
      stockSold
    }
  }
`;

export const useStockFlowSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    stockFlowSummaryReport: {
      stockAvailable: number,
      stockReceived: number,
      stockSold: number,
    };
  }>(STOCK_FLOW_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const STOCK_MOVEMENT_LOG_SUMMARY = gql`
  query StockMovementLogSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    stockMovementLogSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      stockAdjusted
      stockAvailable
    }
  }
`;

export const useStockMovementSummaryLog = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    stockMovementLogSummaryReport: {
      stockAdjusted: number,
      stockAvailable: number,
    };
  }>(STOCK_MOVEMENT_LOG_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const STOCK_MOVEMENT_LOGS = gql`
  query StockMovementLogReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $q: String
  ) {
    stockMovementLogReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
      q: $q
    ) {
      nodes {
        dateOfMovement
        productName
        actionType
        adjustmentReason
        quantityAdjusted
        unitCost
        location
        quantityLeft
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const useStockMovementLogs = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
  q,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
  q?: string,
}) => {
  return useQuery<{
    stockMovementLogReport: {
      nodes: StockMovementLogReport[],
      pageInfo: {
        endCursor: string,
        hasNextPage: boolean,
        hasPreviousPage: boolean,
        startCursor: string,
      }
    };
  }>(STOCK_MOVEMENT_LOGS, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
      q,
    },
    skip: !salonId,
  });
}

const STAFF_ROSTER = gql`
  query StaffRosterReport(
    $salonId: ID!
    $staffId: ID
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    staffRosterReport(
      salonId: $salonId
      staffId: $staffId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        staff
        totalDuration
        schedule {
          closeTime
          date
          duration
          isWorking
          openTime
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const useStaffRoster = ({
  salonId,
  staffId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
}: {
  salonId: string,
  staffId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
  }) => {
  return useQuery<{
    staffRosterReport: StaffRosterConnection;
  }>(STAFF_ROSTER, {
    variables: {
      salonId,
      staffId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
    },
    skip: !salonId,
  });
}

const STAFF_ROSTEER_SUMMARY_REPORT = gql`
  query StaffRosterSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    staffRosterSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      activeStaff
      totalHoursWorked
      totalStaff
      staffTrend
      activeStaffGraphData {
        category
        label
        value
      }
      currentStaffTrend {
        data
        dateRange
      }
      totalStaffGraphData {
        category
        label
        value
      }
    }
  }
`;

export const useStaffRosterSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    staffRosterSummaryReport: StaffRosterSummaryReport;
  }>(STAFF_ROSTEER_SUMMARY_REPORT, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const STAFF_APPOINTMENT_SUMMARY_REPORTS = gql`
  query StaffAppointmentSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    staffAppointmentSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        staffMember
        service
        date
        status
        price
        paid
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const useStaffAppointmentSummaryReports = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
}) => {
  return useQuery<{
    staffAppointmentSummaryReport: StaffAppointmentSummaryConnection;
  }>(STAFF_APPOINTMENT_SUMMARY_REPORTS, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
    },
    skip: !salonId,
  });
}

const STAFF_APPOINTMENT_SUMMARY = gql`
  query StaffAppointmentSummary(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    staffAppointmentSummary(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      appointmentTrend
      mvpStaff
      ratingTrend
      totalAppointments
      totalStaff
      averagePerformanceGraphData {
        label
        value
      }
      bottomPerformingStaff {
        name
        services
        soldValue
      }
      topPerformingStaff {
        name
        services
        soldValue
      }
    }
  }
`

export const useStaffAppointmentSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    staffAppointmentSummary: StaffAppointmentSummaryType;
  }>(STAFF_APPOINTMENT_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const CLIENT_OVERVIEW = gql`
  query ClientOverviewReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    clientOverviewReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        appointments
        cancelled
        completed
        customer
        noShow
        outstandingPayments
        pointsEarned
        totalSales
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const useClietOverview = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
}) => {
  return useQuery<{
    clientOverviewReport: ClientOverviewConnection;
  }>(CLIENT_OVERVIEW, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
    },
    skip: !salonId,
  });
}

const CLIENT_OVERVIEW_SUMMARY = gql`
  query ClientOverviewSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    clientOverviewSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      newClients
      outstandingPayments
      totalAppointments
      totalClients
      totalPointsEarned
      mvpClient
    }
  }
`;

export const useClientOverviewSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    clientOverviewSummaryReport: ClientOverviewSummaryReport;
  }>(CLIENT_OVERVIEW_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const CLIENT_RETENTION_LIST = gql`
  query ClientRetentionReport(
      $salonId: ID!
      $startDate: ISO8601DateTime!
      $endDate: ISO8601DateTime!
      $after: String
      $before: String
      $first: Int
      $last: Int
    ) {
    clientRetentionReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        customer
        email
        lastAppointment
        lastServiceAmount
        phoneNumber
      }
    }
  }
`;

export const useClientsRetentionList = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
}) => {
  return useQuery<{
    clientRetentionReport: ClientRetentionConnection;
  }>(CLIENT_RETENTION_LIST, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
    },
    skip: !salonId,
  });
}

const RETENTION_SUMMARY = gql`
  query ClientRetentionReportSummary(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
      clientRetentionSummaryReport(salonId: $salonId, startDate: $startDate, endDate: $endDate) {
          totalClients
      }
  }
`

export const useClientRetentionSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    clientRetentionSummaryReport: {
      totalClients: number,
    };
  }>(RETENTION_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const PACKAGES_SALES = gql`
  query PackagseSalesReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $q: String
  ) {
    packagesSalesReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
      q: $q
    ) {
      nodes {
        packageName
        client
        servicesIncluded {
          name
          quantity
          total
        }
        dateOfPurchase
        dateOfLastUse
        price
        voucherCode
        voucherStatus
        totalServices
        voucherBalance
        servicesRemaining {
          name
          quantity
          total
        }
        location
        packagesSalesHistory {
          serviceName
          staffsAssigned
          dateOfUse
          location
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`

export const usePackageSalesReport = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
  q,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
  q?: string,
}) => {
  return useQuery<{
    packagesSalesReport: PackagesSalesConnection;
  }>(PACKAGES_SALES, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
      q,
    },
    skip: !salonId,
  });
}

const PACKAGES_OVERVIEW_SUMMARY = gql`
  query PackagesOverviewSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    packagesOverviewSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      mvpClient
      topSellingPackage
      totalOutstanding
      totalRedeemed
      totalSold
      totalSoldValue
    }
  }
`;

export const usePackageOverviewSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    packagesOverviewSummaryReport: PackagesOverviewSummaryReport;
  }>(PACKAGES_OVERVIEW_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const REDEEM_PACKAGES_REPORT = gql`
  query RedeemedPackagesReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $q: String
  ) {
    redeemedPackagesReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
      q: $q
    ) {
      nodes {
        packageName
        client
        servicesIncluded {
          name
          quantity
          total
        }
        dateOfPurchase
        dateOfLastUse
        price
        voucherCode
        voucherStatus
        totalServices
        voucherBalance
        servicesRemaining {
          name
          quantity
          total
        }
        location
        packagesSalesHistory {
          serviceName
          staffsAssigned
          dateOfUse
          location
        }
        staff
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`

export const useRedeemedPackagesSales = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
  q,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
  q?: string,
}) => {
  return useQuery<{
    redeemedPackagesReport: PackagesSalesConnection;
  }>(REDEEM_PACKAGES_REPORT, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
      q,
    },
    skip: !salonId,
  });
}

const PACKAGES_OVERVIEW_REPORT_LIST = gql`
  query PackagesOverviewReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $q: String
  ) {
    packagesOverviewReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
      q: $q
    ) {
      nodes {
        packageName
        sold
        redeemed
        outstanding
        soldValue
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`

export const usePackagesOverviewReportList = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
  q,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
  q?: string,
}) => {
  return useQuery<{
    packagesOverviewReport: PackagesOverviewConnection;
  }>(PACKAGES_OVERVIEW_REPORT_LIST, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
      q,
    },
    skip: !salonId,
  });
}

const PACKAGES_OVERVIEW_REPORT_SUMMARY = gql`
  query PackagesOverviewSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    packagesOverviewSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      mvpClient
      topSellingPackage
      totalOutstanding
      totalRedeemed
      totalSold
      totalSoldValue
    }
  }
`

export const usePackagesOverviewReportSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    packagesOverviewSummaryReport: PackagesOverviewSummaryReport;
  }>(PACKAGES_OVERVIEW_REPORT_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const APPOINTMENT_OVERVIEW_SUMMARY = gql`
  query AppointmentOverviewSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    appointmentOverviewSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      busiestDay
      cancelledAppointments
      completedAppointments
      mvpTeamMember
      saleTrend
      noShowAppointments
      totalAppointments
      appointmentProductsGraphData {
        label
        value
      }
      appointmentServiceGraphData {
        label
        value
      }
      appointmentCountGraphData {
        category
        label
        value
      }
    }
  }
`;

export const useAppointmentOverviewSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    appointmentOverviewSummaryReport: AppointmentOverviewSummaryReport;
  }>(APPOINTMENT_OVERVIEW_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const APPOINTMENT_OVERVIEW_STAFF = gql`
  query AppointmentOverviewReportByStaff(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    appointmentOverviewReportByStaff(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      all
      cancelled
      completed
      noShow
      staffMember
      value
    }
  }
`;

export const useAppointmentOverviewStaff = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    appointmentOverviewReportByStaff: AppointmentOverviewByStaff[];
  }>(APPOINTMENT_OVERVIEW_STAFF, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const APPOINTMENT_OVERVIEW_SERVICE = gql`
  query AppointmentOverviewReportByService(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    appointmentOverviewReportByService(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      appointments
      service
      value
    }
  }
`;

export const useAppointmentOverviewService = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    appointmentOverviewReportByService: AppointmentOverviewByService[];
  }>(APPOINTMENT_OVERVIEW_SERVICE, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const DELETED_APPOINTMENTS = gql`
  query DeletedAppointmentReport($salonId: ID!, $startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
    deletedAppointmentReport(salonId: $salonId, startDate: $startDate, endDate: $endDate) {
      appointmentDate
      cancelled
      cancelledAt
      customer
      email
      phoneNumber
      price
      service
      deletedAt
      deletedBy
    }
  }
`

export const useDeletedAppointments = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    deletedAppointmentReport: CancelledAppointments[];
  }>(DELETED_APPOINTMENTS, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const CANCELLED_APPOINTMENT_SUMMARY = gql`
  query CancelledAppointmentSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    cancelledAppointmentSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      lostRevenue
      totalCancelledAppointments
    }
  }
`;

export const useCancelledAppointmentSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    cancelledAppointmentSummaryReport: CancelledAppointmentSummaryReport;
  }>(CANCELLED_APPOINTMENT_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}



const CLIENT_CANCELLED_APPOINTMENT_SUMMARY = gql`
  query ClientCancelledAppointmentSummaryReport(
    $clientId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    clientCancelledAppointmentSummaryReport(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      lostRevenue
      totalCancelledAppointments
      totalWaived
      totalApplied
    }
  }
`;

export const useClientCancelledAppointmentSummary = ({
  clientId,
  startDate,
  endDate,
}: {
  clientId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    clientCancelledAppointmentSummaryReport: {
      lostRevenue: number;
      totalCancelledAppointments: number;
      totalWaived: number;
      totalApplied: number;
    };
  }>(CLIENT_CANCELLED_APPOINTMENT_SUMMARY, {
    variables: {
      clientId,
      startDate,
      endDate,
    },
    skip: !clientId,
  });
}

const CANCELLED_APPOINTMENTS_REPORT_LIST = gql`
  query CancelledAppointmentReport(
    $salonId: ID!
    $clientId: ID
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    cancelledAppointmentReport(
      salonId: $salonId
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        appointmentDate
        cancelled
        cancelledAt
        customer
        email
        phoneNumber
        price
        service
        status
        bookingId
        outstandingFee
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const useCancelledAppointmentList = ({
  salonId,
  clientId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
  clientId?: string,
}) => {
  return useQuery<{
    cancelledAppointmentReport: CancelledAppointmentsConnection;
  }>(CANCELLED_APPOINTMENTS_REPORT_LIST, {
    variables: {
      salonId,
      clientId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
    },
    skip: !salonId,
  });
}

const APPOINTMENT_LIST = gql`
  query AppointmentListReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    appointmentListReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        client
        createdAt
        duration
        price
        salon
        scheduledDate
        service
        status
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const useAppointmentList = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
}) => {
  return useQuery<{
    appointmentListReport: AppointmentListConnection;
  }>(APPOINTMENT_LIST, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
    },
    skip: !salonId,
  });
}

const APPOINTMENT_LIST_SUMMARY = gql`
  query AppointmentListSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    appointmentListSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      busiestDay
      mostBookedService
      totalAppointments
      totalDuration
    }
  }
`

export const useAppointmentReportSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    appointmentListSummaryReport: AppointmentListSummaryReport;
  }>(APPOINTMENT_LIST_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const PROMO_ACTIVITY = gql`
  query PromoActivityReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    promoActivityReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        promoName
        created
        ended
        status
        customers
        sales
        promoValue
        promoType
        promotionsApplied
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const usePromoActivity = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
}) => {
  return useQuery<{
    promoActivityReport: PromoActivityConnection;
  }>(PROMO_ACTIVITY, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
    },
    skip: !salonId,
  });
}

const PROMO_ACTIVITY_SUMMARY = gql`
  query PromoActivitySummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    promoActivitySummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      activePromos
      totalPromoRecipients
      totalPromos
    }
  }
`

export const usePromoActivitySummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    promoActivitySummaryReport: PromoActivitySummaryReport;
  }>(PROMO_ACTIVITY_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const SALEES_OVERVIEW_SUMMARY = gql`
  query SalesOverviewSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    salesOverviewSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      bestSellingProduct
      bookedServiceCount
      mostPopularService
      productsSold
      servicesSold
      soldProductCount
      totalGrossSales
      totalNetSales
    }
  }
`

export const useSalesOveerviewSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    salesOverviewSummaryReport: SalesOverviewSummaryReport;
  }>(SALEES_OVERVIEW_SUMMARY, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const SALES_OVERVIEW_BY_SERVICE = gql`
  query SalesOverviewByServiceReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    salesOverviewByServiceReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        discount
        grossSales
        itemSold
        netSales
        refunds
        service
        tax
        totalSales
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`

export const useSalesOverviewByService = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  after?: string,
  before?: string,
  first?: number,
  last?: number,
}) => {
  return useQuery<{
    salesOverviewByServiceReport: SalesOverviewByServiceConnection;
  }>(SALES_OVERVIEW_BY_SERVICE, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
    },
    skip: !salonId,
  });
}

const SALES_OVERVIEW_BY_PRODUCT = gql`
  query SalesOverviewByProductReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    salesOverviewByProductReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      discount
      grossSales
      itemSold
      netSales
      product
      refunds
      saleType
      tax
      totalSales
    }
  }
`;

export const useSalesOverviewByProduct = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    salesOverviewByProductReport: SalesOverviewByProduct[];
  }>(SALES_OVERVIEW_BY_PRODUCT, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const SALES_OVERVIEW_BY_STAFF = gql`
  query SalesOverviewByStaffReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    salesOverviewByStaffReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      staffMember
      services
      products
      promoDiscounts
      refunds
      totalSales
    }
  }
`;

export const useSalesOverviewByStaff = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    salesOverviewByStaffReport: SalesOverviewByStaffMember[];
  }>(SALES_OVERVIEW_BY_STAFF, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const VOIDED_SALES = gql`
  query VoidedSalesReport($endDate: ISO8601Date!, $startDate: ISO8601Date!, $salonId: ID!) {
    voidedSalesReport(endDate: $endDate, startDate: $startDate, salonId: $salonId) {
      client
      item
      price
      quantitySold
      saleDate
    }
  }
`

export const useVoidedSales = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    voidedSalesReport: VoidedSalesReport[];
  }>(VOIDED_SALES, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}


const OUTSTANDING_SALES = gql`
  query OutstandingSalesSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    outstandingSalesSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      outstandingSalesAmount
      outstandingSalesCount
    }
  }
`;

export const useOutstandingSalesSummary = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string;
  startDate: string;
  endDate: string;
}) => {
  return useQuery<{
    outstandingSalesSummaryReport: {
      outstandingSalesAmount: number;
      outstandingSalesCount: number;
    };
  }>(OUTSTANDING_SALES, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
  });
};

const OUTSTANDING_SALES_REPORTS = gql`
  query OutstandingSalesReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    outstandingSalesReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        customer
        service
        serviceDate
        checkInDate
        price
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const useOutstandingSalesReports = ({
  salonId,
  startDate,
  endDate,
  after,
  before,
  first,
  last,
}: {
  salonId: string;
  startDate: string;
  endDate: string;
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}) => {
  return useQuery<{
    outstandingSalesReport: {
      nodes: {
        customer: string;
        service: string;
        serviceDate: string;
        checkInDate: string;
        price: number;
      }[];
      pageInfo: {
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string;
      };
    };
  }>(OUTSTANDING_SALES_REPORTS, {
    variables: {
      salonId,
      startDate,
      endDate,
      after,
      before,
      first,
      last,
    },
  });
}

const SALES_REPORTS_MERGE = gql`
  query TotalSalesAndSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    totalSalesSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      totalGrossSales
      totalNetSales
      totalTaxes
      saleTrend
      productsTrendGraphData {
        category
        label
        value
      }
      servicesTrendGraphData {
        category
        label
        value
      }
    }
    
    totalSalesReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      discountTotal
      grossSales
      netSales
      refunds
      taxes
      totalSales
    }
  }
`

export const useTotalSalesAndTotalSummarySales = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    totalSalesSummaryReport: TotalSalesSummaryReport;
    totalSalesReport: TotalSalesSummary;
  }>(SALES_REPORTS_MERGE, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}

const TOTAL_GENERAL_SALES = gql`
  query TotalSalesGeneralOverviewSummaryReport(
    $salonId: ID
    $salonStaffId: ID
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    totalSalesGeneralOverviewSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      salonStaffId: $salonStaffId
    ) {
      grossSalesGraphData {
        category
        label
        value
      }
      netSalesGraphData {
        category
        label
        value
      }
      deductibles {
        category
        label
        value
      }
    }
  }
`

export const useTotalGeneralSales = ({
  salonId,
  startDate,
  endDate,
  salonStaffId,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
  salonStaffId?: string,
}) => {
  return useQuery<{
    totalSalesGeneralOverviewSummaryReport: TotalSalesGeneralOverviewSummaryReport;
  }>(TOTAL_GENERAL_SALES, {
    variables: {
      salonId,
      startDate,
      endDate,
      salonStaffId,
    },
    skip: !salonId,
  });
}

const GET_TIPS_REPORTS = gql`
  query TipsReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    tipsReport(salonId: $salonId, startDate: $startDate, endDate: $endDate) {
        dateReceived
        tipAmount
        client {
            firstName
            lastName
        }
        salonStaff {
            user {
                firstName
                lastName
            }
        }
    }
}
`;

export const useTipsReports = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string,
  startDate: string,
  endDate: string,
}) => {
  return useQuery<{
    tipsReport: TipsReport[];
  }>(GET_TIPS_REPORTS, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId,
  });
}