import { useResendEmailVerification } from 'api/useAccount'
import { COLORS } from 'constants/colors'
import { useUserCache } from 'hooks'
import React from 'react'
import { Button, Heading, Modal, Paragraph, SvgEmailSent, SvgLogo, ToastProps } from 'ui'

const VerifyEmailNoticeModal = ({ isVisible, closeModal, addToast }: {
  isVisible: boolean,
  closeModal: () => void,
  addToast: (toast: ToastProps) => void
}) => {
  const {
    resendEmailVerification,
    loading: isResendingEmailVerification
  } = useResendEmailVerification()
  const { getUserData } = useUserCache();
  const user = getUserData()

  const resendEmailVerificationAsync = () => {
    resendEmailVerification({
      variables: {
        input: {
          email: user?.email
        }
      }
    })
      .then(({ data }) => {
        const { resendEmailVerification } = data
        if (resendEmailVerification?.status === 200) {
          addToast({
            variant: 'success',
            message: resendEmailVerification?.message
          })
          closeModal()
        } else {
          addToast({
            variant: 'error',
            message: resendEmailVerification?.errors[0].message
          })
        }
      })
      .catch((error) => {
        addToast({
          variant: 'error',
          message: 'An error occured, kindly try again'
        })
      })

  }
  return (
    <Modal
      show={isVisible}
      closeModal={closeModal}
    >
      <div className='flex flex-col items-center space-y-9'>
        <div className='flex flex-col items-center space-y-3'>
          <SvgLogo width="60px" height="100%" />
          <Heading
            variant='h1'
            size='h8'
            weight='medium'
            className='w-full text-center'
          >
            Verify Email
          </Heading>
          <Paragraph
            size='b5'
            weight='medium'
            className='w-full text-center max-w-[400px]'
            color={COLORS.GREY[400]}
          >
            We have sent you an email to complete verification to secure your account.
          </Paragraph>
        </div>

        <div className='w-full max-w-[250px] max-h-[250px] flex justify-center items-center'>
          <SvgEmailSent width="100%" height="100%" />
        </div>

        <Button
          variant='primary'
          className=''
          size='lg'
          rounded='lg'
          loading={isResendingEmailVerification}
          disabled={isResendingEmailVerification}
          onClick={resendEmailVerificationAsync}
        >
          Resend Email
        </Button>
        <Button
          variant='secondary'
          className='mx-auto'
          size='sm'
          rounded='md'
          onClick={closeModal}
        >
          Close
        </Button>
      </div>
    </Modal>
  )
}

export default VerifyEmailNoticeModal